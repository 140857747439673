import React, { useState, useEffect, useRef, useCallback } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import Select from "react-select";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { Modal } from "bootstrap";
import Header from '../../../Components/Header/headerView';
import Footer from '../../../Components/Footer/footer';
import Menu from '../../../Components/Menu/menu';
import NDSTooltip from "../../../Components/Tooltip/ndsTooltip";
import Paginator from "../../../Components/Paginator/paginator";
import { connect } from "react-redux";
import FileSaver from "file-saver";
import { OpenRequest, CommentModal, RequestCreatedModal } from '../../../Utils/modals';
import { entityName, entitySynonyms } from "../../../Utils/regexValidations"; 
import * as dateService from "../../../Utils/format/date";
import * as interactionService from "../../../Services/interaction";
import entitiesService from "../../../Services/entitiesService";
import categoriesService from "../../../Services/categoriesService";
import requestsService from "../../../Services/requestsService";
import { set } from "firebase/database";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../../Components/Loader/actions";
import { v4 as uuidv4 } from "uuid";

const EditEntity = (props) => {
  const workspace = props.workspace.id;
  const user = props.user;
  const role = props.user.role;
  const workspace_engine = props.workspace.workspace;

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("1");
  const [loadData, setLoadData] = useState(true);
  const [entity, setEntity] = useState({});
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const [newChanges, setNewChanges] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [inputName, setInputName] = useState("");
  const [inputDescription, setInputDescription] = useState("");
  const [inputSynonym, setInputSynonym] = useState("");
  const [synonymEdit, setSynonymEdit] = useState(false);
  const [originalListSynonyms, setOriginalListSynonyms] = useState([]);
  const [categoryDescription, setCategoryDescription] = useState("");
  const [checked, setChecked] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [synonymsList, setSynonymsList] = useState([]);
  const [synonym, setSynonym] = useState("");
  const [changes, setChanges] = useState([]);
  const [moveStash, setMoveStash] = useState({});
  const [showedExamples, setShowedExamples] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(4);
  const itemsPerPage = 5;
  const deleteRef = useRef({});
  const cancelRef = useRef({});
  const [addedContent, setAddedContent] = useState([])
  const [loadAutoSuggestions, setLoadAutoSuggestions] = useState(true)
  const selectEntityModalRef = useRef({});
  const [adminNextStatus, setAdminNextStatus] = useState("Select");
  const [errorMessage, setErrorMessage] = useState("Something went wrong. Please try again.");
  const [validationError, setValidationError] = useState(false);
  const [nameError, setNameError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [categoryError, setCategoryError] = useState(null);
  const [descriptionCatError, setDescriptionCatError] = useState(null);
  const [selectedSynonyms, setSelectedSynonyms] = useState([]);
  const dispatch = useDispatch();

  const removeMoveHof = (customFunc, elements, ignore) => {
    if (!elements) customFunc(elements);
    if (!Array.isArray(elements)) throw Error("Elements is not an array");
    let _moveStash = JSON.parse(JSON.stringify(moveStash));
    elements.forEach((c) => {
      if (c._id in _moveStash && !ignore.map((e) => e._id).includes(c._id))
        delete _moveStash[c._id];
    });
    if (JSON.stringify(_moveStash) !== JSON.stringify(moveStash)) {
      setMoveStash(_moveStash);
    }
    customFunc(elements);
  };

  //Validations
  //nameTest but with spaces
  const namePrefixTest = /^(?!sys-|sys_)/; //Name can not begin with the prefix "sys-" or "luis-"

  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined
      );
  const required = (value) => (value ? undefined : "Required");
  const requiredCategory = (value) =>
    selectedCategory && selectedCategory.length > 0 ? undefined : "Required";
  const maxLength = (max) => (value) =>
    value && value.length > max
      ? `Must be ${max} characters or less`
      : undefined;
  const minLength = (min) => (value) =>
    value && value.length < min
      ? `Must be ${min} characters or more`
      : undefined;
  const name = (value) =>
    value && !entityName.test(value) ? "Invalid name" : undefined;
  const namePrefix = (value) =>
    value && !namePrefixTest.test(value) ? "Invalid name" : undefined;
  const categoryName = (value) =>
    value && value.length > 0 && console.log(value)
      ? "Invalid name"
      : undefined;
  const syn = (value) =>
    value && !entitySynonyms.test(value) ? "Invalid synonym" : undefined;
  const synonymExists = (value) =>
    value === inputSynonym && isSynonymInList(value)
      ? "Synonym already exists"
      : undefined;
  
  const handleChangeError = (event, field, initial, type) => {
    console.log("Event:", event);
    let newValue = "";
    if (field === "category"){
      if (event.length < 1){
        setCategoryError(true);
      console.log("Error:", true)
      }
      else{
        setCategoryError(false);
        console.log("Error:", false)
      }
    }
    else{
      if(initial){
        newValue = event;
      }
      else{
        newValue = event.target.value;
      }
    }
    if (field === "name"){
      const error = composeValidators(
        required,
        name,
        namePrefix,
        maxLength(64),
        minLength(3)
      )(newValue);
      setNameError(error);
      console.log("Error:", error)
    }
    else if (field === "description"){
      if(type === "category"){
        const error = composeValidators(
          maxLength(128)
        )(newValue);
        setDescriptionCatError(error);
      }
      else{
        const error = composeValidators(
          maxLength(128)
        )(newValue);
        setDescriptionError(error);
      }
    }
  };

  useEffect(() => {
    if (nameError || descriptionError || categoryError) {
      setValidationError(true);
      console.log("hay error");
    }
    else{
      setValidationError(false);
      console.log("no hay error");
    }
    
  }, [nameError, descriptionError, categoryError]);

  useEffect(() => {
    if (selectedCategory.length < 1){
      setCategoryError(true);
    }
    else if (selectedCategory.length > 0){
      setCategoryError(false);
    }
  }, [selectedCategory]);


  const synonymName = (value) =>
      value === inputSynonym && isSynonymNameDifferent(value)
          ? "Example synonym can't be same as Entity name"
          : undefined;


  //Check if the example is already in the list
  const isSynonymInList = (synonym) => {
    let found = false;
    if (synonym === "") return found;
    synonymsList.forEach((item) => {
      console.log(item);
      let synonymClean = synonym.replace(/ /g, "").replace(/\t/g, "");
      let itemClean = item.value.replace(/ /g, "").replace(/\t/g, "");
      if (itemClean.toLowerCase() === synonymClean.toLowerCase()) {
        found = true;
      }
    });
    return found;
  };

  const isSynonymNameDifferent = (name) => {
    let different = false;
    if (name === "") return different;
    let exampleClean = name.replace(/ /g, "").replace(/\t/g, "");
    return different;
  };

  const selectAdapter = ({ input, meta, ...rest }) => (
    <>
      <Select
        {...input}
        {...rest}
        id="category"
        className="creatable-select w-100"
        placeholder="Select categories"
        required
        isClearable
        isMulti
        options={categories}
        value={selectedCategory}
        onChange={(e) => [setSelectedCategory(e), input.onChange(e), handleSelectChange(e), handleChangeError(e, "category")]}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,

          control:
            meta?.error && meta?.touched
              ? (base, state) => ({
                  ...base,
                  borderColor: "#dc3545",
                  boxShadow: "none",
                  "&:hover": {
                    borderColor: "#dc3545",
                  },
                })
              : (base, state) => ({
                  ...base,
                  borderColor: "#ced4da",
                  boxShadow: "none",
                  "&:hover": {
                    borderColor: "#ced4da",
                  },
                }),
          colors: {
            ...theme.colors,
            primary25: "#e9ecef",
            primary: meta?.error && meta?.touched ? "#dc3545" : "#007bff",
            neutral90: meta?.error && meta?.touched ? "#dc3545" : "#495057",
          },
          height: "100%",
        })}
      />
      <label
        htmlFor="category"
        data-toggle="tooltip"
        title="Category names must be unique and can only contain alphanumeric characters, underscores, hyphens, and dots. Maximum length is 64 characters."
        style={meta?.error ? { color: "red" } : null}
      >
        {meta.error ? (
          <span className="error">
            <i className="fas fa-exclamation-circle fa-sm"></i>
            You must add at least 1 category
          </span>
        ) : (
          "Category"
        )}
      </label>
    </>
  );

  const [sort, setSort] = useState({});

  const setListSynonymsWithID = (synonyms) => {
    synonyms?.forEach((e) => {
      e.type = e.type || undefined;
      e._id = e._id || uuidv4();
    });
    let newSynonyms = synonyms?.map((e) => Object.entries(e).sort().toString());
    let oldSynonyms = synonymsList?.map((e) =>
      Object.entries(e).sort().toString()
    );
    let ignore = synonymsList.filter((e) =>
      newSynonyms
        ?.filter((x) => oldSynonyms?.includes(x))
        .includes(Object.entries(e).sort().toString())
    );
    removeMoveHof(setSynonymsList, synonyms, ignore);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const entityID = useParams().entity_id;

  async function getEntity(entityID) {
    dispatch(startLoading());
    let params = {
      workspace_id: workspace,
      entity_id: entityID,
      categories_names: true,
    };
    try{
      let res = await entitiesService.collectOne(params);
      setEntity(res.data);
      params = {
        workspace_id: workspace,
      };
      let options = await categoriesService.collectAll(params);
      console.log("Options", options.data.categories);
      console.log("Entity", res.data);
      setOriginals(res.data, formatOptions(options.data.categories));
      setSelectedCategory(formatOptions(res?.data?.category ?? []));
      setLoadData(false);
    }
    catch(err){
      console.log(err);
    }
    dispatch(stopLoading());
  }

  const formatOptions = (categories) => {
    let options = [];
    for (var i = 0; i < categories.length; i++) {
      options.push({
        value: categories[i].value || categories[i].id,
        label: categories[i].name,
      });
    }
    return options;
  };


  const handleSelectChange = (newValue) => {
    //setSelectedCategory(newValue);
    //Check if there are changes
    console.log("newva", newValue);
    console.log("current", selectedCategory);

    let oldCategories = selectedCategory; //entity.categories;
    console.log("Old", oldCategories);

    let addedCategories = newValue.filter(
      (e) => !oldCategories.find((x) => x.value === e.value)
    );

    let removedCategories = oldCategories.filter(
      (e) => !newValue.find((x) => x.value === e.value)
    );

    //let removedCategories = oldCategories.filter(e => !newValue.includes(e.value));
    console.log("added", addedCategories);
    console.log("deleted", removedCategories);

    addedCategories.forEach((e) => {
      console.log(e);
      if (e.type !== "delete") {
        e.type = "create";
        addCategory(e);
      } else if (e.type === "delete") {
        //e.type = null
        console.log("Recovering", e);
        recoverCategory(e);
      }
    });
    removedCategories.forEach((e) => {
      e.type = "delete";
      removeCategory(e);
    });
  };

  const setOriginals = (entity, categOptions) => {

    setInputName(entity.name);
    handleChangeError(entity.name, "name", true);

    setInputDescription(entity.description);
    handleChangeError(entity.description, "description", true);

    setListSynonymsWithID(entity.synonyms);
    //split synonyms and set the original listSynonims

    setOriginalListSynonyms(JSON.parse(JSON.stringify(entity.synonyms)));

    //Default categories
    let originalCategories = [];

    entity.category.forEach((category) => {
      categOptions?.find((option) => {
        if (option.id === category.value) {
          originalCategories.push(option);
        }
      });
    });

    setCategoryList(originalCategories);
    //Remove existing categories from options
    let newOptions = categOptions?.filter((option) => {
      return !originalCategories.includes(option);
    });
    setCategories(newOptions);
    console.log("Original", originalCategories);
    console.log("New", newOptions);
  };

  function addCategory(category) {
    entity.category.forEach((item) => {
      if (item.value === category.value) {
        recoverCategory(category);
        return;
      }
    });

    if (category.length > 0 || category.value) {
      console.log(category);
      let tempCategories = [...selectedCategory];

      handleChanges("categories", "add", category.value);
      let tempObj = {
        label: category.label,
        value: category.value,
        type: "create",
        newOne: true,
      };
      tempCategories.push(tempObj);
      //setCategoryList(tempCategories)
      setSelectedCategory(tempCategories);
    }
  }

  function removeCategory(category) {
    console.log(category);
    let tempCategories = [...selectedCategory];
    tempCategories.forEach((item) => {
      if (item.value === category.value) {
        if (category.type === "create") {
          item.previous = "create";
          item.recovered = null;
        }
        item.type = "delete";
        tempCategories = tempCategories.filter(
          (item) => item.value !== category.value
        );

        let tempChanges = newChanges;
        tempChanges.forEach((item, index) => {
          if (item.value_id === category.value) {
            tempChanges.splice(index, 1);
          }
        });
        setNewChanges(tempChanges);
      } else if (category.newOne) {
        tempCategories = tempCategories.filter(
          (item) => item.value !== category.value
        );
        //Remove from changes
        let tempChanges = newChanges;
        tempChanges.forEach((item, index) => {
          if (item.value_id === category.value) {
            tempChanges.splice(index, 1);
          }
        });
        setNewChanges(tempChanges);
      }
    });

    if (!categories.includes(category)) {
      let tempOptions = [...categories];
      tempOptions.push(category);
      setCategories(tempOptions);
    }

    setSelectedCategory(tempCategories);
    !category.newOne && handleChanges("categories", "delete", category.value);
  }

  function recoverCategory(category) {
    let undo = false;
    entity.category.forEach((item) => {
      if (item.value === category.value) {
        undo = true;
      }
    });
    newChanges.forEach((item) => {
      if (item.value === category.value && item.type === "delete") {
        undo = true;
      }
    });

    if (category.previous) {
      let tempCategories = [...selectedCategory];
      tempCategories.forEach((item) => {
        if (item.value === category.value) {
          item.type = category.previous;
        }
      });
      //setCategoryList(tempCategories)
      setSelectedCategory(tempCategories);
      //Remove from changes
      let tempChanges = newChanges;
      tempChanges.forEach((item, index) => {
        if (item.value_id === category.value) {
          tempChanges.splice(index, 1);
        }
      });
      setNewChanges(tempChanges);
      return;
    }

    if (undo && category.type === "delete") {
      let tempCategories = [...selectedCategory];
      tempCategories.forEach((item) => {
        if (item.value === category.value) {
          item.type = null;
        }
      });
      tempCategories.push(category);

      //setCategoryList(tempCategories)
      setSelectedCategory(tempCategories);
      //Remove from changes
      let tempChanges = newChanges;
      tempChanges.forEach((item, index) => {
        if (item.value_id === category.value) {
          tempChanges.splice(index, 1);
        }
      });
      setNewChanges(tempChanges);
    } else {
      let tempCategories = [...selectedCategory];

      tempCategories.forEach((item) => {
        if (item.value === category.value) {
          item.recovered = true;
        }
      });
      //setCategoryList(tempCategories)
      setSelectedCategory(tempCategories);

      let tempChanges = newChanges;
      tempChanges.push({
        field: "categories",
        value_id: category.value,
        new_op: "delete",
        undo: true,
      });
      setNewChanges(tempChanges);
    }
  }

  function saveName() {
    let nameValue = "";
    let alreadyEdited = false;
    console.log("Save name");
    //Check if the name object has stashed changes
    newChanges.find((change) => change.field === "name")
      ? (alreadyEdited = true)
      : (alreadyEdited = false);

    //If not in changes, add it
    if (alreadyEdited === false) {
      console.log("Not in changes");
      nameValue = inputName;
      let tempOldValue = "";
      if (changes?.edited?.name?.last_value !== undefined) {
        tempOldValue = changes?.edited?.name?.last_value;
      } else {
        tempOldValue = entity.name;
      }
      nameValue !== tempOldValue &&
    handleChanges("name", "update", nameValue, tempOldValue);
    handleChanges("name", "update", nameValue, tempOldValue);
    // }
        handleChanges("name", "update", nameValue, tempOldValue);
    // }

      //handleChanges('name', 'update', nameValue, tempOldValue)
    } else {
      //If in changes, update it
      console.log("Already in changes");
      nameValue = inputName;
      let tempChanges = [...newChanges];
      tempChanges.forEach((change) => {
        if (change.field === "name") {
          change.new_value = nameValue;
          change.value = nameValue;
        }
        if (change.new_value === change.last_value) {
          console.log("Remove from changes, same value");
          tempChanges = tempChanges.filter((item) => item.field !== "name");
        }
      });
      setNewChanges(tempChanges);
    }
  }

  function saveDescription(newOne = true) {
    let descriptionValue = "";
    console.log("Save description");
    let alreadyEdited = false;
    //Check if the description object has stashed changes
    newChanges.find((change) => change.field === "description")
      ? (alreadyEdited = true)
      : (alreadyEdited = false);

    //If not in changes, add it
    if (alreadyEdited === false) {
      console.log("Not in changes");
      descriptionValue = inputDescription;
      let tempOldValue = "";
      if (changes?.edited?.description?.last_value !== undefined) {
        tempOldValue = changes?.edited?.description?.last_value;
      } else {
        tempOldValue = entity.description;
      }
      descriptionValue !== tempOldValue &&
        handleChanges("description", "update", descriptionValue, tempOldValue);
    } else {
      //If in changes, update it
      console.log("Already in changes");
      descriptionValue = inputDescription;
      let tempChanges = [...newChanges];
      tempChanges.forEach((change) => {
        if (change.field === "description") {
          change.new_value = descriptionValue;
          //change.value=descriptionValue
        }
        if (change.new_value === change.last_value) {
          console.log("Remove from changes, same value");
          tempChanges = tempChanges.filter(
            (item) => item.field !== "description"
          );
        }
      });
      setNewChanges(tempChanges);
    }
  }

  const addSynonym = (e) => {
    if (inputSynonym !== "") {
      var tempObject = {
        value: inputSynonym,
        type: "create",
        newOne: true,
      };
      setListSynonymsWithID(synonymsList.concat(tempObject));
      handleChanges("synonyms", "add", inputSynonym);
      setInputSynonym("");
    }
  };

  const addSynonyms2 = (recommendations) => {

      setAddedContent([recommendations.length]);
      let listTempObjects = []
      recommendations.forEach((recommendation)=>{
      var tempObject = {
      value: recommendation,
      type: "create",
      newOne: true,
      };
      listTempObjects.push(tempObject)
  })
  setListSynonymsWithID(synonymsList.concat(listTempObjects));
      let changes = []
      recommendations.forEach((recommendation) => {
      
      changes.push({
            field: "synonyms",
            value: recommendation,
            new_op: "create",
          });
      setNewChanges([...newChanges, ...changes]);
      })
  };

  const handleChanges = (fieldType, operation, value, oldValue) => {
    let change = [];

    let oldOp = fieldType;

    if (operation === "delete" || operation === "update") {
      Object.entries(changes).forEach(([operation, fields]) => {
        if (fields[fieldType] !== undefined && fields[fieldType].length > 0) {
          fields[fieldType].forEach((item) => {
            if (item.value === value) {
              oldOp = operation;
            } else if (item.value_id === value) {
              oldOp = operation;
            }
          });
        }
      });
    }

    switch (operation) {
      case "add":
        if (fieldType === "categories") {
          change.push({
            field: fieldType,
            value_id: value,
            new_op: "create",
          });
        } else {
          change.push({
            field: fieldType,
            value: value,
            new_op: "create",
          });
        }
        break;
      case "delete":
        if (fieldType === "categories") {
          change.push({
            field: fieldType,
            value_id: value,
            new_op: "delete",
            old_op: oldOp,
            undo: false,
          });
        } else {
          change.push({
            field: fieldType,
            value: oldValue,
            new_op: "delete",
            old_op: oldOp,
            undo: false,
          });
        }
        break;
      case "update":
        if (
          !newChanges.some((item) => item.value === value) &&
          oldValue !== value
        ) {
          console.log("newField", newChanges);
          change.push({
            field: fieldType,
            value: oldValue,
            last_value: oldValue,
            new_value: value,
            new_op: "update",
            old_op: oldOp,
          });
        } else if (oldValue === value) {
          console.log("sameValue", newChanges);
          let tempChanges = newChanges;
          tempChanges.forEach((item, index) => {
            if (item.field === fieldType) {
              tempChanges.splice(index, 1);
            }
          }, tempChanges);
        } else {
          console.log("update", newChanges);
          let tempChanges = newChanges;
          tempChanges.forEach((item) => {
            if (
              item.field === fieldType &&
              item.new_op !== operation &&
              item.value !== value
            ) {
              //item.value = value
              item.new_value = value;
            }
          });
          setNewChanges(...tempChanges);
        }
        break;
      default:
        break;
    }
    setNewChanges([...newChanges, ...change]);
    console.log("newChanges", newChanges);
  };

  function handleEdition(synonym) {
    setInputSynonym(synonym.value);
    setSynonymEdit(synonym.value);
    synonym.type && (synonym.prevType = synonym.type);
    synonym.type = "update";
    let tempSynonyms = JSON.parse(JSON.stringify(synonymsList));
    tempSynonyms.forEach((item, index) => {
      if (item.value === synonym.value) {
        tempSynonyms[index].type = "update";
      }
    }, tempSynonyms);
    setListSynonymsWithID(tempSynonyms);
  }

  function deleteSynonym(synonym) {
    synonym.value instanceof Array &&
      (synonym.value = synonym.value[0].edition);

    let tempSynonyms = JSON.parse(JSON.stringify(synonymsList));
    tempSynonyms.forEach((item, index) => {
      if (item.value === synonym.value) {
        tempSynonyms[index].prevType = tempSynonyms[index].type;
        tempSynonyms[index].type = "delete";
        if (item.newOne) {
          if (synonym.type === "create")
          {
              setAddedContent([addedContent[0] -1])
          }
          tempSynonyms.splice(index, 1); //Remove if just added new one
          //Remove from the changes
          let tempChanges = newChanges;
          tempChanges.forEach((item, i) => {
            if (item.field === "synonyms" && item.value === synonym.value) {
              tempChanges.splice(i, 1);
            }
          }, tempChanges);
          setNewChanges(tempChanges);
        }
      }
    }, tempSynonyms);
    setListSynonymsWithID(tempSynonyms);
    !synonym.newOne &&
      handleChanges(
        "synonyms",
        "delete",
        synonym.value,
        synonym.editions?.length > 0
          ? synonym.editions[0].edition
          : synonym.value
      );
  }

  function recoverSynonym(synonym) {
    if (synonym._id in moveStash) {
      delete moveStash[synonym._id];
      setMoveStash({
        ...moveStash,
      });
      return;
    }
    //Check if synonym is in the original list
    let undo = false;
    let skipChange = false;

    entity.synonyms.forEach((item) => {
      if (item.value === synonym.value && !item.previous) {
        undo = true;
      }
    });

    newChanges.forEach((item) => {
      if (item.value === synonym.value && item.type === "delete") {
        undo = true;
      }
    });

    if (synonym.prevType) {
      let tempSynonyms = JSON.parse(JSON.stringify(synonymsList));
      tempSynonyms.forEach((item, index) => {
        if (item.value === synonym.value) {
          item.type = synonym.prevType;
          item.value instanceof Object && (item.value = item.value.edition);
        }
      }, tempSynonyms);
      setListSynonymsWithID(tempSynonyms);
      //Remove change
      let tempChanges = newChanges;
      tempChanges.forEach((item, index) => {
        if (item.field === "synonyms" && item.value === synonym.value) {
          tempChanges.splice(index, 1);
        }
      }, tempChanges);
      setNewChanges(tempChanges);
      return;
    }

    if (undo && synonym.type === "delete") {
      let tempSynonyms = JSON.parse(JSON.stringify(synonymsList));
      tempSynonyms.forEach((item, index) => {
        if (item.value === synonym.value) {
          item.type = null;
        }
      }, tempSynonyms);
      setListSynonymsWithID(tempSynonyms);
      //Remove change
      let tempChanges = newChanges;
      tempChanges.forEach((item, index) => {
        if (item.field === "synonyms" && item.value === synonym.value) {
          tempChanges.splice(index, 1);
        }
      }, tempChanges);
      setNewChanges(tempChanges);
      return;
    } else {
      let tempSynonyms = JSON.parse(JSON.stringify(synonymsList));
      tempSynonyms.forEach((item, index) => {
        if (item.value === synonym.value) {
          item.recovered = true;
        }
      }, tempSynonyms);
      setListSynonymsWithID(tempSynonyms);
      //Add change
      let tempChanges = newChanges;
      tempChanges.push({
        field: "synonyms",
        value: synonym.value,
        old_op: "delete",
        new_op: "delete",
        undo: true,
      });
      setNewChanges(tempChanges);
    }
  }

  function undoSynonymRecovery(synonym) {
    let tempSynonyms = JSON.parse(JSON.stringify(synonymsList));
    console.log("Undo recovery", synonym);
    tempSynonyms.forEach((item, index) => {
      if (item.value === synonym.value) {
        console.log(item);
        item.type = synonym.prevType ? synonym.prevType : "delete";
        item.recovered = false;
      }
    }, tempSynonyms);
    setListSynonymsWithID(tempSynonyms);
    //Remove change
    let tempChanges = newChanges;
    tempChanges.forEach((item, i) => {
      if (item.value === synonym.value) {
        tempChanges.splice(i, 1);
      }
    }, tempChanges);
    setNewChanges(tempChanges);
  }

  function editSynonym(synonym, previous) {
    let original = false;

    entity.synonyms.forEach((originalItem) => {
      if (originalItem.value === synonym) {
        original = true;
      }
    });

    console.log(original);
    let tempSynonyms = synonymsList;
    tempSynonyms.forEach((item, index) => {
      if (original) {
        item.value === synonym && (tempSynonyms[index].type = null);
        //Remove change
        let tempChanges = newChanges;
        tempChanges.forEach((item, index) => {
          if (item.field === "synonyms" && item.value === synonym) {
            tempChanges.splice(index, 1);
          }
        }, tempChanges);
        setNewChanges(tempChanges);
        setInputSynonym("");
        setSynonymEdit(false);
      } else if (
        item.type === "update" &&
        !item.previous &&
        item.value !== synonym
      ) {
        console.log("Editing", item.previous, synonym);
        tempSynonyms[index].value = synonym;
        tempSynonyms[index].previous = previous;
        //Add change
        handleChanges("synonyms", "update", synonym, previous);
        setInputSynonym("");
        setSynonymEdit(false);
      }
    }, tempSynonyms);
    setListSynonymsWithID(tempSynonyms);
  }

  function cancelEdition() {
    let editingSynonym = synonymEdit;
    let tempSynonyms = JSON.parse(JSON.stringify(synonymsList));
    //Check if the synonym is in the original list
    let original = false;
    entity.synonyms.forEach((originalItem) => {
      if (originalItem.value === inputSynonym) {
        original = true;
      }
    });

    if (!original) {
      console.log("Original synonym");
      tempSynonyms.forEach((item, index) => {
        if (item.type === "update") {
          tempSynonyms[index].type = item.prevType;
          //tempSynonyms[index].previous = null
          editingSynonym = false;
        }
      }, tempSynonyms);
      setListSynonymsWithID(tempSynonyms);
      setInputSynonym("");
      setSynonymEdit(false);
    } else {
      console.log("New synonym");
      tempSynonyms.forEach((item, index) => {
        if (item.type === "update") {
          tempSynonyms[index].type = item.prevType ? item.prevType : null;
          tempSynonyms[index].previous = null;
          editingSynonym = false;
        }
      }, tempSynonyms);
      setListSynonymsWithID(tempSynonyms);
      setInputSynonym("");
      setSynonymEdit(false);
    }
  }

  function undoSynonymEdit(synonym) {
    let tempSynonyms = JSON.parse(JSON.stringify(synonymsList));
    console.log("synonym list", tempSynonyms);
    console.log("Undo edit", synonym);
    let abortFunction = false;
    tempSynonyms.forEach((item, index) => {
      if (synonym.previous === item.value) {
        tempSynonyms.splice(index, 1);
        setInputSynonym("");
        setSynonymEdit(false);
        setListSynonymsWithID(tempSynonyms);
        let tempChanges = [...newChanges];
        tempChanges = tempChanges.filter((item) => item.value !== synonym.previous);
        console.log(tempChanges);
        setNewChanges(tempChanges);
        abortFunction = true;
    }
    else if (item.value === synonym.value) {
      tempSynonyms[index].type = item.prevType;
      tempSynonyms[index].value = synonym.previous;
      tempSynonyms[index].previous = null;
      tempSynonyms[index].recovered = false;
      tempSynonyms[index].prevType = null;
    }
    }, tempSynonyms);
    if (abortFunction) return;
    setListSynonymsWithID(tempSynonyms);
    setInputSynonym("");
    setSynonymEdit(false);
    //Remove change
    let tempChanges = newChanges;
    console.log(tempChanges)
    tempChanges.forEach((item, i) => {
      if (item.last_value === synonym.previous) {
        console.log("Remove change", item);
        tempChanges.splice(i, 1);
      }
    }, tempChanges);
    setNewChanges(tempChanges);
  }

  function undoSynonymCreate(synonym) {
    deleteSynonym(synonym);
  }


  function changesToPayload(){
    let changes = [];
    newChanges.forEach((change) => {
      if (change.field === "synonyms") {
        switch (change.new_op) {
          case "create":
            changes.push({
              field: "synonyms",
              operation: "create",
              value: change.value,
            });
            break;
          case "delete":
            changes.push({
              field: "synonyms",
              operation: "delete",
              value: change.value,
            });
            break;
          case "update":
            changes.push({
              field: "synonyms",
              operation: "delete",
              value: change.value,
            });
            changes.push({
              field: "synonyms",
              operation: "create",
              value: change.new_value,
            });
            break;
        }
      } 
      else if (change.field === "categories") {
        switch (change.new_op) {
          case "create":
            changes.push({
              field: "category",
              operation: "create",
              value: change.value_id,
            });
            break;
          case "delete":
            changes.push({
              field: "category",
              operation: "delete",
              value: change.value_id,
            });
            break;
        }
      }
      else if (change.field === "name") {
        changes.push({
          field: "name",
          operation: "update",
          value: change.new_value,
        });
      }
      else if (change.field === "description") {
        changes.push({
          field: "description",
          operation: "update",
          value: change.new_value,
        });
      }
    })

    let body = {
      object_id: entityID,
      object_type: "entity",
      "changes": changes
    }

    return body;
  }

  async function handleSave(comment) {
    let params = {
      workspace_id: workspace,
      user_id: user.id,
    }
    let body = changesToPayload();
    body.comments = comment;

    try{
      console.log("Params", params);
      console.log("Body", body);
      let res = await requestsService.create(params, body);
      console.log(res);
      let requestCreatedModal = new Modal(document.getElementById("requestCreatedModal"));
      requestCreatedModal.show();
    }
    catch(err){
      console.log(err);
    }
  }

  const handleCheck = (object) => {
    var newChecked = new Set(checked);
    var isChecked = newChecked.has(object?._id);
    isChecked ? newChecked.delete(object?._id) : newChecked.add(object?._id);
    if (allChecked && isChecked) {
      setAllChecked(false);
    }
    setChecked(newChecked);

    setSelectedSynonyms((prevSelectedSynonyms) => {
      const updatedSelectedSynonyms = [...prevSelectedSynonyms];
      const index = updatedSelectedSynonyms.findIndex((synonym) => synonym._id === object._id);
  
      if (index !== -1) {
        updatedSelectedSynonyms.splice(index, 1);
      } else {
        updatedSelectedSynonyms.push(object);
      }
      return updatedSelectedSynonyms;
    });

  };

  const handleAllCheck = (event, value) => {
    if (event.target.checked) {
      setChecked(new Set([...checked, ...synonymsList.map((e) => e._id)]));
      setSelectedSynonyms(synonymsList);
    } else {
      setChecked(new Set());
      setSelectedSynonyms([]);
    }
    setAllChecked(event.target.checked);
  };

  const handleCancelSelection = () => {
    // setChecked(new Set());
    // setAllChecked(false);
    if (Object.keys(cancelRef.current).length === 0) return;
    let original = JSON.parse(JSON.stringify(originalListSynonyms));
    [...checked].forEach((e) => {
      let originalIndex = original.findIndex((ee) => ee._id === e);
      if (originalIndex !== -1) {
        if (cancelRef.current[e] !== null) {
          interactionService.simulateMouseClick(cancelRef.current[e]);
        }
      } else {
        let currentIndex = synonymsList.findIndex((ee) => ee._id === e);
        selectedSynonyms.forEach((synonym, index) => {
          if (synonym._id === e) {
              selectedSynonyms.splice(index, 1);
          }
        })
        if (currentIndex !== -1) {
          interactionService.simulateMouseClick(cancelRef.current[e]);
        }
      }
    });
    setListSynonymsWithID(JSON.parse(JSON.stringify(synonymsList)));
  };

  const pruneChecked = useCallback(() => {
    let currentIds = new Set(synonymsList.map((e) => e._id));
    let newChecked = new Set([...checked].filter((i) => currentIds.has(i)));
    if (JSON.stringify(checked) !== JSON.stringify(newChecked))
      setChecked(newChecked);
  }, [checked, synonymsList]);

  useEffect(() => {
    pruneChecked();

    if (loadAutoSuggestions && synonymsList.length > 0)
    { 
        let recommendation_list = []
        entity?.automatic_suggestions?.forEach(
          suggestion => {
              recommendation_list.push(suggestion.recommendation)     
            }
        );
        if (recommendation_list.length > 0)
        { 
          addSynonyms2(recommendation_list)
         
        }

        setLoadAutoSuggestions(false)
        
    }
  }, [synonymsList, pruneChecked]);

  const handleDownloadSelection = (list) => {
    console.log(list);
    if (list.length === 0) {
      return;
    }
    const headers = ['Synonyms'];

    const csvData = list.map((synonym) => {
      const synonymsValue = synonym.new_value || synonym.value;
      return `${synonymsValue}`;
    });

    const csvContent = `${headers.join(',')}\n${csvData.join('\n')}`;
    const blob = new Blob([csvContent], {
      type: 'data:text/csv;charset=utf-8',
    });
    FileSaver.saveAs(blob, 'list.csv');
  };

  const handleDeleteSelection = () => {
    [...checked].forEach((e) => {
      if (e in deleteRef.current && deleteRef.current[e] !== null) {
        interactionService.simulateMouseClick(deleteRef.current[e]);
      }
      if (e in cancelRef.current && cancelRef.current[e] !== null) {
        interactionService.simulateMouseClick(cancelRef.current[e]);
      }
    });
  };

  useEffect(() => {
    if (loadData) {
      getEntity(entityID);
    }
  }, [loadData, entityID, synonym, selectedCategory, activeTab]);

  const isPristine = (synonym, includeAdding = false) => {
    return (
      synonym.type !== "delete" &&
      !synonymEdit &&
      (synonym.type !== "create" || !includeAdding) &&
      synonym.type !== "delete" &&
      synonym.type !== "update" &&
      !(synonym._id in moveStash)
    );
  };

  let newChecked = [...checked].filter((c) =>
    synonymsList.find((e) => e._id === c)
  );
  if (newChecked.length !== [...checked].length) {
    setChecked(new Set(newChecked));
  }

  useEffect(() => {
    const tempSynonymsList = [...synonymsList];
    let temp_array = [];
    for (let i = 0; i < tempSynonymsList.length; i++) {
      let example = tempSynonymsList[i];
      if (example.type === "create") {
        temp_array.push(example);
        tempSynonymsList.splice(i, 1);
        i--;
      }
    }
    const listSynonymsWAdded = temp_array.concat(tempSynonymsList);
    splitDetailsIntoState(listSynonymsWAdded, itemsPerPage);
    //splitDetailsIntoState(temp_array, itemsPerPage); // This logic was part of Show only changes, leaving it because i am not if it is needed
   }, [entity, synonymsList]);

  const splitArray = (array) => {
    const splitted = [];
    if (array) {
      for (let i = 0; i < array.length; i += itemsPerPage) {
        const chunk = array.slice(i, i + itemsPerPage);
        splitted.push(chunk);
      }
    }
    return splitted;
  };

  const splitDetailsIntoState = (examplesArr) => {
    if(examplesArr.length > itemsPerPage){
      const splittedExamples = splitArray(examplesArr);
      setTotalPages(splittedExamples.length);
      setShowedExamples(splittedExamples);    
    } else {
        setTotalPages([examplesArr].length);
        setShowedExamples([examplesArr]);
    }
  };

  return (
    <div className={`${props.className}`}>
      {/* // <!-- Modal error--> */}
      {/* // <!-- Modal approve--> */}
      <OpenRequest
        handleSave={handleSave}
      />
      <CommentModal handleSave={handleSave}/>
      <RequestCreatedModal />
      <Header />
      <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
        <div className="w-10">
          <Menu activeTab={3} />
        </div>
        <div className="container px-20 mt-20 mb-50">
          <Form
            onSubmit={() => {
              // new Modal(document.getElementById("approveModal")).show();
            }}
            render={({ form, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div>
                  <div className="row">
                    <div className="col-12 col-lg-9 my-20">
                      <div className="card-w -m mt-30">
                        <div className="row">
                          <div className="col-10 col-lg-6">
                             <p className="h3 -b">Edit entity</p>
                            <div className="form-group mt-40">
                              <Field
                                name="category"
                                id="category"
                                initialValue={selectedCategory}
                                className="creatable-select w-100"
                                validate={composeValidators(
                                  requiredCategory,
                                  categoryName
                                )}
                                component={selectAdapter}
                              />
                            </div>
                            <div
                              className="form-group mt-40"
                              style={{ display: "block" }}
                            >
                              <div className="col">
                                {entity?.name !== inputName && (
                                  <div className="row">
                                    <p className="label -edited mx-10">
                                      Edited
                                    </p>
                                    <p className="label -previous mx-10">
                                      {entity?.name}
                                    </p>
                                  </div>
                                )}
                              </div>
                              <Field
                                name="name"
                                initialValue={inputName}
                                validate={composeValidators(
                                  required,
                                  name,
                                  namePrefix,
                                  maxLength(64),
                                  minLength(3)
                                )}
                              >
                                {(props) => (
                                  <>
                                    <input
                                      {...props.input}
                                      type="text"
                                      name="name"
                                      placeholder={
                                        changes?.edited?.name?.new_value
                                      }
                                      autoComplete="off"
                                      required={true}
                                      id="name"
                                      value={inputName}
                                      onChange={(e) => [
                                        setInputName(e.target.value),
                                        props.input.onChange(e),
                                        handleChangeError(e, "name"),
                                      ]}
                                      onBlur={(e) => [
                                        saveName(),
                                        props.input.onBlur(e),
                                      ]}
                                      onLoad={(e) => [
                                        setInputName(e.target.value),
                                        props.input.onChange(e),
                                      ]}
                                      style={
                                        props.meta?.error
                                          ? { borderColor: "red" }
                                          : null
                                      }
                                    />
                                    <label
                                      htmlFor="name"
                                      data-toggle="tooltip"
                                      title="Entities can contain only Unicode alphanumeric, underscore, hyphen, and dot characters. It can not have spaces, be empty or be longer than 64 characters."
                                      style={
                                        props.meta?.error
                                          ? { color: "red" }
                                          : null
                                      }
                                    >
                                      {props.meta.error ? (
                                        <span className="error">
                                          <i className="fas fa-exclamation-circle fa-sm"></i>
                                          {props.meta.error}
                                        </span>
                                      ) : (
                                        "Entity name"
                                      )}
                                    </label>
                                  </>
                                )}
                              </Field>
                            </div>
                            <div
                              className="form-group mt-40"
                              style={{ display: "block" }}
                            >
                              <div className="col">
                                {(entity?.description !== inputDescription) && (
                                  <div className="row">
                                    <p className="label -edited mx-10">
                                      Edited
                                    </p>
                                    <p className="label -previous mx-10">
                                      {entity?.description}
                                    </p>
                                  </div>
                                )}
                              </div>
                              <Field
                                name="description"
                                initialValue={inputDescription}
                                validate={composeValidators(
                                  maxLength(128)
                                )}
                              >
                                {(props) => (
                                  <>
                                    <input
                                      {...props.input}
                                      type="text"
                                      name="description"
                                      placeholder={
                                        changes?.edited?.description?.new_value
                                      }
                                      autoComplete="off"
                                      id="description"
                                      value={inputDescription}
                                      onChange={(e) => [
                                        setInputDescription(e.target.value),
                                        props.input.onChange(e),
                                        handleChangeError(e, "description"),
                                      ]}
                                      onBlur={(e) => [
                                        saveDescription(),
                                        props.input.onBlur(e),
                                      ]}
                                      style={
                                        props.meta?.error
                                          ? { borderColor: "red" }
                                          : null
                                      }
                                    />
                                    <label
                                      htmlFor="description"
                                      data-toggle="tooltip"
                                      title="This string cannot contain carriage return, newline, or tab characters. It can not be empty or longer than 128 characters."
                                      style={
                                        props.meta?.error
                                          ? { color: "red" }
                                          : null
                                      }
                                    >
                                      {props.meta.error ? (
                                        <span className="error">
                                          <i className="fas fa-exclamation-circle fa-sm"></i>
                                          {props.meta.error}
                                        </span>
                                      ) : (
                                        "Entity description"
                                      )}
                                    </label>
                                  </>
                                )}
                              </Field>
                            </div>
                          </div>
                        </div>
                    </div>
                     
                    </div>
                      
                    <div className="col-12 col-lg-3 mt-40">
                      <div className="row">
                        <button
                          className={`button w-100 ${validationError ? '-inactive' : '-primary'}`}
                          data-bs-toggle="modal"
                          data-bs-target="#commentModal"
                          disabled={validationError}
                        >
                          CREATE REQUEST
                        </button>
                      </div>
                      <div className="row mt-10">
                        <button 
                            className="button -text w-100"
                            onClick={() => (window.location.href = `/detail/entity/${entityID}`)}
                        >
                            RETURN
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <Nav tabs className="col-12 d-flex">
                      <NavItem>
                        <NavLink
                          className={activeTab === "1" ? "active" : "inactive"}
                          onClick={() => {
                            toggle("1");
                          }}
                        >
                          <span>
                            <p className="icon">
                              <i className="fa-solid fa-lines-leaning"></i>
                            </p>
                            <p className="h3 -b">SYNONYMS</p>
                          </span>
                        </NavLink>
                        </NavItem>
                    </Nav>
                  </div>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <div className="row">
                        <div className="col-12 col-lg-9">
                          <div className="card-w -m my-40">
                            <div className="row mb-10">
                              <p className="h4">
                                Add synonyms to facilitate communication with
                                users.
                              </p>
                            </div>
                            <div className="row">
                              <div className="form-group mt-40">
                                {/* <input
                            type="text"
                            name="login"
                            placeholder="Ex. If you have Credit Card like entity you can use CC for synonym"
                            onChange={(e) => setInputSynonym(e.target.value)}
                            value={inputSynonym}
                            autoComplete="off"
                            required=""
                            id="id_login"
                          ></input>
                          <label htmlFor="id_login">Synonym</label> */}
                                <Field
                                  name="synonym"
                                  initialValue={inputSynonym}
                                  validate={composeValidators(
                                    syn,
                                    synonymExists,
                                    maxLength(64),
                                    synonymName
                                  )}
                                >
                                  {(props) => (
                                    <>
                                      <input
                                        {...props.input}
                                        type="text"
                                        name="synonym"
                                        placeholder="Ex. If you have Credit Card like entity you can use CC for synonym"
                                        autoComplete="off"
                                        required={false}
                                        id="synonym"
                                        value={inputSynonym}
                                        onChange={(e) => [
                                          setInputSynonym(e.target.value),
                                          props.input.onChange(e),
                                        ]}
                                        onBlur={(e) => props.input.onBlur(e)}
                                        onSubmit={(e) => [
                                          e.preventDefault(),
                                          addSynonym(e),
                                        ]}
                                        onKeyPress={(e) => [
                                          e.key === "Enter" &&
                                            !form.getFieldState("synonym")
                                              ?.error && [
                                              e.preventDefault(),
                                              addSynonym(e),
                                            ],
                                        ]}
                                        style={
                                          props.meta?.error
                                            ? { borderColor: "red" }
                                            : null
                                        }
                                      />
                                      <label
                                        htmlFor="synonym"
                                        data-toggle="tooltip"
                                        title="This string cannot contain spaces, carriage return, newline, or tab characters. It can not be empty or longer than 64 characters."
                                        style={
                                          props.meta?.error
                                            ? { color: "red" }
                                            : null
                                        }
                                      >
                                        {props.meta.error &&
                                        props.meta.touched ? (
                                          <span className="error">
                                            <i className="fas fa-exclamation-circle fa-sm"></i>
                                            {props.meta.error}
                                          </span>
                                        ) : (
                                          "Synonym"
                                        )}
                                      </label>
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>
                            <div className="row mt-20">
                              {!synonymEdit ? (
                                <div className="col-12 col-md-4 col-lg-3">
                                  <button
                                    className={`button w-100 -outline-primary -${
                                      inputSynonym &&
                                      !form.getFieldState("synonym").error
                                        ? "active"
                                        : "inactive"
                                    }`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (
                                        !synonymEdit &&
                                        !form.getFieldState("synonym")?.error
                                      )
                                        addSynonym(e);
                                    }}
                                    disabled={
                                      !inputSynonym &&
                                      !form.getFieldState("synonym")?.error
                                    }
                                  >
                                    ADD SYNONYM
                                  </button>
                                </div>
                              ) : (
                                <>
                                  <div className="col-12 col-md-4 col-lg-3">
                                    <button
                                      className="button w-100 -outline-secondary"
                                      onClick={(e) => [
                                        e.preventDefault(),
                                        cancelEdition(),
                                      ]}
                                    >
                                      CANCEL
                                    </button>
                                  </div>
                                  <div className="col-12 col-md-4 col-lg-3">
                                    <button
                                      className={`button w-100 -outline-primary -${
                                        inputSynonym &&
                                        !form.getFieldState("synonym").error
                                          ? "active"
                                          : "inactive"
                                      }`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (
                                          inputSynonym &&
                                          !form.getFieldState("synonym")?.error
                                        )
                                          editSynonym(
                                            inputSynonym,
                                            synonymEdit
                                          );
                                      }}
                                    >
                                      SAVE CHANGES
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>

                            <div className="row mt-50">
                              <div className="col-12">
                                <div className="bg-light">
                                  <div
                                    className="row justify-content-end"
                                    style={{
                                      visibility: [...checked].length
                                        ? "visible"
                                        : "hidden",
                                    }}
                                  >
                                    <div className="col-3 col-md-1 d-flex justify-content-center">
                                      <button
                                        className="button -text w-100"
                                        onClick={(e) => [
                                          e.preventDefault(),
                                          handleCancelSelection(e),
                                        ]}
                                      >
                                        <NDSTooltip
                                          icon="fa-solid fa-circle-xmark"
                                          target="cancel"
                                          tooltipMessage="Cancel"
                                          placement="bottom"
                                        ></NDSTooltip>
                                      </button>
                                    </div>
                                    
                                    <div className="col-3 col-md-1 d-flex justify-content-center">
                                      <button
                                        className="button -text w-100"
                                        onClick={(e) => [
                                          e.preventDefault(),
                                          handleDownloadSelection(
                                            selectedSynonyms
                                          ),
                                        ]}
                                      >
                                        <NDSTooltip
                                          icon="fa-solid fa-download"
                                          target="download"
                                          tooltipMessage="Download"
                                          placement="bottom"
                                        ></NDSTooltip>
                                        {/* <i className="fa-solid fa-download"></i> */}
                                      </button>
                                    </div>
                                    <div
                                      className="col-3 col-md-1 d-flex justify-content-center"
                                      style={{
                                        visibility:
                                          [...checked].length &&
                                          [...checked].reduce(
                                            (a, c) =>
                                              a &&
                                              isPristine(
                                                synonymsList.find(
                                                  (e) => e._id === c
                                                )
                                              ),
                                            true
                                          )
                                            ? "visible"
                                            : "hidden",
                                      }}
                                    >
                                      <button
                                        type="button"
                                        className="-text -red w-100"
                                        onClick={(e) => [
                                          e.preventDefault(),
                                          handleDeleteSelection(e),
                                        ]}
                                      >
                                        <NDSTooltip
                                          icon="fa-solid fa-trash-can"
                                          target="trash"
                                          tooltipMessage="Delete"
                                          placement="bottom"
                                        ></NDSTooltip>
                                        {/* <i className="fa-solid fa-trash-can"></i> */}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>
                                        <input
                                          className="form-check-input -white"
                                          type="checkbox"
                                          value=""
                                          id="flexCheckDefault"
                                          checked={allChecked}
                                          onChange={(event) =>
                                            handleAllCheck(event)
                                          }
                                        ></input>
                                      </th>
                                      <th>
                                        <p className="d-flex align-items-center w-100">
                                          Synonyms{" "}
                                          {/*<button className="i -white w-15">
                                            <i className="fa-solid fa-arrow-up-a-z"></i>
                                          </button>*/}
                                        </p>
                                      </th>
                                      <th className="row m-10 align-items-center">
                                        Actions
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {showedExamples && showedExamples.length > 0 ? (
                                      showedExamples[page - 1].map(
                                        (synonym, index) => (
                                        <tr key={index}>
                                          <td>
                                            <input
                                              className="form-check-input -blue"
                                              type="checkbox"
                                              value=""
                                              checked={checked.has(
                                                synonym?._id
                                              )}
                                              onChange={() =>
                                                handleCheck(synonym)
                                              }
                                            ></input>
                                          </td>
                                          <td>
                                            {synonym.type === "update" ? (
                                              <>
                                                <div className="row">
                                                  <p className="label -edited">
                                                    Edited
                                                  </p>
                                                </div>
                                                <div className="row">
                                                  <p className="label -previous">
                                                    {synonym.last_value}
                                                  </p>
                                                </div>
                                              </>
                                            ) : synonym.type === "update" ? (
                                              <>
                                                <div className="row">
                                                  <p className="label -edited">
                                                    Editing
                                                  </p>
                                                </div>
                                                <div className="row">
                                                  <p className="label -previous">
                                                    {synonym.previous}
                                                  </p>
                                                </div>
                                              </>
                                            ) : null}
                                            {synonym.type === "create" ? (
                                              <>
                                                <div className="row">
                                                  <p className="label -added">
                                                    Adding
                                                  </p>
                                                </div>
                                                <div
                                                style={{display:'none'}}
                                                ref={(el) =>
                                                  (cancelRef.current[
                                                    synonym._id
                                                  ] = el)
                                                }
                                                onClick={(e) => [
                                                  e.preventDefault(),
                                                  undoSynonymCreate(synonym),
                                                ]}
                                                >
                                                </div>
                                              </>
                                            ) : null}
                                            {synonym.type === "delete" ? (
                                              <div className="row">
                                                <p className="label -deleted">
                                                  Deleting
                                                </p>
                                              </div>
                                            ) : null}
                                            {synonym._id in moveStash ? (
                                              <div className="row">
                                                <p className="label -deleted">
                                                  Moving to{" "}
                                                  {
                                                    moveStash[synonym._id]
                                                      .element.label
                                                  }
                                                </p>
                                              </div>
                                            ) : null}
                                            {synonym.recovered === true ? (
                                              <div className="row">
                                                <p className="label -added">
                                                  Recovering
                                                </p>
                                              </div>
                                            ) : null}
                                            <div className="row">
                                              {synonym?.new_value
                                                ? synonym.new_value
                                                : synonym?.value}
                                            </div>
                                          </td>
                                          {/* <td>0</td> */}
                                          <td>
                                            <div className="row">
                                              {isPristine(synonym) ? (
                                                <>
                                                  {!synonym.newOne && (
                                                    <button
                                                      className="button -icon"
                                                      onClick={(e) => [
                                                        e.preventDefault(),
                                                        handleEdition(synonym),
                                                      ]}
                                                    >
                                                      <span className="material-icons-outlined">
                                                        <NDSTooltip
                                                          icon="fa-solid fa-pencil"
                                                          target="pencil"
                                                          tooltipMessage="Edit"
                                                          placement="bottom"
                                                        ></NDSTooltip>
                                                      </span>
                                                    </button>
                                                  )}
                                                  <button
                                                    className="button -icon -danger"
                                                    ref={(el) =>
                                                      (deleteRef.current[
                                                        synonym._id
                                                      ] = el)
                                                    }
                                                    onClick={() =>
                                                      deleteSynonym(synonym)
                                                    }
                                                  >
                                                    <span className="material-icons-outlined">
                                                      <NDSTooltip
                                                        icon="fa-solid fa-trash-can"
                                                        target="trash"
                                                        tooltipMessage="Delete"
                                                        placement="bottom"
                                                      ></NDSTooltip>
                                                    </span>
                                                  </button>
                                                </>
                                              ) : synonym?.recovered === true &&
                                                !synonymEdit &&
                                                synonym.type !== "delete" &&
                                                synonym.type != "update" ? (
                                                <div className="col-12 col-md-4">
                                                  <button className="button -icon -success">
                                                    <i className="fa-solid fa-check"></i>{" "}
                                                    Recovering
                                                  </button>
                                                  <button
                                                    className="button -primary"
                                                    ref={(el) => {
                                                      if (el) {
                                                        cancelRef.current[synonym._id] = el;
                                                      }
                                                    }}
                                                    onClick={(e) => [
                                                      e.preventDefault(),
                                                      undoSynonymRecovery(
                                                        synonym
                                                      ),
                                                    ]}
                                                  >
                                                    <i className="fa-solid fa-undo"></i>{" "}
                                                    Undo
                                                  </button>
                                                </div>
                                              ) : !synonymEdit &&
                                                synonym.type != "update" ? (
                                                <div>
                                                <button
                                                  className="button -icon "
                                                  ref={(el) =>
                                                    (cancelRef.current[
                                                      synonym._id
                                                    ] = el)
                                                  }
                                                  onClick={(e) => [
                                                    e.preventDefault(),
                                                    recoverSynonym(synonym),
                                                  ]}
                                                >
                                                  <i className="fa-solid fa-undo"></i>{" "}
                                                  Recover
                                                </button>
                                                </div>
                                              ) : (
                                                synonym.type === "update" &&
                                                !synonymEdit && (
                                                  <div>
                                                  <button
                                                    className="button -icon -undo"
                                                    ref={(el) =>
                                                      (cancelRef.current[
                                                        synonym._id
                                                      ] = el)
                                                    }
                                                    onClick={(e) => [
                                                      e.preventDefault(),
                                                      undoSynonymEdit(synonym),
                                                    ]}
                                                  >
                                                    <i className="fa-solid fa-check"></i>{" "}
                                                    UNDO Edition
                                                  </button>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan="5">No synonyms found</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="row mt-20">
                               <Paginator
                                pages={totalPages}
                                currentPage={page}
                                setPage={(page) => setPage(page)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </form>
            )}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    workspace: state.workspace,
    user: state.user,
  };
}

export default connect(mapStateToProps)(EditEntity);
