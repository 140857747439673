var mouseClickEvents = ['mousedown', 'click', 'mouseup'];
function simulateMouseClick(element) {
    setTimeout(function () {
        mouseClickEvents.forEach(function (mouseEventType) {
            return element.dispatchEvent(new MouseEvent(mouseEventType, {
                view: window,
                bubbles: true,
                cancelable: true,
                buttons: 1
            }));
        });
    }, 10);
}
var formatCreatableSelect = function (objects) {
    var options = [];
    for (var i = 0; i < objects.length; i++) {
        options.push({ value: objects[i]._id, label: objects[i].name.value, _id: objects[i]._id });
    }
    return options;
};
export { simulateMouseClick, formatCreatableSelect };
