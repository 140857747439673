import { START_REQUEST_LOADING, STOP_REQUEST_LOADING } from './actions';

const initialState = {
  isLoading: false,
};

const loadingRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_REQUEST_LOADING:
      return { ...state, isLoading: true };
    case STOP_REQUEST_LOADING:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default loadingRequestReducer;
