import React, { useState } from "react";
import Header from "../../../Components/Header/headerView";
import Footer from "../../../Components/Footer/footer";
import NDSTooltip from '../../../Components/Tooltip/ndsTooltip';
import Menu from "../../../Components/Menu/menu";
import { Link } from "react-router-dom";
import { ApproveModal, SavedModal, 
    CloseModal, RejectModal, CommentModal, DeployModal } from '../../../Utils/modals';
import classNames from "classnames";
import Paginator from "../../../Components/Paginator/paginator";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { Form, Field } from "react-final-form";
import Select from "react-select";
import { useNavigate } from "react-router-dom";

export const RequestsValidateScreen = ({_this}) => {
    const navigate = useNavigate();
return (
    <div className={`${_this?.props?.className}`}>
      {/* // <!-- Modal Delete--> */}
      {/* // <!-- Modal approve--> */}
      <ApproveModal
      />
      {/* // <!-- Modal saved--> */}
      <SavedModal />
      {/* // <!-- Modal close--> */}
      <CloseModal 
      />
      {/* // <!-- Modal reject--> */}
      <RejectModal 
      />
      {/* // <!-- Modal deploy--> */}
      <DeployModal
      />
      <CommentModal
        handleSave={_this.commentModalFunction}
      />
      <div
      className="modal fade"
      id="discardModal"
      tabIndex="1"
      aria-labelledby="rejectModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h2" id="rejectModalLabel">
              Discarded
            </p>
            <button
              type="button"
              className="button-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body d-flex flex-column align-items-center justify-content-center">
            <p className="text">
              This suggestion has been discarded.
            </p>
          </div>
          <div className="modal-footer d-flex flex-column align-items-center justify-content-center">
            <div className="row w-100">
                <button className="button -primary" data-bs-dismiss="modal" onClick={() => (navigate("/requests", { state: { tab: '4' , filter: 'inbox', checkbox: '5'} }))}>
                  CONTINUE
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="modal fade"
      id="recoverModal"
      tabIndex="1"
      aria-labelledby="recoverModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h2" id="rejectModalLabel">
              Recovered
            </p>
            <button
              type="button"
              className="button-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body d-flex flex-column align-items-center justify-content-center">
            <p className="text">
              This suggestion has been recovered and will apear in the Automatic Suggestions inbox tab.
            </p>
          </div>
          <div className="modal-footer d-flex flex-column align-items-center justify-content-center">
            <div className="row w-100">
                <button className="button -primary" data-bs-dismiss="modal" onClick={() => (navigate("/requests", { state: { tab: '4'} }))}>
                  CONTINUE
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>

            <Header />
            <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
                <div className="w-10">
                    <Menu activeTab={1} />
                </div>
                <div className="container px-20 mt-20 mb-50">
                    <div className="row">
                        <div className="col-12 col-lg-9 my-20">
                            {_this.requestComments && _this.requestComments !== "" && (
                                <>
                                <p className="card-g">
                                    <a
                                        className="w-100 d-flex justify-content-between"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseExample"
                                        aria-expanded="false"
                                        aria-controls="collapseExample"
                                    >
                                        <p className="h3 -b">Comments</p>
                                        <i className="fa-solid fa-circle-chevron-up color-blue"></i>
                                    </a>
                                </p>
                                    <div
                                        className="collapse"
                                        id="collapseExample"
                                    >
                                        <div className="card-w -m">
                                            <div className="row mb-20 mx-10">
                                                <div className="pipeline-history">
                                                    <div className="col-12">
                                                        <div className="event">
                                                            <div className="col-12 col-sm-1">
                                                                <div
                                                                    className="user-img -editor"
                                                                >
                                                                    <p className="user-name">
                                                                        {
                                                                            _this.lastUser?.first_name?.charAt(0)
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-11">
                                                                <div className="event-info">
                                                                    <p className="name">
                                                                        {" "}
                                                                        <b>
                                                                            {
                                                                                _this.lastUser?.first_name
                                                                            }{" "}
                                                                        </b>{" "}
                                                                    </p>
                                                                    <div className="d-flex">
                                                                        <p className="date">
                                                                            {_this.lastUpdate}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                            <p className="mb-0">
                                                                {
                                                                    _this.requestComments
                                                                }{" "}
                                                            </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </>
                            )}

                            <div className="card-w -m mt-20">
                                <div className="row mb-20">
                                    <p className="h3 -b">Request summary</p>
                                </div>
                                <div className="row mb-20 mx-10">
                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <p className="h4 -b">Type</p>
                                        <p className="text">{_this.objectTypeView}</p>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <p className="h4 -b">Source</p>
                                        <p className="text">{_this.requestSource}</p>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <p className="h4 -b">Status</p>
                                        <p className="text">{_this.requestStatus}</p>
                                    </div>
                                    </div>
                </div>
              </div>
              {(_this.addedContent.length > 0 ||
                _this.editedContent.length > 0 ||
                _this.deletedContent.length > 0) && (
                <div className="card-p">
                  <table>
                    <thead>
                      <tr>
                        <th>
                          {_this.addedContent.length > 0 ? (
                            <p className="added">Content added:</p>
                          ) : null}
                        </th>
                        <th>
                          {_this.editedContent.length > 0 ? (
                            <p className="edited">Content edited:</p>
                          ) : null}
                        </th>
                        <th>
                          {_this.deletedContent.length > 0 ? (
                            <p className="deleted">Content deleted:</p>
                          ) : null}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {_this.addedContent.map((item, index) => (
                            <ul>
                              <li key={index}>{item}</li>
                            </ul>
                          ))}
                        </td>
                        <td>
                          {_this.editedContent.map((item, index) => (
                            <ul>
                              <li key={index}>{item}</li>
                            </ul>
                          ))}
                        </td>
                        <td>
                          {_this.deletedContent.map((item, index) => (
                            <ul>
                              <li key={index}>{item}</li>
                            </ul>
                          ))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className="col-12 col-lg-3  d-flex flex-column justify-content-center">
                {(_this.displayValidate || _this.displayEdit) && (
                <div className="row">
                  <button
                    type="button"
                    className={`button w-100 ${_this.validationError ? '-inactive' : '-primary'}`}
                    data-bs-toggle="modal"
                    data-bs-target="#commentModal"
                    disabled={_this.validationError}
                    onClick={_this.changeHandleSave}
                  >
                    APPROVE AND CONTINUE
                  </button>
                </div>
                )}
                {_this.displayDeploy && (
                <div className="row">
                  <button
                    type="button"
                    className={`button w-100 ${_this.validationError ? '-inactive' : '-primary'}`}
                    onClick={_this.handleDeploy}
                    disabled={_this.validationError}
                  >
                    DEPLOY TO NLP
                  </button>
                </div>
                )}
                {_this.displaySave && (
                <div className="row mt-10">
                  <button
                    className="button -outline-primary w-100"
                    onClick={() => _this.handleSave("", true)}
                  >
                    SAVE CHANGES
                  </button>
                </div>
                )}
                {_this.displayRollback && (
                <div className="row mt-10">
                  <button
                    className="button -warning w-100"
                    data-bs-toggle="modal"
                    data-bs-target="#commentModal"
                    onClick={_this.changeHandleRollback}
                  >
                    REJECT CHANGES
                  </button>
                </div>
                )}
                {_this.displayDiscard && (
                <div className="row mt-10">
                  <button
                    className="button -warning w-100"
                    data-bs-toggle="modal"
                    data-bs-target="#commentModal"
                    onClick={_this.changeHandleDiscard}
                  >
                    DISCARD SUGGESTION
                  </button>
                </div>
                )}
                {_this.displayClose && (
                <div className="row mt-10">
                  <button
                    type="button"
                    className="button -text -red w-100"
                    data-bs-toggle="modal"
                    data-bs-target="#commentModal"
                    onClick={_this.changeHandleClose}
                  >
                    CLOSE REQUEST
                  </button>
                </div>
                )}
                {_this.displayRecover && (
                <div className="row mt-10">
                  <button
                    type="button"
                    className="button -primary w-100"
                    data-bs-toggle="modal"
                    data-bs-target="#commentModal"
                    onClick={_this.changeHandleRecover}
                  >
                    RECOVER SUGGESTION
                  </button>
                </div>
                )}
              </div>

          </div>

                    <Form
                        onSubmit={() => {
                            // new Modal(document.getElementById("approveModal")).show();
                        }}
                        render={({ form, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <>
                                    <div className="row">
                                        <div className="col-12 col-lg-9 my-20">
                                                <div className="card-w -m">
                                                    <div className="row">
                                                      {_this.toBeDeleted ? (
                                                        <label className="label -deleted">
                                                            {console.log(_this.requestStatus)}
                                                          {_this.requestStatus !== "Deployed" ? "Deleting" : "Deleted"}
                                                        </label>
                                                      ):(_this.requestStatus === "automatic_suggestion" || _this.newObject &&
                                                        <label className="label -newContent">
                                                            New
                                                        </label>
                                                      )}
                                                      {_this.rejected && (
                                                        <label className="label -rejected">
                                                            Rejected
                                                        </label>
                                                        )}      
                                                    </div>
                                                    <p className="h3 -b mt-10">
                                                        Name
                                                    </p>
                                                    <div>
                                                        <div className="row">
                                                            {_this.object?.name && (_this.object?.name !== _this.inputName) && (
                                                                <>
                                                                    <div className="row">
                                                                        <p className="label -edited mx-10">
                                                                            Edited
                                                                        </p>
                                                                    </div>
                                                                    <p className="label -previous mx-10">
                                                                        {_this.object?.name}
                                                                    </p>
                                                                </>
                                                            )}
                                                            <div>
                                                                <div className="col-12">
                                                                    {!_this.viewerMode ? (
                                                                        <>
                                                                            <div className="form-group mt-40">
                                                                                <Field
                                                                                    name="name"
                                                                                    initialValue={
                                                                                        _this.inputName
                                                                                    }
                                                                                    validate={_this.composeValidators(
                                                                                        _this.required,
                                                                                        _this.name,
                                                                                        _this.namePrefix,
                                                                                        _this.maxLength(
                                                                                            _this.objectType === "intent" ? 128 : 64
                                                                                        )
                                                                                    )}
                                                                                >
                                                                                    {props => (
                                                                                        <>
                                                                                            <input
                                                                                                {...props.input}
                                                                                                type="text"
                                                                                                name="name"
                                                                                                placeholder={
                                                                                                    _this.changes
                                                                                                        ?.edited
                                                                                                        ?.name
                                                                                                        ?.new_value
                                                                                                }
                                                                                                autoComplete="off"
                                                                                                required={
                                                                                                    true
                                                                                                }
                                                                                                id="name"
                                                                                                value={
                                                                                                    _this.inputName
                                                                                                }
                                                                                                onChange={e => [
                                                                                                    _this.setInputName(
                                                                                                        e
                                                                                                            .target
                                                                                                            .value
                                                                                                    ),
                                                                                                    props.input.onChange(
                                                                                                        e
                                                                                                    ),
                                                                                                    _this.handleChangeError(e, "name"),
                                                                                                ]}
                                                                                                onBlur={e => [
                                                                                                    _this.saveName(),
                                                                                                    props.input.onBlur(
                                                                                                        e
                                                                                                    ),
                                                                                                ]}
                                                                                                onLoad={e => [
                                                                                                    _this.setInputName(
                                                                                                        e
                                                                                                            .target
                                                                                                            .value
                                                                                                    ),
                                                                                                    props.input.onChange(
                                                                                                        e
                                                                                                    ),
                                                                                                ]}
                                                                                                style={
                                                                                                    props
                                                                                                        .meta
                                                                                                        ?.error
                                                                                                        ? {
                                                                                                              borderColor:
                                                                                                                  "red",
                                                                                                          }
                                                                                                        : null
                                                                                                }
                                                                                            />
                                                                                            <label
                                                                                                htmlFor="name"
                                                                                                data-toggle="tooltip"
                                                                                                title="Entities can contain only Unicode alphanumeric, underscore, hyphen, and dot characters. It can not have spaces, be empty or be longer than 64 characters."
                                                                                                style={
                                                                                                    props
                                                                                                        .meta
                                                                                                        ?.error
                                                                                                        ? {
                                                                                                              color: "red",
                                                                                                          }
                                                                                                        : null
                                                                                                }
                                                                                            >
                                                                                                {props
                                                                                                    .meta
                                                                                                    .error ? (
                                                                                                    <span className="error">
                                                                                                        <i className="fas fa-exclamation-circle fa-sm"></i>
                                                                                                        {
                                                                                                            props
                                                                                                                .meta
                                                                                                                .error
                                                                                                        }
                                                                                                    </span>
                                                                                                ) : (
                                                                                                        (_this.objectType === "entity" && "Entity name") 
                                                                                                        || 
                                                                                                        (_this.objectType === "intent" && "Intent name")
                                                                                                        ||
                                                                                                        (_this.objectType === "category" && "Category name")
                                                                                                )}
                                                                                            </label>
                                                                                        </>
                                                                                    )}
                                                                                </Field>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <div
                                                                            className="row"
                                                                            style={{
                                                                                color: "blue",
                                                                            }}
                                                                        >
                                                                            <p className="h2 -b -mt 10">
                                                                                {
                                                                                    _this.inputName
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>

                                        {/*aqui comienza la descripcion*/}
                                            <div className="col-12 col-lg-9 my-20">
                                                <div className="card-w -m">
                                                    <p className="h3 -b mt-10">
                                                        Description
                                                    </p>
                                                    <div>
                                                        <div className="row">
                                                            {console.log(_this.object)}
                                                            {(_this.object?.description || _this.object?.description === "") && (_this.object?.description !== _this.inputDescription) && (
                                                                <>
                                                                    <div className="row">
                                                                        <p className="label -edited mx-10">
                                                                            Edited
                                                                        </p>
                                                                    </div>
                                                                    <p className="label -previous mx-10">
                                                                        {_this.object?.description}
                                                                    </p>
                                                                </>
                                                            )}
                                                            <div>
                                                                <div className="col-12">
                                                                    {!_this.viewerMode ? (
                                                                        <div className="form-group mt-40">
                                                                            <Field
                                                                                name="description"
                                                                                initialValue={
                                                                                    _this.inputDescription
                                                                                }
                                                                                validate={_this.composeValidators(
                                                                                    _this.maxLength(
                                                                                        128
                                                                                    )
                                                                                )}
                                                                            >
                                                                                {props => (
                                                                                    <>
                                                                                        <input
                                                                                            {...props.input}
                                                                                            type="text"
                                                                                            name="description"
                                                                                            placeholder={
                                                                                                _this.changes
                                                                                                    ?.edited
                                                                                                    ?.description
                                                                                                    ?.new_value
                                                                                            }
                                                                                            autoComplete="off"
                                                                                            id="description"
                                                                                            value={
                                                                                                _this.inputDescription
                                                                                            }
                                                                                            onChange={e => [
                                                                                                _this.setInputDescription(
                                                                                                    e
                                                                                                        .target
                                                                                                        .value
                                                                                                ),
                                                                                                props.input.onChange(
                                                                                                    e
                                                                                                ),
                                                                                                _this.handleChangeError(e, "description"),
                                                                                            ]}
                                                                                            onBlur={e => [
                                                                                                _this.saveDescription(),
                                                                                                props.input.onBlur(
                                                                                                    e
                                                                                                ),
                                                                                            ]}
                                                                                            style={
                                                                                                props
                                                                                                    .meta
                                                                                                    ?.error
                                                                                                    ? {
                                                                                                          borderColor:
                                                                                                              "red",
                                                                                                      }
                                                                                                    : null
                                                                                            }
                                                                                        />
                                                                                        <label
                                                                                            htmlFor="description"
                                                                                            data-toggle="tooltip"
                                                                                            title="This value cannot contain carriage return, newline, or tab characters. It can not be empty or longer than 128 characters."
                                                                                            style={
                                                                                                props
                                                                                                    .meta
                                                                                                    ?.error
                                                                                                    ? {
                                                                                                          color: "red",
                                                                                                      }
                                                                                                    : null
                                                                                            }
                                                                                        >
                                                                                            {props
                                                                                                .meta
                                                                                                .error ? (
                                                                                                <span className="error">
                                                                                                    <i className="fas fa-exclamation-circle fa-sm"></i>
                                                                                                    {
                                                                                                        props
                                                                                                            .meta
                                                                                                            .error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : (
                                                                                                    (_this.objectType === "entity" && "Entity description") 
                                                                                                    || 
                                                                                                    (_this.objectType === "intent" && "Intent description")
                                                                                                    ||
                                                                                                    (_this.objectType === "category" && "Category description")
                                                                                            )}
                                                                                        </label>
                                                                                    </>
                                                                                )}
                                                                            </Field>
                                                                        </div>
                                                                    ) : (
                                                                        <div
                                                                            className="row"
                                                                            style={{
                                                                                color: "blue",
                                                                            }}
                                                                        >
                                                                            <p className="h2 -b -mt 10">
                                                                                {
                                                                                    _this.inputDescription
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                    <div className="row ">
                                        <Nav tabs className="col-12 d-flex">
                                            {(_this.objectType === "category" || _this.objectType === "entity") && (
                                            <NavItem>
                                                <NavLink
                                                    className={classNames({
                                                        active:
                                                        _this.activeTab === "1",
                                                    })}
                                                    onClick={() => {
                                                        _this.toggle("1");
                                                    }}
                                                >
                                                    <span>
                                                        <p className="icon">
                                                            <i className="fa-solid fa-lines-leaning"></i>
                                                        </p>
                                                        <p className="h3 -b">
                                                            {(_this.objectType === "category" && "ENTITIES") || (_this.objectType === "entity" && "CATEGORIES")}
                                                        </p>
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                            )}
                                            {(_this.objectType === "intent" || _this.objectType === "entity") && (
                                            <NavItem>
                                                <NavLink
                                                    className={classNames({
                                                        active:
                                                        _this.activeTab === "2",
                                                    })}
                                                    onClick={() => {
                                                        _this.toggle("2");
                                                    }}
                                                >
                                                    <span>
                                                        <p className="icon">
                                                            <i className="fa-solid fa-lines-leaning"></i>
                                                        </p>
                                                        <p className="h3 -b">
                                                            {(_this.objectType === "entity" && "SYNONYMS") || (_this.objectType === "intent" && "EXAMPLES")}
                                                        </p>
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                            )}
                                        </Nav>
                                    </div>
                                    <TabContent activeTab={_this.activeTab}>
                                    {(_this.objectType === "intent" || _this.objectType === "entity") && (
                                        <TabPane tabId="2">
                                            <div className="row">
                                                <div className="col-12 col-lg-9">
                                                    <div className="card-w -m my-40">
                                                        {!_this.viewerMode ? (
                                                            <>
                                                                <div className="row mb-10">
                                                                    <p className="h4">
                                                                    {
                                                                    (_this.objectType === "entity" && "Add synonyms to facilitate communication with users.") 
                                                                    || 
                                                                    (_this.objectType === "intent" && "Write examples of what users are trying to say.")
                                                                    }                                                                        
                                                                    </p>
                                                                </div>
                                                                <form
                                                                    onSubmit={e => {
                                                                        e.preventDefault();
                                                                        if (
                                                                            !_this.childrenEdit &&
                                                                            !form.getFieldState(
                                                                                "synonym"
                                                                            )
                                                                                ?.error
                                                                        )
                                                                        _this.addChild(
                                                                                e
                                                                            );
                                                                    }}
                                                                >
                                                                    <div className="row">
                                                                        <div className="form-group mt-40">
                                                                            <Field
                                                                                name="child"
                                                                                initialValue={
                                                                                    _this.inputChildren
                                                                                }
                                                                                validate={_this.composeValidators(
                                                                                    _this.child,
                                                                                    _this.synonymExists,
                                                                                    _this.maxLength(
                                                                                        _this.objectType === "entity" ? 64 : 1024
                                                                                    )
                                                                                )}
                                                                            >
                                                                                {props => (
                                                                                    <>
                                                                                        <input
                                                                                            {...props.input}
                                                                                            type="text"
                                                                                            name="child"
                                                                                            placeholder={
                                                                                                (_this.objectType === "entity" && "Ex. If you have Credit Card like entity you can use CC for synonym") 
                                                                                                || 
                                                                                                (_this.objectType === "intent" && "Ej. I want a credit card.")
                                                                                                }   
                                                                                            autoComplete="off"
                                                                                            required={
                                                                                                false
                                                                                            }
                                                                                            id="child"
                                                                                            value={
                                                                                                _this.inputChildren
                                                                                            }
                                                                                            onChange={e => [
                                                                                                _this.setInputChildren(
                                                                                                    e
                                                                                                        .target
                                                                                                        .value
                                                                                                ),
                                                                                                props.input.onChange(
                                                                                                    e
                                                                                                ),
                                                                                            ]}
                                                                                            onBlur={e =>
                                                                                                props.input.onBlur(
                                                                                                    e
                                                                                                )
                                                                                            }
                                                                                            onSubmit={e => [
                                                                                                e.preventDefault(),
                                                                                                _this.addChild(
                                                                                                    e
                                                                                                ),
                                                                                            ]}
                                                                                            onKeyPress={e => [
                                                                                                e.key ===
                                                                                                    "Enter" &&
                                                                                                    !form.getFieldState(
                                                                                                        "child"
                                                                                                    )
                                                                                                        ?.error && [
                                                                                                        e.preventDefault(),
                                                                                                        _this.addChild(
                                                                                                            e
                                                                                                        ),
                                                                                                    ],
                                                                                            ]}
                                                                                            style={
                                                                                                props
                                                                                                    .meta
                                                                                                    ?.error
                                                                                                    ? {
                                                                                                          borderColor:
                                                                                                              "red",
                                                                                                      }
                                                                                                    : null
                                                                                            }
                                                                                        />

                                                                                        <label
                                                                                            htmlFor="child"
                                                                                            data-toggle="tooltip"
                                                                                            title="This string cannot contain spaces, carriage return, newline, or tab characters. It can not be empty or longer than 64 characters."
                                                                                            style={
                                                                                                props
                                                                                                    .meta
                                                                                                    ?.error
                                                                                                    ? {
                                                                                                          color: "red",
                                                                                                      }
                                                                                                    : null
                                                                                            }
                                                                                        >
                                                                                            {props
                                                                                                .meta
                                                                                                .error &&
                                                                                            props
                                                                                                .meta
                                                                                                .touched ? (
                                                                                                <span className="error">
                                                                                                    <i className="fas fa-exclamation-circle fa-sm"></i>
                                                                                                    {
                                                                                                        props
                                                                                                            .meta
                                                                                                            .error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : (
                                                                                                (_this.objectType === "entity" && "Synonym") 
                                                                                                || 
                                                                                                (_this.objectType === "intent" && "Example")
                                                                                            )}
                                                                                        </label>
                                                                                    </>
                                                                                )}
                                                                            </Field>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row mt-20">
                                                                        {!_this.childrenEdit ? (
                                                                            <div className="col-12 col-md-4 col-lg-3">
                                                                                <button
                                                                                    className={`button w-100 -outline-primary -${
                                                                                        _this.inputChildren &&
                                                                                        !form.getFieldState(
                                                                                            "child"
                                                                                        )
                                                                                            .error
                                                                                            ? "active"
                                                                                            : "inactive"
                                                                                    }`}
                                                                                    onClick={e => {
                                                                                        e.preventDefault();
                                                                                        if (
                                                                                            !_this.childrenEdit &&
                                                                                            !form.getFieldState(
                                                                                                "child"
                                                                                            )
                                                                                                ?.error
                                                                                        )
                                                                                        _this.addChild(
                                                                                                e
                                                                                            );
                                                                                    }}
                                                                                    disabled={
                                                                                        !_this.inputChildren &&
                                                                                        !form.getFieldState(
                                                                                            "child"
                                                                                        )
                                                                                            ?.error
                                                                                    }
                                                                                >
                                                                                    ADD
                                                                                    {
                                                                                        (_this.objectType === "entity" && " SYNONYM") 
                                                                                        || 
                                                                                        (_this.objectType === "intent" && " EXAMPLE")
                                                                                    }   
                                                                                </button>
                                                                            </div>
                                                                        ) : (
                                                                            <>
                                                                                <div className="col-12 col-md-4 col-lg-3">
                                                                                    <button
                                                                                        className="button -outline-secondary w-100"
                                                                                        onClick={e => [
                                                                                            e.preventDefault(),
                                                                                            _this.cancelEdition(),
                                                                                        ]}
                                                                                    >
                                                                                        CANCEL
                                                                                    </button>
                                                                                </div>
                                                                                <div className="col-12 col-md-4 col-lg-3">
                                                                                    <button
                                                                                        className={`button w-100 -outline-primary -${
                                                                                            _this.inputChildren &&
                                                                                            !form.getFieldState(
                                                                                                "child"
                                                                                            )
                                                                                                .error
                                                                                                ? "active"
                                                                                                : "inactive"
                                                                                        }`}
                                                                                        onClick={e => {
                                                                                            e.preventDefault();
                                                                                            if (
                                                                                                _this.inputChildren &&
                                                                                                !form.getFieldState(
                                                                                                    "child"
                                                                                                )
                                                                                                    ?.error
                                                                                            )
                                                                                            _this.editChildren(
                                                                                                _this.inputChildren,
                                                                                                _this.childrenEdit
                                                                                                );
                                                                                        }}
                                                                                    >
                                                                                        SAVE
                                                                                        CHANGES
                                                                                    </button>
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </form>
                                                                <div className="row mt-50">
                                                                    <div className="col-12">
                                                                        <div className="bg-light">
                                                                            <div
                                                                                className="row justify-content-end"
                                                                                style={{
                                                                                    visibility:
                                                                                        [
                                                                                            ..._this.checked,
                                                                                        ]
                                                                                            .length
                                                                                            ? "visible"
                                                                                            : "hidden",
                                                                                }}
                                                                            >
                                                                                <div className="col-4 col-sm-3 col-md-2">
                                                                                    <button
                                                                                        className="button -text"
                                                                                        onClick={e => [
                                                                                            e.preventDefault(),
                                                                                            _this.handleCancelSelection(
                                                                                                e
                                                                                            ),
                                                                                        ]}
                                                                                    >
                                                                                        <NDSTooltip
                                                                                            icon="fa-solid fa-circle-xmark"
                                                                                            target="cancel"
                                                                                            tooltipMessage="Cancel"
                                                                                            placement="bottom"
                                                                                        ></NDSTooltip>
                                                                                        {/* <i className="fa-solid fa-circle-xmark"></i>
                                            CANCEL */}
                                                                                    </button>
                                                                                </div>
                                                                                <div className="col-4 col-sm-3 col-md-2">
                                                                                    <button
                                                                                        className="button -text"
                                                                                        onClick={e => [
                                                                                            e.preventDefault(),
                                                                                            _this.handleDownloadSelection(
                                                                                                "synonym",
                                                                                                _this.selectedSynonyms
                                                                                            ),
                                                                                        ]}
                                                                                    >
                                                                                        <NDSTooltip
                                                                                            icon="fa-solid fa-download"
                                                                                            target="download"
                                                                                            tooltipMessage="Download"
                                                                                            placement="bottom"
                                                                                        ></NDSTooltip>
                                                                                        {/* <i className="fa-solid fa-download"></i>
                                            DOWNLOAD */}
                                                                                    </button>
                                                                                </div>
                                                                                <div className="col-4 col-sm-3 col-md-2">
                                                                                    <button
                                                                                        className="button -text -red"
                                                                                        onClick={e => [
                                                                                            e.preventDefault(),
                                                                                            _this.handleDeleteSelection(
                                                                                                e
                                                                                            ),
                                                                                        ]}
                                                                                    >
                                                                                        <NDSTooltip
                                                                                            icon="fa-solid fa-trash-can"
                                                                                            target="trash"
                                                                                            tooltipMessage="Delete"
                                                                                            placement="bottom"
                                                                                        ></NDSTooltip>
                                                                                        {/* <i className="fa-solid fa-trash-can"></i>
                                            DELETE */}
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <table>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>
                                                                                        <input
                                                                                            className="form-check-input -white"
                                                                                            type="checkbox"
                                                                                            value=""
                                                                                            id="flexCheckDefault"
                                                                                            checked={
                                                                                                _this.allChecked
                                                                                            }
                                                                                            onChange={event =>
                                                                                                _this.handleAllCheck(
                                                                                                    event
                                                                                                )
                                                                                            }
                                                                                        ></input>
                                                                                    </th>
                                                                                    <th>
                                                                                        <p className="d-flex align-items-center w-100">
                                                                                        {
                                                                                            (_this.objectType === "entity" && " Synonyms") 
                                                                                            || 
                                                                                            (_this.objectType === "intent" && " Examples")
                                                                                        }   
                                                                                            {/*<button className="i -white w-15">
                                                                                                <i
                                                                                                    className={
                                                                                                        _this.sort[
                                                                                                            "Synonyms"
                                                                                                        ]
                                                                                                            ?.className ??
                                                                                                        "fa-solid fa-arrow-up-a-z"
                                                                                                    }
                                                                                                    onClick={
                                                                                                        _this.handleSort
                                                                                                    }
                                                                                                ></i>
                                                                                            </button>*/}
                                                                                        </p>
                                                                                    </th>
                                                                                    <th>
                                                                                        <p className="d-flex align-items-center w-100">
                                                                                            <button className="i -white w-15">
                                                                                            </button>
                                                                                        </p>
                                                                                    </th>
                                                                                    <th className="row m-10 align-items-center">
                                                                                        Actions
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {console.log(_this.showedChildren)}
                                                                                {/*aqui se van a comenzar a editar*/}
                                                                                {_this.listChildren.length >
                                                                                    0 &&
                                                                                    _this.showedChildren[_this.page -1].map(
                                                                                        (
                                                                                            child,
                                                                                            index
                                                                                        ) =>
                                                                                            child ? (
                                                                                                <tr
                                                                                                    key={
                                                                                                        child?._id
                                                                                                    }
                                                                                                >
                                                                                                    <td>
                                                                                                        <input
                                                                                                            className="form-check-input -blue"
                                                                                                            type="checkbox"
                                                                                                            value=""
                                                                                                            id="flexCheckDefault"
                                                                                                            checked={_this.checked.has(
                                                                                                                child?._id
                                                                                                            )}
                                                                                                            onChange={() =>
                                                                                                                _this.handleCheck(
                                                                                                                    child
                                                                                                                )
                                                                                                            }
                                                                                                        ></input>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {/*aqui comienzan las flags*/}
                                                                                                        {child.type ===
                                                                                                        "edited" ? (
                                                                                                            <>
                                                                                                                <div className="row">
                                                                                                                    <p className="label -edited">
                                                                                                                        Edited
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                                <div className="row">
                                                                                                                    <p className="label -previous">
                                                                                                                        {
                                                                                                                            child.last_value
                                                                                                                        }
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        ) : child.type ===
                                                                                                          "editing" ? (
                                                                                                            <>
                                                                                                                <div className="row">
                                                                                                                    <p className="label -edited">
                                                                                                                        Editing
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                                <div className="row">
                                                                                                                    <p className="label -previous">
                                                                                                                        {
                                                                                                                            child.previous
                                                                                                                        }
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        ) : null}
                                                                                                        {child.type ===
                                                                                                        "added" ? (
                                                                                                            <div className="row">
                                                                                                                <p className="label -added">
                                                                                                                    Added
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        ) : null}
                                                                                                        {child.type ===
                                                                                                        "adding" ? (
                                                                                                            <div className="row">
                                                                                                                <p className="label -added">
                                                                                                                    Adding
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        ) : null}
                                                                                                        {child.type ===
                                                                                                        "deleted" ? (
                                                                                                            <div className="row">
                                                                                                                <p className="label -deleted">
                                                                                                                    Deleted
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        ) : null}
                                                                                                        {child.type ===
                                                                                                        "deleting" ? (
                                                                                                            <div className="row">
                                                                                                                <p className="label -deleted">
                                                                                                                    Deleting
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        ) : null}
                                                                                                        {child.recovered ===
                                                                                                        true ? (
                                                                                                            <div className="row">
                                                                                                                <p className="label -added">
                                                                                                                    Recovering
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        ) : null}
                                                                                                        <div className="row">
                                                                                                            {child.new_value
                                                                                                                ? child.new_value
                                                                                                                : child?.value}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div className="mx-5"></div>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div className="row">
                                                                                                            {child.type !==
                                                                                                                "deleted" &&
                                                                                                            !_this.childrenEdit &&
                                                                                                            child.type !==
                                                                                                                "deleting" &&
                                                                                                            child.type !=
                                                                                                                "editing" ? (
                                                                                                                <>
                                                                                                                    {!child.newOne && (
                                                                                                                        <button
                                                                                                                            className="button -icon"
                                                                                                                            onClick={e => [
                                                                                                                                e.preventDefault(),
                                                                                                                                _this.handleEdition(
                                                                                                                                    child
                                                                                                                                ),
                                                                                                                            ]}
                                                                                                                        >
                                                                                                                            <span className="material-icons-outlined">
                                                                                                                                <NDSTooltip
                                                                                                                                    icon="fa-solid fa-pencil"
                                                                                                                                    target="pencil"
                                                                                                                                    tooltipMessage="Edit"
                                                                                                                                    placement="bottom"
                                                                                                                                ></NDSTooltip>
                                                                                                                                {/* <i className="fa-solid fa-pencil"></i> */}
                                                                                                                            </span>
                                                                                                                        </button>
                                                                                                                    )}
                                                                                                                    <button
                                                                                                                        className="button -icon -danger"
                                                                                                                        ref={el =>
                                                                                                                            (_this.deleteRef.current[
                                                                                                                                child._id
                                                                                                                            ] =
                                                                                                                                el)
                                                                                                                        }
                                                                                                                        onClick={e => [
                                                                                                                            e.preventDefault(),
                                                                                                                            _this.deleteChildren(
                                                                                                                                child
                                                                                                                            ),
                                                                                                                        ]}
                                                                                                                    >
                                                                                                                        <span className="material-icons-outlined">
                                                                                                                            <NDSTooltip
                                                                                                                                icon="fa-solid fa-trash-can"
                                                                                                                                target="trash"
                                                                                                                                tooltipMessage="Delete"
                                                                                                                                placement="bottom"
                                                                                                                            ></NDSTooltip>
                                                                                                                            {/* <i className="fa-solid fa-trash-can"></i> */}
                                                                                                                        </span>
                                                                                                                    </button>
                                                                                                                </>
                                                                                                            ) : child?.recovered ===
                                                                                                                  true &&
                                                                                                              !_this.childrenEdit &&
                                                                                                              child.type !==
                                                                                                                  "deleting" &&
                                                                                                              child.type !=
                                                                                                                  "editing" ? (
                                                                                                                <div className="col-12 col-md-4">
                                                                                                                    <button className="button -icon -success">
                                                                                                                        <i className="fa-solid fa-check"></i>{" "}
                                                                                                                        Recovering
                                                                                                                    </button>
                                                                                                                    <button
                                                                                                                        className="button -primary"
                                                                                                                        onClick={e => [
                                                                                                                            e.preventDefault(),
                                                                                                                            _this.undoSynonymRecovery(
                                                                                                                                child
                                                                                                                            ),
                                                                                                                        ]}
                                                                                                                    >
                                                                                                                        <i className="fa-solid fa-undo"></i>{" "}
                                                                                                                        Undo
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            ) : !_this.childrenEdit &&
                                                                                                              child.type !==
                                                                                                                  "editing" ? (
                                                                                                                <div>
                                                                                                                <button
                                                                                                                    className="button -icon "
                                                                                                                    onClick={e => [
                                                                                                                        e.preventDefault(),
                                                                                                                        _this.recoverChildren(
                                                                                                                            child
                                                                                                                        ),
                                                                                                                    ]}
                                                                                                                >
                                                                                                                    <i className="fa-solid fa-undo"></i>{" "}
                                                                                                                    Recover
                                                                                                                </button>
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                child.type ===
                                                                                                                    "editing" &&
                                                                                                                !_this.childrenEdit && (
                                                                                                                    <div>
                                                                                                                    <button
                                                                                                                        className="button -icon -undo"
                                                                                                                        onClick={e => [
                                                                                                                            e.preventDefault(),
                                                                                                                            _this.undoChildrenEdit(
                                                                                                                                child
                                                                                                                            ),
                                                                                                                        ]}
                                                                                                                    >
                                                                                                                        <i className="fa-solid fa-undo"></i>{" "}
                                                                                                                        UNDO
                                                                                                                        Edition
                                                                                                                    </button>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ) : null
                                                                                    )}
                                                                                {/*aqui termina la edicion de cada uno*/}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="row mb-10">
                                                                    <p className="h4">
                                                                        These
                                                                        are the
                                                                        current
                                                                        {_this.objectType === "entity" ? " synonyms " : " examples "}
                                                                        that
                                                                        went
                                                                        through
                                                                        the{" "}
                                                                        <b>
                                                                            validation
                                                                        </b>{" "}
                                                                        process
                                                                    </p>
                                                                </div>

                                                                <div className="row mt-50">
                                                                    <div className="col-12">
                                                                        <table>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>
                                                                                        <p className="d-flex align-items-center w-100">
                                                                                            {_this.objectType === "entity" ? " Synonyms" : " Examples"}{" "}
                                                                                            {/*<button className="i -white w-15">
                                                                                                <i className="fa-solid fa-arrow-up-a-z"></i>
                                                                                            </button>*/}
                                                                                        </p>
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {/*aqui se van a comenzar a editar*/}
                                                                                {_this.listChildren.length >
                                                                                    0 &&
                                                                                    _this.showedChildren[_this.page -1].map(
                                                                                        (child,
                                                                                            index) =>
                                                                                            child ? (
                                                                                                <tr key={index}>
                                                                                                    <td>
                                                                                                        <div className="row mx-10">
                                                                                                            {child.type ===
                                                                                                            "edited" ? (
                                                                                                                <>
                                                                                                                    <div className="row">
                                                                                                                        <p className="label -edited">
                                                                                                                            Edited
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                    <div className="row">
                                                                                                                        <p className="label -previous">
                                                                                                                            {
                                                                                                                                child.last_value
                                                                                                                            }
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                </>
                                                                                                            ) : child.type ===
                                                                                                              "editing" ? (
                                                                                                                <>
                                                                                                                    <div className="row">
                                                                                                                        <p className="label -edited">
                                                                                                                            Editing
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                    <div className="row">
                                                                                                                        <p className="label -previous">
                                                                                                                            {
                                                                                                                                child.previous
                                                                                                                            }
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                </>
                                                                                                            ) : null}
                                                                                                            {child.type ===
                                                                                                            "added" ? (
                                                                                                                <div className="row">
                                                                                                                    <p className="label -added">
                                                                                                                        Added
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            ) : null}
                                                                                                            {child.type ===
                                                                                                            "deleted" ? (
                                                                                                                <div className="row">
                                                                                                                    <p className="label -deleted">
                                                                                                                        Deleted
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            ) : null}
                                                                                                            {child.type ===
                                                                                                            "deleting" ? (
                                                                                                                <div className="row">
                                                                                                                    <p className="label -deleted">
                                                                                                                        Deleting
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            ) : null}
                                                                                                            {child.recovered ===
                                                                                                            true ? (
                                                                                                                <div className="row">
                                                                                                                    <p className="label -added">
                                                                                                                        Recovering
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            ) : null}
                                                                                                            <div className="row">
                                                                                                                {child.new_value
                                                                                                                    ? child.new_value
                                                                                                                    : child?.value}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ) : null
                                                                                    )}
                                                                                {/*aqui termina la edicion de cada uno*/}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                        <div className="row mt-20">
                                                            <Paginator
                                                            pages={_this.totalPages}
                                                            currentPage={_this.page}
                                                            setPage={_this.handlePageChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        )}
                                        {(_this.objectType === "category" || _this.objectType === "entity") && (
                                        <TabPane tabId="1">
                                            <div className="row">
                                                <div className="col-12 col-lg-9">
                                                    <div className="card-w -m my-40">
                                                        {!_this.viewerMode ? (
                                                            <>
                                                                <div className="row mb-10">
                                                                    <p className="h4">
                                                                        {(_this.objectType === "entity") ? (
                                                                        <>
                                                                        You can
                                                                        match
                                                                        entities
                                                                        with
                                                                        categories
                                                                        for
                                                                        example
                                                                        the
                                                                        entity{" "}
                                                                        <b>
                                                                            "Credit
                                                                            card"
                                                                        </b>{" "}
                                                                        match
                                                                        with the
                                                                        category{" "}
                                                                        <b>
                                                                            “Cards”,
                                                                            “Financial
                                                                            products”,
                                                                            etc.
                                                                        </b>{" "}
                                                                        it’s
                                                                        posible
                                                                        match
                                                                        more
                                                                        than one
                                                                        category
                                                                        for each
                                                                        entity.
                                                                        </>
                                                                    ):(_this.objectType === "category") && (
                                                                        <>
                                                                            You can
                                                                        match
                                                                        categories
                                                                        with
                                                                        entities
                                                                        for
                                                                        example
                                                                        the
                                                                        category{" "}
                                                                        <b>
                                                                            “Cards”,
                                                                            “Financial
                                                                            products”,
                                                                            etc.
                                                                        </b>{" "}
                                                                        match
                                                                        with the
                                                                        entity{" "}
                                                                        <b>
                                                                            "Credit
                                                                            card"
                                                                        </b>{" "}
                                                                        it’s
                                                                        posible
                                                                        match
                                                                        more
                                                                        than one
                                                                        entity
                                                                        for each
                                                                        category.
                                                                        </>
                                                                    )}
                                                                    </p>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12 col-md-6">
                                                                        <div className="form-group mt-40">
                                                                            <Select
                                                                                placeholder={`Search ${_this.objectType === "entity" ? "category" : "entity"} by it's name`}
                                                                                name="colors"
                                                                                className="basic-multi-select w-100"
                                                                                classNamePrefix="select"
                                                                                options={
                                                                                    _this.categories
                                                                                }
                                                                                value={
                                                                                    _this.selectedCategory
                                                                                }
                                                                                onChange={
                                                                                    _this.setSelectedCategory
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-20">
                                                                    <div className="col-12 col-md-4">
                                                                        <button
                                                                            className={`button w-100 -outline-primary -${
                                                                                _this.selectedCategory?.value
                                                                                    ? "active"
                                                                                    : "inactive"
                                                                            }`}
                                                                            onClick={e => [
                                                                                e.preventDefault(),
                                                                                _this.addCategory(),
                                                                            ]}
                                                                        >
                                                                            ADD
                                                                            {_this.objectType === "entity" ? " CATEGORY" : " ENTITY"}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className="row mt-50">
                                                                    <div className="col-12">
                                                                        <div className="row justify-content-end"
                                                                        style={{
                                                                            visibility:
                                                                                [
                                                                                    ..._this.categoryChecked,
                                                                                ]
                                                                                    .length
                                                                                    ? "visible"
                                                                                    : "hidden",
                                                                        }}
                                                                        >
                                                                            <div className="col-4 col-sm-3 col-md-2">
                                                                                <button
                                                                                    className="button -text"
                                                                                    onClick={e => [
                                                                                        e.preventDefault(),
                                                                                        _this.handleCancelSelectionCategory(
                                                                                            e
                                                                                        ),
                                                                                    ]}
                                                                                >
                                                                                    <NDSTooltip
                                                                                        icon="fa-solid fa-circle-xmark"
                                                                                        target="cancel"
                                                                                        tooltipMessage="Cancel"
                                                                                        placement="bottom"
                                                                                    ></NDSTooltip>
                                                                                    {/* <i className="fa-solid fa-circle-xmark"></i>
                                          CANCEL */}
                                                                                </button>
                                                                            </div>
                                                                            <div className="col-4 col-sm-3 col-md-2">
                                                                                <button
                                                                                    className="button -text"
                                                                                    onClick={e => [
                                                                                        e.preventDefault(),
                                                                                        _this.handleDownloadSelection(
                                                                                            "category",
                                                                                            _this.selectedCategories
                                                                                        ),
                                                                                    ]}
                                                                                >
                                                                                    <NDSTooltip
                                                                                        icon="fa-solid fa-download"
                                                                                        target="download"
                                                                                        tooltipMessage="Download"
                                                                                        placement="bottom"
                                                                                    ></NDSTooltip>
                                                                                    {/* <i className="fa-solid fa-download"></i>
                                          DOWNLOAD */}
                                                                                </button>
                                                                            </div>
                                                                            <div className="col-4 col-sm-3 col-md-2">
                                                                                <button
                                                                                    className="button -text -red"
                                                                                    onClick={e => [
                                                                                        e.preventDefault(),
                                                                                        _this.handleDeleteSelectionCategory(
                                                                                            e
                                                                                        ),
                                                                                    ]}
                                                                                >
                                                                                    <NDSTooltip
                                                                                        icon="fa-solid fa-trash-can"
                                                                                        target="trash"
                                                                                        tooltipMessage="Delete"
                                                                                        placement="bottom"
                                                                                    ></NDSTooltip>
                                                                                    {/* <i className="fa-solid fa-trash-can"></i>
                                          DELETE */}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                        {_this.categoriesError && (
                                                                        <label
                                                                            htmlFor="category"
                                                                            data-toggle="tooltip"
                                                                            style={{ color: "red" }}
                                                                        >
                                                                            <span className="error">
                                                                                <i className="fas fa-exclamation-circle fa-sm"></i>
                                                                                You must add at least 1 category
                                                                            </span>
                                                                        </label>
                                                                        )}
                                                                        <table>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>
                                                                                        <input
                                                                                            className="form-check-input -white"
                                                                                            type="checkbox"
                                                                                            value=""
                                                                                            id="flexCheckDefault"
                                                                                            checked={
                                                                                                _this.allCategoriesChecked
                                                                                            }
                                                                                            onChange={event =>
                                                                                                _this.handleAllCheckCategories(
                                                                                                    event
                                                                                                )
                                                                                            }
                                                                                        ></input>
                                                                                    </th>
                                                                                    <th>
                                                                                        <p className="d-flex align-items-center w-100">
                                                                                            {_this.objectType === "entity" ? "Category" : _this.objectType === "category" && "Entity"}{" "}
                                                                                            {/*<button className="i -white w-15">
                                                                                                <i className="fa-solid fa-arrow-up-a-z"></i>
                                                                                            </button>*/}
                                                                                        </p>
                                                                                    </th>
                                                                                    <th>
                                                                                        <p className="d-flex align-items-center justify-content-center w-100">
                                                                                            Actions{" "} 
                                                                                        </p>
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {_this.categoryList.length >

                                                                                    0 &&
                                                                                    _this.categoryList.map(
                                                                                        (category, index) =>
                                                                                            category ? (
                                                                                                <tr key={index}>
                                                                                                    <td>
                                                                                                        <input
                                                                                                            className="form-check-input -blue"
                                                                                                            type="checkbox"
                                                                                                            value=""
                                                                                                            id="flexCheckDefault"
                                                                                                            checked={_this.categoryChecked.has(
                                                                                                                category?.value
                                                                                                            )}
                                                                                                            onChange={() =>
                                                                                                                _this.handleCheckCategory(
                                                                                                                    category
                                                                                                                )
                                                                                                            }
                                                                                                        ></input>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {category.type ===
                                                                                                        "edited" ? (
                                                                                                            <div className="row">
                                                                                                                <p className="label -edited">
                                                                                                                    Edited
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        ) : null}
                                                                                                        {category.type ===
                                                                                                        "added" ? (
                                                                                                            <div className="row">
                                                                                                                <p className="label -added">
                                                                                                                    Added
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        ) : null}
                                                                                                        {category.type ===
                                                                                                        "adding" ? (
                                                                                                            <div className="row">
                                                                                                                <p className="label -added">
                                                                                                                    Adding
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        ) : null}
                                                                                                        {category.type ===
                                                                                                        "deleted" ? (
                                                                                                            <div className="row">
                                                                                                                <p className="label -deleted">
                                                                                                                    Deleted
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        ) : null}
                                                                                                        {category.type ===
                                                                                                        "deleting" ? (
                                                                                                            <div className="row">
                                                                                                                <p className="label -deleted">
                                                                                                                    Deleting
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        ) : null}
                                                                                                        {category.recovered ===
                                                                                                        true ? (
                                                                                                            <div className="row">
                                                                                                                <p className="label -added">
                                                                                                                    Recovering
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        ) : null}

                                                                                                        <div className="row">
                                                                                                            {
                                                                                                                category.label
                                                                                                            }
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    {/* <td>3</td> */}
                                                                                                    <td className="d-flex justify-content-center w-100">
                                                                                                        {category?.type !==
                                                                                                            "deleted" &&
                                                                                                        category?.type !==
                                                                                                            "deleting" ? (
                                                                                                                <button
                                                                                                                    className="button -icon -danger"
                                                                                                                    ref={el =>
                                                                                                                        (_this.deleteRefCategory.current[
                                                                                                                            category.value
                                                                                                                        ] =
                                                                                                                            el)
                                                                                                                    }
                                                                                                                    onClick={e => [
                                                                                                                        e.preventDefault(),
                                                                                                                        _this.removeCategory(
                                                                                                                            category
                                                                                                                        ),
                                                                                                                    ]}
                                                                                                                >                                                                                                                <NDSTooltip
                                                                                                                    icon="fa-solid fa-trash-can"
                                                                                                                    target="trash"
                                                                                                                    tooltipMessage="Delete"
                                                                                                                    placement="bottom"
                                                                                                                ></NDSTooltip>
                                                                                                                {/* <i className="fa-solid fa-trash-can"></i> */}
                                                                                                            </button>
                                                                                                        ) : (category?.recovered ===
                                                                                                              true &&
                                                                                                              category?.type !==
                                                                                                                  "deleting") ||
                                                                                                          (category?.recovered ===
                                                                                                              true &&
                                                                                                              category?.type ===
                                                                                                                  "deleting") ? (
                                                                                                            <div className="col-12 col-md-4">
                                                                                                                <button className="button -icon -success">
                                                                                                                    <i className="fa-solid fa-check"></i>{" "}
                                                                                                                    Recovering
                                                                                                                </button>
                                                                                                                <button
                                                                                                                    className="button -primary"
                                                                                                                    onClick={e => [
                                                                                                                        e.preventDefault(),
                                                                                                                        _this.undoCategoryRecovery(
                                                                                                                            category
                                                                                                                        ),
                                                                                                                    ]}
                                                                                                                >
                                                                                                                    <i className="fa-solid fa-undo"></i>{" "}
                                                                                                                    Undo
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        ) : (
                                                                                                            <div className="col-12 col-md-4">
                                                                                                            <button
                                                                                                                className="button -icon "
                                                                                                                
                                                                                                                onClick={e => [
                                                                                                                    e.preventDefault(),
                                                                                                                    _this.recoverCategory(
                                                                                                                        category
                                                                                                                    ),
                                                                                                                ]}
                                                                                                            >
                                                                                                                <i className="fa-solid fa-undo"></i>{" "}
                                                                                                                Recover
                                                                                                            </button>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ) : (
                                                                                                <tr>
                                                                                                    <td colSpan="4">
                                                                                                        No
                                                                                                        {_this.objectType === "entity" ? " categories " : " entities "}
                                                                                                        found
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                    )}
                                                                                    {_this.categoryList.length === 0 &&  <tr>
                                                                                                    <td colSpan="4">
                                                                                                        No {_this.objectType === "entity" ? " categories " : " entities "} were found
                                                                                                    </td>
                                                                                                </tr>} 
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="row mb-10">
                                                                    <p className="h4">
                                                                        These
                                                                        are the
                                                                        updated
                                                                        {_this.objectType === "entity" ? " categories " : " entities "}
                                                                        that
                                                                        went
                                                                        through
                                                                        the{" "}
                                                                        <b>
                                                                            validation
                                                                        </b>{" "}
                                                                        process
                                                                    </p>
                                                                </div>

                                                                <div className="row mt-50">
                                                                    <div className="col-12">
                                                                        <table>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>
                                                                                        <p className="d-flex align-items-center w-100">
                                                                                        {_this.objectType === "entity" ? "Categories" : "Entities"}{" "}
                                                                                            {/*<button className="i -white w-15">
                                                                                                <i className="fa-solid fa-arrow-up-a-z"></i>
                                                                                            </button>*/}
                                                                                        </p>
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {_this.categoryList.length >
                                                                                    0 &&
                                                                                    _this.categoryList.map(
                                                                                        (category, index) =>
                                                                                            category ? (
                                                                                                <tr key={index}>
                                                                                                    <td>
                                                                                                        <div className="row mx-10">
                                                                                                            {category.type ===
                                                                                                            "edited" ? (
                                                                                                                <div className="row">
                                                                                                                    <p className="label -edited">
                                                                                                                        Edited
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            ) : null}
                                                                                                            {category.type ===
                                                                                                            "edited" ? (
                                                                                                                <div className="row">
                                                                                                                    <p className="label -previous">
                                                                                                                        Cancelations
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            ) : null}
                                                                                                            {category.type ===
                                                                                                            "added" ? (
                                                                                                                <div className="row">
                                                                                                                    <p className="label -added">
                                                                                                                        Added
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            ) : null}
                                                                                                            {category.type ===
                                                                                                            "deleted" ? (
                                                                                                                <div className="row">
                                                                                                                    <p className="label -deleted">
                                                                                                                        Deleted
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            ) : null}
                                                                                                            {category.type ===
                                                                                                            "deleting" ? (
                                                                                                                <div className="row">
                                                                                                                    <p className="label -deleted">
                                                                                                                        Deleting
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            ) : null}
                                                                                                            {category.recovered ===
                                                                                                            true ? (
                                                                                                                <div className="row">
                                                                                                                    <p className="label -added">
                                                                                                                        Recovering
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            ) : null}

                                                                                                            <div className="row">
                                                                                                                {
                                                                                                                    category.label
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ) : (
                                                                                                <tr>
                                                                                                    <td colSpan="4">
                                                                                                        No
                                                                                                        {_this.objectType === "entity" ? " categories " : " entities "}
                                                                                                        found
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                    )}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}

                                                        <div className="row mt-20">
                                                            <Paginator />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        )}
                                    </TabContent>
                                </>
                            </form>
                        )}
                    />
                </div>
            </div>
            <Footer />
        </div>
)};