import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../../Components/Header/headerView';
import Footer from '../../../Components/Footer/footer';
import Menu from '../../../Components/Menu/menu';
import Paginator from '../../../Components/Paginator/paginator';
import { Modal } from "bootstrap";
import { connect } from "react-redux";
import workspacesService from '../../../Services/workspacesService';
import Select from "react-select";
import { Form, Field } from "react-final-form";
import { ImportedWorkspaceModal, ErrorWorkspaceModal } from '../../../Utils/modals';
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../../Components/Loader/actions";

const ImportWorkspace = props => {
  const user = props.user;
  const org = props.workspace.organization;
  const [workspaceID, setWorkspaceID] = useState("");
  const [selectedNLP, setSelectedNLP] = useState(null);
  const [nlpInstances, setNlpInstances] = useState([]);

  const dispatch = useDispatch();

  const getNLPInstances = async () => {
    dispatch(startLoading());
    let params = {
      organization: org
    };
    let res = await workspacesService.collectAllNlpInstances(params);
    if (res.status === 200) {
      console.log(res.data);
      let instances = res.data.map((instance) => ({
        value: instance.id,
        label: instance.name,
      }));
      setNlpInstances(instances);
    }
    dispatch(stopLoading());
  };

  useEffect(() => {
    getNLPInstances();
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("yes")
    const successModal = new Modal(document.getElementById("importedWorkspaceModal"));
    const errorDuplicatedModal = new Modal(document.getElementById("errorWorkspaceModal"));
    console.log("yes 2")
    const body = {
      user_id: user.id,
      nlp_instance: selectedNLP.value,
      external_workspace_id: workspaceID,
    };
    console.log(body);
    try {
      let res = await workspacesService.import(body);
      console.log(res);
      successModal.show();
    } catch (error) {
      if (error.response.status === 409) {
        errorDuplicatedModal.show();
      }
      else{
      }      
      console.log(error);
    }
  };

  const selectAdapter = ({ meta, ...rest }) => (
    <Select
      {...rest}
      id="NLP"
      className="creatable-select w-100"
      placeholder="Select NLP Engine instance"
      required
      isClearable
      options={nlpInstances}
      value={selectedNLP}
      onChange={(e) => setSelectedNLP(e)}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        control:
          meta?.error && meta?.touched
            ? (base, state) => ({
                ...base,
                borderColor: "#dc3545",
                boxShadow: "none",
                "&:hover": {
                  borderColor: "#dc3545",
                },
              })
            : (base, state) => ({
                ...base,
                borderColor: "#ced4da",
                boxShadow: "none",
                "&:hover": {
                  borderColor: "#ced4da",
                },
              }),
        colors: {
          ...theme.colors,
          primary25: "#e9ecef",
          primary: meta?.error && meta?.touched ? "#dc3545" : "#007bff",
          neutral90: meta?.error && meta?.touched ? "#dc3545" : "#495057",
        },
        height: "100%",
      })}
    />
  );

  return (
    <div>
      <ImportedWorkspaceModal />
      <ErrorWorkspaceModal />

      <Header />
      <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
        <div className="w-10">
          <Menu activeTab={7} />
        </div>
        <div className="container px-20 mt-50">
          <div className="row mt-20 mb-200">
            <div className="col-12">
              <p className="h1 -r">Import workspace</p>
            </div>
            <div className="col-12 col-lg-9 d-flex mt-40">
              <div className="card-w p-20 w-100">
                <div className="row">
                  <div className="col-12 col-lg-9 mt-40">
                    <p className="h4 -b">Importing from NLP Engine instance</p>
                    <Form
                      onSubmit={() => {}}
                      render={() => (
                        <div className="form-group w-100 mt-40">
                          <div className="form-group w-100 mt-40">
                            <Field
                              name="category"
                              id="category"
                              className="creatable-select w-100"
                              component={selectAdapter}
                            />
                            <label className="info" htmlFor="">
                              NLP Engine instance
                            </label>
                          </div>
                        </div>
                      )}
                    />

                    <div className="form-group w-100 mt-40">
                      <input
                        name="workspaceID"
                        type="text"
                        className="form-control"
                        placeholder="Workspace ID"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(e) => setWorkspaceID(e.target.value)}
                        value={workspaceID}
                      />
                      <label className="info" htmlFor="workspaceID">
                        Workspace ID
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 mt-40">
              <a href="/UserManage">
                <button
                  className="button -primary w-100"
                  onClick={handleSubmit}
                >
                  CONTINUE
                </button>
              </a>
              <a href="/UserManage">
                <button className="button -outline-primary mt-10 w-100">
                  CANCEL
                </button>
              </a>
            </div>
          </div>
          <div className="row mt-20">
            <Paginator />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    workspace: state.workspace,
  };
};

export default connect(mapStateToProps)(ImportWorkspace);
