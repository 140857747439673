import React, { Component, useEffect, useState } from "react";
import workspacesService from "../../Services/workspacesService";
import {
  setWorkspace,
  setAvailableWorkspaces,
  setValue,
  setRoles,
  setOrganizationsSlice,
  setOrganizationSlice,
} from "../../app/slices/workspaces/workspace";
import user, { setOrganization } from "../../app/slices/user/user";
import { connect, useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../Loader/actions";
import { setActions } from "../../app/slices/workspaces/workspace";
import usersService from "../../Services/usersService";

const Menu = (props) => {
  const [tabArray, setTabArray] = useState(["", "", "", "", "", "", "", "", "", ""]);
  const [workspaceOptions, setWorkspaceOptions] = useState(
    props.workspace.availableWorkspaces ?? []
  );
  const [userCredentials, setUserCredentials] = useState(
    props.workspace.credentials ?? []
  );
  const [currentWorkspace, setCurrentWorkspace] = useState(props.workspace.id);
  const [workspaceName, setWorkspaceName] = useState(props.workspace.name);
  const [usersViewPermission, setUsersViewPermission] = useState(props?.workspace?.actions?.users?.view ?? false);
  const [superadminPermission, setSuperadminPermission] = useState(props?.workspace?.actions?.app?.superadmin ?? false);
  const [homeViewPermission, setHomeViewPermission] = useState(props?.workspace?.actions?.home?.view ?? false);
  const [intentsViewPermission, setIntentsViewPermission] = useState(props?.workspace?.actions?.intents?.view ?? false);
  const [entitiesViewPermission, setEntitiesViewPermission] = useState(props?.workspace?.actions?.entities?.view ?? false);
  const [categoriesViewPermission, setCategoriesViewPermission] = useState(props?.workspace?.actions?.categories?.view ?? false);
  const [suggestionsViewPermission, setSuggestionsViewPermission] = useState(props?.workspace?.actions?.suggestions?.view ?? false);
  const [requestsViewPermission, setRequestsViewPermission] = useState(props?.workspace?.actions?.requests?.view ?? false);
  
  const [organizations , setOrganizations] = useState([]);
  const [currentOrganization, setCurrentOrganization] = useState(props?.workspace?.organization ?? "");

  const dispatch = useDispatch();

  const changeWorkspace = (id) => {
    //Get the current workspace
    let workspaces = workspaceOptions;
    let current = workspaces.find((workspace) => workspace.id === id);
    let roles =
    props.workspace.credentials.find(
      (element) => element.workspace_id === id
    ) ?? [];
    if (roles.roles) {
      roles = roles.roles.map((role) => role);
    }
    else{
      roles = [];
    }
    let workspaceName = current?.name ?? "";
    setWorkspaceName(workspaceName);
    dispatch(setWorkspace(current.id));
    dispatch(setRoles(roles));
    dispatch(setValue(current));
    //Navigate to home
    setTimeout(() => { window.location.href = "/"; }, 1000);
  };

  const changeOrganization = (id) => {
    //Get the current workspace
    let current = organizations.find((organization) => organization.id === id);
    console.log("Current", current);
    let org = current?.id ?? "";
    setCurrentOrganization(org);
    const payload = {
      organization_to_switch: org,
      organizations: props.user.organizations,
      is_super_admin: props.user.is_super_admin,
    }
    const setOrganizationPromise = new Promise((resolve, reject) => {
      dispatch(setOrganizationSlice(payload));
      resolve();
    });
    setOrganizationPromise.then(() => {
      console.log("Organization set");
      console.log("Credentials", props.workspace.credentials);
      setWorkspaceOptions([]);
      setCurrentWorkspace("");
      setWorkspaceName("");
      dispatch(setAvailableWorkspaces([]));
      dispatch(setWorkspace(""));
      dispatch(setValue({}));
      //Navigate to home
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    });
  };

  async function setWorkspaces(credentials, preventRefresh = false) {
    console.log("Credentials", credentials)
    let workspaces = [];
    if (credentials.length > 0) {
      console.log("Credentials 2")
      credentials.forEach((element) => {
        workspaces.push(element.workspace_id);
      });
      if (workspaces.length === 0) {
        return;
      }

      let currentWorkspaces = workspaceOptions;
      let currentWorkspacesIds = [];
      currentWorkspaces.forEach((workspace) => {
        currentWorkspacesIds.push(workspace);
      });

      currentWorkspacesIds.sort();
      workspaces.sort();

      //Check if the workspaces are the same
      currentWorkspacesIds.forEach((workspace, i) => {
        if (workspace !== workspaces[i]) {
          preventRefresh = false;
        }
      });

      if (
        preventRefresh &&
        currentWorkspaces.length > 0 &&
        workspaces.length > 0
      ) {
        console.log("vacios")
        return;
      }

      console.log("Workspaces", workspaces);

      const params = {
        workspace_ids: workspaces.join("&"),
        organization: props.workspace.organization,
      }

      let response = await workspacesService.collectMany(params);
      //console.log("Rquest", workspaces.join('&'));
      response = response.data;
      console.log("Response", response);
      if (response && response.length > 0) {
        //match for current workspace
        let currentWorkspace = response.find(
          (element) => element.id === props.workspace.id
        );
        if (!currentWorkspace) {
          currentWorkspace = response[0];
        }
        let roles = props.workspace.credentials.find(
          (element) => element.workspace_id === currentWorkspace.id
        ).roles

        if (props.workspace.is_admin){
          roles = [...roles, "Admin"];
        }
        console.log("Roles", roles)
        console.log("available workspaces", response)
        setWorkspaceOptions(response);
        setCurrentWorkspace(currentWorkspace);
        setWorkspaceName(currentWorkspace.name);
        dispatch(setAvailableWorkspaces(response));
        dispatch(setWorkspace(currentWorkspace.id));
        dispatch(setValue(currentWorkspace));
        dispatch(
          setRoles(
            roles
          )
        );
      }
    }
    else{
      console.log("No workspaces");
    }
  }

  const getOrganizations = async () => {
    let res = await workspacesService.collectAllOrganizations();
    if (res.status === 200) {
      console.log("Organizations", res.data);
      setCurrentOrganization(res.data[0].id);
      //dispatch(setOrganizationSlice(res.data[0].id));
      setOrganizations(res.data);
      dispatch(setOrganizationsSlice(res.data));
      let payload = {
        organization_to_switch: res.data[0].id,
        is_super_admin: props.user.is_super_admin,
      }
      dispatch(setOrganizationSlice(payload));
    }
  };

  useEffect(() => {
    console.log("Workspace", props.workspace);
    console.log("User", props.user);
    console.log("Organizations", organizations);
  }, [props.workspace, organizations]);

  const getActions = async (workspace) => {
    dispatch(startLoading());
    if (workspace) {
      let token = localStorage.getItem("token");
      try {
        let response = await usersService.getPermissions(workspace, token);
        console.log("Actions", response);
        if (response.data) {
          dispatch(setActions(response.data));
        }
      } catch (error) {
        console.error("Error obteniendo permisos", error);
      }
    }
    //await new Promise((resolve) => setTimeout(resolve, 2000));
    dispatch(stopLoading());
  };

  useEffect(() => {
    tabArray[props.activeTab] = "-active";
    setTabArray(tabArray);
    setUsersViewPermission(props?.workspace?.actions?.users?.view ?? false);
    setSuperadminPermission(props?.workspace?.actions?.workspaces?.view ?? false);
    setHomeViewPermission(props?.workspace?.actions?.home?.view ?? false);
    setIntentsViewPermission(props?.workspace?.actions?.intents?.view ?? false);
    setEntitiesViewPermission(props?.workspace?.actions?.entities?.view ?? false);
    setCategoriesViewPermission(props?.workspace?.actions?.categories?.view ?? false);
    setSuggestionsViewPermission(props?.workspace?.actions?.suggestions?.view ?? false);
    setRequestsViewPermission(props?.workspace?.actions?.requests?.view ?? false);
  }, [props.workspace]);

  useEffect(() => {
    if (superadminPermission) {
      getActions(props.workspace.value);
    }
    else{
      getActions(props.workspace.id);
    }
  }, [props.workspace.id]);

  useEffect(() => {
    console.log(props)
    console.log("0")
    if (props?.workspace?.organizations?.length > 0) {
      console.log("here 1")
      setCurrentOrganization(props?.workspace?.organization);
      setOrganizations(props?.workspace?.organizations);
    }
    else if (superadminPermission && props?.workspace?.availableWorkspaces?.length === 0) {
      console.log("here 2")
      getOrganizations();
    }
    console.log("Organizations", organizations);
    console.log("superadmin", superadminPermission);
    console.log("available workspaces", props?.workspace?.availableWorkspaces);
  }, [props?.workspace?.organizations, superadminPermission]);


  useEffect(() => {
    //Update the workspace options when there are changes in the store workspace
    if (!workspaceOptions && props.workspace.availableWorkspaces.length > 0) {
      console.log("1");
      setWorkspaceOptions(props.workspace.availableWorkspaces);
    }
    if (!userCredentials || userCredentials !== props.workspace.credentials) {
      console.log("2");
      setUserCredentials(props.workspace.credentials);
    }

    //If empty get the workspaces
    if (workspaceOptions.length === 0 && (!props.workspace.availableWorkspaces || props.workspace.availableWorkspaces.length === 0)) {
      console.log("3");
      setWorkspaces(props.workspace.credentials, true);
    }

  }, [props, userCredentials, workspaceOptions]);


  const workspacesDropdown = () => {
    return (
      <div className="dropdown-item" style={{ width: "155px" }}>
        <p className="text -i ">Chatbot</p>
        <button
          className="-outline-dropdown dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style={{ width: "100%" }}
        >
          {currentWorkspace &&
            (workspaceName.length > 12
              ? workspaceName.substring(0, 12) + "..."
              : workspaceName)}
        </button>
        <ul className="dropdown-menu">
          {workspaceOptions.map((workspace, index) => {
            return (
              <li key={index}>
                <a
                  className={`dropdown-item ${
                    workspaceName === workspace.name ? "-active" : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    changeWorkspace(workspace.id);
                  }}
                >
                  {workspace.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const OrganizationsDropdown = () => {
    return organizations?.length>1? (
      <div className="dropdown-item" style={{ width: "155px" }}>
        <p className="text -i " style={{ color: "#354DE8" }}>Organization</p>
        <button
          className="-outline-dropdown dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style={{
            width: "100%",
            borderColor: "#354DE8",
            color: "#FFFFFF",
            backgroundColor: "#354DE8"
          }}
        >
          {currentOrganization &&
            (currentOrganization.length > 12
              ? currentOrganization.substring(0, 12) + "..."
              : currentOrganization)}
        </button>
        <ul className="dropdown-menu">
          {organizations.map((organization, index) => {
            return (
              <li key={index}>
                <a
                  className={`dropdown-item ${
                    currentOrganization === organization.id ? "-active" : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    changeOrganization(organization.id);
                  }}
                >
                  {organization.id}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    ):<>{currentOrganization &&
      (currentOrganization.length > 18
        ? currentOrganization.substring(0, 18) + "..."
        : currentOrganization)}</>;
  };

  return (
    <>
      <div className="side-navbar d-none d-md-block">
        <div className="navbar-nav me-auto bg-white">
          <div className="dropdown-item">
            <div className="menu-header-logo">
              {/* <img src={logo} alt="logo" /> */}
            </div>
            <div className="nav-item m-10">
                {organizations?.length > 0 ? (
                  OrganizationsDropdown()
                ) : (
                  <p className="text -i">No organizations</p>
                )}
              </div>
            {!superadminPermission && (
              <div className="nav-item m-10 mt-30">
                {workspaceOptions?.length > 0 ? (
                  workspacesDropdown()
                ) : (
                  <p className="text -i">No workspaces</p>
                )}
              </div>
            )}
            
              

          </div>
          <div className="menu-body">
            {homeViewPermission && (
            <div className="nav-item">
              <a href="/" className={`nav-link menu-item ${tabArray[0]}`}>
                <i className="fa-solid fa-home "></i>
                <p className="text -tabs">Home</p>
              </a>
            </div>
            )}
            {requestsViewPermission && (
            <div className="nav-item">
              <a
                href="/requests"
                className={`nav-link menu-item ${tabArray[1]}`}
              >
                <i className="fa-solid fa-envelope "></i>
                <p className="text -tabs">Requests</p>
              </a>
            </div>
            )}
            {intentsViewPermission && (
            <div className="nav-item">
              <a
                href="/intents"
                className={`nav-link menu-item ${tabArray[2]}`}
              >
                <i className="fa-solid fa-code-branch "></i>
                <p className="text -tabs">Intents</p>
              </a>
            </div>
            )}
            {entitiesViewPermission && (
            <div className="nav-item">
              <a
                href="/entities"
                className={`nav-link menu-item ${tabArray[3]}`}
              >
                <i className="fa-solid fa-key "></i>
                <p className="text -tabs">Entities</p>
              </a>
            </div>
            )}
            {categoriesViewPermission && (
            <div className="nav-item">
              <a
                href="/categories"
                className={`nav-link menu-item ${tabArray[4]}`}
              >
                <i className="fa-solid fa-star "></i>
                <p className="text -tabs">Categories</p>
              </a>
            </div>
            )}
            {suggestionsViewPermission && (
            <div className="nav-item">
              <a
                href="/jobs"
                className={`nav-link menu-item ${tabArray[5]}`}
              >
                <i className="fa-solid fa-wand-sparkles "></i>
                <p className="text -tabs">Jobs</p>
              </a>
            </div>    
            )}
            {usersViewPermission && (
              <>
                <div className="nav-item">
                  <a
                    href="/usermanage"
                    className={`nav-link menu-item ${tabArray[6]}`}
                  >
                    <i className="fa-solid fa-users "></i>
                    <p className="text -tabs">Users</p>
                  </a>
                </div>
              </>
              )}
              {superadminPermission && (
              <>
              
                <div className="nav-item">
                  <a
                    href="/workspacemanage"
                    className={`nav-link menu-item ${tabArray[7]}`}
                  >
                    <i className="fa-solid fa-list "></i>
                    <p className="text -tabs">Workspaces</p>
                  </a>
                </div>

                <div className="nav-item">
                  <a
                    href="/adminsmanage"
                    className={`nav-link menu-item ${tabArray[8]}`}
                  >
                    <i className="fa-solid fa-users "></i>
                    <p className="text -tabs">Admins</p>
                  </a>
                </div>

                <div className="nav-item">
                  <a
                    href="/organizationsmanage"
                    className={`nav-link menu-item ${tabArray[9]}`}
                  >
                    <i className="fa-solid fa-building "></i>
                    <p className="text -tabs">Organizations</p>
                  </a>
                </div>
              
              </>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    workspace: state.workspace,
  };
};

export default connect(mapStateToProps)(Menu);
