import API from "./axios";

const intentsService = {

    //Endpoint to check if an intent with a given name exists.
    exists: async (params) => {
        return await API.get("/intents/exists", { params });
    },

    //Endpoint to fetch an intent by its ID.
    collectOne: async (params) => {
        return await API.get("/intents/collect_one", { params });
    },
    
    //Endpoint to fetch all intents from a workspace.
    collectAll: async (params) => {
        return await API.get("/intents/collect_all", { params });
    },
    
    //Endpoint to fetch multiple intents by their IDs.
    collectMany: async (workspace_id, intent_ids) => {
        return await API.get(`/intents/collect_many/${workspace_id}`, {
        params: {
            intent_ids,
        },
        });
    },

};

export default intentsService;