import API from "./axios";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const usersService = {

  //Endpoint to get user permissions.
  getPermissions: async (workspace_id: string, token: string) => {
    return await API.get(`/users/getPermissions?workspace_id=${workspace_id}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },

  getSuperAdminPermissions: async (token: string) => {
    return await API.get(`/users/getSuperAdminPermissions`, {
      headers: { Authorization: `Bearer ${token}` }
    });
  },
  
  //Endpoint to retrieve user information.
  collectOne: async (params) => {
    try {
      const res = await API.get("/users/collect_one", { params });
      return res.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },

  //Endpoint for user authentication and login.
  login: async (body) => {
      return await API.post("/users/login", body);
  },

  //Endpoint to create a new user.
  create: async (params, body) => {
      return await API.post("/users/create", body, { params });
  },

  //Endpoint to delete a user.
  delete: async (params) => {
      return await API.delete("/users/delete?full_delete=false", { params });
  },

  //Endpoint to activate a user.
  activate: async (params) => {
      return await API.post("/users/activate", { params });
  },

  //Endpoint to retrieve users information.
  collectMany: async (params) => {
      return await API.get("/users/collect_many", { params });
  },

  //Endpoint to edit users.
  update: async (params, body) => {
      return await API.post("/users/update", body, { params });
  },

  //Endpoint to send a password recovery email.
  recoverPassword: async (params) => {
      return await API.post("/users/passRecovery", { params });
  },

  collectAllAdminsByOrganization: async (params) => {
    return await API.get("/users/collect_all_admins_by_organization", { params });
  },

  createAdmin: async (params, body) => {
    return await API.post("/users/create_admin", body, { params });
  },

  deleteAdmin: async (params) => {
    return await API.delete("/users/delete_admin", { params });
  },

  collectOneSuperAdmin: async (params) => {
    return await API.get("/users/collect_one_super_admin", { params });
  },

};

export default usersService;