import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../../../Components/Header/headerView';
import Footer from '../../../../Components/Footer/footer';
import Menu from '../../../../Components/Menu/menu';
import Paginator from '../../../../Components/Paginator/paginator';
import { Modal } from "bootstrap";
import { connect } from "react-redux";
import workspacesService from '../../../../Services/workspacesService';
import Select from "react-select";
import { Form, Field } from "react-final-form";
import { ImportedWorkspaceModal, ErrorWorkspaceModal } from '../../../../Utils/modals';
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../../../Components/Loader/actions";
import workspace from '../../../../app/slices/workspaces/workspace';

const ManageNlp = props => {
  console.log(props);
  const user = props.user;
  const org = props.workspace.organization;
  const [nlpInstances, setNlpInstances] = useState([]);
  const [nlpInstancesPage, setNlpInstancesPage] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 3;

  const dispatch = useDispatch();

  const getNLPInstances = async () => {
    dispatch(startLoading());
    let params = {
      organization: org
    };
    let res = await workspacesService.collectAllNlpInstances(params);
    if (res.status === 200) {
      console.log(res.data);

      setNlpInstances(res.data);
      pageSize < res.data.length
        ? setTotalPages(Math.ceil(res.data.length / pageSize))
        : setTotalPages(1);

      let tempInstancesPage = res.data.slice(
        (page - 1) * pageSize,
        page * pageSize
      );
      setNlpInstancesPage(tempInstancesPage);
    }
    dispatch(stopLoading());
  };

  const handlePageChange = (page) => {
    setPage(page);
    let tempInstancesPage = nlpInstances.slice(
      (page - 1) * pageSize,
      page * pageSize
    );
    setNlpInstancesPage(tempInstancesPage);
  };

  useEffect(() => {
    getNLPInstances();
  }, [user]);

  return (
    <div>
      <ImportedWorkspaceModal />
      <ErrorWorkspaceModal />

      <Header />
      <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
        <div className="w-10">
          <Menu activeTab={7} />
        </div>
        <div className="container px-20 mt-50">
          <div className="row mt-20 mb-50">
            <div className="col-12 mb-50">
              <p className="h1 -r">Manage NLP Engine instances</p>
            </div>
            <div className="col-3">
                <button
                  className="button -primary"
                  onClick={() => {window.location.href = "/createnlp"}}
                >
                  CREATE NLP Engine instance
              </button>
            </div>
          </div>

          <div className="row">
        {nlpInstancesPage.length > 0 ? (
          <>
        {nlpInstancesPage.map((nlpInstance, index) => (
          <div className="col-3 card-w d-flex flex-column align-items-center justify-content-center p-20 mx-50" key={index}>
            <Link to={`/editnlp/${nlpInstance.id}`}>
              <div className="row mx-0 mb-20">
                <div className="col-12 p-0 text-center">
                  <p className="h3">{nlpInstance.name}</p>
                </div>
              </div>
              <div className="row text-center">
                <div className="col-6">
                  <p className="text mb-10">
                    <i className="fa-solid fa-users color-gray"></i>
                    Organization
                  </p>
                  <p className="text mb-10">
                    <i className="fa-solid fa-cog color-gray"></i>
                    Engine
                  </p>
                  <p className="text mb-10">
                    <i className="fa-solid fa-file color-gray"></i>
                    Version
                  </p>
                </div>
                <div className="col-6">
                  <p className="text text-c mb-10">
                  {nlpInstance.organization}
                  </p>
                  <p className="text text-c mb-10">
                  {(nlpInstance.engine).charAt(0).toUpperCase() + (nlpInstance.engine).slice(1)}
                  </p>
                  <p className="text text-c mb-10">
                  {nlpInstance.nlp_version}
                  </p>
                </div>
              </div>
            </Link>
          </div>
        ))}
        </>
        ):(
          <div className="col-12 text-center mt-100 mb-100">
            <p className="h3">No NLP Engine instances found</p>
          </div>
        )}
        </div>
        
        {nlpInstances?.length > pageSize && (
          <div className="row mt-20">
            <Paginator
              pages={totalPages}
              currentPage={page}
              setPage={handlePageChange}
            />
          </div>
        )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    workspace: state.workspace,
  };
};

export default connect(mapStateToProps)(ManageNlp);
