import React, { Component } from 'react';
import './footer.scss';

class Footer extends Component {
  render() {
    return (
      <div className="footer bg-black">
        <div className="container py-50">
          <div className="site-name">
            <p className="h4 -b color-white mb-40">Re entrenamiento</p>
          </div>
          <div className="site-links">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-3 mb-20">
                <p className="info -b mb-20 color-white">Site Map</p>
                <a href="/home" className="color-white"><p className="info -l mb-10 color-white">Home</p></a>
                <a href="/intents" className="color-white"><p className="info -l mb-10 color-white">Intents</p></a>
                <a href="/requests" className="color-white"><p className="info -l mb-10 color-white">Requests</p></a>
                <a href="/entities" className="color-white"><p className="info -l mb-10 color-white">Entities</p></a>
                <a href="/categories" className="color-white"><p className="info -l mb-10 color-white">Categories</p></a>
                <a href="/jobs" className="color-white"><p className="info -l mb-10 color-white">Jobs</p></a>
                
              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-20">
                <p className="info -b mb-20 color-white">Support</p>
                <a href="mailto:support@ndscognitivelabs.com" className="color-white"><p className="info -l mb-10 color-white">support@ndscognitivelabs.com</p></a>
                <a href="tel:556789-3394" className="color-white"><p className="info -l mb-10 color-white">556789-3394</p></a>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-20">
                <p className="info -b mb-20 color-white">Social media</p>
                <a href="https://www.facebook.com/NDSCognitiveLabs" className="color-white"><p className="info -l mb-10 color-white">Facebook/NDScognitivelabs</p></a>
                <a href="https://www.linkedin.com/company/nds-cognitive-labs/mycompany/" className="color-white"><p className="info -l mb-10 color-white">Linkedin/NDScognitivelabs</p></a>
                <a href="https://twitter.com/ndscognitivelab?lang=es" className="color-white"><p className="info -l mb-10 color-white">Twitter/NDScognitivelabs</p></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
