import React, { useState, useEffect, useRef } from "react";
import { Modal } from "bootstrap";
import Select from "react-select";
import { connect } from "react-redux";
import { v4 as uuid } from "uuid";
import FileSaver from "file-saver";
import * as arrayService from "../../../Services/array";
import * as interactionService from "../../../Services/interaction";
import { hideModals } from "../../../Utils/modals";
import { categoryName, categoryDescription } from "../../../Utils/regexValidations";
import { entityName, entitySynonyms } from "../../../Utils/regexValidations";
import { CreateEntityScreen } from "./createEntityScreen";
import * as dateService from "../../../Utils/format/date";
import categoriesService from "../../../Services/categoriesService";
import requestsService from "../../../Services/requestsService";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../../Components/Loader/actions";

const CreateEntity = (props) => {
  const workspace = props.workspace.id;
  const user = props.user.id;

  const [loadData, setLoadData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [categories, setCategories] = useState(props.categories);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [editingIndex, setEditingIndex] = useState(undefined);
  const [addedOn, setAddedOn] = useState([]);
  const [entityNameC, setEntityName] = useState("");
  const [synonymsList, setSynonymsList] = useState([]);
  const [newCategory, setNewCategory] = useState(false);
  const [categoryDescriptionC, setCategoryDescription] = useState("");
  const [inputDescription, setInputDescription] = useState("");
  const [intentOptions, setIntentOptions] = useState([]);
  const [selectedIntents, setSelectedIntents] = useState([]);
  const [linkedIntents, setLinkedIntents] = useState([]);
  const [intentsButton, setIntentsButton] = useState("-inactive");
  const [checked, setChecked] = useState(new Set([]));
  const [allChecked, setAllChecked] = useState(false);
  const deleteRef = useRef({});
  const synonymInputRef = useRef();
  const [adminNextStatus, setAdminNextStatus] = useState("Select");
  const [errorMessage, setErrorMessage] = useState("Something went wrong. Please try again.");
  const [validationError, setValidationError] = useState(false);
  const [nameError, setNameError] = useState(null);
  const [nameEnt, setNameEntError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [errorUniqueName, setErrorUniqueName] = useState(false);
  const [nameUsedError, setNameUsedError] = useState("");

  const dispatch = useDispatch();

  //Validations
   
  const nameTest = /^[a-zA-Z0-9_\-\.áéíóúÁÉÍÓÚüÜ]+$/; //Name with no spaces, just alphanumeric, underscore hyphen and dot
  const namePrefixTest = /^(?!sys-|sys_)/; //Name can not begin with the prefix "sys-" or "luis-"

  function duplicateEntityModal() {
    return (
      <div
        className="modal fade"
        id="duplicateEntityModal"
        tabIndex="1"
        aria-labelledby="errorModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p className="h2" id="errorModalLabel">
                Intent not created
              </p>
              <button
                type="button"
                className="button-close w-100"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body">
              <p className="text"> You tried to create an intent that already exists</p>
            </div>
            <div className="modal-footer">
              <div className="row w-100">
                <div className="col-12 col-md-6 mb-10">
                  <button
                    className="button -primary w-100"
                    data-bs-dismiss="modal"
                  >
                    RETURN
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }


  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined
      );
  const required = (value) => (value ? undefined : "Required");
  const maxLength = (max) => (value) =>
    value && value.length > max
      ? `Must be ${max} characters or less`
      : undefined;
  const name = (value) =>
    value && !categoryName.test(value) ? "Invalid name" : undefined;
  const nameEntC = (value) =>
    value && !entityName.test(value) ? "Invalid name" : undefined;
  const namePrefix = (value) =>
    value && !namePrefixTest.test(value) ? "Invalid name" : undefined;
  const categoryNameC = (value) =>
    (value.label ? !categoryName.test(value.label) : !categoryName.test(value))
      ? "Invalid name"
      : undefined;
  const description = (value) =>
    value && !categoryDescription.test(value) ? "Invalid description" : undefined;
  const syn = (value) =>
    value && !entitySynonyms.test(value) ? "Invalid synonym" : undefined;
  const minLength = (value) => {
    return value && !/^.{4,}$/.test(value)
      ? "Must have at least 4 characters"
      : undefined;
  };
  const validateUniqueName = () => {
    return errorUniqueName || (entityNameC === nameUsedError) ? "Entity already exists" : undefined;
  };

  const handleChangeError = (event, field, initial, type) => {
    let newValue = "";
    if(initial){
      newValue = event;
    }
    else{
      newValue = event.target.value;
    }
    console.log("text ",newValue);
    if (field === "name"){
      if (type === "category"){
        if (newValue && newValue.label !== undefined){
          newValue = newValue.label;
        }
        console.log("text Cat",newValue);
        const error = composeValidators(
          required,
          name,
          namePrefix,
          maxLength(64),
          minLength
        )(newValue);
        setNameError(error);
        console.log("Error:", error)
      }
      else{
        const error = composeValidators(
          required,
          nameEntC,
          namePrefix,
          maxLength(64),
          minLength
        )(newValue);
        setNameEntError(error);
        console.log("Error:", error)
      }
    }
    else if (field === "description"){
      if (type === "category"){
        const error = composeValidators(
          maxLength(128)
        )(newValue);
        setDescriptionError(error);
      }
      else{
        const error = composeValidators(
          maxLength(128)
        )(newValue);
        setDescriptionError(error);
      }    
    }
  };

  useEffect(() => {
    if (nameError || descriptionError || nameEnt || synonymsList.length === 0 || entityNameC === "" || selectedCategory === null || selectedCategory === "") {
      setValidationError(true);
      console.log("hay error");
    }
    else{
      setValidationError(false);
      console.log("no hay error");
    }
  }, [nameError, descriptionError, nameEnt, synonymsList, entityNameC, selectedCategory]);

  useEffect(() => {
    if (errorUniqueName) {
      if (nameUsedError !== entityNameC) {
        setErrorUniqueName(false);
      }
    }
  }, [entityNameC]);

  const uniqueField = (value) => {
    if (
      !isNaN(editingIndex) &&
      editingIndex === synonymsList.map((e) => e.value).indexOf(value)
    )
      return undefined;
    return synonymsList?.find(
      (e) => e?.value?.toLowerCase() === (value || "").toLowerCase()
    )
      ? "Synonyms must be unique"
      : undefined;
  };
  const noSpaces = (value) => {
    return /[\s]/.test(value) ? "This value must not have spaces" : undefined;
  };

  //Check if the synonym is already in the list
  const isSynonymInList = (example) => {
    let found = false;
    synonymsList.forEach((item) => {
      if (item.value === example) {
        found = true;
      }
    });
    return found;
  };

  const selectAdapter = ({meta, ...rest }) => (
    <>
      <Select
        {...rest}
        id="category"
        className="creatable-select w-100"
        placeholder="Select category"
        required
        isClearable
        options={categories}
        value={selectedCategory}
        onChange={(e) => [setSelectedCategory(e), handleChangeError(e, "name", true, "category"),]}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,

          control:
            meta?.error && meta?.touched && nameError
              ? (base, state) => ({
                  ...base,
                  borderColor: "#dc3545",
                  boxShadow: "none",
                  "&:hover": {
                    borderColor: "#dc3545",
                  },
                })
              : (base, state) => ({
                  ...base,
                  borderColor: "#ced4da",
                  boxShadow: "none",
                  "&:hover": {
                    borderColor: "#ced4da",
                  },
                }),
          colors: {
            ...theme.colors,
            primary25: "#e9ecef",
            primary: meta?.error && meta?.touched && nameError ? "#dc3545" : "#007bff",
            neutral90: meta?.error && meta?.touched && nameError ? "#dc3545" : "#495057",
          },
          height: "100%",
        })}
      />
      <label
        htmlFor="category"
        data-toggle="tooltip"
        title="Category names must be unique and can only contain alphanumeric characters, underscores, hyphens, and dots. Maximum length is 64 characters."
        style={(meta?.error && meta?.touched && nameError) || !selectedCategory ? { color: "red" } : null}
      >
        {meta.error && meta.touched && nameError ? (
          <span className="error">
            <i className="fas fa-exclamation-circle fa-sm"></i>
            {meta.error}
          </span>
        ) : !selectedCategory ? (
          <span className="error">
          <i className="fas fa-exclamation-circle fa-sm"></i>
            You must add a category
          </span>
        ): (
          "Category"
        )}
      </label>
    </>
  );

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  //Get categories
  async function getCategories() {
    dispatch(startLoading());
    
    console.log("getCategories", workspace);

    let params = {
      workspace_id: workspace,
    };

    try{
      const res = await categoriesService.collectAll(params);
      createOptions(res.data.categories);
      console.log("catgcall", res.data.categories);
      setLoadData(false);
    }
    catch(err){
      console.log(err);
    }
    dispatch(stopLoading());
}

  const createOptions = (categories) => {
    let options = [];
    for (var i = 0; i < categories.length; i++) {
      options.push({
        value: categories[i].id,
        label: categories[i].name,
      });
    }
    console.log("options", options);
    setCategories(options);
  };

  const addSynonym = (value) => {
    if (isNaN(editingIndex)) {
      const synonym = value.newSynonymName;
      if (synonym !== "") {
        setSynonymsList([...synonymsList, { value: synonym, _id: uuid() }]);
        setAddedOn([...addedOn, new Date()]);
        window.setFormValue("newSynonymName", "");
      }
    } else {
      const newSynonymList = [...synonymsList];
      newSynonymList[editingIndex].value = value.newSynonymName;
      addedOn[editingIndex] = new Date();
      setSynonymsList(newSynonymList);
      setEditingIndex(undefined);
      window.setFormValue("newSynonymName", "");
    }
  };

  const removeSynonym = (synonymIndex) => {
    if (synonymIndex > -1) {
      synonymsList.splice(synonymIndex, 1);
      setSynonymsList([...synonymsList]);
    }
  };

  async function createEntity() {
    hideModals();
    setLoading(true);

    let categoryID = selectedCategory.value;

    let synonyms = [];
    synonymsList.forEach((synonym) => {
      synonyms.push(synonym.value);
    });

    let params = {
      workspace_id: workspace,
      user_id: user,
    };

    let changes = [
      {
        "field": "name",
        "operation": "create",
        "value": entityNameC
      },
      {
        "field": "description",
        "operation": "create",
        "value": inputDescription
      },
      {
        "field": "category",
        "operation": "create",
        "value": categoryID
      },
      ...synonyms.map(synonym => ({
        field: "synonyms",
        operation: "create",
        value: synonym,
      }))
    ]

    let body = {
      object_type: "entity",
      changes: changes,
    }

    console.log("body", body);
    console.log("params", params);

    try{
      let res = await requestsService.create(params, body);
      console.log(res);
      setLoading(false);
      var modal = new Modal(document.getElementById("createModal"), {
        keyboard: false,
      });
      modal.show();
    }
    catch(err){
      if (err?.response?.data?.detail === "400: Object already exists.") {
        setErrorUniqueName(true);
        setNameUsedError(categoryNameC)
      }
      console.log(err);
    }
  }

  useEffect(() => {
    if (loadData) {
      getCategories();
    }
    if (selectedCategory !== null && selectedCategory.value === 0) {
      setNewCategory(true);
    } else {
      setNewCategory(false);
    }
  }, [loadData, selectedCategory]);

  function handleCancelEdition() {
    setEditingIndex(undefined);
    window.setFormValue("newSynonymName", "");
    synonymInputRef.current.focus();
  }

  function handleEdition(e, synonym, index) {
    e.preventDefault();
    let tempSynonyms = [...synonymsList];
    // tempSynonyms.splice(tempSynonyms.indexOf(synonym),1)
    setSynonymsList(tempSynonyms);
    synonymInputRef.current.focus();
    setEditingIndex(index);
    setTimeout(() => {
      synonymInputRef.current.select();
      window.setFormValue("newSynonymName", synonym.value);
    }, 100);
  }

  const handleCheck = (object) => {
    var newChecked = new Set(checked);
    var isChecked = newChecked.has(object?._id);
    isChecked ? newChecked.delete(object?._id) : newChecked.add(object?._id);
    if (allChecked && isChecked) {
      setAllChecked(false);
    }
    setChecked(newChecked);
  };

  const handleAllCheck = (event, value) => {
    if (event.target.checked) {
      setChecked(new Set([...checked, ...synonymsList.map((e) => e._id)]));
    } else {
      setChecked(new Set());
    }
    setAllChecked(event.target.checked);
  };

  async function handleDownloadSelection(e) {
    let columns = [
      ...e.target.parentElement.parentElement.parentElement.parentElement.parentElement
        .getElementsByTagName("table")[0]
        .getElementsByTagName("tr")[0]
        .getElementsByTagName("th"),
    ].map((e) => e.innerText);
    let data = [
      ...e.target.parentElement.parentElement.parentElement.parentElement.parentElement
        .getElementsByTagName("table")[0]
        .getElementsByTagName("tr"),
    ]
      .slice(1)
      .filter((e) => checked.has(e.id))
      .map((e) =>
        [...e.getElementsByTagName("td")].map((e) =>
          e.innerText.replace("\n\n", "->")
        )
      );
    let columnIndexes = arrayService.findIndices(
      columns,
      (e) => e && e !== "Actions"
    );
    let str =
      columns.filter((e, i) => columnIndexes.includes(i)).join(",") +
      "\r\n" +
      data
        .map((e) => e.filter((e, i) => columnIndexes.includes(i)).join(","))
        .join("\r\n");

    FileSaver.saveAs(
      new Blob([str], {
        type: "data:text/csv;charset=utf-8,",
      }),
      `list.csv`
    );
  }

  const handleDeleteSelection = () => {
    [...checked].forEach((e) => {
      if (e in deleteRef.current && deleteRef.current[e] !== null) {
        interactionService.simulateMouseClick(deleteRef.current[e]);
      }
    });
  };

  return (
    <CreateEntityScreen
      _this={{
        props,
        loadData,
        loading,
        activeTab,
        categories,
        selectedCategory,
        editingIndex,
        addedOn,
        entityNameC,
        synonymsList,
        newCategory,
        categoryDescriptionC,
        inputDescription,
        selectedIntents,
        linkedIntents,
        intentsButton,
        checked,
        allChecked,
        deleteRef,
        synonymInputRef,
        adminNextStatus,
        errorMessage,
        validationError,
        nameError,
        nameEnt,
        descriptionError,
        dateService,
        handleChangeError,
        composeValidators,
        required,
        maxLength,
        name,
        nameEntC,
        namePrefix,
        categoryNameC,
        description,
        syn,
        minLength,
        uniqueField,
        noSpaces,
        isSynonymInList,
        selectAdapter,
        toggle,
        addSynonym,
        removeSynonym,
        createEntity,
        handleCancelEdition,
        handleEdition,
        handleCheck,
        handleAllCheck,
        handleDownloadSelection,
        handleDeleteSelection,
        duplicateEntityModal,
        setEntityName,
        setInputDescription,
        validateUniqueName,
        errorUniqueName,
        nameUsedError,
      }}
    />
  );
  
};

const mapStateToProps = (state) => {
  return {
    workspace: state.workspace,
    user: state.user,
  };
};

export default connect(mapStateToProps)(CreateEntity);
