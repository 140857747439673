import { get } from "http";
import API from "./axios";

const activitiesService = {

    //Retrieves an activity based on the provided object ID tied to a request.
    collect_one: async (params) => {
        return await API.get("/activities/collect_one", { params });
    },

};

    export default activitiesService;