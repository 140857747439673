export const START_REQUEST_LOADING = 'START_REQUEST_LOADING';
export const STOP_REQUEST_LOADING = 'STOP_REQUEST_LOADING';

export const startRequestLoading = () => ({
  type: START_REQUEST_LOADING,
});

export const stopRequestLoading = () => ({
  type: STOP_REQUEST_LOADING,
});
