import React, { useState } from 'react';
import Header from '../../../Components/Header/headerView';
import Footer from '../../../Components/Footer/footer';
import Menu from '../../../Components/Menu/menu';
import Paginator from '../../../Components/Paginator/paginator';
import { Modal } from "bootstrap";
import { connect } from "react-redux";
import workspacesService from '../../../Services/workspacesService';
import { setAvailableWorkspaces } from "../../../app/slices/workspaces/workspace";
import { useDispatch } from "react-redux";

const CreateOrganization = props => {
  const user = props.user;
  const [organizationName, setOrganizationName] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const successModal = new Modal(document.getElementById("createdOrganizationModal"));
    const body = {
      name: organizationName,
    };
    console.log(body);
    try {
      let res = await workspacesService.createOrganization(body);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
    finally {
      let res = await workspacesService.collectAllOrganizations();
      if (res.status === 200) {
        dispatch(setAvailableWorkspaces(res.data));
        console.log("Organizations", res.data);
        successModal.show();
      }
    }
  };

  return (
    <div>
      <div
      className="modal fade"
      id="createdOrganizationModal"
      tabIndex="1"
      aria-labelledby="approveModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h2" id="approveModalLabel">
              Organization created
            </p>
            <button
              type="button"
              className="button-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body d-flex flex-column align-items-center justify-content-center">
            <p className="text">
              The organization has been created successfully.
            </p>
          </div>
          <div className="modal-footer">
            <div className="row w-100">
                <button className="button -primary" data-bs-dismiss="modal" onClick={() => (window.location.href = "/organizationsmanage")}>
                  CONTINUE
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>

      <Header />
      <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
        <div className="w-10">
          <Menu activeTab={9} />
        </div>
        <div className="container px-20 mt-50">
          <div className="row mt-20 mb-200">
            <div className="col-12">
              <p className="h1 -r">New organization</p>
            </div>
            <div className="col-12 col-lg-9 d-flex mt-40">
              <div className="card-w p-20 w-100">
                <div className="row">
                  <div className="col-12 col-lg-9 mt-40">
                    <p className="h4 -b">Create organization</p>
                    <div className="form-group w-100 mt-40">
                      <input
                        name="organizationName"
                        type="text"
                        className="form-control"
                        placeholder="Organization Name"
                        aria-describedby="basic-addon2"
                        onChange={(e) => setOrganizationName(e.target.value)}
                        value={organizationName}
                      />
                      <label className="info" htmlFor="organizationName">
                        Organization Name
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 mt-40">
                <button
                  className="button -primary w-100"
                  onClick={handleSubmit}
                >
                  CONTINUE
                </button>
              <a href="/organizationsmanage">
                <button className="button -outline-primary mt-10 w-100">
                  CANCEL
                </button>
              </a>
            </div>
          </div>
          <div className="row mt-20">
            <Paginator />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(CreateOrganization);
