import React, { useState, useEffect } from "react";
import firebaseApp from "../../firebase";
import { getAuth } from "firebase/auth";
import { store, persistor } from "../../app/store";
import { logout } from "../../app/slices/user/user";
import {
  logOut
} from "../../app/slices/workspaces/workspace";
import { connect } from "react-redux";

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [first_name, setFirst_name] = useState(props.user.first_name);
  const [first_surname, setFirst_surname] = useState(props.user.first_surname);
  const [second_surname, setSecond_surname] = useState(props.user.second_surname);
  const [rolesOptions, setRolesOptions] = useState(props.workspace.roles);
  const wrapperRef = React.createRef();
  const [editUserPermission, setEditUserPermission] = useState(props.workspace?.actions?.users?.update ?? false);
  const [editWorkspacePermission, setEditWorkspacePermission] = useState(props.workspace?.actions?.workspaces?.update ?? false);
  const [superadminPermission, setSuperadminPermission] = useState(props?.workspace?.actions?.app?.superadmin ?? false);

  const auth = getAuth(firebaseApp);

  const handleLogout = () => {
    store.dispatch(logOut());
    store.dispatch(logout());
    persistor.pause();
    persistor.flush().then(() => {
      return persistor.purge();
    });
    window.location.href = '/';
  };

  const viewRoles = () => {
    let currentRole = store.getState().user.role;
    let roles = [...rolesOptions];
    if (roles === undefined || roles.length === 0) {
      if (props.user.is_super_admin){
        roles = ["Super Admin"]
      }
      else{
        roles = ["Viewer"];
      }
    }
    roles = new Set(roles);
    roles = [...roles];

    return roles.map((role, index) => {
      let roleName = role

      return (
        <div key={index} className={"profileWrapper"}>
          <div className="profile" value={role}>
            <i className="icon fa-solid fa-check"></i>
            {roleName}
          </div>
        </div>
      );
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        isOpen
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, isOpen]);

  return (
    <div className="header">
      <div className="header-wrapper">
        <div className="toggle d-md-none">
          <button className="handler">
            <i className="fa-solid fa-bars"></i>
          </button>
        </div>
        <a href="/home">
          <div className="d-flex">
            <p className="header-title d-none d-md-block">Re entrenamiento</p>
          </div>
        </a>
        {
          props.user?.id && (
          <div className="header-user px-10" ref={wrapperRef}>
            <div
              className="col-12 d-flex align-items-center"
              onClick={() => setIsOpen(!isOpen)}
            >
              <div className="col-3">
                <div className={`user-img -User`}>
                  <p className="user-name">{first_name.charAt(0)}</p>
                </div>
              </div>
              <div className="col-7 w-120 d-none d-md-block">
                <p className="header-name">
                  {first_name.split(" ").length > 1 ? first_name.split(" ")[0] : first_name}
                </p>
                <p className="header-username">{(first_surname+second_surname).length > 15 ? first_surname : (first_surname + " " + second_surname)}</p>
              </div>
              <div className="col-1">
              </div>
              <div className="col-1 d-none d-md-block">
                <i className="fas fa-chevron-down"></i>
              </div>
            </div>
            <div className={`dropdown -${isOpen ? "open" : ""}`}>
              <p className="option">Licenses</p>
              {viewRoles()}
              <div className="mt-white">                
                <div className="configuration">
                  <button className="option" onClick={handleLogout}>
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    workspace: state.workspace,
  };
};

//export default Header;
export default connect(mapStateToProps)(Header);
