import moment from 'moment-timezone';
// Generate Human Time
/**
 * @function humanizeDateTime
 *
 * @param {*} date
 * @return {string}
 */
var humanizeDateTime = function (__date, utc) {
    if (utc === void 0) { utc = true; }
    var _date = __date;
    if (utc) {
        _date = changeDatetimeByTimezone(__date);
    }
    var currentDate = new Date();
    var utcDate = new Date(_date);
    if (isNaN(utcDate.getTime())) {
        console.error("Invalid date:", _date);
        return "Invalid date";
    }
    var timeDiff = currentDate.getTime() - utcDate.getTime();
    if (timeDiff < 1000 * 60 * 60 * 24) {
        if (timeDiff < 1000 * 60 * 60) {
            if (timeDiff < 1000 * 60) {
                return Math.round(timeDiff / 1000) + " seconds ago";
            }
            return Math.round(timeDiff / (1000 * 60)) + " minutes ago";
        }
        return Math.round(timeDiff / (1000 * 60 * 60)) + " hours ago";
    }
    else if (timeDiff < 1000 * 60 * 60 * 24 * 2) {
        return "Yesterday";
    }
    else if (timeDiff < 1000 * 60 * 60 * 24 * 7) {
        return "".concat(Math.floor(timeDiff / (1000 * 60 * 60 * 24)), " days ago");
    }
    else if (timeDiff < 1000 * 60 * 60 * 24 * 14) {
        return "Last week";
    }
    else if (timeDiff < 1000 * 60 * 60 * 24 * 30) {
        return "".concat(Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 7)), " weeks ago");
    }
    else if (timeDiff < 1000 * 60 * 60 * 24 * 60) {
        return "Last month";
    }
    else if (timeDiff < 1000 * 60 * 60 * 24 * 365) {
        return "".concat(Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 30)), " months ago");
    }
    else if (timeDiff < 1000 * 60 * 60 * 24 * 365 * 2) {
        return "Last year";
    }
    else {
        return "".concat(Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365)), " years ago");
    }
};
var changeTimeByTimezone = function (datetime, timezone) {
    if (timezone === void 0) { timezone = 'America/Mexico_City'; }
    var parsedDateAsUtc = moment.utc(datetime);
    if (!parsedDateAsUtc.isValid()) {
        console.error("Invalid datetime:", datetime);
        return "Invalid datetime";
    }
    return parsedDateAsUtc.clone().tz(timezone).format("M/D/YYYY h:mm:ss a");
};
var changeDatetimeByTimezone = function (datetime, timezone) {
    if (timezone === void 0) { timezone = 'America/Mexico_City'; }
    var result = moment.utc(datetime).tz(timezone);
    if (!result.isValid()) {
        console.error("Invalid datetime:", datetime);
        return "Invalid datetime";
    }
    return result.format();
};
// Converts ISO UTC date string to local ISO string
var convertIsoUtcToLocal = function (dateString) {
    var utcDate = new Date(dateString);
    if (isNaN(utcDate.getTime())) {
        console.error("Invalid dateString:", dateString);
        return "Invalid date";
    }
    var offsetMinutes = utcDate.getTimezoneOffset();
    var localTime = new Date(utcDate.getTime() - offsetMinutes * 60 * 1000);
    return localTime.toISOString();
};
export { humanizeDateTime, changeDatetimeByTimezone, changeTimeByTimezone, convertIsoUtcToLocal };
