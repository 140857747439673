import React, { useState, useEffect } from 'react';
import Header from '../../../../Components/Header/headerView';
import Footer from '../../../../Components/Footer/footer';
import Menu from '../../../../Components/Menu/menu';
import Paginator from '../../../../Components/Paginator/paginator';
import { Modal } from "bootstrap";
import { connect } from "react-redux";
import workspacesService from '../../../../Services/workspacesService';
import { CreatedNlpModal } from '../../../../Utils/modals';

const CreatetNlp = props => {
  const user = props.user;
  const org = props.workspace.organization;
  const [name, setName] = useState("");
  const [key, setKey] = useState("");
  const [nlpUrl, setNlpUrl] = useState("");
  const [nlpVersion, setNlpVersion] = useState("");
  const [nlpEngine, setNlpEngine] = useState("watson");

  const areFieldsEmpty = () => {
    return name === "" || key === "" || nlpUrl === "" || nlpVersion === "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const successModal = new Modal(document.getElementById("createdNlpModal"));

    const body = {
      name: name,
      organization: org,
      nlp_url: nlpUrl,
      nlp_version: nlpVersion,
      key: key,
      engine: nlpEngine
    };

    console.log(body);
    try {
      let res = await workspacesService.createNlpInstance(body);
      console.log(res);
      successModal.show();
    } catch (error) {
      console.log(error);
    }
  };   

  return (
    <div>
      <CreatedNlpModal />

      <Header />
      <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
        <div className="w-10">
          <Menu activeTab={7} />
        </div>
        <div className="container px-20 mt-50">
          <div className="row mt-20 mb-200">
            <div className="col-12">
              <p className="h1 -r">Create NLP Engine instance</p>
            </div>
            <div className="col-12 col-lg-9 d-flex mt-40">
              <div className="card-w p-20 w-100">
                <div className="row">
                  <div className="col-12 col-lg-9 mt-40">
                    {/*<p className="h4 -b"></p>*/}

                    <div className="form-group w-100 mt-40">
                      <input
                        name="name"
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                      />
                      <label className="info" htmlFor="workspaceID">
                        Name
                      </label>
                    </div>

                    <div className="form-group w-100 mt-40">
                      <input
                        name="key"
                        type="text"
                        className="form-control"
                        placeholder="Key"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(e) => setKey(e.target.value)}
                        value={key}
                      />
                      <label className="info" htmlFor="workspaceID">
                        Key
                      </label>
                      </div>

                    <div className="form-group w-100 mt-40">
                      <input
                        name="nlpUrl"
                        type="text"
                        className="form-control"
                        placeholder="NLP Url"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(e) => setNlpUrl(e.target.value)}
                        value={nlpUrl}
                      />
                      <label className="info" htmlFor="workspaceID">
                        NLP Url
                      </label>
                      </div>

                    <div className="form-group w-100 mt-40">
                      <input
                        name="nlpVersion"
                        type="text"
                        className="form-control"
                        placeholder="NLP Version"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(e) => setNlpVersion(e.target.value)}
                        value={nlpVersion}
                      />
                      <label className="info" htmlFor="workspaceID">
                        NLP Version
                      </label>
                      </div>

                    <div className="form-group w-100 mt-40">
                    <select className="form-select" value={nlpEngine} onChange={(e) => setNlpEngine(e.target.value)}>
                      <option value="watson">Watson</option>
                      <option value="clu">CLU</option>
                      <option value="dialogflow">Dialogflow</option>
                    </select>
                      <label className="info" htmlFor="workspaceID">
                        NLP Engine
                      </label>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 mt-40">
                <button
                  className={`button w-100 ${areFieldsEmpty() ? '-inactive' : '-primary'}`}
                  onClick={handleSubmit}
                  disabled={areFieldsEmpty()}
                >
                  CREATE
                </button>
              <a href="/managenlp">
                <button className="button -outline-primary mt-10 w-100">
                  CANCEL
                </button>
              </a>
            </div>
          </div>
          <div className="row mt-20">
            <Paginator />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    workspace: state.workspace,
  };
};

export default connect(mapStateToProps)(CreatetNlp);
