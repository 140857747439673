import React, { useState, useEffect, useCallback } from "react";
import { useParams, Link, Navigate } from "react-router-dom";
import { Modal } from "bootstrap";
import Header from "../../../Components/Header/headerView";
import Footer from "../../../Components/Footer/footer";
import Menu from "../../../Components/Menu/menu";
import NDSTooltip from "../../../Components/Tooltip/ndsTooltip";
import { positions } from "react-alert";
import { connect } from "react-redux";
import Select from "react-select";
import qs from 'qs';
import workspacesService from "../../../Services/workspacesService";
import usersService from "../../../Services/usersService";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../../Components/Loader/actions";

const EditAdmin = (props) => {
  const org = props.workspace.organization;
  const [loadData, setLoadData] = useState(true);
  const [user, setUser] = useState([]);
  const [workspaces, setWorkspaces] = useState([]);
  const [originalIDS, setOriginalIDS] = useState([]);
  const [workspaceOptions, setWorkspaceOptions] = useState([]);
  const [newWorkspace, setNewWorkspace] = useState(false);
  const [userAdmin, setUserAdmin] = useState(false);
  const [enabledButton, setEnabledButton] = useState(false);
  const userID = useParams().user_id;

  const dispatch = useDispatch();

  const options = {
    timeout: 5000,
    position: positions.TOP_CENTER,
  };

  async function getUser(userID) {
    dispatch(startLoading());
    try{
      let params = {
        user_id: userID,
        organization: org,
      };
      let res = await usersService.collectOneSuperAdmin(params);
      res = res.data;
      console.log("user", res);
      setUser(res);
      setUserAdmin(res.is_admin);
      let userWorkspaces = res.permissions;
      console.log("userWorkspaces", userWorkspaces);
      let IDs = [];
      let workpacesArray = userWorkspaces.map((workspace) => {
        IDs.push(workspace.workspace_id);
        return workspace.workspace_id;
      });
      setOriginalIDS(IDs);
      console.log("workpacesArray", workpacesArray);
      if (workpacesArray.length > 0) {
        const params = {
          workspace_ids: workpacesArray.join("&"),
          organization: org,
        };
        let res2 = await workspacesService.collectMany(params);
        console.log("workspaces", res2);
        let workspaces = res2.data.map((workspace) => {
          let licences = userWorkspaces.find((userLicense) => {
            return userLicense.workspace_id === workspace.id;
          }).roles
          return {
            workspace_id: workspace.id,
            name: workspace.name,
            licenses: licences,
          };
        });
        console.log("wwsps", workspaces)
        setWorkspaces(workspaces);
      }
      else{
        setWorkspaces([]);
      }
    }
    catch (err){
      console.log(err);
    }
    dispatch(stopLoading());
}

  const getWorkspaces = useCallback(async () => {
    // let organization = 'nds-org';
    dispatch(startLoading());
    let params = {
      organization: org,
    };

    try{
      let res = await workspacesService.collectManyByOrganizationAdmin(params);
      let options = [];
      res.data.forEach((workspace) => {
        options.push({
          value: workspace.id,
          label: workspace.name,
        });
      });
      setWorkspaceOptions(options);
      setLoadData(false);
    }
    catch(err){
      console.log(err);
    }
    dispatch(stopLoading());
  }, [workspaces, org]);

  const updateUser = useCallback(async () => {
    let body = {
      //user_id: userID,
      //If there is new userAdmin, add it, else field is null
      //userAdmin: userAdmin ? userAdmin : null,
      is_admin: userAdmin,
      permissions:
        newWorkspace.workspace_id !== undefined &&
        newWorkspace.licenses.length > 0
          ? [...workspaces, newWorkspace]
          : [...workspaces],
    };
    if (Array.isArray(body.permissions)) {
      body.permissions = body.permissions.map(item => {
          // Renombrar el campo 'licenses' a 'roles'
          let { licenses, ...rest } = item;
          return {
              ...rest,
              roles: licenses
          };
      });
  
  } else {
      console.error('params.permissions no es un array.');
  }

  let params = {
    user_id: userID,
    new_first_name:  userAdmin.first_name || user.first_name,
    new_first_surname: userAdmin.first_surname || user.first_surname,
    new_second_surname: userAdmin.second_surname || user.second_surname,
    organization: org,
  }
    console.log("params", params);
    console.log("body", body);
    body = {new_permissions: body}
    try{
      let res = await usersService.update(params, body);
      console.log(res);
      let modal = new Modal(document.getElementById("SavedModal"));
      modal.show();
    }
    catch (err){
      console.log(err);
    }
  }, [workspaces, newWorkspace, userAdmin, userAdmin, userID]);

  const showWorkspaces = (permissions) => {
    return permissions.map((workspace) => {
      function handleLicenseChange(e, workspace) {
        let licenses = workspace.licenses;
        console.log(e.target);
        if (e.target.checked) {
          licenses.push(e.target.value);
        } else {
          licenses = licenses.filter((license) => {
            return license !== e.target.value;
          });
        }
        let newWorkspaces = workspaces.map((workspace) => {
          if (workspace.workspace_id === e.target.id) {
            workspace.licenses = licenses;
          }
          return workspace;
        }, []);

        setWorkspaces(newWorkspaces);
      }

      function handleRemoveLicenses(id) {
        //Remove all licenses from license array
        let newWorkspaces = workspaces.filter(workspace => workspace.workspace_id !== id);
        //If is not in originalIDS, remove workspace from workspaces array
        if (!originalIDS.includes(id)) {
          newWorkspaces = newWorkspaces.filter((workspace) => {
            return workspace.workspace_id !== id;
          });
        }
        setWorkspaces(newWorkspaces);
      }
      let licenses = workspace.licenses;

      return (
        <>
          <div
            className="modal fade"
            id={`RemoveWorkspaceModal${workspace.workspace_id}`} //"RemoveWorkspaceModal"
            tabIndex="1"
            aria-labelledby="RemoveWorkspaceModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <p className="h2" id="RemoveWorkspaceModalLabel">
                    Remove workspace licenses
                  </p>
                  <button
                    type="button"
                    className="button-close w-100"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    X
                  </button>
                </div>
                <div className="modal-body">
                  <p className="text">
                    All the licenses of <b>{user?.first_name}</b> in the{" "}
                    <b>{workspace.name}</b> workspace have been removed. The
                    user will no longer be able to make any changes or see the
                    workspace.
                  </p>
                </div>
                <div className="modal-footer">
                  <div className="row w-100 justify-content-end">
                    <div className="col-12 col-md-5 mb-10">
                      <a>
                        <button
                          className="button -outline-primary w-100"
                          data-bs-dismiss="modal"
                        >
                          CANCEL
                        </button>
                      </a>
                    </div>
                    <div className="col-12 col-md-5 mb-10">
                      <a>
                        <button
                          className="button -primary w-100"
                          data-bs-dismiss="modal"
                          onClick={() =>
                            handleRemoveLicenses(workspace.workspace_id)
                          }
                        >
                          CONTINUE
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card-w d-flex mt-20 p-20 px-20">
            <div className="col-12 px-20">
              <div className="d-flex justify-content-between">
                <p className="h2">{workspace.name}</p>
                <div>
                  {/* <a href="/TransferTasks" className="-blue">
                  <NDSTooltip
                    icon="fa-solid fa-arrow-right-arrow-left"
                    target="transferTasks"
                    tooltipMessage="Transfer tasks to another user"
                    placement="bottom"
                  ></NDSTooltip>
                </a> */}
                  {/* <a className="link -blue">
                  <NDSTooltip
                    icon="fa-solid fa-pencil"
                    target="pencil"
                    tooltipMessage="Edit"
                    placement="bottom"
                  ></NDSTooltip>
                </a> */}
                  <a
                    // data-bs-toggle="modal"
                    // data-bs-target="#RemoveWorkspaceModal"
                    onClick={() => {
                      let modal = new Modal(
                        document.getElementById(
                          `RemoveWorkspaceModal${workspace.workspace_id}`
                        )
                      );
                      modal.show();
                    }}
                  >
                    <NDSTooltip
                      id={workspace.workspace_id}
                      icon="fa-solid fa-trash-can text-danger"
                      target="trash"
                      tooltipMessage="Remove permissions"
                      placement="bottom"
                    ></NDSTooltip>
                  </a>
                </div>
              </div>
              <div className="col-12 d-flex p-10 justify-content-around mb-10">
                <div className="row">
                  <div className="col-12 col-lg-4 col-md-8 mb-10">
                    <div
                      className={`card-user d-flex flex-column ${
                        licenses.includes("Editor") ? "-selected" : null
                      }`}
                      onClick={() =>
                        handleLicenseChange(
                          {
                            target: {
                              checked: !licenses.includes("Editor"),
                              value: "Editor",
                              id: workspace.workspace_id,
                            },
                          },
                          workspace
                        )
                      }
                    >
                      <div className="form-field">

                        <input
                          type="checkbox"
                          name="Editor"
                          id={workspace.workspace_id}
                          value="Editor"
                          checked={licenses.includes("Editor")}
                          onChange={(e) => handleLicenseChange(e, workspace)}
                        />
                        <label htmlFor="Editor" className="h4 -b mx-10">
                          Editor
                        </label>
                        {/* <p className="h4 -b">Editor</p> */}
                        <ul>
                          <li>Can create and edit intents &amp; entities.</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-4 col-md-8 mb-10">
                    <div
                      className={`card-user d-flex flex-column ${
                        licenses.includes("Validator") ? "-selected" : null
                      }`}
                      onClick={() =>
                        handleLicenseChange(
                          {
                            target: {
                              checked: !licenses.includes("Validator"),
                              value: "Validator",
                              id: workspace.workspace_id,
                            },
                          },
                          workspace
                        )
                      }
                    >
                      <div className="form-field">
                        
                        <input
                          type="checkbox"
                          name="Validator"
                          id={workspace.workspace_id}
                          value="Validator"
                          checked={licenses.includes("Validator")}
                          onChange={(e) => handleLicenseChange(e, workspace)}
                        />
                        <label htmlFor="Validator" className="h4 -b mx-10">
                          Validator
                        </label>
                        {/* <p className="h4 -b">Editor</p> */}
                        <ul>
                          <li>Can create and edit intents &amp; entities.</li>
                          <li>
                            Can validate or reject the adjustments suggested by
                            an Editor.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-4 col-md-8 mb-10">
                    <div
                      className={`card-user d-flex flex-column ${
                        licenses.includes("Deployer") ? "-selected" : null
                      }`}
                      onClick={() =>
                        handleLicenseChange(
                          {
                            target: {
                              checked: !licenses.includes("Deployer"),
                              value: "Deployer",
                              id: workspace.workspace_id,
                            },
                          },
                          workspace
                        )
                      }
                    >
                      <div className="form-field">
                        <input
                          type="checkbox"
                          name="Deployer"
                          value="Deployer"
                          id={workspace.workspace_id}
                          checked={licenses.includes("Deployer")}
                          onChange={(e) => handleLicenseChange(e, workspace)}
                        />
                        <label htmlFor="Deployer" className="h4 -b mx-10">
                          Deployer
                        </label>
                        <ul>
                          <li>
                            Can validate or reject the adjustments suggested by
                            an Editor and a Validator.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    });
  };

  useEffect(() => {
    console.log("New workspace", newWorkspace);
    if (newWorkspace?.workspace_id && newWorkspace?.licenses?.length > 0) {
      setEnabledButton(true);
    }
    else{
      setEnabledButton(false);
    }
  }, [newWorkspace]);


  const showNewWorkspace = () => {
    function handleLicenseChange(e) {
      if (e.target.checked) {
        licenses.push(e.target.value);
      } else {
        licenses = licenses.filter((license) => {
          return license !== e.target.value;
        });
      }
      setNewWorkspace({ ...newWorkspace, licenses: licenses });
    }
    function handleWorkspaceChange(e) {
      newWorkspaceID = e.value;
      setNewWorkspace({ ...newWorkspace, workspace_id: newWorkspaceID });
    }
    let newWorkspaceID = newWorkspace.workspace_id || "";
    let licenses = newWorkspace.licenses || [];

    function addNewWorkspace() {
      if (!newWorkspace.workspace_id || !newWorkspace.licenses.length) {
        return;
      }
      let newWorkspaces = workspaces;
      let tempNewWorkspace = newWorkspace;
      tempNewWorkspace.name = workspaceOptions.filter((workspace) => {
        return workspace.value === newWorkspace.workspace_id;
      })[0].label;
      newWorkspaces.push(tempNewWorkspace);
      setWorkspaces(newWorkspaces);
      setNewWorkspace(false);
      //remove the workspace from the options
      let newWorkspaceOptions = workspaceOptions.filter((workspace) => {
        return workspace.value !== newWorkspace.workspace_id;
      });
      setWorkspaceOptions(newWorkspaceOptions);
    }

    return (
      <>
        <div className="card-w d-flex mt-20">
          <div className="row">
            <div className="col-12 justify-content-around px-40 mt-20">
              <div className="col-12 col-lg-8 col-md-10">
                <label className="info" htmlFor="workspaceCard">
                  Select workspace
                </label>
                <div className="dropdown dropdown-Mi mt-6 w-100">
                  <Select
                    id="workspaceCard"
                    options={workspaceOptions}
                    placeholder="Select workspace"
                    onChange={handleWorkspaceChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 px-20">
              <label className="col-12 info mb-10" htmlFor="card">
                Select the licenses that the user will have within the platform
              </label>
              <div className="col-12 d-flex p-10 justify-content-around mb-10">
                <div className="row">
                  <div className="col-12 col-lg-4 col-md-8 mb-10">
                    <div
                      className={`card-user d-flex flex-column ${
                        licenses.includes("Editor") ? "-selected" : null
                      }`}
                      onClick={() => {
                        if (licenses.includes("Editor")) {
                          licenses = licenses.filter((license) => {
                            return license !== "Editor";
                          });
                        } else {
                          licenses.push("Editor");
                        }
                        setNewWorkspace({
                          ...newWorkspace,
                          licenses: licenses,
                        });
                      }}
                    >
                      <div className="form-field">
                        <input
                          type="checkbox"
                          name="Editor"
                          id={newWorkspaceID}
                          value="Editor"
                          checked={licenses.includes("Editor")}
                          onChange={handleLicenseChange}
                        />
                        <label htmlFor="Editor" className="h4 -b mx-10">
                          Editor
                        </label>
                        {/* <p className="h4 -b">Editor</p> */}
                        <ul>
                          <li>Can create and edit intents &amp; entities.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4 col-md-8 mb-10">
                    <div
                      className={`card-user d-flex flex-column ${
                        licenses.includes("Validator") ? "-selected" : null
                      }`}
                      onClick={() => {
                        if (licenses.includes("Validator")) {
                          licenses = licenses.filter((license) => {
                            return license !== "Validator";
                          });
                        } else {
                          licenses.push("Validator");
                        }
                        setNewWorkspace({
                          ...newWorkspace,
                          licenses: licenses,
                        });
                      }}
                    >
                      <div className="form-field">
                        <input
                          type="checkbox"
                          name="Validator"
                          id={newWorkspaceID}
                          value="Validator"
                          checked={licenses.includes("Validator")}
                          onChange={handleLicenseChange}
                        />
                        <label htmlFor="Validator" className="h4 -b mx-10">
                          Validator
                        </label>
                        {/* <p className="h4 -b">Editor</p> */}
                        <ul>
                          <li>Can create and edit intents &amp; entities.</li>
                          <li>
                            Can validate or reject the adjustments suggested by
                            an Editor.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4 col-md-8 mb-10">
                    <div
                      className={`card-user d-flex flex-column ${
                        licenses.includes("Deployer") ? "-selected" : null
                      }`}
                      onClick={() => {
                        if (licenses.includes("Deployer")) {
                          licenses = licenses.filter((license) => {
                            return license !== "Deployer";
                          });
                        } else {
                          licenses.push("Deployer");
                        }
                        setNewWorkspace({
                          ...newWorkspace,
                          licenses: licenses,
                        });
                      }}
                    >
                      <div className="form-field">
                        <input
                          type="checkbox"
                          name="Deployer"
                          value="Deployer"
                          id={newWorkspaceID}
                          checked={licenses.includes("Deployer")}
                          onChange={handleLicenseChange}
                        />
                        <label htmlFor="Deployer" className="h4 -b mx-10">
                          Deployer
                        </label>
                        <ul>
                          <li>
                            Can validate or reject the adjustments suggested by
                            an Editor and a Validator.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 d-flex justify-content-end mb-20">
              {workspaces.length > 0 && (
                <div className="col-lg-3 col-md-5 align-items-center mx-10">
                  <button
                    className="button -outline-primary"
                    onClick={() => setNewWorkspace(false)}
                  >
                    CANCEL
                  </button>
                </div>
              )}
              <div className="col-lg-3 col-md-5 align-items-center mx-10">
                <button
                  className={`button w-100 ${enabledButton ? '-primary' : '-inactive'}`}
                  onClick={() => addNewWorkspace()}
                  disabled={!enabledButton}
                >
                  Add workspace
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleChangeUserInfo = (e) => {
    //If is empty, set to null
    if (e.target.value === "") {
      setUserAdmin({ ...userAdmin, [e.target.name]: null });
      return;
    }
    setUserAdmin({ ...userAdmin, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    console.log("******VALIDATIONS******")
    console.log("loadData", loadData);
    console.log("user", user);
    console.log("workspaces", workspaces);
    console.log("originalIDS", originalIDS);
    console.log("workspaceOptions", workspaceOptions);
    console.log("newWorkspace", newWorkspace);
    console.log("userAdmin", userAdmin);
    console.log("userAdmin", userAdmin);
    console.log("userID", userID);
    console.log("************")
  }, [loadData, user, workspaces, originalIDS, workspaceOptions, newWorkspace, userAdmin, userAdmin, userID]);  

  useEffect(() => {
    console.log("workspaceOptions", workspaceOptions);
    if (loadData) {
      getUser(userID);
    }
  }, [loadData, userID]);

  useEffect(() => {
    if (loadData) {
      getWorkspaces();
    }

    workspaces.length < 1 && setNewWorkspace(true);

    let currentWorkspaces = workspaces.map((workspace) => {
      return workspace.workspace_id;
    });
    let filteredOptions = workspaceOptions.filter((option) => {
      return !currentWorkspaces.includes(option.value);
    });
    setWorkspaceOptions(filteredOptions);
  }, [loadData, workspaces, getWorkspaces]);

  return (
    <div className={`${props.className}`}>
      {/* // <!-- Modal all workspaces used--> */}
      <div
        className="modal fade"
        id="AllWorkspacesUsedModal"
        tabIndex="1"
        aria-labelledby="AllWorkspacesUsedModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p className="h2" id="ConflictNoLicensesModalLabel">
                <i className="fa-solid fa-triangle-exclamation font-75"></i>
                Conflict detected
              </p>
              <button
                type="button"
                className="button-close w-100"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body">
              <p className="text">
                {user?.first_name} already has a license for all current
                workspaces. You could either modify the existing licenses or
                create a new workspace.
              </p>
            </div>
            <div className="modal-footer">
              <div className="row w-100 justify-content-end">
                <div className="col-12 col-md-5 mb-10">
                  <a>
                    <button
                      className="button -outline-primary w-100"
                      data-bs-dismiss="modal"
                    >
                      OK
                    </button>
                  </a>
                </div>
                <div className="col-12 col-md-5 mb-10">
                  <a href="/ImportWorkspace">
                    <button className="button -primary w-100">
                      CREATE NEW WORKSPACE
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // <!-- Modal Edit User--> */}
      <div
        className="modal fade"
        id="SavedModal"
        tabIndex="1"
        aria-labelledby="SavedModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p className="h2" id="SavedModalLabel">
                Edit Admin
              </p>
              <button
                type="button"
                className="button-close w-100"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body">
              <p className="d-flex text justify-content-center">
                The Admin has been successfully updated!
              </p>
            </div>
            <div className="modal-footer">
              <div className="row w-100 justify-content-end">
                <div className="col-12 col-md-5 mb-10">
                  {/* <a href="/AdminsManage"> */}
                  <button
                    className="button -primary w-100"
                    onClick={() => (window.location.href = "/AdminsManage")}
                  >
                    CONTINUE
                  </button>
                  {/* </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // <!-- Modal Conflict NoLicenses--> */}
      <div
        className="modal fade"
        id="ConflictNoLicensesModal"
        tabIndex="1"
        aria-labelledby="ConflictNoLicensesModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p className="h2" id="ConflictNoLicensesModalLabel">
                <i className="fa-solid fa-triangle-exclamation font-75"></i>
                Conflict detected
              </p>
              <button
                type="button"
                className="button-close w-100"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body">
              <p className="text">
                The user doesn’t have any license assigned in the{" "}
                <b>NDS project</b> workspace. If you don’t assign a license, the
                user will be a Viewer and won’t be able to make any changes.
              </p>
            </div>
            <div className="modal-footer">
              <div className="row w-100 justify-content-end">
                <div className="col-12 col-md-5 mb-10">
                  <a href="/EditUser">
                    <button className="button -outline-primary w-100">
                      CANCEL
                    </button>
                  </a>
                </div>
                <div className="col-12 col-md-5 mb-10">
                  <a href="/AdminsManage">
                    <button className="button -primary w-100">CONTINUE</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Header />
      <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
        <div className="w-10">
          <Menu activeTab={6} />
        </div>
        <div className="container px-20 mb-50">
          <div className="row">
            <div className="col-12 my-10">
              <div className="row mt-50 mb-10">
                <p className="h1 -r">Edit user</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-9 row mb-10">
            <div className="d-flex column mt-30">
              <p className="h2 -r mr-10">User information</p>
              {!userAdmin ? (
                <a className="link" onClick={() => setUserAdmin(true)}>
                  Edit
                </a>
              ) : (
                <a className="link" onClick={() => setUserAdmin(false)}>
                  Cancel
                </a>
              )}
            </div>
            {!userAdmin && user?.first_name && (
              <div className="col-12 col-lg-4 col-md-8 card-w d-flex align-items-center p-20 w-50">
                <div className="col-2 mr-10">
                  <div className="user-img -editor">
                    <p className="user-name h1 text-c">{user?.first_name?.charAt(0) ?? "U"}</p>
                  </div>
                </div>
                <div className="col-12 col-md-10">
                  <p className="h3 -b m-0">
                    {user?.first_name ?? "" +
                      " " +
                      user?.first_surname ?? "" +
                      " " +
                      user?.second_surname ?? ""}
                  </p>
                  <p className="text">{user?.email ?? ""}</p>
                </div>
              </div>
            )}

            {/* CUANDO SE ESTÉ EDITANDO LA INFORMACIÓN PERSONAL DEL USUARIO ASÍ ES CÓMO SE VE */}

            {userAdmin && (
              <>
                <div className="col-12 col-md-6 mt-20">
                  <div className="form-group">
                    <input
                      name="first_name"
                      type="text"
                      className="form-control"
                      placeholder={user.first_name}
                      // aria-label="Recipient's username"
                      // aria-describedby="basic-addon2"
                      onChange={(e) => {
                        handleChangeUserInfo(e);
                      }}
                    ></input>
                    <label className="info" htmlFor="first_name">
                      First name
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-20">
                  <div className="form-group">
                    <input
                      name="first_surname"
                      type="text"
                      className="form-control"
                      placeholder={user?.first_surname}
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      onChange={(e) => {
                        handleChangeUserInfo(e);
                      }}
                    ></input>
                    <label className="info" htmlFor="first_surname">
                      First surname
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-20">
                  <div className="form-group">
                    <input
                      name="second_surname"
                      type="text"
                      className="form-control"
                      placeholder={user?.second_surname}
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      onChange={(e) => {
                        handleChangeUserInfo(e);
                      }}
                    ></input>
                    <label className="info" htmlFor="second_surname">
                      Second surname
                    </label>
                  </div>
                </div>
                <div className="col-12 mt-20">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={userAdmin}
                      onChange={() => {
                        setUserAdmin(!userAdmin);
                      }}
                      defaultChecked={userAdmin}
                      id="flexCheckDefault"
                    />
                    <label className="link" htmlFor="flexCheckDefault">
                      <p>
                        <b>This user is Admin</b>
                      </p>
                    </label>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="d-flex">
            <div className="row">
              <div className="col-12 col-12 col-md-9 row mt-50">
                <div>
                  <p className="h2 -r">Workspaces &amp; licenses</p>
                </div>
                {newWorkspace && showNewWorkspace()}
                {workspaces && showWorkspaces(workspaces)}
                {/* Estas dos son el estado normal cuando entras al perfil de un usuario */}
              </div>
              <div className="col-12 col-md-3 mt-100 mb-100">
                <button
                  className="button -primary w-100"
                  // data-bs-toggle="modal"
                  // data-bs-target="#EditUserModal"
                  // data-bs-toggle="modal"
                  // data-bs-target="#ConfirmationModal"
                  onClick={() => updateUser()}
                >
                  SAVE
                </button>
                {/* Botón trigger para el segundo modal aka Conflict detected no licenses */}
                {/* <button className="button -primary w-100" data-bs-toggle="modal" data-bs-target="#ConflictNoLicensesModal">CONTINUE</button> */}
                {!newWorkspace && (
                  <button
                    className="button -outline-primary mt-10 w-100"
                    onClick={() => setNewWorkspace(true)}
                  >
                    <i className="fa-solid fa-circle-plus"></i>Add workspace
                  </button>
                )}
                <a href="/AdminsManage">
                  <button className="button -outline-primary mt-10 w-100">
                    CANCEL
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    workspace: state.workspace,
  };
};

export default connect(mapStateToProps)(EditAdmin);
