import React, { forwardRef } from 'react';

const DragAndDrop = forwardRef((props, ref) => {

  const { fileData, dispatch, singleFile, placeholder='Select a file...', handleUpload } = props;
  
  const inputRef = React.useRef();

  const disabled = {
    // pointerEvents: "none",
    opacity: 0.5,
    background: "#CCC",
  };

  const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();

    dispatch({ type: 'SET_DROP_DEPTH', dropDepth: fileData.dropDepth + 1 });
  };

  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();

    dispatch({ type: 'SET_DROP_DEPTH', dropDepth: fileData.dropDepth - 1 });
    if (fileData.dropDepth > 0) return
    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false })
  };

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();

    e.dataTransfer.dropEffect = 'copy';
    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: true });
  };

  const handleUnset = e => {
    dispatch({ type: 'SET_FILE_LIST', files: [] });
    dispatch({ type: 'SET_DROP_DEPTH', dropDepth: 0 });
    dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false });
    inputRef.current.files = new DataTransfer().files;
  }

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();

    let files = [...(e.dataTransfer??e.target).files];

    if (files && files.length > 0) {
      if (singleFile) { 
        dispatch({ type: 'SET_FILE_LIST', files });
      }
      else
      {
        const existingFiles = fileData.fileList.map(f => f.name)
        files = files.filter(f => !existingFiles.includes(f.name))
        dispatch({ type: 'ADD_FILE_TO_LIST', files });
      }
      dispatch({ type: 'SET_DROP_DEPTH', dropDepth: 0 });
      dispatch({ type: 'SET_IN_DROP_ZONE', inDropZone: false });
    }
  };

  const fileSize = file => {
    let fileSizeString = ""
    fileSizeString = (
      file.size < 1e3 ? Math.round((file.size + Number.EPSILON) * 100) / 100 :
        file.size < 1e6 ? Math.round((file.size/1e3 + Number.EPSILON) * 100) / 100 :
          Math.round((file.size/1e6 + Number.EPSILON) * 100) / 100).toString();
    fileSizeString = fileSizeString + " " + (
      file.size < 1e3 ? "bytes" :
        file.size < 1e6 ? "KB" :
          "MB");

    return fileSizeString
  }

  const filesSize = files => {
    const filesSize = files?.fileList?.reduce(
      (acc, val) => acc + val.size, 0);
    return filesSize
  }

  return (
    <div
      ref={ref}
      className={`row my-50 ${
        fileData.inDropZone
          ? "drag-drop-zone inside-drag-area"
          : "drag-drop-zone"
      }`}
      id="drop_zone"
      onDrop={(e) => handleDrop(e)}
      onDragOver={(e) => handleDragOver(e)}
      onDragEnter={(e) => handleDragEnter(e)}
      onDragLeave={(e) => handleDragLeave(e)}
    >
      <div
        className="file-space d-flex flex-column mb-30"
        onClick={(e) => inputRef.current.click(e)}
      >
        <p className="mt-20">
          <i className="fa-solid fa-cloud-arrow-up"></i>
        </p>
        <input
          type="file"
          name="file"
          accept={".csv"}
          ref={inputRef}
          onChangeCapture={handleDrop}
          style={{ display: "none" }}
        />
        <p className="h4 -l">
          Drop your .csv file here, or click to select it manually
        </p>
      </div>

      <div className="file">
        <div className="row w-100">
          <div className="col w-100" onClick={(e) => inputRef.current.click(e)}>
            {singleFile ? (
              <p
                className="text"
                style={{
                  ...{ cursor: "pointer" },
                  ...(fileData?.fileList?.[0]?.name ? {} : disabled),
                }}
              >
                {fileData?.fileList?.[0]?.name ?? placeholder}
              </p>
            ) : fileData?.fileList?.length ? (
              <ul>
                {fileData?.fileList?.map((file, index) => {
                  return (
                    <li key={index}>
                      {file.name} ({fileSize(file)})
                    </li>
                  );
                })}
              </ul>
            ) : (
              placeholder
            )}
          </div>
          <div className="col-1">
            <button
              className="button -warning ml-0 w-100"
              name="file"
              onClick={handleUnset}
            >
              X
            </button>
          </div>
        </div>
      </div>
      <button
        className={
          filesSize(fileData) > 0
            ? "button -primary mt-20 w-100"
            : "button -primary -inactive mt-20 w-100"
        }
        onClick={(e) => {
          handleUpload();
          handleUnset();
        }}
        /*{...(fileData?.fileList?.length & (filesSize(fileData) > 0)
          ? {}
          : { disabled: true })}*/
      >
        UPLOAD
      </button>
    </div>
  );
});

export default DragAndDrop;
