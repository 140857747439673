import React, { Component } from 'react';
import Header from '../../../Components/Header/headerView';
import Footer from '../../../Components/Footer/footer';
import Menu from '../../../Components/Menu/menu';
import manually from '../../../Images/manually.png';
import csv from '../../../Images/csv.png';

class CreateEntityOpt extends Component {
  render() {
    return (
      <div>
        <Header/>
        <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
          <div className="w-10">
            <Menu activeTab={3}/>
          </div>
          <div className="container px-20 mb-50">
            <div className="mt-50 mb-20">
              <p className="h1 -r">Create Entity</p>
            </div>
            <div className="row mb-40">
              <div className="col-12 col-sm-6">
                <div className="card mb-20 d-flex justify-content-center">
                  <p className="h4 color-gray">You can chose the way you will create a new Entity:</p>
                </div>
              </div>
            </div>
            <div className="row mb-100">
              <div className="col-12 col-md-6 col-lg-4">
                <div className="card mb-20">
                  <div className="row mb-20">
                    <div className="col-6 col-md-3">
                      <img src={csv} alt="CSV" />
                    </div>
                  </div>
                  <div className="row">
                    <p className="h3 -b mb-10">Upload a CSV file</p>
                  </div>
                  <div className="row mb-20">
                    <p className="h4 color-gray mb-20">Upload a CSV file with a predetermined template get our template <a href="/csvtemplate/entity" className='text -b link'>here</a></p>
                  </div>
                  <div className="row justify-content-end mt-auto">
                    <div className="col-12 col-md-8">
                      <a href="/createcsv/entity"><button className='button -outline-primary w-100'>CREATE FROM CSV</button></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="card mb-20">
                  <div className="row mb-20">
                    <div className="col-6 col-md-3">
                      <img src={manually} alt="manually" />
                    </div>
                  </div>
                  <div className="row">
                    <p className="h3 -b mb-10">Manually</p>
                  </div>
                  <div className="row mb-20">
                    <p className="h4 color-gray mb-20">Fill a form with a few text fields.</p>
                  </div>
                  <div className="row justify-content-end mt-auto">
                    <div className="col-12 col-md-8">
                      <a href="/createentity"><button className='button -outline-primary w-100'>CREATE MANUALLY</button></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default CreateEntityOpt;
