import React, { useState, useEffect, useCallback } from "react";
import { Link, Navigate } from "react-router-dom";
import { Modal } from "bootstrap";
import Header from "../../../Components/Header/headerView";
import Footer from "../../../Components/Footer/footer";
import Menu from "../../../Components/Menu/menu";
import NDSInput from "../../../Components/Input/ndsInput";
import { connect } from "react-redux";
import workspacesService from "../../../Services/workspacesService";
import usersService from "../../../Services/usersService";
import { set } from "firebase/database";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../../Components/Loader/actions";
import user from "../../../app/slices/user/user";

const mapStateToProps = state => {
    return {
        workspace: state.workspace,
        user: state.user,
    };
};

const roles = [
    {
        name: "Editor",
        description: <li>Can create and edit intents &amp; entities.</li>,
    },
    {
        name: "Validator",
        description: (
            <ul>
                <li>Can create and edit intents &amp; entities.</li>
                <li>
                    Can validate or reject the adjustments suggested by an
                    Editor.
                </li>
            </ul>
        ),
    },
    {
        name: "Deployer",
        description: (
            <ul>
                <li>Can create and edit intents &amp; entities.</li>
                <li>
                    Can validate or reject the adjustments suggested by an
                    Editor and a Validator.
                </li>
            </ul>
        ),
    },
];

const SelectWorkspace = connect(mapStateToProps)(props => {
    const [propsState, setPropsState] = useState(props);
    const [workspaces, setWorkspaces] = useState([]);
    const [loadData, setLoadData] = useState(false);
    const org = props.workspace.organization;
    const dispatch = useDispatch();

    useEffect(() => {
        setPropsState(props);
    }, [props]);

    const handleChange = (checked, name) => {
        let permissions = propsState.permissions;
        if (checked) {
            permissions.add(name);
        } else {
            permissions.delete(name);
        }
        setPropsState({
            ...propsState,
            permissions: permissions,
        });
    };

    const getWorkspaces = useCallback(async () => {
        dispatch(startLoading());
        let params = {
            organization: org,
        };
        try{
            let res = await workspacesService.collectManyByOrganization(params);
            setWorkspaces(res.data);
        }
        catch(err){
            console.log(err);
        }
        dispatch(stopLoading());
    }, [org]);

    useEffect(() => {
        if (!loadData) {
            getWorkspaces();
            setLoadData(true);
        }
    }, [org, loadData]);

    return (
        <div className="card-w d-flex mt-20">
            <div className="row">
                <div className="col-12 justify-content-around px-20 mt-20">
                    <div className="col-12 col-md-6  col-lg-4 px-10">
                        <label className="info" htmlFor="card">
                            Workspace
                        </label>
                        <div className="dropdown dropdown-Mi mt-6">
                            <button
                                className="button -outline-primary dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                {propsState.selectedWorkspace.name
                                    ? propsState.selectedWorkspace.name
                                    : "Select workspace"}
                            </button>
                            <ul className="dropdown-menu">
                                {workspaces &&
                                    workspaces.map((e, index) => (
                                        <li
                                            key={index}
                                            onClick={() => {
                                                let selectedWorkspace =
                                                    propsState.selectedWorkspace;
                                                selectedWorkspace.id = e.id;
                                                selectedWorkspace.name = e.name;
                                                setPropsState({
                                                    ...propsState,
                                                    selectedWorkspace:
                                                        selectedWorkspace,
                                                });
                                            }}
                                        >
                                            <a className="dropdown-item p-10">
                                                {e.name}
                                            </a>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-12 px-20 mt-20">
                    <label className="col-12 info mb-10" htmlFor="card">
                        Select the licenses that the user will have within the
                        platform
                    </label>
                    <div className="col-12 d-flex p-10 justify-content-around mb-10">
                        <div className="row">
                            {roles.map((e, index) => (
                                <div
                                    key={index}
                                    className="col-12 col-lg-4 mb-10 disable-text-selection"
                                    onClick={() =>
                                        handleChange(
                                            !propsState.permissions.has(e.name),
                                            e.name
                                        )
                                    }
                                >
                                    <div
                                        className={`card-user d-flex flex-column ${
                                            propsState.permissions.has(e.name)
                                                ? "-selected"
                                                : ""
                                        }`}
                                    >
                                        <p className="h4 -b">{e.name}</p>
                                        {e.description}
                                        <div className="mt-auto">
                                            <p className="h4-selector">
                                                <input
                                                    className="form-check-input mx-10"
                                                    type="checkbox"
                                                    id={`flexCheckDefault-${e.name}-${props.list_id}`}
                                                    onChange={ee =>
                                                        handleChange(
                                                            ee.target.checked,
                                                            e.name
                                                        )
                                                    }
                                                    checked={propsState.permissions.has(
                                                        e.name
                                                    )}
                                                />{" "}
                                                SELECT
                                                {propsState.permissions.has(
                                                    e.name
                                                )
                                                    ? "ED"
                                                    : ""}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

const CreateUser = props => {
    const [activeTab, setActiveTab] = useState("1");
    const [errorMessage, setErrorMessage] = useState("");
    const [first_name, setFirstName] = useState("");
    const [first_surname, setFirstSurname] = useState("");
    const [second_surname, setSecondSurname] = useState("");
    const [email, setEmail] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);
    const defaultWorkspacePermissions = new Set(roles.map(e => e.name));
    const [workspaces, setWorkspaces] = useState([
        <SelectWorkspace
            key={0}
            list_id={0}
            selectedWorkspace={{}}
            permissions={new Set(defaultWorkspacePermissions)}
        ></SelectWorkspace>,
    ]);
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    async function createUser() {
        var modal = new Modal(document.getElementById("createUserModal"), {
            keyboard: false,
        });
        let permissions = workspaces
            .filter(e => e.props.selectedWorkspace)
            .map((e, index) => {
                console.log("e",e.props.permissions);
                return {
                    workspace_id: e.props.selectedWorkspace.id,
                    roles: [...e.props.permissions],
                };
            });
        const user_data = {
            first_name: first_name,
            first_surname: first_surname,
            second_surname: second_surname,
            email: email,
        };
        
        const body = {
            user_data: user_data,
            permissions: permissions,
        }
        const params = {
            organization: props.workspace.organization,
            is_admin: isAdmin,
        };
        
        console.log(params);
        console.log(body);

        try{
            let res = await usersService.create(params, body);
            console.log(res);
            if (res.status === 200) {
                modal.show();
            }
            setFirstName("");
            setFirstSurname("");
            setSecondSurname("");
            setEmail("");
            setIsAdmin(false);
            console.log(isAdmin);
            setWorkspaces([
                <SelectWorkspace
                    key={0}
                    list_id={0}
                    selectedWorkspace={{}}
                    permissions={new Set(defaultWorkspacePermissions)}
                ></SelectWorkspace>,
            ]);
        }
        catch(err){
            if (err?.response?.data?.validation_error?.body_params) {
                let message = (
                    <ul>
                        {err.response.data.validation_error.body_params.map(
                            (e, index) => {
                                var loc = e.loc.join(".");
                                return (
                                    <li>
                                        <b>{`${loc}.`}</b> {e.msg}
                                    </li>
                                );
                            }
                        )}
                    </ul>
                );
            }
            console.log(err);
        }
    }

    //Redirect to home if user is not admin
    if (!props?.workspace?.actions?.users?.view) {
        return <Navigate to="/" />;
    }

    return (
        <div className={`${props.className}`}>
            {/* // <!-- Modal Create User--> */}
            <div
                className="modal fade"
                id="createUserModal"
                tabIndex="1"
                aria-labelledby="createUserModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <p className="h2" id="createUserModalLabel">
                                User created!
                            </p>
                            <button
                                type="button"
                                className="button-close w-100"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                X
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="text">
                                The user has been successfully created
                            </p>
                        </div>
                        <div className="modal-footer">
                            <div className="row w-100 justify-content-end">
                                <div className="col-12 col-md-7 mb-10">
                                    <button
                                        className="button -text w-100"
                                        data-bs-dismiss="modal"
                                    >
                                        CREATE ANOTHER USER
                                    </button>
                                </div>
                                <div className="col-12 col-md-5 mb-10">
                                    <a href="/UserManage">
                                        <button className="button -primary w-100">
                                            CONTINUE
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* // <!-- Modal Conflict OnlyUser--> */}
            <div
                className="modal fade"
                id="ConflictOnlyUserModal"
                tabIndex="1"
                aria-labelledby="ConflictOnlyUserModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <p className="h2" id="ConflictOnlyUserModalLabel">
                                Conflict detected
                            </p>
                            <button
                                type="button"
                                className="button-close w-100"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                X
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="text">
                                You can’t de-select any of the licenses because
                                is the only user on the platform.
                            </p>
                        </div>
                        <div className="modal-footer">
                            <div className="row w-100 justify-content-end">
                                <div className="col-12 col-md-5 mb-10">
                                    <a href="/CreateUser">
                                        <button className="button -primary w-100">
                                            CONTINUE
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Header />
            <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
                <div className="w-10">
                    <Menu activeTab={6} />
                </div>
                <div className="container px-20 mb-50">
                    <div className="row">
                        <div className="col-12 my-10">
                            <div className="row mt-50 mb-10">
                                <p className="h1 -r">Create New User</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-10">
                        <div className="col-12 mt-30">
                            <p className="h2 -r">User information</p>
                        </div>
                        <div className="col-12 mb-20">
                            <p className="label color-gray p-0">
                                <i class="fa-solid fa-circle-info"></i>
                                Please fill all fields that have an asterisk (*).
                            </p>
                        </div>
                        
                        <div className="col-lg-4 col-md-8 mt-20">
                            <div className="form-group">
                                <NDSInput 
                                    label="Name*"
                                    placeholder="Name"
                                    value={first_name}
                                    ariaLabel="Recipient's username"
                                    ariaDescribedby="basic-addon2"
                                    onChange={event => {
                                        setFirstName(event.target.value);
                                    }}
                                    errorMessage="This is a required field"
                                ></NDSInput>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-8 mt-20">
                            <div className="form-group">
                                <NDSInput 
                                    label="First Last Name*"
                                    placeholder="First Last Name"
                                    value={first_surname}
                                    ariaLabel="Recipient's username"
                                    ariaDescribedby="basic-addon2"
                                    onChange={event => {
                                        setFirstSurname(event.target.value);
                                    }}
                                    errorMessage="This is a required field"
                                ></NDSInput>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-8 mt-20">
                            <div className="form-group">
                                <label className="form-check-label text">
                                    Second Last Name
                                </label>
                                <input
                                    type="text"
                                    value={second_surname}
                                    className="form-control"
                                    placeholder="Second Last Name"
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                    onChange={event => {
                                        setSecondSurname(event.target.value);
                                    }}
                                    required
                                ></input>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-8 mt-20">
                            <div className="form-group">
                                <NDSInput 
                                    label="Email*"
                                    placeholder="Email"
                                    value={email}
                                    ariaLabel="Recipient's username"
                                    ariaDescribedby="basic-addon2"
                                    onChange={event => {
                                        setEmail(event.target.value);
                                    }}
                                    errorMessage="This is a required field"
                                ></NDSInput>       
                            </div>
                        </div>
                        <div className="col-12 mt-20">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    checked={isAdmin}
                                    type="checkbox"
                                    value={isAdmin}
                                    onChange={() => {
                                        setIsAdmin(!isAdmin);
                                    }}
                                    defaultChecked={isAdmin}
                                    id="flexCheckDefault"
                                />
                                <label
                                    className="link"
                                    htmlFor="flexCheckDefault"
                                >
                                    <p>
                                        <b>This user is Admin</b>
                                    </p>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-30">
                        <div>
                            <p className="h2 -r">Workspaces &amp; licenses</p>
                        </div>
                        {workspaces}
                    </div>
                    <div className="row mt-30 justify-content-center">
                        <div className="col-12 col-md-6 col-lg-4">
                            <button
                                className="button -outline-primary w-100"
                                onClick={() => {
                                    setWorkspaces([
                                        ...workspaces,
                                        <SelectWorkspace
                                            key={workspaces.length}
                                            list_id={workspaces.length}
                                            selectedWorkspace={{}}
                                            permissions={
                                                new Set(
                                                    defaultWorkspacePermissions
                                                )
                                            }
                                        ></SelectWorkspace>,
                                    ]);
                                }}
                            >
                                <i className="fa-solid fa-circle-plus"></i>
                                ADD ANOTHER WORKSPACE
                            </button>
                        </div>
                    </div>
                    <div className="row mt-50 mb-50">
                        <div className="col-12 col-md-6 col-lg-3">
                            <button
                                className="button -primary w-100"
                                // data-bs-toggle="modal"
                                // data-bs-target="#createUserModal"
                                onClick={() => createUser()}
                            >
                                CREATE USER
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default connect(mapStateToProps)(CreateUser);
