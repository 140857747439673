import { get } from "http";
import API from "./axios";
import qs from "qs";
import { create } from "domain";

const workspacesService = {

  //Endpoint to count documents in MongoDB collections for a given workspace.
  getCount: async (workspace_id: string) => {
    return await API.get(`/workspaces/count?workspace_id=${workspace_id}`);
  },

  //Endpoint to get multiple workspaces by their IDs.
  collectMany: async (params) => {
      return await API.get(`/workspaces/collect_many`, { params });
  },

  //Endpoint to get multiple workspaces by their Organization.
  collectManyByOrganization: async (params) => {
    return await API.get(`/workspaces/collect_many_by_organization`, { params });
  },

  collectManyByOrganizationAdmin: async (params) => {
    return await API.get(`/workspaces/collect_many_by_organization_admin`, { params });
  },

  //Endpoint to create a new workspace.
  create: async (body) => {
      return await API.post("/workspaces/create", body);
  },

  //Endpoint to get a workspace by its ID.
  collectOne: async (params) => {
      return await API.get("/workspaces/get", { params });
  },

  //Endpoint to import a workspace.
  import: async (body) => {
      return await API.post("/workspaces/import_workspace", body);
  },

  //Endpoint to get the users of a workspace.
  users: async (params) => {
      return await API.get("/workspaces/workspace_users", { params });
  },

  //Endpoint to update a workspace.
  update: async (params) => {
      return await API.post("/workspaces/update", {}, { params });
  },

  //Endpoint to delete a workspace.
  delete: async (params) => {
      return await API.delete("/workspaces/delete", { params });
  },

  collectAllNlpInstances: async (params) => {
    return await API.get("/workspaces/collect_all_nlp_instances", { params });
  },

  getNlpInstance: async (params) => {
    return await API.get("/workspaces/get_nlp_instance", { params });
  },

  editNlpInstance: async (params, body) => {
    return await API.post("/workspaces/edit_nlp_instance", body, { params });
  },

  deleteNlpInstance: async (params) => {
    return await API.delete("/workspaces/delete_nlp_instance", { params });
  },

  createNlpInstance: async (body) => {
    return await API.post("/workspaces/create_nlp_instance", body);
  },

  collectAllOrganizations: async (params) => {
    return await API.get("/workspaces/collect_all_organizations", { params });
  },

  createOrganization: async (body) => {
    return await API.post("/workspaces/create_organization", body);
  },

  reimportWorkspace: async (params) => {
    return await API.post("/workspaces/reimport_workspace", {}, { params });
  },

};

export default workspacesService;