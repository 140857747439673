import React, { useReducer } from 'react';

const ACTION_TYPES = {
  SET_TEXT: 'SET_TEXT',
  SET_ERROR: 'SET_ERROR',
  CLEAR_ERROR: 'CLEAR_ERROR',
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_TEXT:
      return {
        ...state,
        text: action.payload,
        error: action.payload.length === 0 ? action.errorMessage : '',
      };
    case ACTION_TYPES.SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ACTION_TYPES.CLEAR_ERROR:
      return {
        ...state,
        error: '',
      };
    default:
      return state;
  }
};

const initialState = {
  text: '',
  error: '',
};


const NDSInput = ({ label, placeholder, ariaLabel, ariaDescribedby, errorMessage, onChange, value }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleChange = (e) => {
    dispatch({ type: ACTION_TYPES.SET_TEXT, payload: e.target.value, errorMessage });
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className="form-group">
      <label className="form-check-label text">
        {label}
      </label>
      <input
        type="text"
        className="form-control mb-10"
        value={value}
        placeholder={placeholder}
        aria-label={ariaLabel}
        aria-describedby={ariaDescribedby}
        onChange={handleChange}
        required
      ></input>
      {state.error && <label className="form-check-label -error text">{state.error}</label>}
    </div>
  );
}

export default NDSInput;