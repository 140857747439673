import React, { useState, useEffect, useRef, useCallback } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import Select from "react-select";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { Modal } from "bootstrap";
import Header from '../../../Components/Header/headerView';
import Footer from '../../../Components/Footer/footer';
import Menu from '../../../Components/Menu/menu';
import NDSTooltip from "../../../Components/Tooltip/ndsTooltip";
import Paginator from "../../../Components/Paginator/paginator";
import { connect } from "react-redux";
import FileSaver from "file-saver";
import { OpenRequest, CommentModal, RequestCreatedModal } from '../../../Utils/modals';
import * as dateService from "../../../Utils/format/date";
import * as interactionService from "../../../Services/interaction";
import intentsService from "../../../Services/intentsService";
import requestsService from "../../../Services/requestsService";
import { intentName, intentDescription, intentExamples } from "../../../Utils/regexValidations";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../../Components/Loader/actions";
import { v4 as uuidv4 } from "uuid";

  const IntentsEdit = (props) => {
  const workspace = props.workspace.id;
  const user = props.user;
  const role = props.user.role;
  const workspace_engine = props.workspace.workspace;
  //Set here items to show in each page
  const itemsPerPage = 5;

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("1");
  const [loadData, setLoadData] = useState(true);
  const [loadAutoSuggestions, setLoadAutoSuggestions] = useState(true)
  const [intent, setIntent] = useState([]);
  const [inputName, setInputName] = useState("");
  const [inputDescription, setInputDescription] = useState("");
  const [listExamples, setListExamples] = useState([]);
  const [inputExample, setInputExample] = useState("");
  const [exampleEdit, setExampleEdit] = useState(false);
  const [originalListExamples, setOriginalListExamples] = useState([]);
  const selectIntentModalRef = useRef({});
  const [changes, setChanges] = useState([]);
  const [newChanges, setNewChanges] = useState([]);
  const [checked, setChecked] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(4);
  const [splittedAdded, setSplittedAdded] = useState([]);
  const [onlyAdded, setOnlyAdded] = useState(false);

  const intentID = useParams().intent_id;
  const [moveStash, setMoveStash] = useState({});
  const [addedContent, setAddedContent] = useState([]);
  const [showedExamples, setShowedExamples] = useState([])
  const deleteRef = useRef({});
  const cancelRef = useRef({});
  const [errorMessage, setErrorMessage] = useState("Something went wrong. Please try again.");
  const [validationError, setValidationError] = useState(false);
  const [nameError, setNameError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [selectedExamples, setSelectedExamples] = useState([]);

  const dispatch = useDispatch();

  const removeMoveHof = (customFunc, elements, ignore) => {
    if (!elements) customFunc(elements);
    if (!Array.isArray(elements)) throw Error("Elements is not an array");
    let _moveStash = JSON.parse(JSON.stringify(moveStash));
    elements.forEach((c) => {
      if (c._id in _moveStash && !ignore.map((e) => e._id).includes(c._id))
        delete _moveStash[c._id];
    });
    if (JSON.stringify(_moveStash) !== JSON.stringify(moveStash)) {
      setMoveStash(_moveStash);
    }
    customFunc(elements);
  };

  //Validations
  const emptyTest = /(?!\s\s)/; //this can not be only spaces or empty

  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined
      );
  const required = (value) => (value ? undefined : "Required");
  const maxLength = (max) => (value) =>
    value && value.length > max
      ? `Must be ${max} characters or less`
      : undefined;
  const name = (value) =>
    value && !intentName.test(value) ? "Invalid name" : undefined;
  const description = (value) =>
    value && !intentDescription.test(value) ? "Invalid description" : undefined;
  const empty = (value) =>
    value && !emptyTest.test(value) ? "Invalid value" : undefined;
  const example = (value) =>
    value && !intentExamples.test(value) ? "Invalid example" : undefined;
  const exampleExists = (value) =>
    value === inputExample && isExampleInList(value)
      ? "Example already exists"
      : undefined;

  const handleChangeError = (event, field, initial) => {
    let newValue = "";
    if(initial){
      newValue = event;
    }
    else{
      newValue = event.target.value;
    }
    if (field === "name"){
      const error = composeValidators(
        required,
        name,
        maxLength(64),
      )(newValue);
      setNameError(error);
      console.log("Error:", error)
    }
    else if (field === "description"){
      const error = composeValidators(
        description,
        maxLength(128)
      )(newValue);
      setDescriptionError(error);
    }
  };

  useEffect(() => {
    if (nameError || descriptionError) {
      setValidationError(true);
      console.log("hay error");
    }
    else{
      setValidationError(false);
      console.log("no hay error");
    }
  }, [nameError, descriptionError]);

  const exampleName = (value) =>
      value === inputExample && isExampleNameDifferent(value)
          ? "Example name can't be same as Intent name"
          : undefined;

  //Check if the example is already in the list
  const isExampleInList = (example) => {
    let found = false;
    if (example === "") return found;
    listExamples.forEach((item) => {
      //Case insensitive comparison
      //Remove spaces and tabs
      let exampleClean = example.replace(/ /g, "").replace(/\t/g, "");
      let itemClean = item.value.replace(/ /g, "").replace(/\t/g, "");
      if (itemClean.toLowerCase() === exampleClean.toLowerCase()) {
        found = true;
      }
    });
    return found;
  };

  const isExampleNameDifferent = (name) => {
    let different = false;
    if (name === "") return different;
    let exampleClean = name.replace(/ /g, "").replace(/\t/g, "");
    let intentClean = inputName.replace(/ /g, "").replace(/\t/g, "");
    if (intentClean.toLowerCase() === exampleClean.toLowerCase()) {
      different = true;
    }
    return different;
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const setListExamplesWithID = (examples) => {
    examples?.forEach((e) => {
      e._id = e._id || uuidv4();
    });
    let newExamples = examples?.map((e) => Object.entries(e).sort().toString());
    let oldExamples = listExamples?.map((e) =>
      Object.entries(e).sort().toString()
    );
    let ignore = listExamples.filter((e) =>
      newExamples
        ?.filter((x) => oldExamples?.includes(x))
        .includes(Object.entries(e).sort().toString())
    );
    removeMoveHof(setListExamples, examples, ignore);
  };

  async function getIntent(intentID) {
    dispatch(startLoading());
    let params = {
      intent_id: intentID,
      workspace_id: workspace,
    };

    try{
      let res = await intentsService.collectOne(params);
      let tempIntent = res.data;
      console.log("Intent", res);
      for (let i = 0; i < tempIntent.examples.length; i++) {
        tempIntent.examples[i] = {
          ...tempIntent.examples[i],
          checked: false,
        };
      }
      setIntent(tempIntent);
      setOriginals(res.data);
    }
    catch(err){
      console.log(err);
    }
    setLoadData(false);
    dispatch(stopLoading());
  }

  const setOriginals = (intent) => {
    
    setInputName(intent.name);
    handleChangeError(intent.name, "name", true);

    setInputDescription(intent.description);
    handleChangeError(intent.description, "description", true);

    setListExamplesWithID(intent.examples);

    setOriginalListExamples(JSON.parse(JSON.stringify(intent.examples)));
  };

  const addExample = (e) => {
    if (inputExample !== "") {
      var timeNow = new Date();
      var time = dateService.humanizeDateTime(timeNow);
      var tempObject = {
        value: inputExample,
        type: "create",
        updated_on: timeNow,
        modified: time,
        newOne: true,
      };
      setListExamplesWithID(listExamples.concat(tempObject));
      handleChanges("examples", "add", inputExample);
      setInputExample("");
    }
  };

  /*this function adds the intent's new autommatic suggestions to the new changes
  variable and listExamples array */
  const addExamples2 = (recommendations) => {
      setAddedContent([recommendations.length])
      // setAddedContent([`${recommendations.length} Examples`]);
      var timeNow = new Date();
      var time = dateService.humanizeDateTime(timeNow);
      let listTempObjects = []
      recommendations.forEach((recommendation)=>{
      var tempObject = {
      value: recommendation,
      type: "create",
      updated_on: timeNow,
      modified: time,
      newOne: true,
      };
      listTempObjects.push(tempObject)
   
})    
      
      setListExamplesWithID(listExamples.concat(listTempObjects));
      let noww = new Date();
      let timeNoww = new Date(
      noww.getTime() - noww.getTimezoneOffset() * 60000
    ).toISOString();
      let changes = []
      recommendations.forEach((recommendation) => {
      
      changes.push({
            field: "examples",
            value: recommendation,
            new_op: "create",
            updated_on: timeNoww,
          });
      setNewChanges([...newChanges, ...changes]);
  })
  };


  const handleChanges = (fieldType, operation, value, oldValue) => {

    let change = [];

    let oldOp = fieldType;

    if (operation === "delete" || operation === "update") {
      Object.entries(changes).forEach(([operation, fields]) => {
        // console.log(fields[fieldType]);
        if (fields[fieldType] !== undefined && fields[fieldType].length > 0) {
          fields[fieldType].forEach((item) => {
            // console.log(item, value);
            if (item.value === value) {
              oldOp = operation;
              oldValue = item.value;
            } else if (item.value_id === value) {
              oldOp = operation;
              oldValue = item.value_id;
            }
          });
        }
      });
    }
    // console.log(oldOp);

    //Check if last value is original value //TODO

    switch (operation) {
      case "add":
        change.push({
          field: fieldType,
          value: value,
          new_op: "create",
        });
        break;
      case "delete":
        change.push({
          field: fieldType,
          value: oldValue,
          new_op: "delete",
          undo: false,
        });
        break;
      case "update":
        if (
          !newChanges.some((item) => item.value === value) &&
          oldValue !== value
        ) {
          change.push({
            field: fieldType,
            value: oldValue, // Original value
            last_value: oldValue, //Previous value
            new_value: value, // New value
            new_op: "update",
          });
        } else if (oldValue === value) {
          // console.log("sameValue", newChanges);
          let tempChanges = newChanges;
          tempChanges.forEach((item, index) => {
            if (item.field === fieldType) {
              tempChanges.splice(index, 1);
            }
          }, tempChanges);
        } else {
          // console.log("update", newChanges);
          let tempChanges = newChanges;
          tempChanges.forEach((item) => {
            if (
              item.field === fieldType &&
              item.new_op !== operation &&
              item.value !== value
            ) {
              //item.value = value
              item.new_value = value;
            }
          });
          setNewChanges(...tempChanges);
        }
        break;
      default:
        break;
    }
    setNewChanges([...newChanges, ...change]);
    // console.log("newChanges", newChanges);
  };

  function saveName() {
    let nameValue = "";
    let alreadyEdited = false;
    console.log("Save name");
    //Check if the name object has stashed changes
    newChanges.find((change) => change.field === "name")
      ? (alreadyEdited = true)
      : (alreadyEdited = false);

    //If not in changes, add it
    if (alreadyEdited === false) {
      console.log("Not in changes");
      nameValue = inputName;
      let tempOldValue = "";
      tempOldValue = intent.name;
      nameValue !== tempOldValue &&
        handleChanges("name", "update", nameValue, tempOldValue);

      //handleChanges('name', 'update', nameValue, tempOldValue)
    } else {
      //If in changes, update it
      console.log("Already in changes");
      nameValue = inputName;
      let tempChanges = [...newChanges];
      tempChanges.forEach((change) => {
        if (change.field === "name") {
          change.new_value = nameValue;
          change.value = nameValue;
        }
        if (change.new_value === change.last_value) {
          console.log("Remove from changes, same value");
          tempChanges = tempChanges.filter((item) => item.field !== "name");
        }
      });
      setNewChanges(tempChanges);
    }
  }

  function saveDescription() {
    let descriptionValue = "";
    console.log("Save description");
    let alreadyEdited = false;
    //Check if the description object has stashed changes
    newChanges.find((change) => change.field === "description")
      ? (alreadyEdited = true)
      : (alreadyEdited = false);

    //If not in changes, add it
    if (alreadyEdited === false) {
      console.log("Not in changes");
      descriptionValue = inputDescription;
      let tempOldValue = intent.description;
      descriptionValue !== tempOldValue &&
        handleChanges("description", "update", descriptionValue, tempOldValue);
    } else {
      //If in changes, update it
      console.log("Already in changes");
      descriptionValue = inputDescription;
      let tempChanges = [...newChanges];
      tempChanges.forEach((change) => {
        if (change.field === "description") {
          change.new_value = descriptionValue;
          //change.value=descriptionValue
        }
        if (change.new_value === change.last_value) {
          console.log("Remove from changes, same value");
          tempChanges = tempChanges.filter(
            (item) => item.field !== "description"
          );
        }
      });
      setNewChanges(tempChanges);
    }
  }

  function deleteExample(example) {
    
    example.value instanceof Array &&
      (example.value = example.value[0].edition);

    let tempExamples = JSON.parse(JSON.stringify(listExamples));
    tempExamples.forEach((item, index) => {
      if (item.value === example.value) {
        tempExamples[index].prevType = tempExamples[index].type;
        tempExamples[index].type = "delete";
        if (item.newOne) {
          tempExamples.splice(index, 1); //Remove if just added new one
          //Remove from the changes
          if (example.type === "create" && addedContent[0] >= 0)
            {
              setAddedContent([addedContent[0] - 1]);
            }
          
          let tempChanges = newChanges;
          tempChanges.forEach((item, i) => {
            if (item.field === "examples" && item.value === example.value) {
              tempChanges.splice(i, 1);
            }
          }, tempChanges);
          setNewChanges(tempChanges);
        }
      }
    }, tempExamples);
    setListExamplesWithID(tempExamples);
    !example.newOne &&
      handleChanges(
        "examples",
        "delete",
        example.value,
        example.editions?.length > 0
          ? example.editions[0].edition
          : example.value
      );

  }

  function undoExampleCreate(example) {
    deleteExample(example);
  }

  function handleEdition(example) {
    setInputExample(example.value);
    setExampleEdit(example.value);
    example.type && (example.prevType = example.type);
    example.type = "update";
    let tempExamples = JSON.parse(JSON.stringify(listExamples));
    tempExamples.forEach((item, index) => {
      if (item.value === example.value) {
        tempExamples[index].type = "update";
      }
    }, tempExamples);
    setListExamplesWithID(tempExamples);
  }

  function editExample(example, previous) {
    let original = false;

    intent.examples.forEach((originalItem) => {
      if (originalItem.value === example) {
        original = true;
      }
    });

    console.log(original);
    let tempExamples = listExamples;
    tempExamples.forEach((item, index) => {
      if (original) {
        item.value === example && (tempExamples[index].type = null);
        //Remove change
        let tempChanges = newChanges;
        tempChanges.forEach((item, index) => {
          if (item.field === "examples" && item.value === example) {
            tempChanges.splice(index, 1);
          }
        }, tempChanges);
        setNewChanges(tempChanges);
        setInputExample("");
        setExampleEdit(false);
      } else if (
        item.type === "update" &&
        !item.previous &&
        item.value !== example
      ) {
        console.log("Editing", item.previous, example);
        tempExamples[index].value = example;
        tempExamples[index].previous = previous;
        //Add change
        handleChanges("examples", "update", example, previous);
        setInputExample("");
        setExampleEdit(false);
      }
    }, tempExamples);
    setListExamplesWithID(tempExamples);
  }

  function cancelEdition() {
    let editingExample = exampleEdit;
    let tempExamples = JSON.parse(JSON.stringify(listExamples));
    //Check if the example is in the original list
    let original = false;
    intent.examples.forEach((originalItem) => {
      if (originalItem.value === inputExample) {
        original = true;
      }
    });

    if (!original) {
      console.log("Original example");
      tempExamples.forEach((item, index) => {
        if (item.type === "update" && item.value === inputExample) {
          tempExamples[index].type = item.prevType;
          //tempSynonyms[index].previous = null
          editingExample = false;
        }
      }, tempExamples);
      setListExamplesWithID(tempExamples);
      setInputExample("");
      setExampleEdit(false);
    } else {
      console.log("New example");
      tempExamples.forEach((item, index) => {
        if (item.type === "update" && item.value === inputExample) {
          tempExamples[index].type = null;
          tempExamples[index].previous = null;
          editingExample = false;
        }
      }, tempExamples);
      setListExamplesWithID(tempExamples);
      setInputExample("");
      setExampleEdit(false);
    }
  }

  function undoExampleEdit(example) {
    let tempExamples = JSON.parse(JSON.stringify(listExamples));
    let abortFunction = false;
    tempExamples.forEach((item, index) => {
      if (example.previous === item.value) {
        tempExamples.splice(index, 1);
        setInputExample("");
        setExampleEdit(false);
        setListExamplesWithID(tempExamples);
        let tempChanges = [...newChanges];
        tempChanges = tempChanges.filter((item) => item.value !== example.previous);
        console.log(tempChanges);
        setNewChanges(tempChanges);
        abortFunction = true;
    }
      else if (item.value === example.value) {
        tempExamples[index].type = item.prevType;
        tempExamples[index].value = example.previous;
        tempExamples[index].previous = null;
        tempExamples[index].recovered = false;
        tempExamples[index].prevType = null;
      }
    }, tempExamples);
    if (abortFunction) return;
    setListExamplesWithID(tempExamples);
    setInputExample("");
    setExampleEdit(false);
    //Remove change
    let tempChanges = newChanges;
    tempChanges.forEach((item, i) => {
      //Comparar el último valor del item en tempChanges y el último valor del example
      if (item.last_value === example.previous) {
        tempChanges.splice(i, 1);
      }
    }, tempChanges);
    setNewChanges(tempChanges);
  }

  function recoverExample(example) {
    if (example._id in moveStash) {
      delete moveStash[example._id];
      setMoveStash({
        ...moveStash,
      });
      return;
    }
    //Check if example is in the original list
    let undo = false;

    intent.examples.forEach((item) => {
      if (item.value === example.value && !item.previous) {
        undo = true;
      }
    });

    newChanges.forEach((item) => {
      if (item.value === example.value && item.type === "delete") {
        undo = true;
      }
    });

    if (example.prevType) {
      let tempExamples = JSON.parse(JSON.stringify(listExamples));
      tempExamples.forEach((item, index) => {
        if (item.value === example.value) {
          item.type = example.prevType;
          item.value instanceof Object && (item.value = item.value.edition);
        }
      }, tempExamples);
      setListExamplesWithID(tempExamples);
      //Remove change
      let tempChanges = newChanges;
      tempChanges.forEach((item, index) => {
        if (item.field === "examples" && item.value === example.value) {
          tempChanges.splice(index, 1);
        }
      }, tempChanges);
      setNewChanges(tempChanges);
      return;
    }

    if (undo && example.type === "delete") {
      let tempExamples = JSON.parse(JSON.stringify(listExamples));
      tempExamples.forEach((item, index) => {
        if (item.value === example.value) {
          item.type = null;
        }
      }, tempExamples);
      setListExamplesWithID(tempExamples);
      //Remove change
      let tempChanges = newChanges;
      tempChanges.forEach((item, index) => {
        if (item.field === "examples" && item.value === example.value) {
          tempChanges.splice(index, 1);
        }
      }, tempChanges);
      setNewChanges(tempChanges);
      return;
    } else {
      let tempExamples = JSON.parse(JSON.stringify(listExamples));
      tempExamples.forEach((item, index) => {
        if (item.value === example.value) {
          item.recovered = true;
        }
      }, tempExamples);
      setListExamplesWithID(tempExamples);
      //Add change
      let timeNow = new Date();
      let tempChanges = newChanges;
      tempChanges.push({
        field: "examples",
        value: example.value,
        new_op: "delete",
        undo: true,
        update_on: timeNow,
      });
      setNewChanges(tempChanges);
    }
  }

  function undoRecovery(example) {
    let tempExamples = JSON.parse(JSON.stringify(listExamples));
    console.log("Undo recovery", example);
    tempExamples.forEach((item, index) => {
      if (item.value === example.value) {
        console.log(item);
        item.type = example.prevType ? example.prevType : "delete";
        item.recovered = false;
      }
    }, tempExamples);
    setListExamplesWithID(tempExamples);
    //Remove change
    let tempChanges = newChanges;
    tempChanges.forEach((item, i) => {
      if (item.value === example.value) {
        tempChanges.splice(i, 1);
      }
    }, tempChanges);
    setNewChanges(tempChanges);
  }

  const handleCheck = (object) => {
    var newChecked = new Set(checked);
    var isChecked = newChecked.has(object?._id);
    isChecked ? newChecked.delete(object?._id) : newChecked.add(object?._id);
    if (allChecked && isChecked) {
      setAllChecked(false);
    }
    setChecked(newChecked);

    setSelectedExamples((prevSelectedSynonyms) => {
      const updatedSelectedSynonyms = [...prevSelectedSynonyms];
      const index = updatedSelectedSynonyms.findIndex((example) => example._id === object._id);
  
      if (index !== -1) {
        updatedSelectedSynonyms.splice(index, 1);
      } else {
        updatedSelectedSynonyms.push(object);
      }
      return updatedSelectedSynonyms;
    });
  };

  const handleAllCheck = (event, value) => {
    if (event.target.checked) {
      setChecked(new Set([...checked, ...listExamples.map((e) => e._id)]));
      setSelectedExamples(listExamples);
    } else {
      setChecked(new Set());
      setSelectedExamples([]);
    }
    setAllChecked(event.target.checked);
  };

  const handleCancelSelection = () => {
    // setChecked(new Set());
    // setAllChecked(false);
    if (Object.keys(cancelRef.current).length === 0) return;
    let original = JSON.parse(JSON.stringify(originalListExamples));
    [...checked].forEach((e) => {
      let originalIndex = original.findIndex((ee) => ee._id === e);
      if (originalIndex !== -1) {
        if (cancelRef.current[e] !== null) {
          interactionService.simulateMouseClick(cancelRef.current[e]);
        }
      } else {
        let currentIndex = listExamples.findIndex((ee) => ee._id === e);
        selectedExamples.forEach((example, index) => {
          if (example._id === e) {
            selectedExamples.splice(index, 1);
          }
        })
        if (currentIndex !== -1) {
          interactionService.simulateMouseClick(cancelRef.current[e]);
        }
      }
    });
    setListExamplesWithID(JSON.parse(JSON.stringify(listExamples)));
  };

  const pruneChecked = useCallback(() => {
    let currentIds = new Set(listExamples.map((e) => e._id));
    let newChecked = new Set([...checked].filter((i) => currentIds.has(i)));
    if (JSON.stringify(checked) !== JSON.stringify(newChecked))
      setChecked(newChecked);
  }, [checked, listExamples]);

  useEffect(() => {
    pruneChecked();
      //Adds automatic suggestion recommendations as added examples and set added examples list
      if (loadAutoSuggestions && listExamples.length > 0)
      {
        let recommendation_list = []
        intent?.automatic_suggestions?.forEach(
          suggestion => {
              recommendation_list.push(suggestion.recommendation)     
            }
        );
        if (recommendation_list.length > 0)
        {
          addExamples2(recommendation_list)
        }
        setLoadAutoSuggestions(false)
      }

      

  }, [listExamples, pruneChecked]);

  const handleDownloadSelection = (list) => {
    console.log(list);
    if (list.length === 0) {
      return;
    }
    const headers = ['Examples'];

    const csvData = list.map((synonym) => {
      const synonymsValue = synonym.new_value || synonym.value;
      return `${synonymsValue}`;
    });

    const csvContent = `${headers.join(',')}\n${csvData.join('\n')}`;
    const blob = new Blob([csvContent], {
      type: 'data:text/csv;charset=utf-8',
    });
    FileSaver.saveAs(blob, 'list.csv');
  };

  const handleDeleteSelection = () => {
    [...checked].forEach((e) => {
      if (e in deleteRef.current && deleteRef.current[e] !== null) {
        interactionService.simulateMouseClick(deleteRef.current[e]);
      }
    });
  };

  function changesToPayload(){
    let changes = [];
    newChanges.forEach((change) => {
      if (change.field === "examples") {
        switch (change.new_op) {
          case "create":
            changes.push({
              field: "examples",
              operation: "create",
              value: change.value,
            });
            break;
          case "delete":
            changes.push({
              field: "examples",
              operation: "delete",
              value: change.value,
            });
            break;
          case "update":
            changes.push({
              field: "examples",
              operation: "delete",
              value: change.value,
            });
            changes.push({
              field: "examples",
              operation: "create",
              value: change.new_value,
            });
            break;
        }
      } 
      else if (change.field === "name") {
        changes.push({
          field: "name",
          operation: "update",
          value: change.new_value,
        });
      }
      else if (change.field === "description") {
        changes.push({
          field: "description",
          operation: "update",
          value: change.new_value,
        });
      }
    })

    let body = {
      object_id: intentID,
      object_type: "intent",
      "changes": changes
    }

    return body;
  }

  async function handleSave(comment) {
    let params = {
      workspace_id: workspace,
      user_id: user.id,
    }
    let body = changesToPayload();
    body.comments = comment;

    try{
      console.log("Params", params);
      console.log("Body", body);
      let res = await requestsService.create(params, body);
      console.log(res);
      let requestCreatedModal = new Modal(document.getElementById("requestCreatedModal"));
      requestCreatedModal.show();
    }
    catch(err){
      console.log(err);
    }
  }

  const handlePageChange = (page) => {
    setPage(page);
  };

  useEffect(() => {
    if (loadData) {
      getIntent(intentID);
      setLoadData(false);      
    }
  }, [loadData, intentID, inputExample]);


  

  useEffect(() => {
    const tempListExamples = [...listExamples];
    let temp_array = [];
    for (let i = 0; i < tempListExamples.length; i++) {
      let example = tempListExamples[i];
      if (example.type === "create") {
        temp_array.push(example);
        tempListExamples.splice(i, 1);
        i--;
      }
    }
    const listExamplesWAdded = temp_array.concat(tempListExamples);
    setSplittedAdded(temp_array);
    if (!onlyAdded){
      splitDetailsIntoState(listExamplesWAdded, itemsPerPage);
    }
    else{
      splitDetailsIntoState(temp_array, itemsPerPage);
    }



  }, [listExamples]);

  const isPristine = (example, includeAdding = false) => {
    return (
      example.type !== "delete" &&
      !exampleEdit &&
      (example.type !== "create" || !includeAdding) &&
      example.type !== "delete" &&
      example.type !== "update" &&
      !(example._id in moveStash)
    );
  };

  let newChecked = [...checked].filter((c) =>
    listExamples.find((e) => e._id === c)
  );
  if (newChecked.length !== [...checked].length) {
    setChecked(new Set(newChecked));
  }

  const splitArray = (array, itemsPp) => {
    const splitted = [];
    if (array) {
      for (let i = 0; i < array.length; i += itemsPp) {
        const chunk = array.slice(i, i + itemsPp);
        splitted.push(chunk);
      }
    }
    return splitted;
  };

  const splitDetailsIntoState = (examplesArr, itemsPp = 5) => {
    if(examplesArr.length > itemsPp){
      const splittedExamples = splitArray(examplesArr, itemsPp);
      setTotalPages(splittedExamples.length);
      setShowedExamples(splittedExamples);
    } else {
        setTotalPages([examplesArr].length);
        setShowedExamples([examplesArr]);
    }
  };

  return (
    <div className={`${props.className}`}>
      {/* // <!-- Modal approve--> */}
      <OpenRequest
        handleSave={handleSave}
      />
      <CommentModal handleSave={handleSave}/>
      <RequestCreatedModal />
      <Header />
      <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
        <div className="w-10">
          <Menu activeTab={2} />
        </div>
        <div className="container px-20 mt-20 mb-50">
        
              
          <Form
            onSubmit={() => {
              // new Modal(document.getElementById("approveModal")).show();
            }}
            //onSubmit={null}
            render={({ handleSubmit, form }) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                    
                  <div className="col-12 col-lg-9 my-20">
                    <div className="card-w -m mt-30">
                      
                      <div className="row">
                        <p className="h3 -b">Intent</p>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <div
                            className="form-group mt-20"
                            style={{ display: "block" }}
                          >
                            <div className="col">
                              {intent?.name !== inputName && (
                                <div className="row">
                                  <p className="label -edited mx-10">Edited</p>
                                  <p className="label -previous mx-10">
                                    {intent.name}
                                  </p>
                                </div>
                              )}
                            </div>
                            <Field
                              name="name"
                              initialValue={inputName}
                              validate={composeValidators(
                                required,
                                name,
                                maxLength(64),
                              )}
                            >
                              {(props) => (
                                <>
                                  <input
                                    {...props.input}
                                    type="text"
                                    name="name"
                                    placeholder={inputName}
                                    autoComplete="off"
                                    required={true}
                                    id="name"
                                    value={inputName}
                                    onChange={(e) => [
                                      e.preventDefault(),
                                      setInputName(e.target.value),
                                      props.input.onChange(e),
                                      handleChangeError(e, "name"),
                                    ]}
                                    onBlur={(e) => [
                                      e.preventDefault(),
                                      saveName(),
                                      props.input.onBlur(e),
                                    ]}
                                    style={
                                      props.meta?.error
                                        ? { borderColor: "red" }
                                        : null
                                    }
                                  />
                                  <label
                                    htmlFor="name"
                                    data-toggle="tooltip"
                                    title="Intent names can contain only Unicode alphanumeric, underscore, hyphen, and dot characters. It can not be empty or longer than 128 characters."
                                    style={
                                      props.meta?.error
                                        ? { color: "red" }
                                        : null
                                    }
                                  >
                                    {props.meta?.error ? (
                                      <span className="error">
                                        <i className="fas fa-exclamation-circle fa-sm"></i>
                                        {props.meta?.error}
                                      </span>
                                    ) : (
                                      "Intent name"
                                    )}
                                  </label>
                                </>
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div
                            className="form-group mt-40"
                            style={{ display: "block" }}
                          >
                            <div className="col">
                              {intent?.description !== inputDescription && (
                                <div className="row">
                                  <p className="label -edited mx-10">Edited</p>
                                  <p className="label -previous mx-10">
                                    {intent.description}
                                  </p>
                                </div>
                              )}
                            </div>
                            <Field
                              name="description"
                              initialValue={inputDescription}
                              validate={composeValidators(
                                description,
                                maxLength(128)
                              )}
                            >
                              {(props) => (
                                <>
                                  <input
                                    {...props.input}
                                    type="text"
                                    name="description"
                                    placeholder={
                                      changes?.edited?.description?.new_value
                                    }
                                    autoComplete="off"
                                    id="description"
                                    value={inputDescription}
                                    onChange={(e) => [
                                      e.preventDefault(),
                                      setInputDescription(e.target.value),
                                      props.input.onChange(e),
                                      handleChangeError(e, "description"),
                                    ]}
                                    onBlur={(e) => [
                                      e.preventDefault(),
                                      saveDescription(),
                                      props.input.onBlur(e),
                                    ]}
                                    style={
                                      props.meta?.error
                                        ? { borderColor: "red" }
                                        : null
                                    }
                                  />

                                  <label
                                    htmlFor="description"
                                    data-toggle="tooltip"
                                    title="This string cannot contain carriage return, newline, or tab characters. It can not be empty or longer than 128 characters."
                                    style={
                                      props.meta?.error && props.meta?.touched
                                        ? { color: "red" }
                                        : null
                                    }
                                  >
                                    {props.meta?.error ? (
                                      <span className="error">
                                        <i className="fas fa-exclamation-circle fa-sm"></i>
                                        {props.meta?.error}
                                      </span>
                                    ) : (
                                      "Intent description"
                                    )}
                                  </label>
                                </>
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 mt-40">
                    <div className="row">
                      <button
                        type="button"
                        className={`button w-100 ${validationError ? '-inactive' : '-primary'}`}
                        data-bs-toggle="modal"
                        data-bs-target="#commentModal"
                        disabled={validationError}
                      >
                        CREATE REQUEST
                      </button>
                    </div>
                    <div className="row mt-10">
                      <button
                        type="button"
                        className="button -text w-100"
                        onClick={() => (window.location.href = `/detail/intent/${intentID}`)}
                      >
                        RETURN
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <Nav tabs className="col-12 d-flex">
                    <NavItem>
                      <NavLink
                        className={activeTab === "1" ? "active" : "inactive"}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        <span>
                          <p className="icon">
                            <i className="fa-solid fa-lines-leaning"></i>
                          </p>
                          <p className="h3 -b">EXAMPLES</p>
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <div className="row">
                      <div className="col-12 col-lg-9">
                        <div className="card-w -m my-40">
                          <div className="row mb-10">
                            <p className="h4">
                              Write examples of what users are trying to say
                            </p>
                          </div>
                          <div className="row">
                            <div className="form-group mt-40">
                              {/* <input type="text" name="example" placeholder="Ej. I want a credit card"
                            onChange={e => setInputExample(e.target.value)} value={inputExample}
                            autoComplete="off" required="" id="example"></input>
                          <label htmlFor="example">Example</label> */}
                              <Field
                                name="example"
                                initialValue={inputExample}
                                onSubmit={addExample}
                                validate={composeValidators(
                                  example,
                                  exampleExists,
                                  empty,
                                  maxLength(1024),
                                    exampleName
                                )}
                              >
                                {(props) => (
                                  <>
                                    <input
                                      {...props.input}
                                      type="text"
                                      name="example"
                                      placeholder="Ej. I want a credit card"
                                      autoComplete="off"
                                      required={false}
                                      id="example"
                                      value={inputExample}
                                      onChange={(e) => [
                                        e.preventDefault(),
                                        setInputExample(e.target.value),
                                        props.input.onChange(e),
                                      ]}
                                      onBlur={(e) => props.input.onBlur(e)}
                                      style={
                                        props.meta?.error && props.meta?.touched
                                          ? { borderColor: "red" }
                                          : null
                                      }
                                    />

                                    <label
                                      htmlFor="example"
                                      data-toggle="tooltip"
                                      title="This string cannot contain carriage return, newline, or tab characters. It can not be empty or longer than 128 characters."
                                      style={
                                        props.meta?.error && props.meta?.touched
                                          ? { color: "red" }
                                          : null
                                      }
                                    >
                                      {props.meta.error &&
                                      props.meta.touched ? (
                                        <span className="error">
                                          <i className="fas fa-exclamation-circle fa-sm"></i>
                                          {props.meta.error}
                                        </span>
                                      ) : (
                                        "Example"
                                      )}
                                    </label>
                                  </>
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className="row mt-20">
                            {!exampleEdit ? (
                              <div className="col-12 col-md-4 col-lg-3">
                                {/* <button className={`button -outline-primary -${inputExample ? 'active' : 'inactive'}`}
                            onClick={e => addExample(e)}
                            >ADD EXAMPLE</button> */}
                                <button
                                  type="submit"
                                  className={`button w-100 -outline-primary -${
                                    inputExample &&
                                    !form.getFieldState("example").error
                                      ? "active"
                                      : "inactive"
                                  }`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (
                                      !exampleEdit &&
                                      !form.getFieldState("example")?.error
                                    )
                                      addExample(e);
                                  }}
                                  disabled={
                                    !inputExample &&
                                    !form.getFieldState("example")?.error
                                  }
                                >
                                  ADD EXAMPLE
                                </button>
                              </div>
                            ) : (
                              <>
                                <div className="col-12 col-md-4 col-lg-3">
                                  <button
                                    type="button"
                                    className="button -outline-secondary w-100"
                                    onClick={(e) => [
                                      e.preventDefault(),
                                      cancelEdition(),
                                    ]}
                                  >
                                    CANCEL
                                  </button>
                                </div>
                                <div className="col-12 col-md-4 col-lg-3">
                                  <button
                                    type="button"
                                    className="button -primary w-100"
                                    onClick={(e) => [
                                      e.preventDefault(),
                                      editExample(inputExample, exampleEdit),
                                    ]}
                                  >
                                    SAVE CHANGES
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="row mt-50">
                            <div className="col-12">
                              <div className="bg-light">
                                <div
                                  className="row justify-content-end"
                                  style={{
                                    visibility: [...checked].length
                                      ? "visible"
                                      : "hidden",
                                  }}
                                >
                                  <div className="col-3 col-md-1 d-flex justify-content-center">
                                    <button
                                      type="button"
                                      className="button -text w-100"
                                      onClick={(e) => [
                                        e.preventDefault(),
                                        handleCancelSelection(),
                                      ]}
                                    >
                                      <NDSTooltip
                                        icon="fa-solid fa-circle-xmark"
                                        target="cancel"
                                        tooltipMessage="Cancel"
                                        placement="bottom"
                                      ></NDSTooltip>
                                      {/* <i className="fa-solid fa-circle-xmark"></i> */}
                                    </button>
                                  </div>
                                  
                                  <div className="col-3 col-md-1 d-flex justify-content-center">
                                    <button
                                      type="button"
                                      className="button -text w-100"
                                      onClick={(e) => [
                                        e.preventDefault(),
                                        handleDownloadSelection(
                                          selectedExamples
                                        ),
                                      ]}
                                    >
                                      <NDSTooltip
                                        icon="fa-solid fa-download"
                                        target="download"
                                        tooltipMessage="Download"
                                        placement="bottom"
                                      ></NDSTooltip>
                                      {/* <i className="fa-solid fa-download"></i> */}
                                    </button>
                                  </div>
                                  <div
                                    className="col-3 col-md-1 d-flex justify-content-center"
                                    style={{
                                      visibility:
                                        [...checked].length &&
                                        [...checked].reduce(
                                          (a, c) =>
                                            a &&
                                            isPristine(
                                              listExamples.find(
                                                (e) => e._id === c
                                              )
                                            ),
                                          true
                                        )
                                          ? "visible"
                                          : "hidden",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="button -text -red w-100"
                                      onClick={(e) => [
                                        e.preventDefault(),
                                        handleDeleteSelection(),
                                      ]}
                                    >
                                      <NDSTooltip
                                        icon="fa-solid fa-trash-can"
                                        target="trash"
                                        tooltipMessage="Delete"
                                        placement="bottom"
                                      ></NDSTooltip>
                                      {/* <i className="fa-solid fa-trash-can"></i> */}
                                    </button>
                                    
                                  </div>
                                </div>
                              </div>
                              <table>
                                <thead>
                                  <tr>
                                    <th>
                                      <input
                                        className="form-check-input -white"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckDefault"
                                        checked={allChecked}
                                        onChange={(event) =>
                                          handleAllCheck(event)
                                        }
                                      ></input>
                                    </th>
                                    <th>
                                      <p className="d-flex align-items-center mx-10 w-100">
                                        Examples{" "}
                                        {/*<button
                                          type="button"
                                          className="i -white w-15"
                                        >
                                          <i className="fa-solid fa-arrow-up-a-z"></i>
                                        </button>*/}
                                      </p>
                                    </th>
                                    <th className="row m-10 align-items-center">
                                      Actions
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>

                                  { showedExamples && showedExamples.length > 0 ? (
                                    showedExamples[page -1].map(
                                      (example, index) =>  (
                                        
                                        <tr key={index} id={example._id}>
                                          <td>
                                            <input
                                              className="form-check-input -blue"
                                              type="checkbox"
                                              value=""
                                              checked={checked.has(
                                                example?._id
                                              )}
                                              onChange={() =>
                                                handleCheck(example)
                                              }
                                            ></input>
                                          </td>
                                          <td className="row mx-10 align-items-center">
                                            {example.type === "update" ? (
                                              <>
                                                <div className="row">
                                                  <p className="label -edited">
                                                    Editing
                                                  </p>
                                                </div>
                                                <div className="row">
                                                  <p className="label -previous">
                                                    {example.previous}
                                                  </p>
                                                </div>
                                              </>
                                            ) : null}
                                            {example.type === "create" ? (
                                              <>
                                                <div className="row">
                                                  <p className="label -added">
                                                    Adding
                                                  </p>
                                                </div>
                                                <div
                                                style={{display:'none'}}
                                                ref={(el) =>
                                                  (cancelRef.current[
                                                    example._id
                                                  ] = el)
                                                }
                                                onClick={(e) => [
                                                  e.preventDefault(),
                                                  undoExampleCreate(example),
                                                ]}
                                                >
                                                </div>
                                              </>
                                            ) : null}
                                            {example.type === "delete" ? (
                                              <div className="row">
                                                <p className="label -deleted">
                                                  Deleting
                                                </p>
                                              </div>
                                            ) : null}
                                            {example._id in moveStash ? (
                                              <div className="row">
                                                <p className="label -deleted">
                                                  Moving to{" "}
                                                  {
                                                    moveStash[example._id]
                                                      .element.label
                                                  }
                                                </p>
                                              </div>
                                            ) : null}
                                            {example.recovered === true ? (
                                              <div className="row">
                                                <p className="label -added">
                                                  Recovering
                                                </p>
                                              </div>
                                            ) : null}
                                            <div className="row">
                                              {example?.new_value
                                                ? example.new_value
                                                : example?.value}
                                            </div>
                                          </td>
                                          <td>
                                            <div className="row">
                                              {isPristine(example) ? (
                                                <>
                                                  {!example.newOne && (
                                                    <button
                                                      type="button"
                                                      className="button -icon"
                                                      onClick={(e) => [
                                                        e.preventDefault(),
                                                        handleEdition(example),
                                                      ]}
                                                    >
                                                      <span className="material-icons-outlined">
                                                        <NDSTooltip
                                                          icon="fa-solid fa-pencil"
                                                          target="pencil"
                                                          tooltipMessage="Edit"
                                                          placement="bottom"
                                                        ></NDSTooltip>
                                                        {/* <i className="fa-solid fa-pencil"></i> */}
                                                      </span>
                                                    </button>
                                                  )}
                                                  <button
                                                    type="button"
                                                    className="button -icon -danger"
                                                    ref={(el) =>
                                                      (deleteRef.current[
                                                        example._id
                                                      ] = el)
                                                    }
                                                    onClick={(e) => [
                                                      e.preventDefault(),
                                                      deleteExample(example),
                                                      console.log("Cl")
                                                    ]}
                                                  > 
                                                    <span className="material-icons-outlined">
                                                      <NDSTooltip
                                                        icon="fa-solid fa-trash-can"
                                                        target="trash"
                                                        tooltipMessage="Delete"
                                                        placement="bottom"
                                                      ></NDSTooltip>
                                                      {/* <i className="fa-solid fa-trash-can"></i> */}
                                                    </span>
                                                  </button>
                                                </>
                                              ) : example?.recovered === true &&
                                                !exampleEdit &&
                                                example.type !== "delete" &&
                                                example.type !== "update" ? (
                                                <div className="col-12 col-md-4">
                                                  <button className="button -icon -success">
                                                    <i className="fa-solid fa-check"></i>{" "}
                                                    Recovering
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="button -primary"
                                                    ref={(el) =>
                                                      (cancelRef.current[
                                                        example._id
                                                      ] = el)
                                                    }
                                                    onClick={(e) => [
                                                      e.preventDefault(),
                                                      undoRecovery(example),
                                                    ]}
                                                  >
                                                    <i className="fa-solid fa-undo"></i>{" "}
                                                    Undo
                                                  </button>
                                                </div>
                                              ) : !exampleEdit &&
                                                example.type !== "update" ? (
                                                <div>
                                                <button
                                                  type="button"
                                                  className="button -icon "
                                                  ref={(el) =>
                                                    (cancelRef.current[
                                                      example._id
                                                    ] = el)
                                                  }
                                                  onClick={(e) => [
                                                    e.preventDefault(),
                                                    recoverExample(example),
                                                  ]}
                                                >
                                                  <i className="fa-solid fa-undo"></i>{" "}
                                                  Recover
                                                </button>
                                                </div>
                                              ) : (
                                                example.type === "update" &&
                                                !exampleEdit && (
                                                  <div>
                                                  <button
                                                    type="button"
                                                    className="button -icon -undo"
                                                    ref={(el) =>
                                                      (cancelRef.current[
                                                        example._id
                                                      ] = el)
                                                    }
                                                    onClick={(e) => [
                                                      e.preventDefault(),
                                                      undoExampleEdit(example),
                                                    ]}
                                                  >
                                                    <i className="fa-solid fa-check"></i>{" "}
                                                    UNDO Edition
                                                  </button>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </td>
                                        </tr>
                                      )
                                    )
                                   ) : (
                                    <tr>
                                      <td colSpan="5">No synonyms found</td>
                                    </tr>
                                  )}
                                  
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="row mt-20">
                            <Paginator
                              pages={totalPages}
                              currentPage={page}
                              setPage={handlePageChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </form>
            )}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    workspace: state.workspace,
    role: state.user.role,
    user: state.user,
  };
}

export default connect(mapStateToProps)(IntentsEdit);
