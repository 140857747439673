import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { Form, Field } from "react-final-form";
import Header from '../../../Components/Header/headerView';
import Footer from '../../../Components/Footer/footer';
import Menu from '../../../Components/Menu/menu';
import { useParams } from 'react-router-dom';
import { store } from '../../../app/store';
import { connect } from "react-redux";
import { OpenRequest, CommentModal, RequestCreatedModal } from '../../../Utils/modals';
import { categoryName, categoryDescription } from "../../../Utils/regexValidations";
import categoriesService from '../../../Services/categoriesService';
import requestsService from '../../../Services/requestsService';
import entitiesService from '../../../Services/entitiesService';
import { Modal } from 'bootstrap';
import Select from "react-select";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../../Components/Loader/actions";

const EditCategory = (props) => {

  const [loadData, setLoadData] = useState(true);
  const [category, setCategory] = useState({});
  const [inputName, setInputName] = useState("");
  const [inputDescription, setInputDescription] = useState("");
  const [newChanges, setNewChanges] = useState([]);
  const [entities, setEntities] = useState([]);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState(false);
  const [nameError, setNameError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [entityError, setEntityError] = useState(null);
  const [errorUniqueName, setErrorUniqueName] = useState(false);
  const [nameUsedError, setNameUsedError] = useState("");

  const dispatch = useDispatch();

  //Validations
  const namePrefixTest = /^(?!sys-|sys_)/; //Name can not begin with the prefix "sys-" or "luis-"

  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined
      );
  const required = (value) => (value ? undefined : "Required");
  const maxLength = (max) => (value) =>
    value && value.length > max
      ? `Must be ${max} characters or less`
      : undefined;
  const name = (value) =>
    value && !categoryName.test(value) ? "Invalid name" : undefined;
  const namePrefix = (value) =>
    value && !namePrefixTest.test(value) ? "Invalid name" : undefined;
  const description = (value) =>
    value && !categoryDescription.test(value) ? "Invalid description" : undefined;

  const handleChangeError = (event, field, initial) => {
    let newValue = "";
    if (field === "entities"){
      if (event.length < 1){
        setEntityError(true);
      console.log("Error:", true)
      }
      else{
        setEntityError(false);
        console.log("Error:", false)
      }
    }
    else{
      if(initial){
        newValue = event;
      }
      else{
        newValue = event.target.value;
      }
    }
    if (field === "name"){
      const error = composeValidators(
        required,
        name,
        namePrefix,
        maxLength(64)
      )(newValue);
      setNameError(error);
      console.log("Error:", error)
    }
    else if (field === "description"){
      const error = composeValidators(
        description,
        maxLength(128)
      )(newValue);
      setDescriptionError(error);
    }
  };

  useEffect(() => {
    if (errorUniqueName && (nameUsedError !== inputName)) {
        setErrorUniqueName(false);
    }
    else if (inputName === nameUsedError) {
      setErrorUniqueName(true);
    }
  }, [inputName]);

  const selectAdapter = ({ input, meta, ...rest }) => (
    <>
      <Select
        {...input}
        {...rest}
        id="entities"
        className="creatable-select w-100"
        placeholder="Select entities"
        required
        isClearable
        isMulti
        options={entities}
        value={selectedEntities}
        onChange={(e) => [setSelectedEntities(e), input.onChange(e), handleSelectChange(e), handleChangeError(e, "entities")]}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,

          control:
            meta?.error && meta?.touched
              ? (base, state) => ({
                  ...base,
                  borderColor: "#dc3545",
                  boxShadow: "none",
                  "&:hover": {
                    borderColor: "#dc3545",
                  },
                })
              : (base, state) => ({
                  ...base,
                  borderColor: "#ced4da",
                  boxShadow: "none",
                  "&:hover": {
                    borderColor: "#ced4da",
                  },
                }),
          colors: {
            ...theme.colors,
            primary25: "#e9ecef",
            primary: meta?.error && meta?.touched ? "#dc3545" : "#007bff",
            neutral90: meta?.error && meta?.touched ? "#dc3545" : "#495057",
          },
          height: "100%",
        })}
      />
      <label
        htmlFor="entities"
        data-toggle="tooltip"
        title="Category names must be unique and can only contain alphanumeric characters, underscores, hyphens, and dots. Maximum length is 64 characters."
        style={meta?.error ? { color: "red" } : null}
      >
        {meta.error ? (
          <span className="error">
            <i className="fas fa-exclamation-circle fa-sm"></i>
            {meta.error}
          </span>
        ) : (
          "Entities"
        )}
      </label>
    </>
  );

  const handleSelectChange = (newValue) => {
    //setSelectedCategory(newValue);
    //Check if there are changes
    console.log("newva", newValue);
    console.log("current", selectedEntities);

    let oldEntities = selectedEntities; //entity.categories;
    console.log("Old", oldEntities);

    let addedEntities = newValue.filter(
      (e) => !oldEntities.find((x) => x.value === e.value)
    );

    let removedEntities = oldEntities.filter(
      (e) => !newValue.find((x) => x.value === e.value)
    );

    //let removedEntities = oldEntities.filter(e => !newValue.includes(e.value));
    console.log("added", addedEntities);
    console.log("deleted", removedEntities);

    addedEntities.forEach((e) => {
      console.log(e);
      if (e.type !== "delete") {
        e.type = "create";
        addEntity(e);
      } else if (e.type === "delete") {
        //e.type = null
        console.log("Recovering", e);
        recoverEntity(e);
      }
    });
    removedEntities.forEach((e) => {
      e.type = "delete";
      removeEntity(e);
    });
  };


  function addEntity(entity) {
    category.entities.forEach((item) => {
      if (item.value === entity.value) {
        recoverEntity(entity);
        return;
      }
    });

    if (entity.length > 0 || entity.value) {
      console.log(entity);
      let tempEntities = [...selectedEntities];

      handleChanges("entities", entity.value, "create");
      let tempObj = {
        label: entity.label,
        value: entity.value,
        type: "create",
        newOne: true,
      };
      tempEntities.push(tempObj);
      //setCategoryList(tempEntities)
      setSelectedEntities(tempEntities);
    }
  }

  function removeEntity(entity) {
    let tempEntities = [...selectedEntities];
    tempEntities.forEach((item) => {
      if (item.value === entity.value) {
        if (entity.type === "create") {
          item.previous = "create";
          item.recovered = null;
        }
        item.type = "delete";
        tempEntities = tempEntities.filter(
          (item) => item.value !== entity.value
        );

        let tempChanges = newChanges;
        tempChanges.forEach((item, index) => {
          if (item.value_id === entity.value) {
            tempChanges.splice(index, 1);
          }
        });
        setNewChanges(tempChanges);
      } else if (entity.newOne) {
        tempEntities = tempEntities.filter(
          (item) => item.value !== entity.value
        );
        //Remove from changes
        let tempChanges = newChanges;
        tempChanges.forEach((item, index) => {
          if (item.value_id === entity.value) {
            tempChanges.splice(index, 1);
          }
        });
        setNewChanges(tempChanges);
      }
    });

    if (!entities.includes(entity)) {
      let tempOptions = [...entities];
      tempOptions.push(entity);
      setEntities(tempOptions);
    }

    setSelectedEntities(tempEntities);
    !entity.newOne && handleChanges("entities", entity.value, "delete");
  }

  function recoverEntity(entity) {
    let undo = false;
    category.entities.forEach((item) => {
      if (item.value === entity.value) {
        undo = true;
      }
    });
    newChanges.forEach((item) => {
      if (item.value === entity.value && item.type === "delete") {
        undo = true;
      }
    });

    if (entity.previous) {
      let tempEntities = [...selectedEntities];
      tempEntities.forEach((item) => {
        if (item.value === entity.value) {
          item.type = entity.previous;
        }
      });
      //setCategoryList(tempCategories)
      setSelectedEntities(tempEntities);
      //Remove from changes
      let tempChanges = newChanges;
      tempChanges.forEach((item, index) => {
        if (item.value_id === entity.value) {
          tempChanges.splice(index, 1);
        }
      });
      setNewChanges(tempChanges);
      return;
    }

    if (undo && entity.type === "delete") {
      let tempEntities = [...selectedEntities];
      tempEntities.forEach((item) => {
        if (item.value === entity.value) {
          item.type = null;
        }
      });
      tempEntities.push(entity);

      //setCategoryList(tempCategories)
      setSelectedEntities(tempEntities);
      //Remove from changes
      let tempChanges = newChanges;
      tempChanges.forEach((item, index) => {
        if (item.value_id === entity.value) {
          tempChanges.splice(index, 1);
        }
      });
      setNewChanges(tempChanges);
    } else {
      let tempEntities = [...selectedEntities];

      tempEntities.forEach((item) => {
        if (item.value === entity.value) {
          item.recovered = true;
        }
      });
      //setCategoryList(tempCategories)
      setSelectedEntities(tempEntities);

      let tempChanges = newChanges;
      tempChanges.push({
        field: "entities",
        value_id: entity.value,
        new_op: "delete",
        undo: true,
      });
      setNewChanges(tempChanges);
    }
  }



  useEffect(() => {
    if (nameError || descriptionError || entityError) {
      setValidationError(true);
      console.log("hay error");
    }
    else{
      setValidationError(false);
      console.log("no hay error");
    }
  }, [nameError, descriptionError, entityError]);

  const workspace = store.getState().workspace.id;
  const categoryID = useParams().category_id;

  const getCategory = useCallback(
    async (categoryID) => {
      dispatch(startLoading());
      let params = {
        categories_names: true,
        workspace_id: workspace,
        category_id: categoryID,
      };
      try {
        let res = await categoriesService.collectOne(params);
        let category = res.data;
        console.log("category", category);
        setCategory(category);
        setInputName(category.name);
        handleChangeError(category.name, "name", true);
        setInputDescription(category.description);
        handleChangeError(category.description, "description", true);
        setSelectedEntities(formatOptions(category?.entities ?? []));
        console.log("category ent", category?.entities);
        let originalEntities = [];

        params = {
          workspace_id: workspace,
        };

        let options = await entitiesService.collectAll(params);
        console.log("options", options);

        let categOptions = formatOptions(options.data.entities);
        console.log("categOptions", categOptions);

        category.entities.forEach((entity) => {
          categOptions?.find((option) => {
            if (option.id === entity.id) {
              originalEntities.push(option);
            }
          });
        });

        //Remove existing entities from options
        let newOptions = categOptions?.filter((option) => {
          return !originalEntities.includes(option);
        });
        setEntities(newOptions);
      }
      catch(err){
        console.log(err);
      } 
      dispatch(stopLoading());
    },
    [category, workspace]
  );

  const formatOptions = (entities) => {
    let options = [];
    for (var i = 0; i < entities.length; i++) {
      options.push({
        value: entities[i].id,
        label: entities[i].name,
      });
    }
    return options;
  };

  function saveName() {
    handleChanges("name", inputName);
  }

  function saveDescription() {
    handleChanges("description", inputDescription);
  }

  const handleChanges = (fieldType, value, operation = "update") => {
    console.log("fieldType", fieldType);
    console.log("value", value);
    console.log("operation", operation);
   let change = [];
   let tempChanges = [];
    tempChanges = newChanges;
    for (let i = 0; i < tempChanges.length; i++) {
      if (tempChanges[i].field === fieldType && fieldType !== "entities") {
        tempChanges[i].value = value;
        setNewChanges([...newChanges]);
        return;
      }
    }
    change.push(
      {
        "field": fieldType,
        "operation": operation,
        "value": value
      }
    );
    setNewChanges([...newChanges, ...change]);
  };

  async function handleSave(comment) {
    let params = {
      workspace_id: workspace,
      user_id: props.user.id,
    };
    let body = {
      "object_id": categoryID,
      "object_type": "category",
      "changes": newChanges,
      "comments": comment
    }
    try{
      let res = await requestsService.create(params, body);
      console.log(res);
      let requestCreatedModal = new Modal(document.getElementById("requestCreatedModal"));
      requestCreatedModal.show();
    }
    catch(err){
      if (err?.response?.data?.detail === "400: Object already exists.") {
        setErrorUniqueName(true);
        setNameUsedError(inputName)
      }
      console.log(err);
    }
  }

  async function handleDelete() {
    let params = {
      workspace_id: workspace,
      user_id: props.user.id,
    };
    let body = {
      "object_id": categoryID,
      "object_type": "category",
      "changes": ["delete"]
    }
    try{
      let res = await requestsService.create(params, body);
      console.log(res);
      navigate('/categories');
    }
    catch(err){
      console.log(err);
    }
  };

  useEffect(() => {
    if (loadData) {
      getCategory(categoryID);
      setLoadData(false);
    }
  }, [loadData, categoryID, getCategory]);

  // console.log(category)
  return (
    <div className={`${props.className}`}>
      {/* // <!-- Modal Delete--> */}
      {/* // <!-- Modal approve--> */}
      <OpenRequest
        handleSave={handleSave}
      />
      <CommentModal handleSave={handleSave}/>
      <RequestCreatedModal />
      <Header />
      <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
        <div className="w-10">
          <Menu activeTab={4} />
        </div>
        <div className="container px-20 mt-20 mb-50">
          <div className="row">
            <div className="col-12 col-lg-9 my-20">
              <div className="row mb-40">
                <p className="h1 -r">Editing Category</p>
              </div>
              <div className="card-w -m">
                <div className="row">
                  <div className="col-12 col-lg-8">                              

                  <div className="form-group mt-40" style={{ display: "block" }}>
                    <div className="col">
                    <Form onSubmit={() => {
                      // something
                    }}
                    render={({ form, handleSubmit }) => ( 
                      <form>
                            <div className="form-group mt-40" style={{ display: "block" }}>
                            <Field
                                name="entities"
                                id="entities"
                                initialValue={selectedEntities}
                                className="creatable-select w-100"
                                validate={composeValidators(
                                  //,
                                  //categoryName
                                )}
                                component={selectAdapter}
                              />
                            </div>
                      </form>
                      )}
                    />
                    </div>
                  </div>

                    <div className="form-group mt-40" style={{ display: "block" }}>
                      <div className="col">
                          {category?.name !== inputName && (
                          <div className="row">
                            <p className="label -edited mx-10">
                              Edited
                            </p>
                            <p className="label -previous mx-10">
                                {category?.name}
                            </p>
                          </div>
                        )}
                    <Form onSubmit={() => {
                      // something
                    }}
                    render={({ form, handleSubmit }) => ( 
                      <form>
                            
                            <Field
                              name="name"
                              id="name"
                              initialValue={inputName}
                              validate={composeValidators(
                                required,
                                name,
                                namePrefix,
                                maxLength(64)
                              )}
                            >
                                  {(props) => (
                                  <>
                                  <input
                                    name="name"
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    value={inputName}
                                    onChange={(e) => [
                                      setInputName(e.target.value),
                                      handleChangeError(e, "name"),
                                    ]}
                                    onBlur={(e) => [
                                      saveName()
                                    ]}
                                    onLoad={(e) => [
                                      setInputName(e.target.value),
                                      props.input.onChange(e),
                                    ]}
                                    placeholder="Name"
                                    style={
                                      props.meta?.error || errorUniqueName
                                        ? { borderColor: "red" }
                                        : null
                                    }
                                  />
                                  <label
                                    htmlFor="name"
                                    data-toggle="tooltip"
                                    title="Entities can contain only Unicode alphanumeric, underscore, hyphen, and dot characters. It can not have spaces, be empty or be longer than 64 characters."
                                    style={
                                      props.meta?.error
                                        ? { color: "red" }
                                        : null
                                    }
                                  >
                                    {props.meta.error ? (
                                      <span className="error">
                                        <i className="fas fa-exclamation-circle fa-sm"></i>
                                        {props.meta.error}
                                      </span>
                                    ):
                                      errorUniqueName ? (
                                        <span className="error" style={{color: "red"}}>
                                        <i className="fas fa-exclamation-circle fa-sm"></i>
                                        Category already exists
                                        </span>
                                    ): null}
                                  </label>
                                  </>
                                  )}
                            </Field>
                      </form>
                      )}
                    />
                    </div>
                    <label htmlFor="name">Category Name</label>
                  </div>

                    <div className="form-group mt-20" style={{ display: "block" }}>
                    <div className="col">
                        {category?.description !== inputDescription && (
                          <div className="row">
                            <p className="label -edited mx-10">
                              Edited
                            </p>
                            <p className="label -previous mx-10">
                              {category?.description}
                            </p>
                          </div>
                        )}
                        <Form onSubmit={() => {
                            // something
                          }}
                          render={({ form, handleSubmit }) => ( 
                            <form>
                                <Field
                                  name="description"
                                  initialValue={inputDescription}
                                  validate={composeValidators(
                                    description,
                                    maxLength(128)
                                  )}
                                >
                                {(props) => (
                                <>
                                <textarea
                                  className="form-control"
                                  id="description"
                                  rows="3"
                                  value={inputDescription}
                                  onChange={(e) => [
                                    setInputDescription(e.target.value),
                                    handleChangeError(e, "description"),
                                  ]}
                                  onBlur={(e) => [
                                    saveDescription()
                                  ]}
                                  onLoad={(e) => [
                                    setInputDescription(e.target.value)
                                  ]}
                                  placeholder="Description"
                                  style={
                                    props.meta?.error
                                      ? { borderColor: "red" }
                                      : null
                                  }
                                >
                                </textarea>
                                <label
                                  htmlFor="description"
                                  data-toggle="tooltip"
                                  title="This value cannot contain carriage return, newline, or tab characters. It can not be empty or longer than 128 characters."
                                  style={
                                    props.meta?.error
                                      ? { color: "red" }
                                      : null
                                  }
                                >
                                  {props.meta?.error && (
                                    <span className="error">
                                      <i className="fas fa-exclamation-circle fa-sm"></i>
                                      {props.meta?.error}
                                    </span>
                                  )}
                                </label>
                                </>
                                )}
                                </Field>
                               
                            </form>
                          )}
                        />
                      </div>         
                      <label htmlFor="description">Description</label>
                      
                    </div>
                    
                  </div>
                  
                  
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 mt-20">
              <>
              <div className="row mb-10">
                <button
                  className={`button w-100 ${validationError ? '-inactive' : '-primary'}`}
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#commentModal"
                  disabled={validationError}
                >
                  CREATE REQUEST
                </button>
              </div>
              <div className="row mt-10">
                <button 
                  className="button -outline-primary w-100"
                  onClick={() => (window.location.href = `/detail/category/${categoryID}`)}
                >
                  RETURN
                </button>
              </div>
              </>
              <div className="row mt-10">
                <button
                  className="button -text -red w-100"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteModal"
                >
                  DELETE CATEGORY
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    workspace: state.workspace,
    user: state.user,
  };
};

export default connect(mapStateToProps)(EditCategory);
