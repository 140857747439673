import API from "./axios";

const categoriesService = {

  //Retrieves a single category based on the provided category ID.
  collectOne: async (params) => {
    return await API.get("/categories/collect_one", {
      params
    });
  },

  //Retrieves all categories within a specified workspace.
  collectAll: async (params) => {
    return await API.get("/categories/collect_all", {
      params
    });
  },

  //Endpoint to retrieve entities belonging to a specific category.
  categoryEntities: async (params) => {
    return await API.get("/categories/category_entities", {
      params
    });
  },

  //Counts the number of entities belonging to a specific category based on the workspace and category.
  count: async (params) => {
    return await API.get("/categories/count", {
      params
    });
  },
};

export default categoriesService;