//https://cloud.ibm.com/apidocs/assistant-v1

//Regex Intents
export const intentName = /^((?!sys-)[a-zA-Z0-9_\-\.áéíóúÁÉÍÓÚüÜ]+$)/;
export const intentDescription = /^[^\r\n\t]+$/;
export const intentExamples = /^[^\r\n\t]+\S$/;

//Regex Entities
export const entityName = /^[^\r\n\t]+\S$/;
//export const entityDescription;
export const entitySynonyms = /^[^\r\n\t]+\S$/

//Regex Categories
export const categoryName = /^[a-zA-Z0-9_\-\.áéíóúÁÉÍÓÚüÜ]+$/;
export const categoryDescription = /^[^\r\n\t]+$/;