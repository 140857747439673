import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Modal } from "bootstrap";
// import suggestion from '../../Images/suggestions.png';
// import history from '../../Images/history.png';
import {connect} from 'react-redux';
import API from '../../../Services/axios';
import { CreateObjectCSVScreen } from './createObjectCSVScreen';
// import Analyzing from './SuggestionsAnalyzing';

const CreateObjectCSV = (props) => {
  const type = useParams().type;
  const workspace = props.workspace.id;
  const user = props.user.id;
  const [activeTab, setActiveTab] = useState('1');
  const navigate = useNavigate();
  //const formData = new FormData();

  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_FILE_LIST':
        return { ...state, fileList: action.files }
      case 'SET_DROP_DEPTH':
        return { ...state, dropDepth: action.dropDepth }
      case 'SET_IN_DROP_ZONE':
        return { ...state, inDropZone: action.inDropZone };
      case 'ADD_FILE_TO_LIST':
        return { ...state, fileList: state.fileList.concat(action.files) };
      default:
        return state;
    }
  };

  const [fileData, dispatchFileData] = React.useReducer(
    reducer, { dropDepth: 0, inDropZone: false, fileList: [] }
  )

  const handleSubmission = async () => {
    let selectedFile = fileData?.fileList?.[0];
    let object = type === 'entity' ? 'entities' : 'intents';
    if (selectedFile.name.includes('.csv')) {
      let params = {
        workspace_id: workspace,
        user_id: user,
      }

      const formData = new FormData();
      formData.append('file', selectedFile);

      let duplicatedIntent = false
      await API.post(`/${object}/create_csv`, formData, {
        params: params,
      })
      /*{
        method: "post",
        url: "/intent/createCSV/",
        data: formData,
        params: params
      })
      */
      .then(res => {
        console.log(res) 
        var modal = new Modal(document.getElementById("csvModal"));
        modal.show();
        
        
      }).catch(err => {
        console.log(err.response.status);
      }).finally(() => {
        
        if (duplicatedIntent === false){
          //navigate(`/${object}`);
        }
        
      });
    }
	};

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  return <CreateObjectCSVScreen _this={{
    type,
    workspace,
    user,
    activeTab,
    setActiveTab,
    navigate,
    fileData,
    dispatchFileData,
    handleSubmission,
    toggle,
    props
  }} />

}

  const mapStateToProps = (state) => {
    return {
      workspace: state.workspace,
      user: state.user,
    }
  }
  export default connect(mapStateToProps)(CreateObjectCSV);
