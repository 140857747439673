import React, { useState, useEffect, useRef, useCallback } from "react";
import { Modal } from "bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { convertFromRaw, EditorState } from "draft-js";
import { useParams, Link } from "react-router-dom";
import { type } from "@testing-library/user-event/dist/type";
import { store } from "../../../app/store";
import { connect } from "react-redux";
import * as dateService from "../../../Utils/format/date";
import * as interactionService from "../../../Services/interaction";
import * as arrayService from "../../../Services/array";
import sortBy from "lodash/sortBy";
import { v4 as uuidv4 } from "uuid";
import FileSaver from "file-saver";
import entitiesService from "../../../Services/entitiesService";
import categoriesService from "../../../Services/categoriesService";
import intentsService from "../../../Services/intentsService";
import requestsService from "../../../Services/requestsService";
import { RequestsValidateScreen } from "./requestsValidateScreen";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../../Components/Loader/actions";
import { intentName, intentDescription, intentExamples, entityName, entitySynonyms, categoryDescription } from "../../../Utils/regexValidations";
import { categoryName as categoryNameRegex } from "../../../Utils/regexValidations";
const RequestsValidate = props => {
    const workspace = props.workspace.id;
    const user = props.user;

  const [activeTab, setActiveTab] = useState("1");
  const [object, setObject] = useState({});
  const [objectType, setObjectType] = useState("");
  const [requestStatus, setRequestStatus] = useState("");
  const [requestComments, setRequestComments] = useState("");
  const [requestSource, setRequestSource] = useState("");
  const [objectTypeView, setObjectTypeView] = useState("");
  const [comments, setComments] = useState("");
  const [lastUser, setLastUser] = useState({});
  const [lastUpdate, setLastUpdate] = useState("");
  const [newObject, setNewObject] = useState(true);
  const [loadData, setLoadData] = useState(true);
  const [addedContent, setAddedContent] = useState([]);
  const [editedContent, setEditedContent] = useState([]);
  const [deletedContent, setDeletedContent] = useState([]);
  const [originalListChildren, setOriginalListChildren] = useState([]);
  const [originalListCategories, setOriginalListCategories] = useState([]);
  const [listChildren, setListChildren] = useState([]);
  const [inputName, setInputName] = useState(null);
  const [inputDescription, setInputDescription] = useState("");
  const [inputChildren, setInputChildren] = useState("");
  const [newChanges, setNewChanges] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categories, setCategories] = useState([]);
  const [childrenEdit, setChildrenEdit] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [onlyChanges, setOnlyChanges] = useState(false);
  const [viewerMode, setViewerMode] = useState(false);
  const [changes, setChanges] = useState({});
  const [toBeDeleted, setToBeDeleted] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [showedChildren, setShowedChildren] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const itemsPerPage = 5;

  const [checked, setChecked] = useState(new Set());
  const [categoryChecked, setCategoryChecked] = useState(new Set());
  const [allChecked, setAllChecked] = useState(false);
  const [allCategoriesChecked, setAllCategoriesChecked] = useState(false);
  const [adminNextStatus, setAdminNextStatus] = useState("Select");
  const [adminDeleting, setAdminDeleting] = useState(false);
  const deleteRef = useRef({});
  const deleteRefCategory = useRef({});
  const recoverRef = useRef({});
  const [validationError, setValidationError] = useState(false);
  const [nameError, setNameError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [categoriesError, setCategoriesError] = useState(null);
  const [selectedSynonyms, setSelectedSynonyms] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [editRequestPermission, setEditRequestPermission] = useState(props.workspace?.actions?.requests?.edit ?? false);
  const [validateRequestPermission, setValidateRequestPermission] = useState(props.workspace?.actions?.requests?.validate ?? false);
  const [rollbackRequestPermission, setRollbackRequestPermission] = useState(props.workspace?.actions?.requests?.rollback ?? false);
  const [closeRequestPermission, setCloseRequestPermission] = useState(props.workspace?.actions?.requests?.close ?? false);
  const [deployRequestPermission, setDeployRequestPermission] = useState(props.workspace?.actions?.requests?.deploy ?? false);

  const [displayEdit, setDisplayEdit] = useState(false);
  const [displayValidate, setDisplayValidate] = useState(false);
  const [displayRollback, setDisplayRollback] = useState(false);
  const [displayClose, setDisplayClose] = useState(false);
  const [displayDeploy, setDisplayDeploy] = useState(false);
  const [displaySave, setDisplaySave] = useState(false);
  const [displayDiscard, setDisplayDiscard] = useState(false);
  const [displayRecover, setDisplayRecover] = useState(false);

  const [commentModalFunction, setCommentModalFunction] = useState(() => handleSave);

  const dispatch = useDispatch();

  //Validations
  const namePrefixTest = /^(?!sys-|sys_)/; //Name can not begin with the prefix "sys-" or "luis-"

  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined
      );
  const required = (value) => (value ? undefined : "Required");
  const maxLength = (max) => (value) =>
    value && value.length > max
      ? `Must be ${max} characters or less`
      : undefined;
  const minLength = (min) => (value) =>
    value && value.length < min
      ? `Must be ${min} characters or more`
      : undefined;
  const name = (value) =>
    value && !(objectType === "entity" ? entityName : objectType === "intent" ? intentName : categoryNameRegex).test(value) ? "Invalid name" : undefined;
  const namePrefix = (value) =>
    value && !namePrefixTest.test(value) ? "Invalid name" : undefined;
  const categoryName = (value) =>
    value && value.length > 0 && console.log(value)
      ? "Invalid name"
      : undefined;
  const synonymExists = (value) =>
    value === inputChildren && isSynonymInList(value)
      ? objectType === "entity" ? "Synonym already exists" : "Example already exists"
      : undefined;
    const child = value =>
    value && !(objectType === "entity" ? entitySynonyms : intentExamples).test(value) ? objectType === "entity" ? "Invalid synonym" : "Invalid example" : undefined;
    
    const handleChangeError = (event, field, initial) => {
        let newValue = "";
        if(initial){
            newValue = event;
        }
        else{
            newValue = event.target.value;
        }
        if (field === "name"){
            const error = composeValidators(
            required,
            name,
            namePrefix,
            maxLength(64)
            )(newValue);
            setNameError(error);
            console.log("Error:", error)
        }
        else if (field === "description"){
            const error = composeValidators(
            maxLength(128)
            )(newValue);
            setDescriptionError(error);
            console.log("Error:", error)
        }
    };
    
    useEffect(() => {
        if (nameError || descriptionError || categoriesError) {
            setValidationError(true);
            console.log("hay error");
        }
        else{
            setValidationError(false);
            console.log("no hay error");
        }
        }, [nameError, descriptionError]);

    useEffect(() => {
        if (objectType === "entity" && !toBeDeleted) {
            console.log(categoryList)
            const filteredCategories = categoryList.filter(item => {
                return (item.type !== 'deleting' && item.type !== 'deleted') || item.recovered;
              });
            console.log(filteredCategories)
            if (filteredCategories.length < 1) {
                setValidationError(true);
                setCategoriesError(true);
            }
            else{
                setValidationError(false);
                setCategoriesError(false);
            }
        }
    }, [objectType, categoryList]);

    //Check if the example is already in the list
    const isSynonymInList = child => {
        let found = false;
        if (child === "") return found;
        listChildren.forEach(item => {
            //console.log(item);
            let synonymClean = child.replace(/ /g, "").replace(/\t/g, "");
            let itemClean = item.value.replace(/ /g, "").replace(/\t/g, "");
            if (itemClean.toLowerCase() === synonymClean.toLowerCase()) {
                found = true;
            }
        });
        return found;
    };

    const [sort, setSort] = useState({});

    const sortKeys = {
        Synonyms: "value",
        Added: "modified",
    };

    const applySort = _sort => {
        let newList = JSON.parse(JSON.stringify(listChildren));
        Object.entries(_sort).forEach(([k, v]) => {
            if (v.direction === -1) {
                newList = sortBy(newList, sortKeys[k]).reverse();
            } else {
                newList = sortBy(newList, sortKeys[k]);
            }
        });
        if (JSON.stringify(newList) !== JSON.stringify(listChildren)) {
            setListChildren(newList);
            splitDetailsIntoState(newList, itemsPerPage);
        }
    };

    const handleSort = e => {
        let header = e.target.parentElement.parentElement.innerText;
        let className = e.target.className;
        let dir = className.includes("-down-") ? 1 : -1;

        let newDir = dir === 1 ? -1 : 1;
        const t = {
            arrow: ["-up-", "-down-"],
            letter: ["-z-a", "-a-z"],
            number: ["-9-1", "-1-9"],
        };
        let newClassName = className;
        Object.entries(t).forEach(([k, v]) => {
            newClassName = newClassName.replace(
                ...(newDir > 0 ? v : v.reverse())
            );
        });
        let newClicks = (sort[header]?.clicks ?? 0) + 1;

        Object.keys(sort).forEach(k => delete sort[k]); // TODO: Admitir múltiples

        sort[header] = {
            direction: newDir,
            className: newClassName,
            clicks: newClicks,
        };
        setSort({ ...sort });
        applySort(sort);
    };

    const setListChildrenWithID = children => {
        children?.forEach(e => {
            e._id = e._id || uuidv4();
        });
        setListChildren(children);
        splitDetailsIntoState(children, itemsPerPage);
    };

    const requestID = useParams().request_id;

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

    async function getRequest(requestID) {
        dispatch(startLoading());
        let params = {
            request_id: requestID,
            workspace_id: workspace
        };

        try{
            let res = await requestsService.collect_one_with_details(params);

            let deletingRequest = false;
            if (res.data.request.changes[0] === "delete"){
                deletingRequest = true;
                setToBeDeleted(deletingRequest);
                setViewerMode(true);
            }
            console.log(res.data);
            let newRequest = true;
            if (res.data.request.object_id){
                setNewObject(false);
                newRequest = false;
            }
            if (res.data.request.is_rejected){
                setRejected(true);
            }

            setRequestComments(res.data.activity.comments);
            setLastUser(res.data.last_user_activity);

            let time = new Date(res.data.activity.created[0].at);
            setLastUpdate(time.toLocaleString());

            const status = (res?.data?.request?.status || "Undefined");
            setRequestStatus(status.charAt(0).toUpperCase() + status.slice(1));

            if (status === "saved"){
                setDisplayEdit(editRequestPermission);
                if (!deletingRequest){
                    setDisplaySave(editRequestPermission);
                }
                setDisplayClose(closeRequestPermission && editRequestPermission);
                if(!editRequestPermission){
                    setViewerMode(true);
                }
            }
            else if (status === "edited"){
                setDisplayValidate(validateRequestPermission);
                setDisplayRollback(rollbackRequestPermission && validateRequestPermission);
                if (!deletingRequest){
                    setDisplaySave(validateRequestPermission);
                }
                setDisplayClose(closeRequestPermission && validateRequestPermission);
                if(!validateRequestPermission){
                    setViewerMode(true);
                }
            }
            else if (status === "validated"){
                setDisplayDeploy(deployRequestPermission);
                setDisplayRollback(rollbackRequestPermission && deployRequestPermission);
                setViewerMode(true);
                setDisplayClose(closeRequestPermission && deployRequestPermission);
                if(!deployRequestPermission){
                    setViewerMode(true);
                }
            }
            else if (status === "deployed"){
                setViewerMode(true);
            }
            else if (status === "closed"){
                setViewerMode(true);
            }
            else if (status === "suggested"){
                setDisplayValidate(validateRequestPermission || editRequestPermission);
                setDisplayDiscard(rollbackRequestPermission && (validateRequestPermission || editRequestPermission));
            }
            else if (status === "discarded"){
                setDisplayRecover(validateRequestPermission || editRequestPermission);
                setViewerMode(true);
            }

            const source = (res?.data?.request?.source || "Undefined");
            setRequestSource(source.charAt(0).toUpperCase() + source.slice(1));

            const type = res.data.request.object_type;
            setObjectTypeView(type.charAt(0).toUpperCase() + type.slice(1));

            setObjectType(res.data.request.object_type);

            if (!deletingRequest){
                setDetails(newRequest, res.data.request.object_type, res.data.request.changes);
            }

            if (!newRequest){
                setObject(res.data.original_object);
            }

            if (res.data.request.object_type === "entity"){
                params = {
                    workspace_id: workspace,
                };
                let optionsCat = await categoriesService.collectAll(params);
    
                const categOptions = optionsCat.data.categories.map(item => ({
                    label: item.name,
                    value: item.id
                }));
                setOriginals(deletingRequest, newRequest, res.data.request.object_type, res.data.original_object, res.data.request.changes, categOptions);
            }
            else if (res.data.request.object_type === "intent"){
                setActiveTab("2");
                setOriginals(deletingRequest, newRequest, res.data.request.object_type, res.data.original_object, res.data.request.changes);
            }
            else if (res.data.request.object_type === "category"){
                params = {
                    workspace_id: workspace,
                };
                let optionsCat = await entitiesService.collectAll(params);
                
                if (optionsCat?.data?.entities){
                    const entityOptions = optionsCat.data.entities.map(item => ({
                        label: item.name,
                        value: item.id
                    }));
                    setOriginals(deletingRequest, newRequest, res.data.request.object_type, res.data.original_object, res.data.request.changes, entityOptions);
                }
            }

            let name = ""
            let description = ""
            if (!deletingRequest){
                res.data.request.changes.forEach(item => {
                    if (item.field === "name") {
                        name = item.value;
                        setInputName(item.value);
                    }
                    if (item.field === "description") {
                        description = item.value;
                        console.log(item.value);
                        setInputDescription(item.value);
                    }
                });
            }
            if (!name || deletingRequest){
                setInputName(res.data.original_object.name);
            }
            if ((!description && description !== "") || deletingRequest || res.data?.original_object?.description){
                console.log("Setting description");
                console.log(res.data.original_object.description);
                setInputDescription(res.data.original_object.description);
            }
        }
        catch(err){
            console.log(err);
        }
        setLoadData(false);
        await delay(1000);
        dispatch(stopLoading());
    }

    const splitDetailsIntoState = (examplesArr, itemsPp) => {
        if(examplesArr.length > itemsPp){
          const splittedExamples = splitArray(examplesArr, itemsPp);
          setTotalPages(splittedExamples.length);
          setShowedChildren(splittedExamples);
        } else {
            setTotalPages([examplesArr].length);
            setShowedChildren([examplesArr]);
        }
    };

    const handlePageChange = (page) => {
        setPage(page);
    };

    const splitArray = (array, itemsPp) => {
    const splitted = [];
    if (array) {
        for (let i = 0; i < array.length; i += itemsPp) {
        const chunk = array.slice(i, i + itemsPp);
        splitted.push(chunk);
        }
    }
    return splitted;
    };

  const setOriginals = (deletingRequest, newRequest, object_type, object, changes, options) => {

        if (object_type === "entity"){
            let tempAdded = [];
            let tempEdited = [];
            let tempDeleted = [];
            let tempNow = object?.synonyms || [];
        
            if (!deletingRequest){
                changes.forEach(item => {
                    if (item.operation === "create" && item.field === "synonyms") {
                        const child = {
                            value: item.value,
                            type: "added",
                        }
                        tempAdded.push(child);
                    }
                    if (item.operation === "update" && item.field === "synonyms") {
                        const child = {
                            value: item.value,
                            type: "edited",
                        }
                        tempEdited.push(child);
                    }
                    if (item.operation === "delete" && item.field === "synonyms") {
                        const child = {
                            value: item.value,
                            type: "deleted",
                        }
                        tempDeleted.push(child);
                    }
                });
            
            }
            let synonyms = [];

            synonyms = tempAdded.concat(tempEdited).concat(tempDeleted);
            

            if (!newRequest){
                tempNow.forEach(child => {
                    let alreadyIn = false;
                    synonyms.forEach(syn => {
                        if (
                            syn.value === child.value ||
                            syn.last_value === child.value ||
                            syn.new_value === child.value
                        ) {
                            alreadyIn = true;
                        }
                    });
                    if (!alreadyIn) {
                        synonyms.push(child);
                    }
                });
            }

            setListChildrenWithID(synonyms);
            setOriginalListChildren(JSON.parse(JSON.stringify(synonyms)));

            //Default categories
            let originalCategories = [];

            if (!newRequest){
                object.category.forEach(category => {
                    originalCategories.push({
                        label: category.name,
                        value: category.value,
                    });
                });
            }
        
                changes.forEach(item => {
                    if (item.operation === "create" && item.field === "category") {
                        let categoryInfo = options.find(option => option.value === item.value);
                        const category = {
                            value: categoryInfo.value,
                            label: categoryInfo.label,
                            type: "added",
                        }
                        originalCategories.push(category);
                    }
                    if (item.operation === "delete" && item.field === "category") {
                        let categoryInfo = options.find(option => option.value === item.value);
                        console.log(categoryInfo);
                        const category = {
                            value: categoryInfo.value,
                            label: categoryInfo.label,
                            type: "deleted",
                        }
                        originalCategories.push(category);
                    }
                });

                //setSelectedCategory(originalCategories);
                
                originalCategories = filterCategories(originalCategories);

                setCategoryList(originalCategories);
                console.log(originalCategories);


                setOriginalListCategories(JSON.parse(JSON.stringify(originalCategories)));
                //Remove existing categories from options
                let newOptions = options?.filter(option => {
                    return !originalCategories.some(category => category.value === option.value);
                });
                setCategories(newOptions);
            
        }
        else if (object_type === "intent"){
            let tempAdded = [];
            let tempEdited = [];
            let tempDeleted = [];
            let tempNow = object?.examples || [];
        
            if (!deletingRequest){
                changes.forEach(item => {
                    if (item.operation === "create" && item.field === "examples") {
                        const example = {
                            value: item.value,
                            type: "added",
                        }
                        tempAdded.push(example);
                    }
                    if (item.operation === "update" && item.field === "examples") {
                        const example = {
                            value: item.value,
                            type: "edited",
                        }
                        tempEdited.push(example);
                    }
                    if (item.operation === "delete" && item.field === "examples") {
                        const example = {
                            value: item.value,
                            type: "deleted",
                        }
                        tempDeleted.push(example);
                    }
                });
            }

            let examples = [];

            examples = tempAdded.concat(tempEdited).concat(tempDeleted);
            if (!newRequest){
                tempNow.forEach(example => {
                    let alreadyIn = false;
                    examples.forEach(ex => {
                        if (
                            ex.value === example.value ||
                            ex.last_value === example.value ||
                            ex.new_value === example.value
                        ) {
                            alreadyIn = true;
                        }
                    });
                    if (!alreadyIn) {
                        examples.push(example);
                    }
                });
            }

            setListChildrenWithID(examples);
            setOriginalListChildren(JSON.parse(JSON.stringify(examples)));
        }
        else if (object_type === "category"){
            //Default entities
            let originalEntities = [];

            if (!newRequest){
                object.entities.forEach(entity => {
                    originalEntities.push({
                        label: entity.name,
                        value: entity.value,
                    });
                });
            }

                changes.forEach(item => {
                    if (item.operation === "create" && item.field === "entities") {
                        let entityInfo = options.find(option => option.value === item.value);
                        const entity = {
                            value: entityInfo.value,
                            label: entityInfo.label,
                            type: "added",
                        }
                        originalEntities.push(entity);
                    }
                    if (item.operation === "delete" && item.field === "entities") {
                        let entityInfo = options.find(option => option.value === item.value);
                        const entity = {
                            value: entityInfo.value,
                            label: entityInfo.label,
                            type: "deleted",
                        }
                        originalEntities.push(entity);
                    }
                });
                originalEntities = filterCategories(originalEntities);

                setCategoryList(originalEntities);
                setOriginalListCategories(JSON.parse(JSON.stringify(originalEntities)));
                //Remove existing entities from options
                let newOptions = options?.filter(option => {
                    return !originalEntities.some(entity => entity.value === option.value);
                }
                );
                setCategories(newOptions);
        }

    };

    function filterCategories(originalCategories) {
        const seenCategories = {};
    
        // Recorrer cada categoría en el arreglo original
        for (const category of originalCategories) {
            const key = `${category.label}-${category.value}`;
            
            if (!seenCategories[key]) {
                seenCategories[key] = category;
            } else {
                if (category.type && !seenCategories[key].type) {
                    seenCategories[key] = category;
                }
            }
        }
    
        return Object.values(seenCategories);
    }

    const setDetails = (newRequest, objectType, changes) => {
        let added = [];
        let edited = [];
        let deleted = [];

        setChanges(changes);

        let numSynonymsAdded = 0;
        let numSynonymsDeleted = 0;

        let numCategoriesAdded = 0;
        let numCategoriesDeleted = 0;

        const childrenField = objectType === "entity" ? "synonyms" : "examples";

        const contField = objectType === "entity" ? "category" : "entities";

        const displayContField = objectType === "entity" ? "Categor" : "Entit";

        changes.forEach(item => {
            if (item.operation === "create") {
                switch (item.field) {
                    case childrenField:
                        numSynonymsAdded++;
                        break;
                
                    case contField:
                        numCategoriesAdded++;
                        break;
                }
            }
            if (item.operation === "delete") {
                switch (item.field) {
                    case childrenField:
                        numSynonymsDeleted++;
                        break;
                    case contField:
                        numCategoriesDeleted++;
                        break;
                }
            }
            if (item.operation === "update") {
                switch (item.field) {
                    case "name":
                        if (newRequest){
                            added.push(`Name`);
                        }
                        else{
                            edited.push(`Name`);
                        }
                        break;
                    case "description":
                        if (newRequest){
                            added.push(`Description`);
                        }
                        else{
                            edited.push(`Description`);
                        }
                        break;
                }
            }
        })

        if (numSynonymsAdded > 0) {
            added.push(
                ` ${numSynonymsAdded} ${
                    numSynonymsAdded > 1 ? childrenField.charAt(0).toUpperCase() + childrenField.slice(1) : childrenField.charAt(0).toUpperCase() + childrenField.slice(1, childrenField.length - 1)
                }`
            );
        }
        if (numCategoriesAdded > 0) {
            added.push(
                ` ${numCategoriesAdded} ${
                    numCategoriesAdded > 1 ? displayContField + "ies" : displayContField + "y"
                }`
            );
        }

        if (numSynonymsDeleted > 0) {
            deleted.push(
                ` ${numSynonymsDeleted} ${
                    numSynonymsDeleted > 1 ? childrenField.charAt(0).toUpperCase() + childrenField.slice(1) : childrenField.charAt(0).toUpperCase() + childrenField.slice(1, childrenField.length - 1)
                }`
            );
        }
        if (numCategoriesDeleted > 0) {
            deleted.push(
                ` ${numCategoriesDeleted} ${
                    numCategoriesDeleted > 1 ? displayContField + "ies" : displayContField + "y"
                }`
            );
        }
        setAddedContent(added);
        setEditedContent(edited);
        setDeletedContent(deleted);
    };

    function handleCommentContent(contentState) {
        let commentContent = convertFromRaw(contentState);
        return (
            <Editor
                editorState={EditorState.createWithContent(commentContent)}
                readOnly={true}
                toolbarHidden={true}
            />
        );
    }

    function addCategory() {
        if (selectedCategory.value) {
            console.log(selectedCategory);
            let tempCategories = [...categoryList];
            const contField = objectType === "entity" ? "category" : "entities";
            handleChanges(contField, "add", selectedCategory.value);
            let tempObj = {
                label: selectedCategory.label,
                value: selectedCategory.value,
                type: "adding",
                newOne: true,
            };
            tempCategories.push(tempObj);
            setCategoryList(tempCategories);
            setSelectedCategory([]);
        }
    }

    function removeCategory(category) {
        console.log(category);
        let tempCategories = [...categoryList];
        let undoFlag = false;
        const contField = objectType === "entity" ? "category" : "entities";
        tempCategories.forEach(item => {
            if (item.value === category.value) {
                if (category.type === "added") {
                    item.undo = true;
                    item.previous = "added";
                    undoFlag = true;
                    item.recovered = null;
                }
                item.type = "deleting";                
            } else if (category.newOne) {
                tempCategories = tempCategories.filter(
                    item => item.value !== category.value
                );
                //Remove from changes
                let tempChanges = newChanges;
                tempChanges.forEach((item, index) => {
                    if (item.value === category.value) {
                        tempChanges.splice(index, 1);
                    }
                });
                setNewChanges(tempChanges);
            }
        });
        setCategoryList(tempCategories);
        if (!undoFlag) {
            !category.newOne &&
                handleChanges(contField, "delete", category.value)
        }
        else{
            let change = []
            change.push({
                field: contField,
                value: category.value,
                undo: true,
                operation: "create",
            });
            setNewChanges([...newChanges, ...change]);
        }
    }

    function recoverCategory(category) {
        let undo = false;

        const contField = objectType === "entity" ? "category" : "entities";
        console.log(object.contField);

        /*object?.contField.forEach(item => {
            if (item.value === category.value) {
                undo = true;
            }
        });*/
        newChanges.forEach(item => {
            if (item.value === category.value && item.type === "deleted") {
                undo = true;
            }
        });

        if (category.previous) {
            let tempCategories = [...categoryList];
            tempCategories.forEach(item => {
                if (item.value === category.value) {
                    item.type = category.previous;
                }
            });
            setCategoryList(tempCategories);
            //Remove from changes
            let tempChanges = newChanges;
            tempChanges.forEach((item, index) => {
                if (item.value === category.value) {
                    tempChanges.splice(index, 1);
                }
            });
            setNewChanges(tempChanges);
            return;
        }

        if (undo && category.type === "deleting") {
            let tempCategories = [...categoryList];
            tempCategories.forEach(item => {
                if (item.value === category.value) {
                    item.type = null;
                }
            });
            setCategoryList(tempCategories);
            //Remove from changes
            let tempChanges = newChanges;
            tempChanges.forEach((item, index) => {
                if (item.value === category.value) {
                    tempChanges.splice(index, 1);
                }
            });
            setNewChanges(tempChanges);
        } else {
            let tempCategories = [...categoryList];

            tempCategories.forEach(item => {
                if (item.value === category.value) {
                    item.recovered = true;
                }
            });
            setCategoryList(tempCategories);

            let tempChanges = newChanges;
            tempChanges.push({
                field: contField,
                value: category.value,
                operation: "delete",
                undo: true,
            });
            setNewChanges(tempChanges);
        }
    }

    function undoCategoryRecovery(category) {
        let tempCategories = [...categoryList];
        tempCategories.forEach(item => {
            if (item.value === category.value) {
                item.recovered = false;
            }
        });
        setCategoryList(tempCategories);

        let tempChanges = newChanges;
        tempChanges.forEach((item, index) => {
            if (item.value === category.value) {
                tempChanges.splice(index, 1);
            }
        });
        setNewChanges(tempChanges);
    }

    function saveName() {
        let nameValue = "";
        let alreadyEdited = false;
        console.log("Save name");
        //Check if the name object has stashed changes
        newChanges.find(change => change.field === "name")
            ? (alreadyEdited = true)
            : (alreadyEdited = false);

        //If not in changes, add it
        if (alreadyEdited === false) {
            console.log("Not in changes");
            nameValue = inputName;
            let tempOldValue = "";
            
            nameValue !== tempOldValue &&
                handleChanges("name", "update", nameValue, tempOldValue);
        } else {
            //If in changes, update it
            console.log("Already in changes");
            nameValue = inputName;
            let tempChanges = [...newChanges];
            tempChanges.forEach(change => {
                if (change.field === "name") {
                    change.new_value = nameValue;
                    change.value = nameValue || object?.name;
                }
                if (change.new_value === object?.name) {
                    console.log("Remove from changes, same value");
                    tempChanges = tempChanges.filter(
                        item => item.field !== "name"
                    );
                }
            });
            setNewChanges(tempChanges);
        }
    }

    function saveDescription(newOne = true) {
        let descriptionValue = "";
        console.log("Save description");
        let alreadyEdited = false;
        //Check if the description object has stashed changes
        newChanges.find(change => change.field === "description")
            ? (alreadyEdited = true)
            : (alreadyEdited = false);

        //If not in changes, add it
        if (alreadyEdited === false) {
            console.log("Not in changes");
            descriptionValue = inputDescription;
            let tempOldValue = "";
            descriptionValue !== tempOldValue &&
                handleChanges(
                    "description",
                    "update",
                    descriptionValue,
                    tempOldValue
                );
        } else {
            //If in changes, update it
            console.log("Already in changes");
            descriptionValue = inputDescription;
            let tempChanges = [...newChanges];
            tempChanges.forEach(change => {
                if (change.field === "description") {
                    change.new_value = descriptionValue;
                    change.value = object?.description || descriptionValue;
                }
                if (change.new_value === change.last_value) {
                    console.log("Remove from changes, same value");
                    tempChanges = tempChanges.filter(
                        item => item.field !== "description"
                    );
                }
            });
            setNewChanges(tempChanges);
        }
    }

    const addChild = e => {
        if (inputChildren !== "") {
            var timeNow = new Date();
            var time = dateService.humanizeDateTime(timeNow);
            var tempObject = {
                value: inputChildren,
                type: "adding",
                update_on: timeNow,
                modified: time,
                newOne: true,
            };
            setListChildrenWithID(listChildren.concat(tempObject));
            handleChanges(objectType === "entity" ? "synonyms" : "examples", "add", inputChildren);
            setInputChildren("");
        }
    };

    const handleChanges = (fieldType, operation, value, oldValue) => {
        let change = [];

        let oldOp = fieldType;

        if (operation === "delete" || operation === "update") {
            Object.entries(changes).forEach(([operation, fields]) => {
                if (
                    fields[fieldType] !== undefined &&
                    fields[fieldType].length > 0
                ) {
                    fields[fieldType].forEach(item => {
                        if (item.value === value) {
                            oldOp = operation;
                        } else if (item.value === value) {
                            oldOp = operation;
                        }
                    });
                }
            });
        }

        switch (operation) {
            case "add":
                    change.push({
                        field: fieldType,
                        value: value,
                        operation: "create",
                    });
                break;
            case "delete":
                    change.push({
                        field: fieldType,
                        value: value,
                        operation: "delete",
                    });
                break;
            case "update":
                if (
                    !newChanges.some(item => item.value === value) &&
                    oldValue !== value
                ) {
                    console.log("newField", newChanges);
                    change.push({
                        field: fieldType,
                        oldValue: oldValue, //Original value
                        value: value, //Original value
                        operation: "update",
                    });
                } else if (oldValue === value) {
                    console.log("sameValue", newChanges);
                    let tempChanges = newChanges;
                    tempChanges.forEach((item, index) => {
                        if (item.field === fieldType) {
                            tempChanges.splice(index, 1);
                        }
                    }, tempChanges);
                } else {
                    console.log("update", newChanges);
                    let tempChanges = newChanges;
                    tempChanges.forEach(item => {
                        if (
                            item.field === fieldType &&
                            item.operation !== operation &&
                            item.value !== value
                        ) {
                            //item.value = value
                            item.value = value;
                        }
                    });
                    setNewChanges(...tempChanges);
                }
                break;
            default:
                break;
        }
        setNewChanges([...newChanges, ...change]);
        console.log("newChanges", newChanges);
    };

    function handleEdition(child) {
        setInputChildren(child.value);
        setChildrenEdit(child.value);
        child.type && (child.prevType = child.type);
        child.type = "editing";
        let tempChildren = [...listChildren];
        tempChildren.forEach((item, index) => {
            if (item.value === child.value) {
                tempChildren[index].type = "editing";
            }
        }, tempChildren);
        setListChildrenWithID(tempChildren);
    }

    function deleteChildren(child) {
        child.value instanceof Array &&
            (child.value = child.value[0].edition);
        
        let undoFlag = false;
        let tempChildren = [...listChildren];
        tempChildren.forEach((item, index) => {
            if (item.value === child.value) {
                if (child.type === "added") {
                    item.undo = true;
                    undoFlag = true;
                }
                tempChildren[index].prevType = tempChildren[index].type;
                tempChildren[index].type = "deleting";
                if (item.newOne) {
                    tempChildren.splice(index, 1); //Remove if just added new one
                    //Remove from the changes
                    let tempChanges = newChanges;
                    tempChanges.forEach((item, i) => {
                        if (
                            item.field === objectType === "entity" ? "synonyms" : "examples" &&
                            item.value === child.value
                        ) {
                            tempChanges.splice(i, 1);
                        }
                    }, tempChanges);
                    setNewChanges(tempChanges);
                }
            }
        }, tempChildren);
        setListChildrenWithID(tempChildren);
        
        if (!undoFlag) {
            !child.newOne &&
            handleChanges(
                objectType === "entity" ? "synonyms" : "examples",
                "delete",
                child.value,
                child.editions?.length > 0
                    ? child.editions[0].edition
                    : child.value
            );
        }
        else{
            let change = []
            change.push({
                field: objectType === "entity" ? "synonyms" : "examples",
                value: child.value,
                undo: true,
                operation: "create",
            });
            setNewChanges([...newChanges, ...change]);
        }
    }

    function recoverChildren(child) {
        console.log("Recover child", child);
        //Check if child is in the original list
        let undo = false;
        let tempChildren = [...listChildren];
        let children = objectType === 'entity' ? object.synonyms : object.examples;
        if (!children) {
            children = listChildren;
        }
        const childrenField = objectType === 'entity' ? 'synonyms' : 'examples';

        children.forEach(item => {
            if (item.value === child.value && !item.previous) {
                undo = true;
            }
        });

        newChanges.forEach(item => {
            if (item.value === child.value && item.type === "deleted") {
                undo = true;
            }
        });

        if (child.prevType) {
            tempChildren.forEach((item, index) => {
                if (item.value === child.value) {
                    item.type = child.prevType;
                    item.value instanceof Object &&
                        (item.value = item.value.edition);
                }
            }, tempChildren);
            setListChildrenWithID(tempChildren);
            //Remove change
            let tempChanges = newChanges;
            tempChanges.forEach((item, index) => {
                if (item.field === childrenField && item.value === child.value) {
                    tempChanges.splice(index, 1);
                }
            }, tempChanges);
            setNewChanges(tempChanges);
            return;
        }

        if (undo && child.type === "deleting") {
            let tempChildren = [...listChildren];
            tempChildren.forEach((item, index) => {
                if (item.value === child.value) {
                    item.type = null;
                }
            }, tempChildren);
            setListChildrenWithID(tempChildren);
            //Remove change
            let tempChanges = newChanges;
            tempChanges.forEach((item, index) => {
                if (item.field === childrenField && item.value === child.value) {
                    tempChanges.splice(index, 1);
                }
            }, tempChanges);
            setNewChanges(tempChanges);
            return;
        } else {
            let tempChildren = [...listChildren];
            tempChildren.forEach((item, index) => {
                if (item.value === child.value) {
                    item.recovered = true;
                }
            }, tempChildren);
            setListChildrenWithID(tempChildren);
            //Add change
            let timeNow = new Date();
            let tempChanges = newChanges;
            tempChanges.push({
                field: childrenField,
                value: child.value,
                undo: true,
                operation: "delete",
            });
            setNewChanges(tempChanges);
        }
    }

    function undoSynonymRecovery(child) {
        let tempChildren = [...listChildren];
        console.log("Undo recovery", child);
        tempChildren.forEach((item, index) => {
            if (item.value === child.value) {
                console.log(item);
                item.type = child.prevType ? child.prevType : "deleted";
                item.recovered = false;
            }
        }, tempChildren);
        setListChildrenWithID(tempChildren);
        //Remove change
        let tempChanges = newChanges;
        tempChanges.forEach((item, i) => {
            if (item.value === child.value) {
                tempChanges.splice(i, 1);
            }
        }, tempChanges);
        setNewChanges(tempChanges);
    }

    function editChildren(child, previous) {
        let original = false;
        let tempChildren = [...listChildren];

        let children = objectType === 'entity' ? object.synonyms : object.examples;
        if (!children) {
            children = listChildren;
        }
        const childrenField = objectType === 'entity' ? 'synonyms' : 'examples';
        console.log(object)
        console.log(children);
        children.forEach(originalItem => {
            if (originalItem.value === child) {
                original = true;
            }
        });

        console.log(original);
        
        tempChildren.forEach((item, index) => {
            if (original) {
                console.log("Original")(item.value === child) &&
                    (tempChildren[index].type = null);
                //Remove change
                let tempChanges = newChanges;
                tempChanges.forEach((item, index) => {
                    if (item.field === childrenField && item.value === child) {
                        tempChanges.splice(index, 1);
                    }
                }, tempChanges);
                setNewChanges(tempChanges);
                setInputChildren("");
                setChildrenEdit(false);
            } else if (
                item.type === "editing" &&
                !item.previous &&
                item.value !== child
            ) {
                console.log("Editing", item.previous, child);
                tempChildren[index].value = child;
                tempChildren[index].previous = previous;
                //Add change
                handleChanges(objectType === "entity" ? "synonyms" : "examples", "update", child, previous);
                setInputChildren("");
                setChildrenEdit(false);
            }
        }, tempChildren);
        setListChildrenWithID(tempChildren);
    }

    function cancelEdition() {
        let editingSynonym = childrenEdit;
        let tempChildren = [...listChildren];
        //Check if the child is in the original list
        let original = false;
        let children = objectType === 'entity' ? object.synonyms : object.examples;
        if (!children) {
            children = listChildren;
        }
        children.forEach(originalItem => {
            if (originalItem.value === inputChildren) {
                original = true;
            }
        });

        if (!original) {
            console.log("Original child");
            tempChildren.forEach((item, index) => {
                if (item.type === "editing") {
                    tempChildren[index].type = item.prevType;
                    //tempChildren[index].previous = null
                    editingSynonym = false;
                }
            }, tempChildren);
            setListChildrenWithID(tempChildren);
            setInputChildren("");
            setChildrenEdit(false);
        } else {
            console.log("New child");
            tempChildren.forEach((item, index) => {
                if (item.type === "editing") {
                    tempChildren[index].type = item.prevType
                        ? item.prevType
                        : null;
                    tempChildren[index].previous = null;
                    editingSynonym = false;
                }
            }, tempChildren);
            setListChildrenWithID(tempChildren);
            setInputChildren("");
            setChildrenEdit(false);
        }
    }

    useEffect(() => {
        console.log("showedChildren", showedChildren);
    }, [showedChildren]);

    function undoChildrenEdit(child) {
        let tempChildren = JSON.parse(JSON.stringify(listChildren));
        console.log("Undo edit", child);
        console.log(tempChildren);
        let abortFunction = false;
        tempChildren.forEach((item, index) => {
        if (child.previous === item.value) {
            tempChildren.splice(index, 1);
            setInputChildren("");
            setChildrenEdit(false);
            setListChildrenWithID(tempChildren);
            let tempChanges = [...newChanges];
            console.log(tempChanges);
            tempChanges = tempChanges.filter((item) => item.value !== child.previous);
            tempChanges = tempChanges.filter((item) => item.oldValue !== child.previous);
            console.log(tempChanges);
            setNewChanges(tempChanges);
            abortFunction = true;
        }
        else if (item.value === child.value) {
        tempChildren[index].type = item.prevType;
        tempChildren[index].value = child.previous;
        tempChildren[index].previous = null;
        tempChildren[index].recovered = false;
        tempChildren[index].prevType = null;
        }
        }, tempChildren);
        if (abortFunction) return;
        setListChildrenWithID(tempChildren);
        setInputChildren("");
        setChildrenEdit(false);
        //Remove change
        let tempChanges = [...newChanges];
        tempChanges.forEach((item, i) => {
            if (item.oldValue === child.value) {
                tempChanges.splice(i, 1);
            }
        }, tempChanges);
        setNewChanges(tempChanges);
    }

    function changesToPayload(){
        let changes = [];
        const childField = objectType === 'entity' ? "synonyms" : "examples";
        const contField = objectType === 'entity' ? "category" : "entities";
        newChanges.forEach((change) => {
          if (change.field === childField) {
            switch (change.operation) {
              case "create":
                let tempChangeCreate = {
                    field: childField,
                    operation: "create",
                    value: change.value,
                }
                if (change.undo){
                    tempChangeCreate.undo = true;
                }
                changes.push(tempChangeCreate);
                break;
              case "delete":
                let tempChangeDelete = {
                    field: childField,
                    operation: "delete",
                    value: change.value,
                }
                if (change.undo){
                    tempChangeDelete.undo = true;
                }
                changes.push(tempChangeDelete);
                break;
              case "update":
                if (change.undo){
                    changes.push({
                        field: childField,
                        operation: "update",
                        undo: true,
                        value: change.value,
                    });
                }
                else{
                    changes.push({
                        field: childField,
                        operation: "delete",
                        value: change.oldValue,
                      });
                      changes.push({
                        field: childField,
                        operation: "create",
                        value: change.value,
                      });
                }
                break;
            }
          } 
          else if (change.field === contField) {
            switch (change.operation) {
              case "create":
                let tempChangeCreate = {
                    field: contField,
                    operation: "create",
                    value: change.value,
                }
                if (change.undo){
                    tempChangeCreate.undo = true;
                }
                changes.push(tempChangeCreate);
                break;
              case "delete":
                let tempChangeDelete = {
                    field: contField,
                    operation: "delete",
                    value: change.value,
                }
                if (change.undo){
                    tempChangeDelete.undo = true;
                }
                changes.push(tempChangeDelete);
                break;
            }
          }
          else if (change.field === "name") {
            changes.push({
              field: "name",
              operation: "update",
              value: change.value,
            });
          }
          else if (change.field === "description") {
            changes.push({
              field: "description",
              operation: "update",
              value: change.value,
            });
          }
        })
        
        let body = {}

        if (changes.length > 0){
          body.new_changes = changes;
        }
    
        return body;
      }

      const changeHandleSave = () => {
        setCommentModalFunction(() => handleSave);
      }
      const changeHandleClose = () => {
        setCommentModalFunction(() => handleClose);
      }
      const changeHandleRollback = () => {
        setCommentModalFunction(() => handleRollback);
      }
      const changeHandleDiscard = () => {
        setCommentModalFunction(() => handleDiscard);
      }
      const changeHandleRecover = () => {
        setCommentModalFunction(() => handleRecover);
      }
    
      async function handleSave(comment = "", saving_changes = false){
        let modal = new Modal(document.getElementById("approveModal"));
        if (saving_changes){
            console.log("Saving changes");
            modal = new Modal(document.getElementById("savedModal"));
        }
        
        let params = {
          workspace_id: workspace,
          user_id: user.id,
          request_id: requestID,
          saving_changes: saving_changes,
        }
        let body = changesToPayload();
        if (comment !== ""){
            body.comments = comment;
        }
    
        try{
          console.log("newChanges", newChanges);
          console.log("Params", params);
          console.log("Body", body);

          if (requestStatus === "Saved"){
            let res = await requestsService.edit(params, body);
            console.log(res);
            console.log("Edit request")
          }
          else if (requestStatus === "Edited" || requestStatus === "Suggested"){
            let res = await requestsService.validate(params, body);
            console.log(res);
            console.log("Validate request")
          }
          modal.show();
        }
        catch(err){
          console.log(err);
        }
      }

      async function handleClose(comment = ""){
        const closeModal = new Modal(document.getElementById("closeModal"));
        let params = {
          workspace_id: workspace,
          user_id: user.id,
          request_id: requestID,
        }
        let body = {}
        if (comment !== ""){
            body.comments = comment;
        }
        try{
          let res = await requestsService.close(params, body);
          console.log(res);
          console.log("Close request")
          closeModal.show();
        }
        catch(err){
          console.log(err);
        }
      }

      async function handleRollback(comment = ""){
        const rejectModal = new Modal(document.getElementById("rejectModal"));
        let params = {
          workspace_id: workspace,
          user_id: user.id,
          request_id: requestID,
        }
        let body = {}
        if (comment !== ""){
            body.comments = comment;
        }
        try{
          let res = await requestsService.rollback(params, body);
          console.log(res);
          console.log("Rollback request")
          rejectModal.show();
        }
        catch(err){
          console.log(err);
        }
      }

      async function handleDiscard(comment = ""){
        const discardModal = new Modal(document.getElementById("discardModal"));
        let params = {
          workspace_id: workspace,
          user_id: user.id,
          request_id: requestID,
        }
        let body = {}
        if (comment !== ""){
            body.comments = comment;
        }
        try{
          let res = await requestsService.discardSuggestion(params, body);
          console.log(res);
          console.log("Discard request")
          discardModal.show();
        }
        catch(err){
          console.log(err);
        }
      }

      async function handleRecover(){
        const params = {
            user_id: props.user.id,
            workspace_id: workspace,
        }
        const body = {
            request_ids: [requestID],
        }
        console.log("body", body);
        try{
            let res = await requestsService.recover(params, body);
            if (res.status === 200){
                console.log("Recover request")
                const modalElement = document.getElementById('recoverModal');
                if (modalElement) {
                    const modal = new Modal(modalElement);
                    modal.show();
                }
            }
            console.log("res", res);
        }
        catch(err){
            console.log(err);
        }
      }

      async function handleDeploy(){
        const modal = new Modal(document.getElementById("deployModal"));
        let params = {
          workspace_id: workspace,
          user_id: user.id,
          request_id: requestID,
        }
        console.log(params);
        try{
          let res = await requestsService.deploy(params);
          console.log(res);
          console.log("Deploy request")
          modal.show();
        }
        catch(err){
          console.log(err);
        }
      }

    const handleCheck = object => {
        var newChecked = new Set(checked);
        var isChecked = newChecked.has(object?._id);
        isChecked
            ? newChecked.delete(object?._id)
            : newChecked.add(object?._id);
        if (allChecked && isChecked) {
            setAllChecked(false);
        }
        setChecked(newChecked);

        setSelectedSynonyms((prevSelectedSynonyms) => {
            const updatedSelectedSynonyms = [...prevSelectedSynonyms];
            const index = updatedSelectedSynonyms.findIndex((child) => child._id === object._id);
        
            if (index !== -1) {
              updatedSelectedSynonyms.splice(index, 1);
            } else {
              updatedSelectedSynonyms.push(object);
            }
            return updatedSelectedSynonyms;
          });

    };

    const handleCheckCategory = object => {
        var newChecked = new Set(categoryChecked);
        var isChecked = newChecked.has(object?.value);
        isChecked
            ? newChecked.delete(object?.value)
            : newChecked.add(object?.value);
        if (allCategoriesChecked && isChecked) {
            setAllCategoriesChecked(false);
        }
        setCategoryChecked(newChecked);

        setSelectedCategories((prevSelectedCategories) => {
            const updatedSelectedSynonyms = [...prevSelectedCategories];
            const index = updatedSelectedSynonyms.findIndex((category) => category.value === object.value);
        
            if (index !== -1) {
              updatedSelectedSynonyms.splice(index, 1);
            } else {
              updatedSelectedSynonyms.push(object);
            }
            return updatedSelectedSynonyms;
          });

    };

    const handleAllCheck = (event, value) => {
        if (event.target.checked) {
            setChecked(new Set([...checked, ...listChildren.map(e => e._id)]));
            setSelectedSynonyms(listChildren);
        } else {
            setChecked(new Set());
            setSelectedSynonyms([]);
        }
        setAllChecked(event.target.checked);
    };

    const handleAllCheckCategories = (event) => {
        if (event.target.checked) {
            setCategoryChecked(new Set([...categoryChecked, ...categoryList.map(e => e.value)]));
            setSelectedCategories(categoryList);
        } else {
            setCategoryChecked(new Set());
            setSelectedCategories([]);
        }
        setAllCategoriesChecked(event.target.checked);
    };

    const handleCancelSelection = () => {
        // setChecked(new Set());
        // setAllChecked(false);
        let original = JSON.parse(JSON.stringify(originalListChildren));
        [...checked].forEach(e => {
            let originalIndex = original.findIndex(ee => ee._id === e);
            if (originalIndex !== -1) {
                listChildren[listChildren.findIndex(ee => ee._id === e)] =
                    original[originalIndex];
            } else {
                let currentIndex = listChildren.findIndex(ee => ee._id === e);
                selectedSynonyms.forEach((child, index) => {
                    if (child._id === e) {
                        selectedSynonyms.splice(index, 1);
                    }
                })
                if (currentIndex !== -1) {
                    interactionService.simulateMouseClick(deleteRef.current[e]);
                }
            }
        });
        setListChildrenWithID([...listChildren]);
    };

    const handleCancelSelectionCategory = () => {
        let original = JSON.parse(JSON.stringify(originalListCategories));
        [...categoryChecked].forEach(e => {
            let originalIndex = original.findIndex(ee => ee.value === e);
            if (originalIndex !== -1) {
                categoryList[categoryList.findIndex(ee => ee.value === e)] =
                    original[originalIndex];
            } else {
                let currentIndex = categoryList.findIndex(ee => ee.value === e);
                selectedCategories.forEach((category, index) => {
                    if (category.value === e) {
                        selectedCategories.splice(index, 1);
                    }
                })
                if (currentIndex !== -1) {
                    interactionService.simulateMouseClick(deleteRefCategory.current[e]);
                }
            }
        });
    };

    const pruneChecked = useCallback(() => {
        let currentIds = new Set(listChildren.map(e => e._id));
        let newChecked = new Set([...checked].filter(i => currentIds.has(i)));
        if (JSON.stringify(checked) !== JSON.stringify(newChecked))
            setChecked(newChecked);
    }, [checked, listChildren]);

    useEffect(() => {
        pruneChecked();
    }, [listChildren, pruneChecked]);

    const handleDownloadSelection = (type, list) => {
        console.log(list);
        if (list.length === 0) {
          return;
        }

        let headers = [];
        let csvData = [];

        if (type === "synonym"){
            headers = ['Synonyms', 'Added'];

            csvData = list.map((child) => {
                const synonymsValue = child.new_value || child.value;
                return `${synonymsValue},${child.modified}`;
            });
        }
        if (type === "category"){
            headers = ['Categories']
            csvData = list.map((category) => {
                const categoriesValue = category.label;
                return `${categoriesValue}`;
            });
        }
    
        const csvContent = `${headers.join(',')}\n${csvData.join('\n')}`;
        const blob = new Blob([csvContent], {
          type: 'data:text/csv;charset=utf-8',
        });
        FileSaver.saveAs(blob, 'list.csv');
      };

  const handleDeleteSelection = () => {
    [...checked].forEach((e) => {
      if (e in deleteRef.current && deleteRef.current[e] !== null) {
        interactionService.simulateMouseClick(deleteRef.current[e]);
      }
    });
  };

  const handleDeleteSelectionCategory = () => {
    [...categoryChecked].forEach((e) => {
      if (e in deleteRefCategory.current && deleteRefCategory.current[e] !== null) {
        interactionService.simulateMouseClick(deleteRefCategory.current[e]);
      }
    });
  };

    useEffect(() => {
        if (loadData) {
            getRequest(requestID);
        }
    }, [loadData, requestID, selectedCategory, activeTab]);

    return <RequestsValidateScreen _this={{
        viewerMode,
        object,
        changes,
        addedContent,
        editedContent,
        deletedContent,
        inputName,
        inputDescription,
        inputChildren,
        listChildren,
        categoryList,
        categories,
        checked,
        categoryChecked,
        allChecked,
        allCategoriesChecked,
        selectedSynonyms,
        selectedCategories,
        activeTab,
        childrenEdit,
        nameError,
        descriptionError,
        validationError,
        sort,
        toBeDeleted,
        newChanges,
        originalListChildren,
        originalListCategories,
        deleteRef,
        deleteRefCategory,
        selectedCategory,
        requestComments,
        lastUser,
        lastUpdate,
        objectType,
        requestStatus,
        newObject,
        requestSource,
        objectTypeView,
        rejected,
        validateRequestPermission,
        rollbackRequestPermission,
        closeRequestPermission,
        displayEdit,
        displayValidate,
        displayRollback,
        displayClose,
        displaySave,
        displayDeploy,
        setInputName,
        setInputDescription,
        setSelectedCategory,
        required,
        handleCheck,
        handleCheckCategory,
        handleAllCheck,
        handleAllCheckCategories,
        handleCancelSelection,
        handleCancelSelectionCategory,
        handleDownloadSelection,
        handleDeleteSelection,
        handleDeleteSelectionCategory,
        handleSort,
        handleEdition,
        handleCommentContent,
        handleSave,
        composeValidators,
        maxLength,
        name,
        namePrefix,
        categoryName,
        child,
        synonymExists,
        handleChangeError,
        isSynonymInList,
        applySort,
        setListChildrenWithID,
        toggle,
        addCategory,
        removeCategory,
        recoverCategory,
        undoCategoryRecovery,
        saveName,
        saveDescription,
        addChild,
        handleChanges,
        setInputChildren,
        deleteChildren,
        recoverChildren,
        undoSynonymRecovery,
        editChildren,
        cancelEdition,
        undoChildrenEdit, 
        handleCheck,
        handleCheckCategory,
        handleAllCheck,
        handleAllCheckCategories,
        handleCancelSelection,
        handleCancelSelectionCategory,
        handleDownloadSelection,
        handleDeleteSelection,
        handleDeleteSelectionCategory,
        handleSort,
        handleEdition,
        handleCommentContent,
        handleSave,
        commentModalFunction,
        setCommentModalFunction,
        changeHandleSave,
        changeHandleClose,
        changeHandleRollback,
        handleDeploy,
        displayDiscard,
        changeHandleDiscard,
        categoriesError,
        displayRecover,
        changeHandleRecover,
        showedChildren,
        totalPages,
        handlePageChange,
        page,
        recoverRef
    }} />;
};

function mapStateToProps(state) {
    return {
        workspace: state.workspace,
        role: state.user.role,
        user: state.user,
        workspace_engine: state.workspace.workspace,
    };
}

export default connect(mapStateToProps)(RequestsValidate);
