import React from "react";
import { Link } from "react-router-dom";
import { Modal } from "bootstrap";
import { logout } from "../app/slices/user/user";
import {
  logOut
} from "../app/slices/workspaces/workspace";
import { store } from "../app/store";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

async function handleLogOut () {
  await store.dispatch(logOut());
  await store.dispatch(logout());
  window.location.href = "/";
};

export function CommentModal({ handleSave }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const getCommentText = () => {
    const contentState = editorState.getCurrentContent();
    return contentState.getPlainText();
  };

  return (
    <div
      className="modal fade"
      id="commentModal"
      tabIndex="-1"
      aria-labelledby="commentModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h2" id="commentModalLabel">
              Leave a Comment
            </p>
          </div>
          <div className="modal-body">
            <div className="container mb-20">
              <div className="row">
                  <div className="row mt-20">
                    <div>
                      <div className="row">
                        <p className="h3 -b">Comment</p>
                      </div>
                      <div className="current-answer">
                        <div className="text-editor">
                          <div
                            className="rich-text"
                            style={{
                              border: "1px solid black",
                              minHeight: "200px",
                            }}
                          >
                            <Editor
                              editorState={editorState}
                              onEditorStateChange={onEditorStateChange}
                              toolbar={{
                                options: ["inline", "list", "link"],
                                inline: {
                                  inDropdown: false,
                                  options: ["bold", "italic", "underline"],
                                },
                                list: {
                                  inDropdown: false,
                                  options: ["unordered", "ordered"],
                                },
                                link: {
                                  inDropdown: false,
                                  options: ["link"],
                                },
                              }}
                              editorStyle={{
                                height: "100px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row justify-content-end mt-40">
                        <div className="col-12 col-md-6 col-lg-3 mb-md-20">
                          <button
                            className="button -outline-primary w-100"
                            data-bs-dismiss="modal"
                          >
                            CANCEL
                          </button>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                          <button
                            className="button -primary w-100"
                            onClick={() => handleSave(getCommentText())}
                            data-bs-dismiss="modal"
                          >
                            CONTINUE
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ErrorModal({ children = null}) {
  const errorText =
    children !== null ? children : "Something went wrong. Please try again.";
    const location = useLocation();
    const pathsWithoutModal = ["/login", "/"];

    if (pathsWithoutModal.includes(location.pathname)) {
      return null;
    }
  return (
    <div
      className="modal fade"
      id="errorModal"
      tabIndex="1"
      aria-labelledby="errorModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          {/* Header */}
          <div className="modal-header">
            <p className="h2" id="errorModalLabel">
              Error
            </p>
            <button
              type="button"
              className="button-close w-100"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>

          {/* Body */}
          <div className="modal-body">
            <p className="text">{errorText}</p>
          </div>

          {/* Footer */}
          <div className="modal-footer">
            <div className="row w-100">
              <div className="col-12 col-md-6 mb-10">
                <button className="button -text w-100" data-bs-dismiss="modal">
                  CANCEL
                </button>
              </div>
              <div className="col-12 col-md-6 mb-10">
                <button
                  className="button -primary w-100"
                  data-bs-dismiss="modal"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function PermissionsUpdatedModal() {
  const location = useLocation();
  const pathsWithoutModal = ["/login", "/"];

  if (pathsWithoutModal.includes(location.pathname)) {
    return null;
  }
  
  return (
    <div
      className="modal fade"
      id="permissionsUpdatedModal"
      tabIndex="1"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false" 
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          {/* Header */}
          <div className="modal-header">
            <p className="h2" id="errorModalLabel">
              Permissions Updated
            </p>
          </div>

          {/* Body */}
          <div className="modal-body">
            <p className="text">Your permissions have been updated. Please log in again to continue.</p>
          </div>

          {/* Footer */}
          <div className="modal-footer">
            <div className="row w-100">
                <button
                  className="button -primary w-100"
                  onClick={() => handleLogOut()}
                >
                  Log In Again
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function DeleteModal({ handleDelete, type }) {
  return (
    <div
      className="modal fade"
      id="deleteModal"
      tabIndex="1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h2" id="deleteModalLabel">
              Delete
            </p>
            <button
              type="button"
              className="button-close w-100"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body justify-content-center align-items-center">
            <p className="text">
              Are you sure you want to create a deletion request for this {type}? 
            </p>
          </div>
          <div className="modal-footer">
            <div className="row w-100">
              <div className="col-12 col-md-6 mb-10">
                <button className="button -text w-100" data-bs-dismiss="modal">
                  CANCEL
                </button>
              </div>
              <div className="col-12 col-md-6 mb-10">
                <button
                  className="button -warning w-100"
                  onClick={handleDelete}
                  data-bs-dismiss="modal"
                >
                  DELETE {type.toLocaleUpperCase()}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function DeleteFileModal({}) {
  return (
    <div
      className="modal fade"
      id="DeleteFileModal"
      tabIndex="-1"
      aria-labelledby="DeleteModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content  p-4">
          <div className="modal-header  border-0">
            <p className="h2 fw-bold" id="deleteModalLabel">
              Delete File
            </p>
          </div>
          <div className="modal-body d-flex justify-content-between align-items-center">
            <p className="text mb-0">
              The file has been deleted.
            </p>
            <button
              className="btn btn-outline-primary px-4 py-2 ms-3"
              data-bs-dismiss="modal"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export function DeleteNlpModal({ handleDelete, type }) {
  return (
    <div
      className="modal fade"
      id="deleteNlpModal"
      tabIndex="1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h2" id="deleteModalLabel">
              Delete
            </p>
            <button
              type="button"
              className="button-close w-100"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body justify-content-center align-items-center">
            <p className="text">
              Are you sure you want to delete this NLP Engine instance? 
            </p>
          </div>
          <div className="modal-footer">
            <div className="row w-100">
              <div className="col-12 col-md-6 mb-10">
                <button className="button -text w-100" data-bs-dismiss="modal">
                  CANCEL
                </button>
              </div>
              <div className="col-12 col-md-6 mb-10">
                <button
                  className="button -warning w-100"
                  onClick={handleDelete}
                  data-bs-dismiss="modal"
                >
                  DELETE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ApproveModal() {
  const navigate = useNavigate();
  return (
    <div
      className="modal fade"
      id="approveModal"
      tabIndex="1"
      aria-labelledby="approveModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h2" id="approveModalLabel">
              Approved
            </p>
            <button
              type="button"
              className="button-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body d-flex flex-column align-items-center justify-content-center">
            <p className="text">
              This request will now pass to the next step.
            </p>
          </div>
          <div className="modal-footer">

            <div className="row w-100">
                <button className="button -primary" data-bs-dismiss="modal" onClick={() => (navigate("/requests", { state: { tab: '2' } }))}>
                  CONTINUE
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function DeployModal() {
  return (
    <div
      className="modal fade"
      id="deployModal"
      tabIndex="1"
      aria-labelledby="approveModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h2" id="approveModalLabel">
              Deployed
            </p>
            <button
              type="button"
              className="button-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body d-flex flex-column align-items-center justify-content-center">
            <p className="text">
              This request has been deployed.
            </p>
          </div>
          <div className="modal-footer">
            <div className="row w-100">
                <button className="button -primary" data-bs-dismiss="modal" onClick={() => (window.location.href = "/requests")}>
                  CONTINUE
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function CsvModal() {
  return (
    <div
      className="modal fade"
      id="csvModal"
      tabIndex="1"
      aria-labelledby="approveModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h2" id="approveModalLabel">
              Requests created by CSV
            </p>
            <button
              type="button"
              className="button-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body d-flex flex-column align-items-center justify-content-center">
            <p className="text">
              The requests have been created successfully.
            </p>
          </div>
          <div className="modal-footer">
            <div className="row w-100">
                <button className="button -primary" data-bs-dismiss="modal" onClick={() => (window.location.href = "/requests")}>
                  CONTINUE
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export function CreatedWorkspaceModal() {
  return (
    <div
      className="modal fade"
      id="createdWorkspaceModal"
      tabIndex="1"
      aria-labelledby="approveModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h2" id="approveModalLabel">
              Workspace created
            </p>
            <button
              type="button"
              className="button-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body d-flex flex-column align-items-center justify-content-center">
            <p className="text">
              The workspace has been created successfully.
            </p>
          </div>
          <div className="modal-footer">
            <div className="row w-100">
                <button className="button -primary" data-bs-dismiss="modal" onClick={() => (window.location.href = "/workspacemanage")}>
                  CONTINUE
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ImportedWorkspaceModal() {
  return (
    <div
      className="modal fade"
      id="importedWorkspaceModal"
      tabIndex="1"
      aria-labelledby="approveModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h2" id="approveModalLabel">
              Workspace imported
            </p>
            <button
              type="button"
              className="button-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body d-flex flex-column align-items-center justify-content-center">
            <p className="text">
              The workspace has been imported successfully.
            </p>
          </div>
          <div className="modal-footer">
            <div className="row w-100">
                <button className="button -primary" data-bs-dismiss="modal" onClick={() => (window.location.href = "/workspacemanage")}>
                  CONTINUE
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function UpdatedNlpModal() {
  return (
    <div
      className="modal fade"
      id="updatedNlpModal"
      tabIndex="1"
      aria-labelledby="approveModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h2" id="approveModalLabel">
              NLP Engine instance updated
            </p>
            <button
              type="button"
              className="button-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body d-flex flex-column align-items-center justify-content-center">
            <p className="text">
              The NLP Engine instance has been updated successfully.
            </p>
          </div>
          <div className="modal-footer">
            <div className="row w-100">
                <button className="button -primary" data-bs-dismiss="modal" onClick={() => (window.location.href = "/managenlp")}>
                  CONTINUE
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function UpdateNlpModal({handleUpdate}) {
  return (
    <div
      className="modal fade"
      id="updateNlpModal"
      tabIndex="1"
      aria-labelledby="approveModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h2" id="approveModalLabel">
              Update
            </p>
            <button
              type="button"
              className="button-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body d-flex flex-column align-items-center justify-content-center">
            <p className="text">
              Are you sure you want to update this NLP Engine instance?
            </p>
          </div>
          <div className="modal-footer">
          <div className="row w-100">
              <div className="col-12 col-md-6 mb-10">
                <button className="button -text w-100" data-bs-dismiss="modal">
                  CANCEL
                </button>
              </div>
              <div className="col-12 col-md-6 mb-10">
                <button
                  className="button -primary w-100"
                  onClick={handleUpdate}
                  data-bs-dismiss="modal"
                >
                  UPDATE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function DeletedNlpModal() {
  return (
    <div
      className="modal fade"
      id="deletedNlpModal"
      tabIndex="1"
      aria-labelledby="approveModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h2" id="approveModalLabel">
              NLP Engine instance deleted
            </p>
            <button
              type="button"
              className="button-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body d-flex flex-column align-items-center justify-content-center">
            <p className="text">
              The NLP Engine instance has been deleted successfully.
            </p>
          </div>
          <div className="modal-footer">
            <div className="row w-100">
                <button className="button -primary" data-bs-dismiss="modal" onClick={() => (window.location.href = "/managenlp")}>
                  CONTINUE
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function CreatedNlpModal() {
  return (
    <div
      className="modal fade"
      id="createdNlpModal"
      tabIndex="1"
      aria-labelledby="approveModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h2" id="approveModalLabel">
              NLP Engine instance created
            </p>
            <button
              type="button"
              className="button-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body d-flex flex-column align-items-center justify-content-center">
            <p className="text">
              The NLP Engine instance has been created successfully.
            </p>
          </div>
          <div className="modal-footer">
            <div className="row w-100">
                <button className="button -primary" data-bs-dismiss="modal" onClick={() => (window.location.href = "/managenlp")}>
                  CONTINUE
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ErrorWorkspaceModal() {
  return (
    <div
      className="modal fade"
      id="errorWorkspaceModal"
      tabIndex="1"
      aria-labelledby="approveModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h2" id="approveModalLabel">
              Error
            </p>
            <button
              type="button"
              className="button-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body d-flex flex-column align-items-center justify-content-center">
            <p className="text">
              The workspace has already been imported.
            </p>
          </div>
          <div className="modal-footer">
            <div className="row w-100">
                <button className="button -primary" data-bs-dismiss="modal">
                  OK
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function OpenRequest({handleSave}) {
  return (
    <div
      className="modal fade"
      id="openRequestModal"
      tabIndex="1"
      aria-labelledby="approveModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h2" id="approveModalLabel">
              Create Request
            </p>
            <button
              type="button"
              className="button-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body d-flex flex-column align-items-center justify-content-center">
            <p className="text">
              This will create a request.
            </p>
          </div>
          <div className="modal-footer">
            <div className="row w-100">
              <div className="col-12 col-md-6 mb-10 d-flex flex-column align-items-center justify-content-center">
                <button className="button -text" data-bs-dismiss="modal">
                  CANCEL
                </button>
              </div>
              <div className="col-12 col-md-6 mb-10 d-flex flex-column align-items-center justify-content-center">
                <button className="button -primary" onClick={handleSave} data-bs-dismiss="modal">
                  CONTINUE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function SavedModal() {
  return (
    <div
      className="modal fade"
      id="savedModal"
      tabIndex="1"
      aria-labelledby="savedModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h2" id="savedModalLabel">
              Saved
            </p>
            <button
              type="button"
              className="button-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body d-flex flex-column align-items-center justify-content-center">
            <p className="text">Your changes have been saved.</p>
          </div>
          <div className="modal-footer">
            <div className="row w-100">
              <div className="col-12 col-md-6 mb-10 d-flex flex-column align-items-center justify-content-center">
                <button
                  className="button -text"
                  data-bs-dismiss="modal"
                  onClick={() => window.location.reload(true)}
                >
                  CLOSE
                </button>
              </div>
              <div className="col-12 col-md-6 mb-10 d-flex flex-column align-items-center justify-content-center">
                <button
                  className="button -primary"
                  onClick={() => (window.location.href = "/requests")}
                  data-bs-dismiss="modal"
                >
                  BACK TO REQUESTS
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function CloseModal() {
  const navigate = useNavigate();
  return (
    <div
      className="modal fade"
      id="closeModal"
      tabIndex="1"
      aria-labelledby="closeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h2" id="closeModalLabel">
              Closed
            </p>
            <button
              type="button"
              className="button-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body d-flex flex-column align-items-center justify-content-center">
            <p className="text">This request has been closed.</p>
          </div>
          <div className="modal-footer">
            <div className="row w-100">
                <button className="button -primary" data-bs-dismiss="modal" onClick={() => (navigate("/requests", { state: { tab: '3' } }))}>
                  CONTINUE
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function RequestCreatedModal() {
  const navigate = useNavigate();
  return (
    <div
      className="modal fade"
      id="requestCreatedModal"
      tabIndex="1"
      aria-labelledby="closeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h2" id="closeModalLabel">
              Request created
            </p>
            <button
              type="button"
              className="button-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body d-flex flex-column align-items-center justify-content-center">
            <p className="text">This request has been created succesfully.</p>
          </div>
          <div className="modal-footer">
            <div className="row w-100">
                <button className="button -primary" data-bs-dismiss="modal" onClick={() => (navigate("/requests", { state: { tab: '2' } }))}>
                  CONTINUE
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function IncompleteModal({type}) {
  return (
    <div
      className="modal fade"
      id="incompleteModal"
      tabIndex="1"
      aria-labelledby="incompleteModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h2" id="incompleteModalLabel">
              Incomplete
            </p>
            <button
              type="button"
              className="button-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body d-flex flex-column align-items-center justify-content-center">
            <p className="text">
              This {type} is incomplete. Please complete all required fields.
            </p>
          </div>
          <div className="modal-footer">
            <div className="row w-100 d-flex flex-column align-items-center justify-content-center">
              <div className="col-12 col-md-6 mb-10">
                <button className="button -text" data-bs-dismiss="modal">
                  CLOSE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function RejectModal() {
  const navigate = useNavigate();
  return (
    <div
      className="modal fade"
      id="rejectModal"
      tabIndex="1"
      aria-labelledby="rejectModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h2" id="rejectModalLabel">
              Rejected
            </p>
            <button
              type="button"
              className="button-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body d-flex flex-column align-items-center justify-content-center">
            <p className="text">
              This request has been rejected.
            </p>
          </div>
          <div className="modal-footer d-flex flex-column align-items-center justify-content-center">
            <div className="row w-100">
                <button className="button -primary" data-bs-dismiss="modal" onClick={() => (navigate("/requests", { state: { tab: '2' } }))}>
                  CONTINUE
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function AdminModal({adminNextStatus, setAdminNextStatus, adminDeleting, type}) {
  return (
      <div
        className="modal fade"
        id="adminSaveModal"
        tabIndex="1"
        aria-labelledby="adminSaveModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <p className="h2" id="adminSaveModalLabel">
                Save
              </p>
              <button
                type="button"
                className="button-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body d-flex flex-column align-items-center justify-content-center">
              <p className="text">Select the next step for this {type}</p>
              {/* Dropdown to select next status */}
              <div className="d-flex flex-column align-items-center justify-content-center">
                <button
                  className="button -outline-primary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {adminNextStatus}
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => setAdminNextStatus("validator")}
                    >
                      Validator
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => setAdminNextStatus("deployer")}
                    >
                      Deployer
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      onClick={() => setAdminNextStatus("NLP Engine")}
                    >
                      Deploy to NLP Engine
                    </a>
                  </li>
                </ul>
              </div>
              <p className="text">You can leave a comment afterwards</p>
            </div>
            <div className="modal-footer d-flex flex-column align-items-center justify-content-center">
              <div className="row w-100">
                <div className="col-12 col-md-6 mb-10 d-flex flex-column align-items-center justify-content-center">
                  <button className="button -text" data-bs-dismiss="modal">
                    CANCEL
                  </button>
                </div>
                <div className="col-12 col-md-6 mb-10 d-flex flex-column align-items-center justify-content-center">
                  <button
                    className="button -primary"
                    data-bs-dismiss={adminNextStatus !== "Select" && "modal"}
                    data-bs-toggle={adminNextStatus !== "Select" && "modal"}
                    data-bs-target={
                      adminDeleting 
                      ? adminNextStatus !== "Select" && "#deleteModal"
                      : adminNextStatus !== "Select" && "#approveModal"
                    }
                    onClick={() => {
                      let modalElement = document.getElementById("adminSaveModal");
                      let modalInstance = new Modal(modalElement);
                      adminNextStatus !== "Select" && modalInstance.hide();
                    }}
                  >
                    CONTINUE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )}

export function ReturnModal({handleSave, redirect}) {
  return(
    <div
    className="modal fade"
    id="returnModal"
    tabIndex="1"
    aria-labelledby="returnModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <p className="h2" id="returnModalLabel">
            Return
          </p>
          <button
            type="button"
            className="button-close w-100"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            X
          </button>
        </div>
        <div className="modal-body">
          <p className="text">
            You haven’t saved your changes yet, do you want to save them?
          </p>
        </div>
        <div className="modal-footer">
          <div className="row w-100">
            <div className="col-12 col-md-6 mb-10">
              <button
                type="button"
                className="button -text w-100"
                data-bs-dismiss="modal"
              >
                <a href={`/${redirect}`}>DON'T SAVE</a>
              </button>
            </div>
            <div className="col-12 col-md-6 mb-10">
              <button
                type="button"
                className="button -primary w-100"
                data-bs-dismiss="modal"
                onClick={() => handleSave("", true)}
              >
                SAVE CHANGES
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)}

export function hideModals() {
  const modals = document.querySelectorAll(".modal");
  modals.forEach(modal => {
    if (modal.classList.contains("show")) {
      const modalInstance = Modal.getInstance(modal);
      modalInstance.hide();
    }
  });
}
