import API from "./axios";

const suggestionsService = {
    collect_many: async (params) => {
        try {
            params['random_number'] = Math.floor(Math.random() * 1000000);
            return await API.get("/suggestion_jobs/collect_many_with_details", { params });
        } catch (error) {
            console.error(error);
        }
    },

    run: async (formData) => {
        try {
            return await API.post("/suggestion_jobs", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
        } catch (error) {
            console.error(error);
        }
    },

    collect_one: async (params) => {
        try {
            const random_number = Math.floor(Math.random() * 1000000);
            return await API.get(`/suggestion_jobs/${params}?random_number=${random_number}`);
        } catch (error) {
            console.error(error);
        }
    },

    cancel: async (jobId) => {
        try{
            return await API.post(`/suggestion_jobs/cancel`, null, {params: { job_id: jobId }});
        } catch (error) {
            console.error(error);
        }
    },

    delete: async (params) => {
        try{
            return await API.delete(`/suggestion_jobs/${params}`);
        } catch (error) {
            console.error(error);
        }
    }


};

export default suggestionsService;