import React, {useEffect, useState, useCallback, useRef} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import suggestion from '../../../Images/suggestions.png';
import * as dateService from '../../../Utils/format/date';
import { store } from "../../../app/store";
import DragAndDrop from '../../../Components/DragAndDrop/dragDrop';
import ProgressBar from 'react-bootstrap/esm/ProgressBar';
import suggestionsService from "../../../Services/suggestionJobsService";
import { v4 as uuidv4 } from 'uuid';

import animationAnalize from '../../../LottieFiles/analyze.json';
import animationLoader from '../../../LottieFiles/GrayLoader.json';

const stateIcons = {
  'done': '✅',
  'cancelled': '❎',
  'failed': '❌',
  'running': '⌛',
  'pending': '📩',
}

const finishedStates = [
    "done",
    "cancelled",
    "failed"
];

const AnalyzingComponent = (props) => {

  const reduxStore = store.getState();
  const workspace = reduxStore.workspace.id;
  const user = reduxStore.user;
  const animationRef = useRef(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  const LoaderOptions = {
    loop: true,
    autoplay: false,
    animationData: animationLoader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const [jobNames, setJobNames] = useState(props.jobNames);
  const [jobIds, setJobIds] = useState([]);
  const [prevJobLength, setPrevJobLength] = useState(0);
  const [intervalID, setIntervalID] = useState(null); 
  const [finished, setFinished] = useState(false); 
  const [stateData, setStateData] = useState([]);
  const dragAndDropRef = useRef();
  const navigate = useNavigate();

  const [progress, setProgress] = useState(0);

const getStates = useCallback(async () => {
  if (Array.isArray(jobIds) && jobIds.length > 0) {
    for (let id of jobIds) {
      let params =  { id };
      await suggestionsService.collect_one(params.id)
      .then((res) => {
        const data = res?.data;
        const dataArray = [data];
        const isFinished = res?.data?.length && res.data.reduce(
          (accumulator, currentValue) => accumulator && finishedStates.includes(currentValue.state),
          true
        );
        res.data.jobName = res.data.job_name;
        const uuid = res.data.jobName.split('_').pop();
        res.data.name = res.data.jobName.replace('_' + uuid, '');
        res.data.dataflow_id = uuid;
        if (isFinished) {
          res.data.createTime = dateService.changeDatetimeByTimezone(res.data.createTime);
          res.data.currentStateTime = dateService.changeDatetimeByTimezone(res.data.currentStateTime);
        }
        else { 
          res.data.createTime = dateService.humanizeDateTime(res.data.createTime);
          res.data.currentStateTime = dateService.humanizeDateTime(res.data.currentStateTime);
        }
        res.data.originalState = res.data.state;
        res.data.state = `${stateIcons[res.data.state] ? stateIcons[res.data.state] + ' ' : ''}${res.data.state}`;
        res.data.name = <a onClick={() => (navigate("/requests", {state: {tab: '4'}, job_name: res.data.job_name}))}></a>
        if (JSON.stringify(dataArray) !== JSON.stringify(stateData)) {
          setStateData(dataArray);
        }
        if (isFinished && isFinished !== finished) {
          setFinished(isFinished);
          setIsAnalyzing(false);
        }
      })
    }
  }
  }, [finished, jobNames, stateData])

  useEffect(() => {
    if (jobNames.length > 0) {
      if (jobNames.length !== prevJobLength) { 
        setFinished(false);
        setPrevJobLength(jobNames.length);
        getStates();
        if (intervalID)
          window.clearInterval(intervalID)
        setIntervalID(window.setInterval(() => getStates(), 10000));
      }
    }
 }, [getStates, jobNames.length, intervalID, prevJobLength]);
  
  useEffect(() => {
    if (finished) {
      clearInterval(intervalID);
    }
  }, [intervalID, finished]);

  useEffect(() => {
    if (isAnalyzing && animationRef.current) {
      animationRef.current.play();
    } else if (!isAnalyzing && animationRef.current) {
      animationRef.current.stop();
    }
  }, [isAnalyzing]);

  useEffect(() => {
    console.log('stateData:', stateData);
  }, [stateData]);
  
  const getHeadings = () => {
    if (stateData?.[0]) {
      return Object.keys(stateData?.[0]);
    }
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_FILE_LIST':
        return { ...state, fileList: action.files }
      case 'SET_DROP_DEPTH':
        return { ...state, dropDepth: action.dropDepth }
      case 'SET_IN_DROP_ZONE':
        return { ...state, inDropZone: action.inDropZone };
      case 'ADD_FILE_TO_LIST':
        const isCsv = action.files[0]?.type === 'text/csv';
        return {
          ...state,
          fileList: isCsv ? state.fileList.concat(action.files) : state.fileList,
          error: isCsv ? '' : 'File type is not supported, please try again.'
        };
      default:
        return state;
    }
  };

  const [fileData, dispatchFileData] = React.useReducer(
    reducer, { dropDepth: 0, inDropZone: false, fileList: [], error: '' }
  );
  
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const isCsv = files.every(file => file.type === 'text/csv');
    if (!isCsv) {
      dispatchFileData({ type: 'SET_FILE_LIST', files: [] });
      dispatchFileData({ type: 'SET_ERROR', error: 'File type not supported. Please upload CSV files only.' });
    } else {
      dispatchFileData({ type: 'SET_FILE_LIST', files });
    }
  };

  const handleUpload = async () => { 
    if (!fileData.fileList.length) {
        console.log('No files to upload');
        return;
    }

    setIsAnalyzing(true);

    const transactionId = uuidv4();
    let uploadRequests = [];
    let count = 0;

    const intervalID = setInterval(() => {
        setProgress((count / fileData.fileList.length) * 100);
    }, 10);

    fileData.fileList.forEach(file => {
        const formData = new FormData();
        formData.append('file', file);

        uploadRequests.push(
            suggestionsService.run(formData)
            .then((res) => { 
              if (res) {
                console.log('Upload response:', res);
                setJobIds(prevIds => [...prevIds, res?.data?.id]);
                setJobNames(prevNames => [...prevNames, res?.data?.job_name]);
                ++count; 
                return res; 
              }
            })
        );
    });

    Promise.all(uploadRequests)
        .then(res => {
            console.log('Upload responses:', res);
        })
        .catch(err => {
            console.error('Upload error:', err);
        })
        .finally(() => {
            clearInterval(intervalID);
            setProgress(100);
        });
};

  const handleCancel = (row) => {
    stateData[stateData.indexOf(row)].isCancelling = true;
    setStateData([...stateData]);
    console.log(row)
    suggestionsService.cancel(row.id)
    .then(() => { 
      getStates();
    });
  }

  return (
    <>
      <div className="row align-items-center justify-content-center">
        {!(finished && stateData?.length) ? (
          0 && jobNames.length ? (
            <>
              <div className="col-12">
              {isAnalyzing && (
                <div>
                <div className="col-12 d-flex flex-column align-items-center">
                  <p className="h1 -r">Analyzing</p>
                  <a href="/results">
                    <p className="h4 color-gray my-20">
                      This process will take us a just a moment.
                    </p>
                  </a>
                </div>
              </div>
              )}
              </div>
            </>
          ) : (
            <></>
          )
        ) : (
          <div className="col-12">
            <div className="row my-50 justify-content-center">
              <div className="col-2">
                <img src={suggestion} alt="suggestion" />
              </div>
            </div>
            <div className="col-12 d-flex flex-column align-items-center">
              <p className="h1 -r">Finished!</p>
            </div>
          </div>
        )}
      </div>
      <div className="row mt-20 mb-50">
        {!jobNames.length ? (
          <></>
        ) : (
          <>
            <div className="col-12 col-lg-3 p-0">
              <button
                className="button -primary mt-20 mb-20 w-100"
                onClick={(e) => {
                  dragAndDropRef.current.scrollIntoView();
                }}
              >
                Add a csv
              </button>
            </div>
            {/* <Table theadData={getHeadings()} tbodyData={stateData} /> */}
            <table className="bg-white">
              <thead>
                <tr>
                  <th></th>
                  {/* <th>
                    <p className="d-flex align-items-center w-100">
                      Create time
                    </p>
                  </th>
                  <th>
                    <p className="d-flex align-items-center w-100">
                      Current State Time
                    </p>
                  </th>
                  <th>
                    <p className="d-flex align-items-center w-100 mr-minus-20">
                      Approximate analysis time
                    </p>
                  </th> */}
                  <th>
                    <p className="d-flex align-items-center w-100">Name</p>
                  </th>
                  <th>
                    <p className="d-flex align-items-center w-100">State</p>
                  </th>
                  <th>
                    <p className="d-flex align-items-center w-100 text-c">
                      Actions
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {stateData?.map((row, index) => {
                  return (
                    <tr key={index}>
                      <td></td>
                      {/* <td className="text">{row.createTime}</td>
                      <td className="text">{row.currentStateTime}</td>
                      <td className="text">
                        {finishedStates.includes(row.status)
                          ? "0 minutes"
                          : row.approximateAnalysisTime ?? "99 minutes"}
                      </td> */}
                      <td className="text">{row.filename}</td>
                      <td className="text d-flex align-items-center">
                        {!finishedStates.includes(row.status) ? (
                          <div className="m-0">
                            <Lottie
                              options={LoaderOptions}
                              height={35}
                              width={35}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        {row.status}
                      </td>
                      <td>
                        {finishedStates.includes(row.status) ? (
                          <></>
                        ) : row.isCancelling ? (
                          <Lottie
                            options={LoaderOptions}
                            height={35}
                            width={35}
                          />
                        ) : (
                          <button
                            className="button -text -red p-0"
                            onClick={() => handleCancel(row)}
                          >
                            Cancel process
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
        <div className="col-12 col-lg-10 mt-10 p-0">
          <div className="card">
            <p className="h4">
              Upload a file with the conversations, you can upload more than one
              file, the platform will then create requests about new <b>Intents</b>{" "}
              and <b>Entities</b>.
            </p>
          </div>
        </div>
        <div className="col-12 col-md-10 d-flex justify-content-center align-items-center">
          {progress && progress !== 100 ? (
            <ProgressBar
              now={progress}
              label={`${progress}%`}
              variant="success"
            />
          ) : (
            <></>
          )}
          <DragAndDrop
            ref={dragAndDropRef}
            fileData={fileData}
            dispatch={dispatchFileData}
            singleFile={false}
            handleUpload={handleUpload}
          ></DragAndDrop>
        </div>
        <div className="col-12 col-md-10 d-flex justify-content-center align-items-center">
          {fileData.error && <p style={{ color: 'red' }}>{fileData.error}</p>}
        </div>
      </div>
    </>
  );
}

export default AnalyzingComponent;
