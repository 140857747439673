import React, { useState, useCallback } from 'react';
import Header from '../../../Components/Header/headerView';
import Footer from '../../../Components/Footer/footer';
import Menu from '../../../Components/Menu/menu';
import NDSTooltip from '../../../Components/Tooltip/ndsTooltip';
import Paginator from '../../../Components/Paginator/paginator';
import { connect } from "react-redux";
import { useParams, Link, Navigate } from "react-router-dom";
import { useEffect } from "react";
import { Modal } from "bootstrap";
import usersService from '../../../Services/usersService';
import workspacesService from '../../../Services/workspacesService';
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../../Components/Loader/actions";
import user from '../../../app/slices/user/user';

const EditWorkspace = (props) => {
  const user = props.user;
  const [loadData, setLoadData] = useState(true);
  const [workspace, setWorkspace] = useState({});
  const [users, setUsers] = useState([]);

  const [workspaceName, setWorkspaceName] = useState("");

  const workspaceID = useParams().workspace_id;
  const [userToDelete, setUserToDelete] = useState(null);

  const dispatch = useDispatch();

  const getWorkspaceInfo = async () => {
    dispatch(startLoading());
    try{
      let params = {
        workspace_id: workspaceID,
      };
      let res = await workspacesService.collectOne(params);
      console.log(res.data)
      setWorkspace(res.data[0]);
    }
    catch(err){
      console.log(err);
    }
    setLoadData(false);
    dispatch(stopLoading());
  };

  const handleClearInput = (event) => {
    setWorkspaceName("");
  };

  const editWorkspace = async () => {
    if (workspaceName !== "" && workspaceName.length >= 3) {

      try{
        let params = {
          workspace_id: workspaceID,
          workspace_name: workspaceName
        }
        console.log("params", params)
        let res = await workspacesService.update(params);
        console.log(res);
        let modal = new Modal(document.getElementById("SucessfulEditionModal"));
        modal.show();
      }
      catch(err){
        console.log(err);
      }
    } else if (workspaceName.length < 3) {
      let modal = new Modal(document.getElementById("InvalidNameModal"));
      modal.toggle();
    } else {
      alert("Error");
    }
  };

  const deleteWorkspace = async () => {
    try{
      let params = {
        workspace_id: workspaceID,
      }
      console.log("params", params)
      let res = await workspacesService.delete(params);
      console.log(res);
      let modal = new Modal(document.getElementById("SuccesfullyDeletedModal"));
      modal.show();
    }
    catch(err){
      console.log(err);
    }
  };

  const reimportWorkspace = async () => {
    try{
      let params = {
        user_id: user.id,
        workspace_id: workspaceID,
      }
      console.log("params", params)
      let res = await workspacesService.reimportWorkspace(params);
      console.log(res);
      let modal = new Modal(document.getElementById("SuccesfullyReimportedModal"));
      modal.show();
    }
    catch(err){
      console.log(err);
    }
  };

  async function removeUser() {
    console.log("workspace name", workspace);
    let user = [];

    try{
      let params = {
        user_id: userToDelete.id,
      };
      let res = await usersService.collectOne(params);
      user = res;
      console.log("user", user);
    }
    catch(err){
      console.log(err);
    }
    let permissions = user.permissions;
    permissions = permissions.map(item => {
      return {
          roles: item.roles.map(role => role.value),
          workspace_id: item.workspace,
      };
    });
    permissions = permissions.filter(item => item.workspace_id !== workspaceID);
    
    let params = {
      is_admin: user.is_admin,
      user_id: user.id,
    };

    let body = {
      permissions: permissions,
    }
    console.log(params)

    try{
      let res = await usersService.update(params, body);
      console.log(res);
      var modal = new Modal(document.getElementById("savedModal"), {
        keyboard: false,
      });
      modal.show();
    }
    catch(err){
      console.log(err);
    }
  }

  useEffect(() => {
    if (loadData) {
      getWorkspaceInfo();
    }
  }, [loadData, users])

  return (

    <div className={`${props.className}`}>
            {/* // <!-- Modal saved--> */}
      <div
        className="modal fade"
        id="savedModal"
        tabIndex="1"
        aria-labelledby="savedModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <p className="h2" id="savedModalLabel">
                Saved
              </p>
              <button
                type="button"
                className="button-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body d-flex flex-column align-items-center justify-content-center">
              <p className="text">Your changes have been saved.</p>
            </div>
            <div className="modal-footer">
              <div className="row w-100">
                <div className="col-12 col-md-6 mb-10 d-flex flex-column align-items-center justify-content-center">
                  <button
                    type="button"
                    className="button -text"
                    data-bs-dismiss="modal"
                    onClick={() => window.location.reload(true)}
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // <!-- Modal saved--> */}
      <div
        className="modal fade"
        id="SuccesfullyDeletedModal"
        tabIndex="1"
        aria-labelledby="savedModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p className="h2" id="savedModalLabel">
                Deleted
              </p>
              <button
                type="button"
                className="button-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body d-flex flex-column align-items-center justify-content-center">
              <p className="text">The workspace has been deleted succesfully.</p>
            </div>
            <div className="modal-footer">
              <div className="row w-100">
                <div className="col-12">
                  <button
                    type="button"
                    className="button -primary"
                    data-bs-dismiss="modal"
                    onClick={() => window.location.href = "/workspacemanage"}
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // <!-- Modal reimported--> */}
      <div
        className="modal fade"
        id="SuccesfullyReimportedModal"
        tabIndex="1"
        aria-labelledby="savedModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p className="h2" id="savedModalLabel">
                Reimported
              </p>
              <button
                type="button"
                className="button-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body d-flex flex-column align-items-center justify-content-center">
              <p className="text">The workspace has been reimported succesfully.</p>
            </div>
            <div className="modal-footer">
              <div className="row w-100">
                <div className="col-12">
                  <button
                    type="button"
                    className="button -primary"
                    data-bs-dismiss="modal"
                    onClick={() => window.location.reload()}
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // <!-- Modal Edit Workspace --> */}
      <div
        className="modal fade"
        id="EditWorkspaceModal"
        tabIndex="1"
        aria-labelledby="EditWorkspaceModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p className="h2" id="EditWorkspaceModalLabel">
                Edit
              </p>
              <button
                type="button"
                className="button-close w-100"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClearInput}
              >
                X
              </button>
            </div>
            <div className="modal-body form-group">
              <input
                //   className="col-12 input-field"
                type="text"
                className="form-control"
                placeholder={workspace?.name}
                aria-describedby="basic-addon2"
                value={workspaceName}
                name="workspaceName"
                onChange={(event) => setWorkspaceName(event.target.value)}
              ></input>
            </div>
            <div className="modal-footer">
              <div className="row w-100">
                <div className="col-12 col-md-4 mb-10">
                  <button
                    className="button -text w-100"
                    data-bs-dismiss="modal"
                    onClick={handleClearInput}
                  >
                    CANCEL
                  </button>
                </div>
                <div className="col-12 col-md-8 mb-10">
                  <button
                    className="button -primary w-100"
                    data-bs-dismiss="modal"
                    onClick={editWorkspace}
                  >
                    EDIT WORKSPACE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // <!-- Modal Delete Workspace --> */}
      <div
        className="modal fade"
        id="DeleteWorkspaceModal"
        tabIndex="1"
        aria-labelledby="DeleteWorkspaceModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p className="h2" id="DeleteWorkspaceModalLabel">
                Delete Workspace
              </p>
              <button
                type="button"
                className="button-close w-100"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body">
              <p className="text">
                Are you sure you want to delete <b>{workspace?.name}</b> ? 
                This action only deletes the workspace in the database, you can always re-import it.
              </p>
            </div>
            <div className="modal-footer">
              <div className="row w-100">
                <div className="col-12 col-md-6 mb-10">
                  <button
                    className="button -text w-100"
                    data-bs-dismiss="modal"
                  >
                    CANCEL
                  </button>
                </div>
                <div className="col-12 col-md-6 mb-10">
                    <button 
                    className="button -warning w-100" 
                    data-bs-dismiss="modal"
                    onClick={() => deleteWorkspace()}
                    >
                      Delete Workspace
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // <!-- Modal Reimport Workspace --> */}
      <div
        className="modal fade"
        id="ReimportWorkspaceModal"
        tabIndex="1"
        aria-labelledby="DeleteWorkspaceModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p className="h2" id="DeleteWorkspaceModalLabel">
                Re-import Workspace
              </p>
              <button
                type="button"
                className="button-close w-100"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body">
              <p className="text">
                Are you sure you want to re-import <b>{workspace?.name}</b> ? This
                will restart the workspace with the information in the NLP Engine. The requests will be rebuilt.
              </p>
            </div>
            <div className="modal-footer">
              <div className="row w-100">
                <div className="col-12 col-md-6 mb-10">
                  <button
                    className="button -text w-100"
                    data-bs-dismiss="modal"
                  >
                    CANCEL
                  </button>
                </div>
                <div className="col-12 col-md-6 mb-10">
                    <button 
                    className="button -white w-100" 
                    data-bs-dismiss="modal"
                    onClick={() => reimportWorkspace()}
                    >
                      Re-import Workspace
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // <!-- Modal Remove User --> */}
      <div
        className="modal fade"
        id="RemoveUserModal"
        tabIndex="1"
        aria-labelledby="RemoveUserModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p className="h2" id="RemoveUserModalLabel">
                Remove User
              </p>
              <button
                type="button"
                className="button-close w-100"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body">
              <p className="text">
                Are you sure you want to remove <b>{userToDelete?.first_name}</b> from 
                <b> {workspace?.name}</b>? The user will no longer be able to work or
                see this workspace.
              </p>
            </div>
            <div className="modal-footer">
              <div className="row w-100">
                <div className="col-12 col-md-6 mb-10">
                  <button
                    className="button -text w-100"
                    data-bs-dismiss="modal"
                  >
                    CANCEL
                  </button>
                </div>
                <div className="col-12 col-md-6 mb-10">
                  <button className="button -warning w-100" onClick={removeUser}  data-bs-dismiss="modal">REMOVE USER</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // <!-- Modal Sucessful Edition --> */}
      <div
        className="modal fade"
        id="SucessfulEditionModal"
        tabIndex="1"
        aria-labelledby="SucessfulEditionModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p className="h2" id="SucessfulEditionModalLabel">
                Sucessful Edition
              </p>
              <button
                type="button"
                className="button-close w-100"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body">
              <p className="text">
                The workspace <b>{workspace?.name}</b> was edited successfully.
              </p>
            </div>
            <div className="modal-footer">
              <div className="row w-100">
                <button
                  className="button -primary w-100"
                  data-bs-dismiss="modal"
                  onClick={() => window.location.reload(true)}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal Invalid Name */}
      <div
        className="modal fade"
        id="InvalidNameModal"
        tabIndex="1"
        aria-labelledby="InvalidNameModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p className="h2" id="InvalidNameModalLabel">
                Invalid Name
              </p>
              <button
                type="button"
                className="button-close w-100"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body">
              <p className="text">
                The workspace name must be between 3 and 50 characters.
              </p>
            </div>
            <div className="modal-footer">
              <div className="row w-100">
                <button
                  className="button -primary w-100"
                  data-bs-dismiss="modal"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Header />
      <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
        <div className="w-10">
          <Menu activeTab={7} />
        </div>
        <div className="container px-20 mt-50">
          <div className="row mt-20 mb-10">
            <p className="h1 -r">Edit workspace</p>
          </div>
          {loadData ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
          <>
          <div className="d-flex">
            <div className="col-12 row justify-content-between">
              <div className="col-12 col-lg-6 col-md-6 mt-100 mb-100">
                <div className="card-w d-flex flex-column p-40 mx-10">
                  <div className="row justify-content-between mx-0 mb-20">
                    <div className="col-10 p-0">
                      <p className="h3">{workspace?.name}</p>
                    </div>
                    <div className="col-2 p-0">
                      <p className="text-e">
                        <button
                          className="-text color-blue justify-content-start w-100"
                          href="EditWorkspace"
                          data-bs-toggle="modal"
                          data-bs-target="#EditWorkspaceModal"
                        >
                          <NDSTooltip
                            icon="fa-solid fa-pencil"
                            target="pencil"
                            tooltipMessage="Edit"
                            placement="bottom"
                          ></NDSTooltip>
                          {/* <i className="fa-solid fa-pencil color-blue"></i> */}
                        </button>
                      </p>
                    </div>
                  </div>
                  <div className="row justify-content-between">
                    <div className="col-9">
                      <p className="text mb-10">
                        <i className="fa-solid fa-users color-gray"></i>
                        Users
                      </p>
                      <p className="text mb-10">
                        <i className="fa-solid fa-code-branch color-gray"></i>
                        Intents
                      </p>
                      <p className="text mb-10">
                        <i className="fa-solid fa-key color-gray"></i>
                        Entities
                      </p>
                      <p className="text">
                        <i className="fa-solid fa-star color-gray"></i>
                        Categories
                      </p>
                    </div>
                    <div className="col-3">
                      <p className="text mb-10">{workspace?.users}</p>
                      <p className="text mb-10">{workspace?.intents}</p>
                      <p className="text mb-10">{workspace?.entities}</p>
                      <p className="text mb-10">{workspace?.categories}</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Este sólo debe de aparecer si es un SUPER admin */}
              <div className="col-12 col-lg-3 col-md-6 mt-20">
                <button
                  className="button -primary w-100 mb-10"
                  onClick={() => {}}
                  data-bs-toggle="modal"
                  data-bs-target="#EditWorkspaceModal"
                >
                  EDIT WORKSPACE NAME
                </button>
                <button
                  className="button -white w-100 mb-10"
                  onClick={() => {}}
                  data-bs-toggle="modal"
                  data-bs-target="#ReimportWorkspaceModal"
                >
                  RE-IMPORT WORKSPACE
                </button>
                <button
                  className="button -warning w-100"
                  data-bs-toggle="modal"
                  data-bs-target="#DeleteWorkspaceModal"
                >
                  DELETE WORKSPACE
                </button>
              </div>
            </div>
          </div>
          </>)}
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    workspace: state.workspace,
    user: state.user,
  };
};

export default connect(mapStateToProps)(EditWorkspace);
