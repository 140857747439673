import { createSlice } from "@reduxjs/toolkit";

export const workspaceSlice = createSlice({
  name: "workspace",
  initialState: {
    id: "",
    name: "",
    value: "",
    roles: [],
    organization: "",
    actions: [],
    is_admin: false,
    credentials: [],
    availableWorkspaces: [],
    organizations: [],
  },
  reducers: {
    setWorkspace: (state, action) => {
      //console.log("To workspace", action.payload);
      state.id = action.payload;
    },
    setActions: (state, action) => {
      if (action.payload === undefined) {
        return;
      }
      state.actions = action.payload;
    },
    setRoles: (state, action) => {
      if (action.payload === undefined) {
        return;
      }
      if (action.payload.length === 0) {
        state.roles = ["Viewer"];
      } else {
        state.roles = action.payload;
      }
    },
    setCredentials: (state, action) => {
      if (action.payload === undefined) {
        return;
      }
      if (!action.payload.is_super_admin) {
        state.organization = action.payload.organizations[0].organization;
        let workspace = null;
        for (let i = 0; i < action.payload.organizations.length; i++) {
          if (action.payload.organizations[i].permissions.length > 0) {
            workspace = action.payload.organizations[i].permissions[0];
            break;
          }
        }
        state.id = workspace.workspace_id;
        state.credentials = action.payload.organizations[0].permissions;
        state.is_admin = action.payload.organizations[0].is_admin;
        let roles = workspace.roles;
        console.log("Roles", roles);
        if (action.payload.organizations[0].is_admin) {
          roles = [...roles, "Admin"];
        }
        state.roles = roles;
        let organizations = [];
        for (let i = 0; i < action.payload.organizations.length; i++) {
          organizations.push({id: action.payload.organizations[i].organization});
        }
        state.organizations = organizations;
      }
    },
    setValue: (state, action) => {
      state.value = action.payload.id;
      state.name = action?.payload?.name;
    },
    setAvailableWorkspaces: (state, action) => {
      state.availableWorkspaces = action.payload;
    },
    setOrganizationSlice: (state, action) => {
      if (action.payload === undefined) {
        return;
      }
      state.organization = action.payload.organization_to_switch;
      if (action.payload.is_super_admin) {

        return;
      }
      // Set Credentials
      const organizations = action.payload.organizations;
      let workspace = null;
      let organization_credentials = null;
      let is_admin = false;
      for (let i = 0; i < organizations.length; i++) {
        if (organizations[i].organization === action.payload.organization_to_switch) {
          console.log("Organization", organizations[i]);
          workspace = organizations[i].permissions[0] ?? [];
          organization_credentials = organizations[i].permissions;
          is_admin = organizations[i].is_admin;
          break;
        }
      }
      console.log("workspace", workspace)
      let roles = [];
      if (workspace.length !== 0) {
        roles = workspace.roles;
      }
      if (is_admin) {
        roles = [...roles, "Admin"];
      }
      console.log("Workspace", workspace);
      console.log("Organization Credentials", organization_credentials);
      state.id = workspace.workspace_id;
      state.credentials = organization_credentials;
      state.roles = roles;
      state.is_admin = is_admin;
    },
    setOrganizationsSlice: (state, action) => {
      state.organizations = action.payload;
    },
    logOut: (state) => {
      state.id = "";
      state.name = "";
      state.value = "";
      state.roles = [];
      state.organization = "";
      state.actions = [];
      // @ts-ignore
      state.credentials = {};
      state.availableWorkspaces = [];
      state.organizations = [];
      state.is_admin = false;
    },
  },
});

export const {
  setWorkspace,
  setRoles,
  setCredentials,
  setValue,
  setAvailableWorkspaces,
  setActions,
  logOut,
  setOrganizationSlice,
  setOrganizationsSlice,
} = workspaceSlice.actions;


export default workspaceSlice.reducer;
