import Header from '../../../Components/Header/headerView';
import Footer from '../../../Components/Footer/footer';
import Menu from '../../../Components/Menu/menu';
import DragAndDrop from '../../../Components/DragAndDrop/dragDrop';
import { CsvModal } from '../../../Utils/modals';

export const CreateObjectCSVScreen = ({_this}) => {
return (
    <div className={`${_this.props.className}`}>
    <CsvModal />
    {/* // <!-- Modal error--> */}
    <Header/>
    <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
      <div className="w-10">
        <Menu activeTab={_this.type === 'intent' ? 2 : 3}/>
      </div>
      <div className="container px-20 mb-50">
        <div className="my-50">
          <p className="h1 -r">Create {_this.type[0].toUpperCase() + _this.type.slice(1,_this.type.length)} from CSV</p>
        </div>
        
        <div className="row my-50">
          <div className='col-12 col-md-10 col-lg-5'>
          <DragAndDrop fileData={_this.fileData} dispatch={_this.dispatchFileData} singleFile={true} handleUpload={_this.handleSubmission}></DragAndDrop>
          </div>
        </div>        
      </div>
    </div>
    <Footer/>
  </div>
)}