import React, { useState, useCallback } from 'react';
import Header from '../../../Components/Header/headerView';
import Footer from '../../../Components/Footer/footer';
import Menu from '../../../Components/Menu/menu';
import NDSTooltip from '../../../Components/Tooltip/ndsTooltip';
import Paginator from '../../../Components/Paginator/paginator';
import { connect } from "react-redux";
import { useParams, Link, Navigate } from "react-router-dom";
import { useEffect } from "react";
import { Modal } from "bootstrap";
import usersService from '../../../Services/usersService';
import workspacesService from '../../../Services/workspacesService';
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../../Components/Loader/actions";

const EditOrganization = (props) => {
  const [loadData, setLoadData] = useState(true);
  const [workspace, setWorkspace] = useState({});
  const [users, setUsers] = useState([]);

  const [workspaceName, setWorkspaceName] = useState("");

  const workspaceID = useParams().workspace_id;
  const [userToDelete, setUserToDelete] = useState(null);

  const dispatch = useDispatch();

  return (

    <div className={`${props.className}`}>
            {/* // <!-- Modal saved--> */}
      <div
        className="modal fade"
        id="savedModal"
        tabIndex="1"
        aria-labelledby="savedModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <p className="h2" id="savedModalLabel">
                Saved
              </p>
              <button
                type="button"
                className="button-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body d-flex flex-column align-items-center justify-content-center">
              <p className="text">Your changes have been saved.</p>
            </div>
            <div className="modal-footer">
              <div className="row w-100">
                <div className="col-12 col-md-6 mb-10 d-flex flex-column align-items-center justify-content-center">
                  <button
                    type="button"
                    className="button -text"
                    data-bs-dismiss="modal"
                    onClick={() => window.location.reload(true)}
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // <!-- Modal Delete Workspace --> */}
      <div
        className="modal fade"
        id="deleteOrganizationModal"
        tabIndex="1"
        aria-labelledby="DeleteWorkspaceModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p className="h2" id="DeleteWorkspaceModalLabel">
                Delete Organization
              </p>
              <button
                type="button"
                className="button-close w-100"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body">
              <p className="text">
                Are you sure you want to delete this organization? This action cannot be undone.
              </p>
            </div>
            <div className="modal-footer">
              <div className="row w-100">
                <div className="col-12 col-md-6 mb-10">
                  <button
                    className="button -text w-100"
                    data-bs-dismiss="modal"
                  >
                    CANCEL
                  </button>
                </div>
                <div className="col-12 col-md-6 mb-10">
                    <button className="button -warning w-100" onClick={() => window.location.reload()}>
                      Delete Organization
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Header />
      <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
        <div className="w-10">
          <Menu activeTab={9} />
        </div>
        <div className="container px-20 mt-50">
          <div className="row mt-20 mb-10">
            <p className="h1 -r">Edit organization</p>
          </div>
          <div className="col-3 mt-40">
            <button
              className="button -warning"
              data-bs-toggle="modal"
                  data-bs-target="#deleteOrganizationModal"
            >
              DELETE ORGANIZATION
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    workspace: state.workspace,
    user: state.user,
  };
};

export default connect(mapStateToProps)(EditOrganization);
