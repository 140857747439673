import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'bootstrap';
import { connect } from 'react-redux';
import { hideModals } from "../../../Utils/modals";
import { intentName, intentDescription, intentExamples } from '../../../Utils/regexValidations';
import { CreateIntentScreen } from './createIntentScreen';
import requestsService from '../../../Services/requestsService';
import intentsService from '../../../Services/intentsService';
import { useNavigate } from 'react-router-dom';

const CreateIntent = (props) => {
  const workspace = props.workspace.id;
  const user = props.user.id;
  const role = props.user.role;
  const navigate = useNavigate();
  
  const [loadData, setLoadData] = useState(true);
  const [activeTab, setActiveTab] = useState("1");
  const [inputName, setInputName] = useState("");
  const [inputExample, setInputExample] = useState("");
  const [exampleEdit, setExampleEdit] = useState(false);
  const [listExamples, setListExamples] = useState([]);
  const [inputDescription, setInputDescription] = useState("");
  const [intentLinkedEntities, setIntentLinkedEntities] = useState([]);
  const [selectedLinkedEntities, setSelectedLinkedEntities] = useState([]);
  const [linkedEntities, setLinkedEntities] = useState([]);
  const [entitiesOptions, setEntitiesOptions] = useState([]);
  const [listaIDs, setListaIDs] = useState([]);
  const [addedOn, setAddedOn] = useState([]);
  const [adminNextStatus, setAdminNextStatus] = useState("Select");
  const [errorMessage, setErrorMessage] = useState("Something went wrong. Please try again.");
  const [validationError, setValidationError] = useState(false);
  const [nameError, setNameError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [createIntentPermission, setCreateIntentPermission] = useState(props?.workspace?.actions?.intents?.create ?? false);
  const [errorUniqueName, setErrorUniqueName] = useState(false);
  const [nameUsedError, setNameUsedError] = useState("");

  //Validations
  const namePrefixTest = /^(?!sys-|sys_)/; //Name can not begin with the prefix "sys-" or "luis-"
  const emptyTest = /(?!\s\s)/; //this can not be only spaces or empty, cannot have consecutive spaces

  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined
      );
  const required = (value) => (value ? undefined : "Required");
  const maxLength = (max) => (value) =>
    value && value.length > max
      ? `Must be ${max} characters or less`
      : undefined;
  const name = (value) =>
    value && !intentName.test(value) ? "Invalid name" : undefined;
  const namePrefix = (value) =>
    value && !namePrefixTest.test(value) ? "Invalid name" : undefined;
  const description = (value) =>
    value && !intentDescription.test(value) ? "Invalid description" : undefined;
  const empty = (value) =>
    value && !emptyTest.test(value) ? "Invalid value" : undefined;
  const example = (value) =>
    value && !intentExamples.test(value) ? "Invalid example" : undefined;
  const exampleExists = (value) =>
    value && isExampleInList(value) ? "Example already exists" : undefined;
  const validateUniqueName = () => {
    return errorUniqueName || (inputName === nameUsedError) ? "Intent already exists" : undefined;
  };

  const handleChangeError = (event, field, initial) => {
    let newValue = "";
    if(initial){
      newValue = event;
    }
    else{
      newValue = event.target.value;
    }
    if (field === "name"){
      const error = composeValidators(
        required,
        name,
        maxLength(64),
      )(newValue);
      setNameError(error);
      console.log("Error:", error)
    }
    else if (field === "description"){
      const error = composeValidators(
        description,
        maxLength(128)
      )(newValue);
      setDescriptionError(error);
    }
  };

  useEffect(() => {
    if (nameError || descriptionError || listExamples.length === 0 || inputName === "") {
      setValidationError(true);
      console.log("hay error");
    }
    else{
      setValidationError(false);
      console.log("no hay error");
    }
  }, [nameError, descriptionError, listExamples, inputName]);

  useEffect(() => {
    if (errorUniqueName) {
      if (nameUsedError !== inputName) {
        setErrorUniqueName(false);
      }
    }
  }, [inputName]);

  //Check if the example is already in the list
  const isExampleInList = (example) => {
    let found = false;
    listExamples.forEach((item) => {
      if (item.toLowerCase() === example.toLowerCase()) {
        found = true;
      }
    });
    return found;
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  function objectToChanges(object) {

  }

  async function createIntent() {
    hideModals();
    let modal = new Modal(document.getElementById("createModal"));

    let changes = [
      {
        "field": "name",
        "operation": "create",
        "value": inputName
      },
      {
        "field": "description",
        "operation": "create",
        "value": inputDescription
      },
      ...listExamples.map(example => ({
        field: "examples",
        operation: "create",
        value: example,
      }))
    ]

    let body = {
      object_type: "intent",
      changes: changes,
    }

    let params = {
      workspace_id: workspace,
      user_id: user,
    };

    console.log(params);
    console.log(body);

    //Validate if intent does not already exist
    //const nameExists = await checkName(inputName) ?? false;
    //console.log(nameExists);
    try{
      let res = await requestsService.create(params, body);
      console.log(res);
      modal.show();
    }
    catch(err){
      if (err?.response?.data?.detail === "400: Object already exists.") {
        setErrorUniqueName(true);
        setNameUsedError(inputName)
      }
      console.log(err);
    }
  }

  //This function checks if intent exists already
  async function checkName(inputName){

    try{
      let params = {
        workspace_id: workspace,
        intent_name: inputName
      }
      let res = await intentsService.exists(params);
      console.log(res);
      return res.data.exists;
    }
    catch(err){
      console.log(err);
    }
}

  const addExample = () => {
    setInputExample("");
    setListExamples((prev) => [inputExample, ...prev]);
  };

  const removeExample = (example) => {
    setListExamples(listExamples.filter((item) => item !== example));
  };

  function editExample(example, previous) {
    let tempExamples = listExamples;
    tempExamples.forEach((item, index) => {
      if (item === previous) {
        tempExamples[index] = example;
        setInputExample("");
        setExampleEdit(false);
      }
    }, tempExamples);
    setListExamples(tempExamples);
  }

  function cancelEdition() {
    setInputExample("");
    setExampleEdit(false);
  }

  function handleEdition(e, example) {
    e.preventDefault();
    setInputExample(example);
    setExampleEdit(example);
  }

  const addLinkedEntity = () => {
    if (selectedLinkedEntities.length > 0) {
      setLinkedEntities([...linkedEntities, ...selectedLinkedEntities]);
      setSelectedLinkedEntities([]);
      let newEntitiesOptions = entitiesOptions.filter(
        (entity) => !selectedLinkedEntities.includes(entity)
      );
      setEntitiesOptions(newEntitiesOptions);
    }
  };

  const removeLinkedIntent = (index) => {
    if (index > -1) {
      setEntitiesOptions([...entitiesOptions, linkedEntities[index]]);
      linkedEntities.splice(index, 1);
      setLinkedEntities([...linkedEntities]);
    }
  };

  function createModal() {
    return (
      <div
        className="modal fade"
        id="createModal"
        tabIndex="1"
        aria-labelledby="createModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p className="h2" id="createModalLabel">
                Intent request created
              </p>
              <button
                type="button"
                className="button-close w-100"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body d-flex flex-column align-items-center justify-content-center">
              <p className="text">Intent request created succesfully!</p>
            </div>
            <div className="modal-footer">
              <div className="row w-100">
                <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="row w-100">
                <button className="button -primary" data-bs-dismiss="modal" onClick={() => (navigate("/requests", { state: { tab: '2' } }))}>
                  CONTINUE
                </button>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }


  useEffect(() => {
    if (loadData) {
      setLoadData(false);
    }
  }, [loadData]);

  return <CreateIntentScreen _this={{
    props,
    workspace,
    activeTab,
    toggle,
    loadData,
    setLoadData,
    inputName,
    setInputName,
    inputExample,
    setInputExample,
    exampleEdit,
    setExampleEdit,
    listExamples,
    setListExamples,
    inputDescription,
    setInputDescription,
    intentLinkedEntities,
    setIntentLinkedEntities,
    selectedLinkedEntities,
    setSelectedLinkedEntities,
    linkedEntities,
    setLinkedEntities,
    entitiesOptions,
    setEntitiesOptions,
    listaIDs,
    setListaIDs,
    addedOn,
    setAddedOn,
    adminNextStatus,
    setAdminNextStatus,
    errorMessage,
    setErrorMessage,
    validationError,
    setValidationError,
    nameError,
    setNameError,
    descriptionError,
    setDescriptionError,
    handleChangeError,
    composeValidators,
    required,
    maxLength,
    name,
    namePrefix,
    description,
    empty,
    example,
    exampleExists,
    isExampleInList,
    addExample,
    removeExample,
    editExample,
    cancelEdition,
    handleEdition,
    addLinkedEntity,
    removeLinkedIntent,
    createIntent,
    createModal,
    createIntentPermission,
    validateUniqueName,
    errorUniqueName,
    nameUsedError,
  }} />
};

  const mapStateToProps = (state) => {
    return {
      workspace: state.workspace,
      user: state.user,
    }
  }

export default connect(mapStateToProps)(CreateIntent);
