import React, { useState, useEffect, useRef } from "react";
import { Modal } from "bootstrap";
import Select from "react-select";
import { connect } from "react-redux";
import { hideModals } from "../../../Utils/modals";
import { categoryName, categoryDescription } from "../../../Utils/regexValidations";
import { CreateCategoryScreen } from "./createCategoryScreen";
import * as dateService from "../../../Utils/format/date";
import entitiesService from "../../../Services/entitiesService";
import requestsService from "../../../Services/requestsService";

const CreateCategory = (props) => {
  const workspace = props.workspace.id;
  const user = props.user.id;
  const role = props.user.role;

  const [loadData, setLoadData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [entities, setEntities] = useState(null);
  const [selectedEntity, setSelectedEntity] = useState([]);
  const [editingIndex, setEditingIndex] = useState(undefined);
  const [addedOn, setAddedOn] = useState([]);
  const [categoryNameC, setCategoryNameC] = useState("");
  const [newCategory, setNewCategory] = useState(false);
  const [categoryDescriptionC, setCategoryDescription] = useState("");
  const [inputDescription, setInputDescription] = useState("");
  const [intentOptions, setIntentOptions] = useState([]);
  const [selectedIntents, setSelectedIntents] = useState([]);
  const [linkedIntents, setLinkedIntents] = useState([]);
  const [intentsButton, setIntentsButton] = useState("-inactive");
  const [checked, setChecked] = useState(new Set([]));
  const [allChecked, setAllChecked] = useState(false);
  const deleteRef = useRef({});
  const synonymInputRef = useRef();
  const [adminNextStatus, setAdminNextStatus] = useState("Select");
  const [errorMessage, setErrorMessage] = useState("Something went wrong. Please try again.");
  const [validationError, setValidationError] = useState(false);
  const [nameError, setNameError] = useState(null);
  const [nameEnt, setNameEntError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [errorUniqueName, setErrorUniqueName] = useState(false);
  const [nameUsedError, setNameUsedError] = useState("");

  //Validations
   
  const nameTest = /^[a-zA-Z0-9_\-\.áéíóúÁÉÍÓÚüÜ]+$/; //Name with no spaces, just alphanumeric, underscore hyphen and dot
  const namePrefixTest = /^(?!sys-|sys_)/; //Name can not begin with the prefix "sys-" or "luis-"

  function duplicateEntityModal() {
    return (
      <div
        className="modal fade"
        id="duplicateEntityModal"
        tabIndex="1"
        aria-labelledby="errorModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p className="h2" id="errorModalLabel">
                Intent not created
              </p>
              <button
                type="button"
                className="button-close w-100"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body">
              <p className="text"> You tried to create an intent that already exists</p>
            </div>
            <div className="modal-footer">
              <div className="row w-100">
                <div className="col-12 col-md-6 mb-10">
                  <button
                    className="button -primary w-100"
                    data-bs-dismiss="modal"
                  >
                    RETURN
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }


  const composeValidators =
    (...validators) =>
    (value) =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined
      );
  const required = (value) => (value ? undefined : "Required");
  const maxLength = (max) => (value) =>
    value && value.length > max
      ? `Must be ${max} characters or less`
      : undefined;
  const name = (value) =>
    value && !categoryName.test(value) ? "Invalid name" : undefined;
  const namePrefix = (value) =>
    value && !namePrefixTest.test(value) ? "Invalid name" : undefined;
  const description = (value) =>
    value && !categoryDescription.test(value) ? "Invalid description" : undefined;
  const minLength = (value) => {
    return value && !/^.{4,}$/.test(value)
      ? "Must have at least 4 characters"
      : undefined;
  };
  const validateUniqueName = () => {
    return errorUniqueName || (categoryNameC === nameUsedError) ? "Category already exists" : undefined;
  };

  const handleChangeError = (event, field, initial) => {
    let newValue = "";
    if(initial){
      newValue = event;
    }
    else{
      newValue = event.target.value;
    }
    console.log("text ",newValue);
    if (field === "name"){ 
      const error = composeValidators(
      required,
      namePrefix,
      maxLength(64),
      minLength
      )(newValue);
      setNameEntError(error);
      console.log("Error:", error)
    }
    else if (field === "description"){
      const error = composeValidators(
        maxLength(128)
      )(newValue);
      setDescriptionError(error);
    }
  };

  useEffect(() => {
    if (nameError || descriptionError || nameEnt || categoryNameC === "") {
      setValidationError(true);
      console.log("hay error");
    }
    else{
      setValidationError(false);
      console.log("no hay error");
    }
  }, [nameError, descriptionError, nameEnt, categoryNameC, selectedEntity]);

  useEffect(() => {
    if (errorUniqueName) {
      if (nameUsedError !== categoryNameC) {
        setErrorUniqueName(false);
      }
    }
  }, [categoryNameC]);

  const selectAdapter = ({meta, ...rest }) => (
    <>
      <Select
        {...rest}
        id="entity"
        className="creatable-select w-100"
        placeholder="Select entities"
        required
        isClearable
        isMulti
        options={entities}
        value={selectedEntity}
        onChange={(e) => [setSelectedEntity(e), handleChangeError(e, "name", true),]}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,

          control:
            meta?.error && meta?.touched && nameError
              ? (base, state) => ({
                  ...base,
                  borderColor: "#dc3545",
                  boxShadow: "none",
                  "&:hover": {
                    borderColor: "#dc3545",
                  },
                })
              : (base, state) => ({
                  ...base,
                  borderColor: "#ced4da",
                  boxShadow: "none",
                  "&:hover": {
                    borderColor: "#ced4da",
                  },
                }),
          colors: {
            ...theme.colors,
            primary25: "#e9ecef",
            primary: meta?.error && meta?.touched && nameError ? "#dc3545" : "#007bff",
            neutral90: meta?.error && meta?.touched && nameError ? "#dc3545" : "#495057",
          },
          height: "100%",
        })}
      />
      <label
        htmlFor="entity"
        data-toggle="tooltip"
        title="Category names must be unique and can only contain alphanumeric characters, underscores, hyphens, and dots. Maximum length is 64 characters."
        style={meta?.error && meta?.touched && nameError ? { color: "red" } : null}
      >
        {meta.error && meta.touched && nameError ? (
          <span className="error">
            <i className="fas fa-exclamation-circle fa-sm"></i>
            {meta.error}
          </span>
        ) : (
          "Entities"
        )}
      </label>
    </>
  );

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  //Get entities
  async function getEntities() {
    console.log("getEntities", workspace);

    let params = {
      workspace_id: workspace,
    };

    try{
    let res = await entitiesService.collectAll(params);
      if (res.status === 200) {
        if (res.data.entities.length > 0) {
          createOptions(res.data.entities);
        }
        else {
          setEntities([]);
        }
      }
      console.log("catgcall", res.data);
      setLoadData(false);
    }
    catch (err) {
      console.log(err);
    }

  }

  const createOptions = (entities) => {
    let options = [];
    for (var i = 0; i < entities.length; i++) {
      options.push({
        value: entities[i].id,
        label: entities[i].name,
      });
    }
    console.log("options", options);
    setEntities(options);
  };

  async function createCategory() {
    setLoading(true);

    let params = {
      workspace_id: workspace,
      user_id: user,
    };

    let changes = [
      {
        "field": "name",
        "operation": "create",
        "value": categoryNameC
      },
      {
        "field": "description",
        "operation": "create",
        "value": inputDescription
      },
      ...selectedEntity.map(entity => ({
        field: "entities",
        operation: "create",
        value: entity.value,
      }))
    ]

    let body = {
      object_type: "category",
      changes: changes,
    }


    console.log("body", body);
    console.log("params", params);
    try{
      let res = await requestsService.create(params, body);
      console.log(res);
      if (res.status === 200) {
        setLoading(false);
        var modal = new Modal(document.getElementById("createModal"), {
          keyboard: false,
        });
        modal.show();
      }
    }
    catch (err) {
      if (err?.response?.data?.detail === "400: Object already exists.") {
        setErrorUniqueName(true);
        setNameUsedError(categoryNameC)
      }
      console.log(err);
    }
  }

  useEffect(() => {
    if (loadData) {
      getEntities();
    }
    if (selectedEntity !== null && selectedEntity.value === 0) {
      setNewCategory(true);
    } else {
      setNewCategory(false);
    }
  }, [loadData, selectedEntity]);

  return (
    <CreateCategoryScreen
      _this={{
        props,
        loadData,
        loading,
        activeTab,
        entities,
        selectedEntity,
        editingIndex,
        addedOn,
        categoryNameC,
        newCategory,
        categoryDescriptionC,
        inputDescription,
        selectedIntents,
        linkedIntents,
        intentsButton,
        checked,
        allChecked,
        deleteRef,
        synonymInputRef,
        adminNextStatus,
        errorMessage,
        validationError,
        nameError,
        nameEnt,
        descriptionError,
        dateService,
        handleChangeError,
        composeValidators,
        required,
        maxLength,
        name,
        namePrefix,
        description,
        minLength,
        selectAdapter,
        toggle,
        createCategory,
        duplicateEntityModal,
        setCategoryNameC,
        setInputDescription,
        validateUniqueName,
        errorUniqueName,
        nameUsedError,
      }}
    />
  );
  
};

const mapStateToProps = (state) => {
  return {
    workspace: state.workspace,
    user: state.user,
  };
};

export default connect(mapStateToProps)(CreateCategory);
