import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import  user  from './slices/user/user';
import workspace from './slices/workspaces/workspace';
import { intentsApiSlice } from './slices/intents-api-slice';
import { entitiesApiSlice } from './slices/entities-api-slice';
import { conflictsApiSlice } from './slices/conflicts-api-slice';
import loadingReducer from '../Components/Loader/loadingReducer';
import loadingRequestReducer from '../Components/RequestLoader/loadingReducer';

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2,
    whitelist: ['user', 'workspace', 'loading']
};

const rootReducer = combineReducers({
    user,
    workspace,
    loading: loadingReducer,
    loadingRequest: loadingRequestReducer,
    [intentsApiSlice.reducerPath]: intentsApiSlice.reducer,
    [entitiesApiSlice.reducerPath]: entitiesApiSlice.reducer,
    [conflictsApiSlice.reducerPath]: conflictsApiSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk].concat(
        [
            intentsApiSlice.middleware,
            conflictsApiSlice.middleware,
            entitiesApiSlice.middleware,
        ]
    ),
});

export const persistor = persistStore(store);
