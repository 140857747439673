import React, { useState, useEffect } from 'react';
import Header from '../../../../Components/Header/headerView';
import Footer from '../../../../Components/Footer/footer';
import Menu from '../../../../Components/Menu/menu';
import Paginator from '../../../../Components/Paginator/paginator';
import { Modal } from "bootstrap";
import { connect } from "react-redux";
import workspacesService from '../../../../Services/workspacesService';
import Select from "react-select";
import { Form, Field } from "react-final-form";
import { UpdatedNlpModal, UpdateNlpModal, DeleteNlpModal, DeletedNlpModal } from '../../../../Utils/modals';
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../../../Components/Loader/actions";
import { useParams } from "react-router-dom";

const EditNlp = props => {
  const user = props.user;
  const [nlpInstance, setNlpInstance] = useState({});
  const [name, setName] = useState("");
  const [key, setKey] = useState("");
  const [nlpUrl, setNlpUrl] = useState("");
  const [nlpVersion, setNlpVersion] = useState("");
  const [nlpEngine, setNlpEngine] = useState("");

  const nlpID = useParams().nlp_id;
  const dispatch = useDispatch();

  const getNLPInstance = async () => {
    dispatch(startLoading());
    let params = {
      nlp_instance_id: nlpID
    };
    let res = await workspacesService.getNlpInstance(params);
    if (res.status === 200) {
      console.log(res.data);
      setNlpInstance(res.data);
      setName(res.data.name);
      setKey(res.data.key);
      setNlpUrl(res.data.nlp_url);
      setNlpVersion(res.data.nlp_version);
      setNlpEngine(res.data.engine);
    }
    dispatch(stopLoading());
  };

  useEffect(() => {
    getNLPInstance();
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const successModal = new Modal(document.getElementById("updatedNlpModal"));

    let changes = {};

    if (name !== nlpInstance.name) {
      changes.name = name;
    }
    if (key !== nlpInstance.key) {
      changes.key = key;
    }
    if (nlpUrl !== nlpInstance.nlp_url) {
      changes.nlp_url = nlpUrl;
    }
    if (nlpVersion !== nlpInstance.nlp_version) {
      changes.nlp_version = nlpVersion;
    }
    if (nlpEngine !== nlpInstance.engine) {
      changes.engine = nlpEngine;
    }

    const body = changes;

    const params = {
      nlp_instance_id: nlpID
    };

    console.log(body);
    try {
      let res = await workspacesService.editNlpInstance(params, body);
      console.log(res);
      successModal.show();
    } catch (error) {
      console.log(error);
    }
  };    

  const handleDelete = async (e) => {
    e.preventDefault();
    const successModal = new Modal(document.getElementById("deletedNlpModal"));
    const params = {
      nlp_instance_id: nlpID
    };
    try {
      let res = await workspacesService.deleteNlpInstance(params);
      console.log(res);
      successModal.show();
    } catch (error) {
      console.log(error);
    }
  };    

  return (
    <div>
      <UpdateNlpModal handleUpdate={handleSubmit}/>
      <UpdatedNlpModal />
      <DeleteNlpModal handleDelete={handleDelete}/>
      <DeletedNlpModal />

      <Header />
      <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
        <div className="w-10">
          <Menu activeTab={7} />
        </div>
        <div className="container px-20 mt-50">
          <div className="row mt-20 mb-200">
            <div className="col-12">
              <p className="h1 -r">Edit NLP Engine instance</p>
            </div>
            <div className="col-12 col-lg-9 d-flex mt-40">
              <div className="card-w p-20 w-100">
                <div className="row">
                  <div className="col-12 col-lg-9 mt-40">
                    {/*<p className="h4 -b"></p>*/}

                    <div className="form-group w-100 mt-40">
                      <input
                        name="name"
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                      />
                      <label className="info" htmlFor="workspaceID">
                        Name
                      </label>
                    </div>

                    <div className="form-group w-100 mt-40">
                      <input
                        name="key"
                        type="text"
                        className="form-control"
                        placeholder="Key"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(e) => setKey(e.target.value)}
                        value={key}
                      />
                      <label className="info" htmlFor="workspaceID">
                        Key
                      </label>
                      </div>

                    <div className="form-group w-100 mt-40">
                      <input
                        name="nlpUrl"
                        type="text"
                        className="form-control"
                        placeholder="NLP Url"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(e) => setNlpUrl(e.target.value)}
                        value={nlpUrl}
                      />
                      <label className="info" htmlFor="workspaceID">
                        NLP Url
                      </label>
                      </div>

                    <div className="form-group w-100 mt-40">
                      <input
                        name="nlpVersion"
                        type="text"
                        className="form-control"
                        placeholder="NLP Version"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={(e) => setNlpVersion(e.target.value)}
                        value={nlpVersion}
                      />
                      <label className="info" htmlFor="workspaceID">
                        NLP Version
                      </label>
                      </div>

                    <div className="form-group w-100 mt-40">
                    <select className="form-select" value={nlpEngine} onChange={(e) => setNlpEngine(e.target.value)}>
                      <option value="watson">Watson</option>
                      <option value="clu">CLU</option>
                      <option value="dialogflow">Dialogflow</option>
                    </select>
                      <label className="info" htmlFor="workspaceID">
                        NLP Engine
                      </label>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 mt-40">
                <button
                  className="button -primary w-100"
                  data-bs-toggle="modal"
                  data-bs-target="#updateNlpModal"
                >
                  UPDATE
                </button>
                <button
                  className="button -warning mt-10 w-100"
                  data-bs-toggle="modal"
                  data-bs-target="#deleteNlpModal"
                >
                  DELETE
                </button>
              <a href="/managenlp">
                <button className="button -outline-primary mt-10 w-100">
                  CANCEL
                </button>
              </a>
            </div>
          </div>
          <div className="row mt-20">
            <Paginator />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(EditNlp);
