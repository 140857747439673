// export const firebaseConfig = {
//   apiKey: "AIzaSyCshixzd2SyAVAKviAgO5YPkJuVtq9GtVU",
//   authDomain: "flask-api-bba7c.firebaseapp.com",
//   projectId: "flask-api-bba7c",
//   storageBucket: "flask-api-bba7c.appspot.com",
//   messagingSenderId: "556580520769",
//   appId: "1:556580520769:web:0f88c3f062695efa0b57c0",
// };
let w: any = window;
export const firebaseConfig = {
  apiKey:
    w._env_?.REACT_APP_FIREBASE_APIKEY ||
    process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: "nds-reentrenamiento.firebaseapp.com",
  projectId: "nds-reentrenamiento",
  storageBucket: "nds-reentrenamiento.appspot.com",
  messagingSenderId: "17164141345",
  appId: "1:17164141345:web:08a526fffe37d3c13a3460",
};
