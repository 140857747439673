import React, { useState, useEffect, useCallback } from "react";
import { Link, Navigate } from "react-router-dom";
import { Modal } from "bootstrap";
import Header from "../../../Components/Header/headerView";
import Footer from "../../../Components/Footer/footer";
import Menu from "../../../Components/Menu/menu";
import NDSInput from "../../../Components/Input/ndsInput";
import { connect } from "react-redux";
import usersService from "../../../Services/usersService";
import { set } from "firebase/database";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../../Components/Loader/actions";

const mapStateToProps = state => {
    return {
        workspace: state.workspace,
        user: state.user,
    };
};

const CreateAdmin = props => {
    const [errorMessage, setErrorMessage] = useState("");
    const [first_name, setFirstName] = useState("");
    const [first_surname, setFirstSurname] = useState("");
    const [second_surname, setSecondSurname] = useState("");
    const [email, setEmail] = useState("");

    async function createUser() {
        var modal = new Modal(document.getElementById("createUserModal"), {
            keyboard: false,
        });
        let permissions = [];
        const user_data = {
            first_name: first_name,
            first_surname: first_surname,
            second_surname: second_surname,
            email: email,
        };
        const body = {
            user_data: user_data,
            permissions: permissions,
        };
        const params = {
            organization: props.workspace.organization,
            is_admin: true,
        };
        console.log(body);

        try{
            let res = await usersService.createAdmin(params, body);
            console.log(res);
            if (res.status === 200) {
                modal.show();
            }
            setFirstName("");
            setFirstSurname("");
            setSecondSurname("");
            setEmail("");
        }
        catch(err){
            if (err?.response?.data?.validation_error?.body_params) {
                let message = (
                    <ul>
                        {err.response.data.validation_error.body_params.map(
                            (e, index) => {
                                var loc = e.loc.join(".");
                                return (
                                    <li>
                                        <b>{`${loc}.`}</b> {e.msg}
                                    </li>
                                );
                            }
                        )}
                    </ul>
                );
                setErrorMessage(message);
            } else {
                setErrorMessage("Something went wrong. Please try again later.");
            }
            console.log(err);
        }
    }

    return (
        <div className={`${props.className}`}>
            {/* // <!-- Modal Create User--> */}
            <div
                className="modal fade"
                id="createUserModal"
                tabIndex="1"
                aria-labelledby="createUserModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <p className="h2" id="createUserModalLabel">
                                Admin created
                            </p>
                            <button
                                type="button"
                                className="button-close w-100"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                X
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="text">
                                The admin has been successfully created.
                            </p>
                        </div>
                        <div className="modal-footer">
                            <div className="row w-100 justify-content-end">
                                <div className="col-12 col-md-7 mb-10">
                                    <button
                                        className="button -text w-100"
                                        data-bs-dismiss="modal"
                                    >
                                        CREATE ANOTHER ADMIN
                                    </button>
                                </div>
                                <div className="col-12 col-md-5 mb-10">
                                    <a href="/adminsmanage">
                                        <button className="button -primary w-100">
                                            CONTINUE
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* // <!-- Modal Conflict OnlyUser--> */}
            <div
                className="modal fade"
                id="ConflictOnlyUserModal"
                tabIndex="1"
                aria-labelledby="ConflictOnlyUserModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <p className="h2" id="ConflictOnlyUserModalLabel">
                                Conflict detected
                            </p>
                            <button
                                type="button"
                                className="button-close w-100"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                X
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="text">
                                You can’t de-select any of the licenses because
                                is the only user on the platform.
                            </p>
                        </div>
                        <div className="modal-footer">
                            <div className="row w-100 justify-content-end">
                                <div className="col-12 col-md-5 mb-10">
                                    <a href="/CreateUser">
                                        <button className="button -primary w-100">
                                            CONTINUE
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Header />
            <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
                <div className="w-10">
                    <Menu activeTab={8} />
                </div>
                <div className="container px-20 mb-50">
                    <div className="row">
                        <div className="col-12 my-10">
                            <div className="row mt-50 mb-10">
                                <p className="h1 -r">Create New Admin</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-10">
                        <div className="col-12 mt-30">
                            <p className="h2 -r">User information</p>
                        </div>
                        <div className="col-12 mb-20">
                            <p className="label color-gray p-0">
                                <i class="fa-solid fa-circle-info"></i>
                                Please fill all fields that have an asterisk (*).
                            </p>
                        </div>
                        
                        <div className="col-lg-4 col-md-8 mt-20">
                            <div className="form-group">
                                <NDSInput 
                                    label="Name*"
                                    placeholder="Name"
                                    value={first_name}
                                    ariaLabel="Recipient's username"
                                    ariaDescribedby="basic-addon2"
                                    onChange={event => {
                                        setFirstName(event.target.value);
                                    }}
                                    errorMessage="This is a required field"
                                ></NDSInput>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-8 mt-20">
                            <div className="form-group">
                                <NDSInput 
                                    label="First Last Name*"
                                    placeholder="First Last Name"
                                    value={first_surname}
                                    ariaLabel="Recipient's username"
                                    ariaDescribedby="basic-addon2"
                                    onChange={event => {
                                        setFirstSurname(event.target.value);
                                    }}
                                    errorMessage="This is a required field"
                                ></NDSInput>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-8 mt-20">
                            <div className="form-group">
                                <label className="form-check-label text">
                                    Second Last Name
                                </label>
                                <input
                                    type="text"
                                    value={second_surname}
                                    className="form-control"
                                    placeholder="Second Last Name"
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                    onChange={event => {
                                        setSecondSurname(event.target.value);
                                    }}
                                    required
                                ></input>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-8 mt-20">
                            <div className="form-group">
                                <NDSInput 
                                    label="Email*"
                                    placeholder="Email"
                                    value={email}
                                    ariaLabel="Recipient's username"
                                    ariaDescribedby="basic-addon2"
                                    onChange={event => {
                                        setEmail(event.target.value);
                                    }}
                                    errorMessage="This is a required field"
                                ></NDSInput>       
                            </div>
                        </div>
                        <div className="col-12 mt-20">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    checked={true}
                                    type="checkbox"
                                    value={true}
                                    disabled={true}
                                    defaultChecked={true}
                                    id="flexCheckDefault"
                                />
                                <label
                                    className="link"
                                    htmlFor="flexCheckDefault"
                                >
                                    <p>
                                        <b>This user is Admin</b>
                                    </p>
                                </label>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row mt-50 mb-50">
                        <div className="col-12 col-md-6 col-lg-3">
                            <button
                                className="button -primary w-100"
                                // data-bs-toggle="modal"
                                // data-bs-target="#createUserModal"
                                onClick={() => createUser()}
                            >
                                CREATE ADMIN
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default connect(mapStateToProps)(CreateAdmin);
