import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import qs from "qs";
import { v4 as uuid } from "uuid";
;
;
var _a;
function isConflictSource(obj) {
    return obj.conflicting_phrases !== undefined;
}
function isConflictTarget(obj) {
    return obj.phrases !== undefined;
}
var token = localStorage.getItem("token");
var w = window;
export var conflictsApiSlice = createApi({
    reducerPath: "api/conflicts",
    baseQuery: fetchBaseQuery({
        baseUrl: "".concat(((_a = w._env_) === null || _a === void 0 ? void 0 : _a.REACT_APP_API_URL) || process.env.REACT_APP_API_URL),
        paramsSerializer: function (params) {
            return qs.stringify(params, {
                arrayFormat: "brackets",
            });
        },
        prepareHeaders: function (headers) {
            headers.set("Content-Type", "application/json");
            headers.set("Authorization", "Bearer ".concat(token));
            headers.set("Access-Control-Allow-Origin", "*");
            headers.set("Access-Control-Allow-Methods", "GET,PUT,POST,DELETE,PATCH,OPTIONS");
            headers.set("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept, Authorization");
            return headers;
        },
    }),
    tagTypes: ["Conflict"],
    endpoints: function (builder) {
        return {
            fetchConflict: builder.query({
                query: function (_id) {
                    return "/intent/collectConflict?_id=".concat(_id);
                },
                transformResponse: function (response) {
                    response.intent = response.conflict.intent_object;
                    response.source.conflicting_phrases =
                        response.source.conflicting_phrases.map(function (e) { return ({
                            phrase: e,
                            state: "conflicting",
                            original_intent_id: response.source._id,
                            current_intent_id: response.source._id,
                            injected_id: uuid(),
                        }); });
                    response.target.forEach(function (e) {
                        return e.phrases.forEach(function (ee) {
                            ee.original_intent_id = e.intent_id;
                            ee.current_intent_id = e.intent_id;
                            ee.injected_id = uuid();
                        });
                    });
                    return response;
                },
            }),
            resolveConflict: builder.mutation({
                query: function (body) {
                    return {
                        url: "/conflicts/resolve",
                        method: 'POST',
                        body: body
                    };
                }
            }),
            merge: builder.mutation({
                query: function (body) {
                    return {
                        url: "/conflicts/merge",
                        method: 'POST',
                        body: body
                    };
                }
            }),
        };
    },
});
export var useFetchConflictQuery = conflictsApiSlice.useFetchConflictQuery, useResolveConflictMutation = conflictsApiSlice.useResolveConflictMutation, useMergeMutation = conflictsApiSlice.useMergeMutation;
export { isConflictSource, isConflictTarget, };
