import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    id: "",
    first_name: "",
    first_surname: "",
    second_surname: "",
    email: "",
    organizations: [],
    is_super_admin: false,
  },
  reducers: {
    login: (state, action) => {
      state.id = action.payload.id;
      state.first_name = action.payload.first_name;
      state.first_surname = action.payload.first_surname;
      state.second_surname = action.payload.second_surname;
      state.email = action.payload.email;
      state.organizations = action.payload.organizations;
      state.is_super_admin = action.payload.is_super_admin ?? false;
    },
    setOrganizations: (state, action) => {
      state.organizations = action.payload;
    },
    logout: (state) => {
      state.id = "";
      state.first_name = "";
      state.first_surname = "";
      state.second_surname = "";
      state.email = "";
      state.organizations = [];
      state.is_super_admin = false;
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      //localStorage.removeItem("UID");
    },
  },
});

export const { login, logout, setOrganizations } = userSlice.actions;

export default userSlice.reducer;
