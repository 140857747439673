import { useParams, Link } from "react-router-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import Header from "../../Components/Header/headerView";
import Footer from "../../Components/Footer/footer";
import Menu from "../../Components/Menu/menu";
import NDSTooltip from "../../Components/Tooltip/ndsTooltip";
import Paginator from "../../Components/Paginator/paginator";
import { DeleteModal, AdminModal } from '../../Utils/modals';
import { useNavigate } from "react-router-dom";
  
export const ObjectsDetailScreen = ({_this}) => {
  const navigate = useNavigate();
  return (
    <div className={`${_this.props.className}`}>
      {/* // <!-- Modal created--> */}
      <div
        className="modal fade"
        id="createModal"
        tabIndex="1"
        aria-labelledby="createModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p className="h2" id="createModalLabel">
                Delete request created
              </p>
              <button
                type="button"
                className="button-close w-100"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body d-flex flex-column align-items-center justify-content-center">
              <p className="text">Delete request created succesfully!</p>
            </div>
            <div className="modal-footer">
              <div className="row w-100">
                <div className="d-flex flex-column align-items-center justify-content-center">
                <button className="button -primary" data-bs-dismiss="modal" onClick={() => (navigate("/requests", { state: { tab: '2' } }))}>
                      CONTINUE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // <!-- Modal error--> */}
      {/* // <!-- Modal Delete--> */}
      <DeleteModal
        type={_this.type}
        handleDelete={_this.handleDelete}
      />
      <Header />
      <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
        <div className="w-10">
          <Menu activeTab={_this.type === 'intent' ? 2 : _this.type === 'entity' ? 3 : 4} />
        </div>
        <div className="container px-20 mt-20 mb-50">
          <div className="row">
            <div className="col-12 col-lg-9 my-20">
              <div className="row mb-40">
                <p className="h1 -r">{_this?.type.charAt(0).toUpperCase() + _this?.type.slice(1)} detail</p>
              </div>
              {/* NEW Entity information card */}
              <div className="card-w -m">
                <p className="h3 -b">{_this?.type.charAt(0).toUpperCase() + _this?.type.slice(1)} information</p>
                <div className="row">
                  <div className="col-12 col-lg-8">
                  <div className="d-flex flex-row">
                      <div className="col-12 col-lg-6">
                        <div className="row mt-20">
                          <p className="h4 -b mb-0">{_this.object?.name}</p>
                          <text className="d-flex flex-start">
                            {_this.object?.description
                              ? _this.object?.description
                              : "No description"}
                          </text>
                        </div>
                      </div>
                      <div className="row">
                        {_this.object?.category && (
                        <div className="col-12 col-md-6 col-lg-12 mt-20">
                          <p className="h3 -r mb-0">Categories:</p>
                          <div className="row">
                            {_this.object?.category?.map((category) => (
                              <div className="col-12 col-md-6 col-lg-12 ">
                                <a
                                  className="link"
                                  href={`/detail/category/${category.value}`}
                                >
                                  <i className="fa-solid fa-tag -blue mr-10"></i>
                                  {category.name}
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                        )}
                      </div>
                      
                    </div>
                    <div className="row mt-20">
                      <div className="col-12 col-md-6 mt-20">
                        <p className="h4 -r mb-0">
                          <i className="fa-solid fa-calendar mr-10"></i>
                          Created at:
                        </p>
                        <p className="text">
                          
                          {new Date(_this.object?.created?.at).toDateString() +
                            "  " +
                            new Date(_this.object?.created?.at).toLocaleTimeString()}
                        </p>
                      </div>
                      
                      <div className="col-12 col-md-6 mt-20">
                        <p className="h4 -r mb-0">
                          <i className="fa-solid fa-calendar mr-10"></i>
                          Last update at:
                        </p>
                        <p className="text">{_this.object?.lastEdition?.date}</p>
                      </div>
                      
                      
                    </div>
                    
                  </div>
                  
                  {/* New status detail side panel here */}
                  <div className="col-12 col-lg-4">
                    <div className="row">
                      
                      <div className="col-12 col-md-6 col-lg-12 mt-20">
                        <p className="h3 -r mb-0">Created by:</p>
                        <p className="text">
                          <i className="fa-solid fa-user-circle -blue mr-10"></i>
                          {(_this.lastEditor?.first_name ?? "") +
                            " " +
                            (_this.lastEditor?.first_surname ?? "") +
                            " " +
                            (_this.lastEditor?.second_surname ?? "")}
                        </p>
                      </div>
                      <div className="row mt-20">
                      <div className="col-12 col-md-6 col-lg-12 mt-20">
                        <p className="h4 -r mb-0">Last edition by:</p>
                        <p className="text">
                          <i className="fa-solid fa-user-circle -blue mr-10"></i>
                          {(_this.lastEditor?.first_name ?? "") +
                            " " +
                            (_this.lastEditor?.first_surname ?? "") +
                            " " +
                            (_this.lastEditor?.second_surname ?? "")}
                        </p>
                      </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 mt-20">
                <div className="row">
                  {console.log(_this.props)}
                  {console.log(_this.editEntityPermission)}
                  {console.log(_this.type)}
                  {((_this.editIntentPermission  && _this.type === "intent") || 
                  (_this.editEntityPermission && _this.type === "entity") || 
                  (_this.editCategoryPermission && _this.type === "category")) && (
                  <Link className="link" to={_this.type === "entity" ? `/editentity/${_this.object?.id}` : _this.type === "intent" ? `/editintent/${_this.object?.id}` : `/editcategory/${_this.object?.id}`}>
                    <button type="button" className="button -primary w-100">
                      EDIT {_this.type}
                    </button>
                  </Link>
                  )}
                   {((_this.deleteIntentPermission  && _this.type === "intent") || 
                  (_this.deleteEntityPermission && _this.type === "entity") || 
                  (_this.deleteCategoryPermission && _this.type === "category")) && (
                    <>
                      {_this.deleteButton}
                    </>
                  )}
                </div>
            </div>
          </div>
          {_this.type != "category" && (
            <>
          <div className="row ">
            <Nav tabs className="col-12 d-flex">
              <NavItem>
                <NavLink
                  className={{ active: _this.activeTab === "1" }}
                  onClick={() => {
                    _this.toggle("1");
                  }}
                >
                  <span>
                    <p className="icon">
                      <i className="fa-solid fa-lines-leaning"></i>
                    </p>
                    <p className="h3 -b">{_this.type === "entity" ? "SYNONYMS" : "EXAMPLES"}</p>
                  </span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          
          <TabContent activeTab={_this.activeTab}>
            <TabPane tabId="1">
              <div className="row">
                <div className="col-12 col-lg-9">
                  <div className="row mt-50">
                    <div className="col-12">
                      <table className="bg-white">
                        <thead>
                          <tr>
                            {/* <th><input className="form-check-input -white" type="checkbox" value="" id="flexCheckDefault"></input></th> */}
                            <th>
                              <p className="d-flex align-items-center mx-20">
                                {_this.type === "entity" ? "Synonyms" : "Examples"}{" "}
                                {/*<button className="i -white w-15">
                                  <i className="fa-solid fa-arrow-up-a-z"></i>
                                </button>*/}
                              </p>
                            </th>
                            {/* <th><p className='d-flex align-items-center w-100'>Conflicts <button className='i -white w-15'><i className="fa-solid fa-arrow-down-1-9"></i></button></p></th> */}
                            <th>
                              <p className="d-flex align-items-center">
                                Added{" "}
                                {/*<button className="i -white w-15">
                                  <i className="fa-solid fa-arrow-down-1-9"></i>
                                </button>*/}
                              </p>
                            </th>
                              <th className="row m-10 align-items-center">
                                Actions
                              </th>
                          </tr>
                        </thead>
                        <tbody>
                          {_this.loadData ? (
                            <tr>
                              <td colSpan="5">
                                <p className="h3 -b">Loading...</p>
                              </td>
                            </tr>
                          ) : _this.splittedDetails &&
                          _this.splittedDetails.length > 0 ? (
                            _this.splittedDetails[_this.page - 1].map((detail, index) => {
                              return (
                                <tr>
                                  {/* <td><input className="form-check-input -blue" type="checkbox" value="" id="flexCheckDefault"></input></td> */}
                                  <td className="row mx-20">{detail}</td>
                                  {/* <td>0</td> */}
                                  <td>
                                    {detail.updated_on
                                      ? _this.dateService.humanizeDateTime(
                                        detail.updated_on
                                        )
                                      : _this.dateService.humanizeDateTime(
                                        _this.object.created[0].at
                                        )}
                                  </td>
                                    <td>
                                      <div className="row justify-content-center">
                                        <button
                                          type="button"
                                          className="primary -icon"
                                          onClick={() => {
                                            window.location.href = `/entitiesedit/${_this.object?.id}`;
                                          }}
                                        >
                                          <span className="material-icons-outlined">
                                            <NDSTooltip
                                              icon="fa-solid fa-pencil"
                                              target="pencil"
                                              tooltipMessage="Edit"
                                              placement="bottom"
                                            ></NDSTooltip>
                                            {/* <i className="fa-solid fa-pencil"></i> */}
                                          </span>
                                        </button>
                                      </div>
                                    </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td>
                                <p className=" h4 -r">No synonyms</p>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row mt-20">
                  <Paginator
                      pages={_this.totalPages}
                      currentPage={_this.page}
                      setPage={(page) => _this.setPage(page)}
                    />
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className="row">
                <div className="col-12 col-lg-9">
                  <div className="mt-50">
                    <table className="bg-white">
                      <thead>
                        <tr>
                          {/* <th>
                            <input
                              className="form-check-input -white"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            ></input>
                          </th> */}
                          <th>
                            <p className="d-flex align-items-center w-100 mx-20">
                              Intents{" "}
                              {/*<button className="i -white w-15">
                                <i className="fa-solid fa-arrow-up-a-z"></i>
                              </button>*/}
                            </p>
                          </th>
                          <th>
                            <p className="d-flex align-items-center w-100">
                              Examples{" "}
                              {/*<button className="i -white w-15">
                                <i className="fa-solid fa-arrow-down-1-9"></i>
                              </button>*/}
                            </p>
                          </th>
                            <th className="row m-10 align-items-center">
                              Actions
                            </th>
                        </tr>
                      </thead>
                      <tbody>
                        {_this.loadData === true ? (
                          <tr>
                            <td colSpan="5">
                              <p className="h3 -b">Loading...</p>
                            </td>
                          </tr>
                        ) : _this.linkedIntents.length > 0 ? (
                          _this.linkedIntents.map((intent, index) => {
                            return (
                              <tr>
                                <td className="row mx-20">
                                  <Link
                                    onClick={() => {
                                      window.open(
                                        `/intentsdetail/${intent?.value}`
                                      );
                                    }}
                                  >
                                    {intent.label}
                                  </Link>
                                </td>
                                <td>{intent.examples.length}</td>
                                  <td>
                                    <button
                                      className="button -icon -danger"
                                      onClick={() => {
                                        window.location.href = `/entitiesedit/${_this.object?._id}`;
                                      }}
                                    >
                                      <span className="material-icons-outlined">
                                        <NDSTooltip
                                          icon="fa-solid fa-trash-can"
                                          target="trash"
                                          tooltipMessage="Delete"
                                          placement="bottom"
                                        ></NDSTooltip>
                                        {/* <i className="fa-solid fa-trash-can"></i> */}
                                      </span>
                                    </button>
                                  </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td>
                              <p className="h4 -r" colSpan="3">
                                No linked intents
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row mt-20">
                  <Paginator />
                </div>
              </div>
            </TabPane>
          </TabContent>
          </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

