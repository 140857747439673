import React, { useState, useEffect } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import Header from '../../../Components/Header/headerView';
import Footer from '../../../Components/Footer/footer';
import Menu from '../../../Components/Menu/menu';
import Paginator from '../../../Components/Paginator/paginator';
import suggestionsImage from '../../../Images/suggestions.png';

export const RequestsScreen = ({_this}) => {
  if (!_this || !_this.props) {
    return <div>Error: _this is undefined or has no props</div>;
  }

  return (
      <div className={`${_this.props.className}`}>
        <div
      className="modal fade"
      id="validateSuggestionsModal"
      tabIndex="1"
      aria-labelledby="validateSuggestionsModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h2" id="deleteModalLabel">
              Validate
            </p>
            <button
              type="button"
              className="button-close w-100"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body justify-content-center align-items-center">
            <p className="text">
              Are you sure you want to validate the selected Suggestions? They will be sent to your inbox. 
            </p>
          </div>
          <div className="modal-footer">
            <div className="row w-100">
              <div className="col-12 col-md-6 mb-10">
                <button className="button -text w-100" data-bs-dismiss="modal">
                  CANCEL
                </button>
              </div>
              <div className="col-12 col-md-6 mb-10">
                <button
                  className="button -primary w-100"
                  onClick={() => (_this.validateRequests())}
                  data-bs-dismiss="modal"
                >
                  VALIDATE SUGGESTIONS
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* */}
    <div
      className="modal fade"
      id="recoverSuggestionsModal"
      tabIndex="1"
      aria-labelledby="recoverSuggestionsModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h2" id="deleteModalLabel">
              Recover
            </p>
            <button
              type="button"
              className="button-close w-100"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body justify-content-center align-items-center">
            <p className="text">
              Are you sure you want to recover the selected Suggestions? They will be shown in Automatic Suggestions. 
            </p>
          </div>
          <div className="modal-footer">
            <div className="row w-100">
              <div className="col-12 col-md-6 mb-10">
                <button className="button -text w-100" data-bs-dismiss="modal">
                  CANCEL
                </button>
              </div>
              <div className="col-12 col-md-6 mb-10">
                <button
                  className="button -primary w-100"
                  onClick={() => (_this.recoverRequests())}
                  data-bs-dismiss="modal"
                >
                  RECOVER REQUESTS
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* */}
    <div
      className="modal fade"
      id="discardSuggestionsModal"
      tabIndex="1"
      aria-labelledby="discardSuggestionsModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h2" id="deleteModalLabel">
              Discard
            </p>
            <button
              type="button"
              className="button-close w-100"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
          </div>
          <div className="modal-body justify-content-center align-items-center">
            <p className="text">
              This will discard all the Automatic Suggestions in this tab. Are you sure you want to proceed? 
            </p>
          </div>
          <div className="modal-footer">
            <div className="row w-100">
              <div className="col-12 col-md-6 mb-10">
                <button className="button -text w-100" data-bs-dismiss="modal">
                  CANCEL
                </button>
              </div>
              <div className="col-12 col-md-6 mb-10">
                <button
                  className="button -warning w-100"
                  onClick={() => (_this.discardAllSuggestions())}
                  data-bs-dismiss="modal"
                >
                  DISCARD ALL SUGGESTIONS
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      <Header />
      <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
        <div className="w-10">
          <Menu activeTab={1} />
        </div>
        <div className="container px-20 mt-50">
          <div className="row ">
            <Nav tabs className="col-12 d-flex">
              <NavItem>
                <NavLink disabled={_this.changePage}
                  className={_this.activeTab === "1" ? "active" : ""}
                  onClick={() => {
                    _this.handleTabChange('1');

                  }}
                >
                  <span>
                    <p className="h3 -b">INBOX</p>
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink disabled={_this.changePage}
                  className={_this.activeTab === "2" ? "active" : ""}
                  onClick={() => {
                    _this.handleTabChange('2');
                  }}
                >
                  <span>
                    <p className="h3 -b">ONGOING REQUESTS</p>
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink disabled={_this.changePage}
                  className={_this.activeTab === "3" ? "active" : ""}
                  onClick={() => {
                    _this.handleTabChange('3');
                  }}
                >
                  <span>
                    <p className="h3 -b">CLOSED REQUESTS</p>
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink disabled={_this.changePage}
                  className={_this.activeTab === "4" ? "active" : ""}
                  onClick={() => {
                    _this.handleTabChange('4');
                  }}
                >
                  <span>
                    <p className="h3 -b">AUTOMATIC SUGGESTIONS</p>
                  </span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <TabContent activeTab={_this.activeTab}>
            <TabPane tabId="1">
              <div className="row mt-40">
                <div className="col-12 col-md-6 col-lg-5">
                  <div className="input-group mb-3">
                    <input
                      id="search1"
                      type="text"
                      className="form-control"
                      placeholder="Search by name or ID"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      onChange={(e) => _this.handleSearch(e.target.value)}
                    ></input>
                    <span className="input-group-text">
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb-30">
                  <select
                    className="form-select"
                    placeholder="Type"
                    onChange={(e) => _this.handleRequestSelect(e.target.value)}
                  >
                    <option value="1">All</option>
                    <option value="2">Intents</option>
                    <option value="3">Entities</option>
                    <option value="4">Categories</option>
                  </select>
                </div>
                <div className="col-6 col-md-3 col-lg-2 mb-30">
                  <button className="button -outline-primary bg-white w-100" onClick={_this.searchReq}>
                    SEARCH
                  </button>
                </div>
                <div className="col-6 col-md-3 col-lg-2 mb-30">
                  <button
                    className="button -text w-100"
                    onClick={() => {
                      _this.searchRequests("");
                      document.getElementById("search1").value = "";
                    }}
                  >
                    CLEAR SEARCH
                  </button>
                </div>
              </div>
              {_this?.requests?.length === 0 ? (
                <div className="row mb-20">
                  <div className="col-12 col-lg-8">
                    <div>
                      <div className="d-flex flex-column align-items-center">
                        <img
                          src={suggestionsImage}
                          alt="Empty inbox"
                          className="mb-20"
                          style={{ width: "200px" }}
                        />
                        <p className="h3 -b">No requests in your inbox</p>
                        <p className="h5 -b">
                          You can start by creating a new intent, entity or category
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {!_this.changePage && _this.requests && (
                    _this.requests.map((request, index) => (
                      <div key={request.id} className="row mb-20">
                        <div className="col-12 col-lg-8">
                          <a
                            className="request"
                            href={`../validaterequest/${request.id}`}
                          >
                            <div className="ident d-flex flex-column">
                            <i
                                  className={`
                                    
                                    ${
                                    request.object_type === "intent"
                                      ? "fa-solid fa-code-branch"
                                      : request.object_type === "entity"
                                        ? "fa-solid fa-key"
                                        : "fa-solid fa-star"
                                    }
                                  `}
                                ></i>
                                <b className="info color-blue">
                                  {request.object_type}
                                </b>
                            </div>
                            <div className="data w-100">
                              <p className="h3 -b mt-20 mb-10">
                                 {request?.activity?.snapshot ? request?.activity?.snapshot?.name : request?.changes.find(item => item.field === 'name')?.value}
                              </p>
                              <div className="row mb-10">
                                {request?.flags?.edited ? (
                                  <div className="status -edited mx-10">
                                    <p className="info">Content edited</p>
                                  </div>
                                ) : null}
                                {request?.flags?.newContent ? (
                                  <div className="status -newContent mx-10">
                                    <p className="info">New Content</p>
                                  </div>
                                ) : null}
                                {request?.flags?.added ? (
                                  <div className="status -added mx-10">
                                    <p className="info">Content added</p>
                                  </div>
                                ) : null}
                                {request?.flags?.deleted ? (
                                  <div className="status -deleted mx-10">
                                    <p className="info">Content deleted</p>
                                  </div>
                                ) : null}
                                {request?.flags?.rejected ? (
                                  <div className="status -deleted mx-10">
                                    <p className="info">Rejected</p>
                                  </div>
                                ) : null}
                                 {request?.flags?.deleting ? (
                                  <div className="status -deleting mx-10">
                                    <p className="info">Deleting</p>
                                  </div>
                                ) : null}
                              </div>
                              <div className="row d-flex justify-content-around">
                                {/* <div className="col-6 col-md-3 d-flex mb-20">
                                <i className="fa-solid fa-fingerprint"></i>
                                <div className="row">
                                  <p className="text">ID:</p>
                                  <p className="text -b">{request.id}</p>
                                </div>
                              </div> */}
                                <div className="col-6 col-md-3 d-flex mb-20">
                                  <i className="fa-solid fa-calendar-days"></i>

                                  {request?.activity?.created[0]?.at ? (
                                    <div className="row">
                                      <p className="text">Last Updated:</p>
                                      <p className="text -b">
                                        {request?.activity?.created[0]?.at &&
                                          new Date(
                                            _this.dateService.convertIsoUtcToLocal(request?.activity?.created[0]?.at)
                                          ).toDateString() +
                                          " " +
                                          new Date(
                                            _this.dateService.convertIsoUtcToLocal(request?.activity?.created[0]?.at)
                                          ).toLocaleTimeString()}
                                      </p>
                                    </div>) : (
                                    <div className="row">
                                      <p className="text">Created At:</p>
                                      <p className="text -b">
                                        {request?.activity?.created[0]?.at &&
                                          new Date(_this.dateService.convertIsoUtcToLocal(request?.activity?.created[0]?.at)

                                          ).toDateString() +
                                          " " +
                                          new Date(
                                            _this.dateService.convertIsoUtcToLocal(request?.activity?.created[0]?.at)
                                          ).toLocaleTimeString()}
                                      </p>
                                    </div>)
                                  }
                                </div>
                                <div className="col-6 col-md-3 d-flex mb-20">
                                  <i className="fa-solid fa-user"></i>
                                  {request?.activity?.created[0]?.by ? (
                                    <div className="row">
                                      <p className="text">Requested by:</p>
                                      <p className="text -b">
                                        {request?.activity?.created[0]?.by &&
                                          request?.user?.first_name +
                                          " " +
                                          request?.user?.first_surname}
                                      </p>
                                    </div>)

                                    : (<div className="row">
                                      <p className="text">Created by:</p>
                                      <p className="text -b">
                                        {request?.user?.first_name &&
                                          request?.user?.first_name +
                                          " " +
                                          request?.user?.first_surname
                                        }
                                      </p>
                                    </div>)}
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    )))}
                    {_this?.requests?.length > 1 && (
                  <div className="row mt-20">
                    <Paginator
                      pages={_this.totalPages}
                      currentPage={_this.page}
                      setPage={_this.handlePageChange}
                    />
                  </div>
                    )}
                </>
              )}
            </TabPane>
            <TabPane tabId="2">
              <div className="row mt-40">
                <div className="col-12 col-md-6 col-lg-5">
                  <div className="input-group mb-3">
                    <input
                      id="search2"
                      type="text"
                      className="form-control"
                      placeholder="Search by name or ID"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      onChange={(e) => _this.handleSearch(e.target.value)}
                    ></input>
                    <span className="input-group-text">
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb-30">
                  <select
                    className="form-select"
                    placeholder="Type"
                    onChange={(e) => _this.handleRequestSelect(e.target.value)}
                  >
                    <option value="1">All</option>
                    <option value="2">Intents</option>
                    <option value="3">Entities</option>
                    <option value="4">Categories</option>
                  </select>
                </div>
                <div className="col-6 col-md-3 col-lg-2 mb-30">
                  <button className="button -outline-primary bg-white w-100" onClick={_this.searchReq}>
                    SEARCH
                  </button>
                </div>
                <div className="col-6 col-md-3 col-lg-2 mb-30">
                  <button
                    className="button -text w-100"
                    onClick={() => {
                      _this.searchRequests("");
                      document.getElementById("search2").value = "";
                    }}
                  >
                    CLEAR SEARCH
                  </button>
                </div>
              </div>
              {_this?.requests?.length === 0 ? (
                <div className="row mb-20">
                  <div className="col-12 col-lg-8">
                    {/* show suggestions image */}
                    <div>
                      <div className="d-flex flex-column align-items-center">
                        <img
                          src={suggestionsImage}
                          alt="Empty inbox"
                          className="mb-20"
                          style={{ width: "200px" }}
                        />
                        <p className="h3 -b">No ongoing requests</p>
                        <p className="h5 -b">
                        You can start by creating a new intent, entity or category
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {!_this.changePage && _this.requests && (
                    _this.requests.map((request, index) =>
                        <div key={request.id} className="row mb-20">
                          <div className="col-12 col-lg-8">
                            <a
                              className="request"
                              href={
                                request.status === "deployed"
                                  ? `../validaterequest/${request.id}`
                                  : `../validaterequest/${request.id}`
                              }
                            >
                              <div className="ident d-flex flex-column">
                              <i
                                    className={`
                                      
                                      ${
                                      request.object_type === "intent"
                                        ? "fa-solid fa-code-branch"
                                        : request.object_type === "entity"
                                          ? "fa-solid fa-key"
                                          : "fa-solid fa-star"
                                      }
                                    `}
                                  ></i>
                                  <b className="info color-blue">
                                    {request.object_type}
                                  </b>
                              </div>
                              <div className="data w-100">
                                <p className="h3 -b mt-20 mb-10">
                                {request?.activity?.snapshot ? request?.activity?.snapshot?.name : request?.changes.find(item => item.field === 'name')?.value}
                                </p>
                                {request?.status === "deployed" ? (
                                  <div className="status -added mx-10">
                                    <p className="info">Approved</p>
                                  </div>
                                ) : null}
                                {request?.status === "validated" ? (
                                  <div className="status -deploying mx-10">
                                    <p className="info">
                                      On deployer dashboard
                                    </p>
                                  </div>
                                ) : null}
                                {request?.status === "edited" ? (
                                  <div className="status -validating mx-10">
                                    <p className="info">
                                      On validator dashboard
                                    </p>
                                  </div>
                                ) : null}
                                {request?.status === "saved" ? (
                                  <div className="status -newContent mx-10">
                                    <p className="info">
                                      On editor dashboard
                                    </p>
                                  </div>
                                ) : null}
                                <div className="row d-flex justify-content-around">
                                  <div className="col-6 col-md-3 d-flex mb-20 mt-10">
                                    <i className="fa-solid fa-calendar-days"></i>
                                    <div className="row">
                                      <p className="text">Last Updated:</p>
                                      <p className="text -b">
                                        {request?.activity?.created[0]?.at
                                          ? new Date(_this.dateService.convertIsoUtcToLocal(
                                            request?.activity?.created[0]?.at)
                                          ).toDateString() +
                                          " " +
                                          new Date(_this.dateService.convertIsoUtcToLocal(
                                            request?.activity?.created[0]?.at)
                                          ).toLocaleTimeString()
                                          : new Date(_this.dateService.convertIsoUtcToLocal(
                                            request?.activity?.created[0]?.at)
                                          ).toDateString() +
                                          " " +
                                          new Date(_this.dateService.convertIsoUtcToLocal(
                                            request?.activity?.created[0]?.at)
                                          ).toLocaleTimeString()}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-6 col-md-3 d-flex mb-20">
                                    <i className="fa-solid fa-user"></i>
                                    <div className="row">
                                      <p className="text">Updated by:</p>
                                      <p className="text -b">
                                        {request?.user?.first_name}{" "}
                                        {request?.user?.first_surname}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                    ))}
                    {_this?.totalPages > 1 && (
                  <div className="row mt-20">
                    <Paginator
                      pages={_this.totalPages}
                      currentPage={_this.page}
                      setPage={_this.handlePageChange}
                    />
                  </div>
                    )}
                </>
              )}
            </TabPane>
            <TabPane tabId="3">
              <div className="row mt-40">
                <div className="col-12 col-md-6 col-lg-5">
                  <div className="input-group mb-3">
                    <input
                      id="search3"
                      type="text"
                      className="form-control"
                      placeholder="Search by name or ID"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      onChange={(e) => _this.handleSearch(e.target.value)}
                    ></input>
                    <span className="input-group-text">
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb-30">
                  <select
                    className="form-select"
                    placeholder="Type"
                    onChange={(e) => _this.handleRequestSelect(e.target.value)}
                  >
                    <option value="1">All</option>
                    <option value="2">Intents</option>
                    <option value="3">Entities</option>
                    <option value="4">Categories</option>
                  </select>
                </div>
                <div className="col-6 col-md-3 col-lg-2 mb-30">
                  <button className="button -outline-primary bg-white w-100" onClick={_this.searchReq}>
                    SEARCH
                  </button>
                </div>
                <div className="col-6 col-md-3 col-lg-2 mb-30">
                  <button
                    className="button -text w-100"
                    onClick={() => {
                      _this.searchRequests("");
                      document.getElementById("search3").value = "";
                    }}
                  >
                    CLEAR SEARCH
                  </button>
                </div>
              </div>
              {_this?.requests?.length === 0 ? (
                <div className="row mb-20">
                  <div className="col-12 col-lg-8">
                    {/* show suggestions image */}
                    <div>
                      <div className="d-flex flex-column align-items-center">
                        <img
                          src={suggestionsImage}
                          alt="Empty inbox"
                          className="mb-20"
                          style={{ width: "200px" }}
                        />
                        <p className="h3 -b">There are no closed request yet.</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {!_this.changePage && _this?.requests?.length > 0 && _this.requests
                      .map((request, index) =>
                          <div key={request.id} className="row mb-20">
                            <div className="col-12 col-lg-8">
                              <a
                                className="request"
                                href={`../validaterequest/${request.id}`}
                              >
                                <div className="ident d-flex flex-column">
                                <i
                                      className={`
                                        
                                        ${
                                        request.object_type === "intent"
                                          ? "fa-solid fa-code-branch"
                                          : request.object_type === "entity"
                                            ? "fa-solid fa-key"
                                            : "fa-solid fa-star"
                                        }
                                      `}
                                    ></i>
                                    <b className="info color-blue">
                                      {request.object_type}
                                    </b>
                                </div>
                                <div className="data w-100">
                                  <p className="h3 -b mt-20 mb-10">
                                  {request?.activity?.snapshot ? request?.activity?.snapshot?.name : request?.changes.find(item => item.field === 'name')?.value}
                                  </p>
                                  {request?.status === "closed" ? (
                                    <div className="status -deleted mx-10">
                                      <p className="info">Closed</p>
                                    </div>
                                  ) : null}
                                  <div className="row d-flex justify-content-around">
                                    <div className="col-6 col-md-3 d-flex mb-20">
                                      <i className="fa-solid fa-calendar-days"></i>
                                      <div className="row">
                                        <p className="text">Close date:</p>
                                        <p className="text -b">
                                        {request?.activity?.created[0]?.at
                                          ? new Date(_this.dateService.convertIsoUtcToLocal(
                                            request?.activity?.created[0]?.at)
                                          ).toDateString() +
                                          " " +
                                          new Date(_this.dateService.convertIsoUtcToLocal(
                                            request?.activity?.created[0]?.at)
                                          ).toLocaleTimeString()
                                          : new Date(_this.dateService.convertIsoUtcToLocal(
                                            request?.activity?.created[0]?.at)
                                          ).toDateString() +
                                          " " +
                                          new Date(_this.dateService.convertIsoUtcToLocal(
                                            request?.activity?.created[0]?.at)
                                          ).toLocaleTimeString()}
                                      </p>
                                      </div>

                                    </div>
                                    {/* 
                                    <div className="col-6 col-md-3 d-flex mb-20">
                                      <i className="fa-solid fa-calendar-days"></i>
                                      <div className="row">
                                        <p className="text">Close date:</p>
                                        <p className="text -b">
                                          {new Date(_this.dateService.convertIsoUtcToLocal(
                                            request?.status?.editions[
                                              request?.status?.editions.length -
                                              1
                                            ]?.updated_on)
                                          ).toDateString()}
                                        </p>
                                      </div>
                                    </div>
                                    */}
                                    <div className="col-6 col-md-3 d-flex mb-20">
                                      <i className="fa-solid fa-user"></i>
                                      <div className="row">
                                        <p className="text">Closed by:</p>
                                        <p className="text -b">
                                          {request?.user?.first_name}{" "}
                                          {request?.user?.first_surname}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                      )}
                  {_this?.totalPages > 1 && (
                  <div className="row mt-20">
                    <Paginator
                      pages={_this.totalPages}
                      currentPage={_this.page}
                      setPage={_this.handlePageChange}
                    />
                  </div>
                  )}
                </>
              )}
            </TabPane>
            <TabPane tabId="4">
              <div className="row mt-40">
                <div className="col-12 col-md-6 col-lg-5">
                  <div className="input-group mb-3">
                    <input
                      id="search4"
                      type="text"
                      className="form-control"
                      placeholder="Search by name or ID"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      onChange={(e) => _this.handleSearch(e.target.value)}
                    ></input>
                    <span className="input-group-text">
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-3 mb-30">
                  <select
                    className="form-select"
                    placeholder="Type"
                    onChange={(e) => _this.handleRequestSelect(e.target.value)}
                    value={_this.checkboxValue}
                  >
                    <option value="1">All</option>
                    <option value="2">Intents</option>
                    <option value="3">Entities</option>
                    <option value="4">Categories</option>
                    <option value="5">Discarded Requests</option>
                  </select>
                </div>
                <div className="col-6 col-md-3 col-lg-2 mb-30">
                  <button className="button -outline-primary bg-white w-100" onClick={_this.searchReq}>
                    SEARCH
                  </button>
                </div>
                <div className="col-6 col-md-3 col-lg-2 mb-30">
                  <button
                    className="button -text w-100"
                    onClick={() => {
                      _this.searchRequests("");
                      document.getElementById("search4").value = "";
                    }}
                  >
                    CLEAR SEARCH
                  </button>
                </div>
              </div>

              <div className="row">
                <div className='col-8'>
                {_this?.requests?.length === 0 ? (
                  <div className="row mb-20">
                    <div className="col-12 col-lg-12">
                      {/* show suggestions image */}
                      <div>
                        <div className="d-flex flex-column align-items-center">
                          <img
                            src={suggestionsImage}
                            alt="Empty inbox"
                            className="mb-20"
                            style={{ width: "200px" }}
                          />
                          <p className="h3 -b">There are no automatic suggestions yet.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {!_this.changePage && _this?.requests?.length > 0 && _this.requests
                        .map((request, index) =>
                            <div key={request.id} className="row mb-20">
                              <div className="col-12 col-lg-11">
                                <a
                                  className="request"
                                  href={`../validaterequest/${request.id}`}
                                >
                                  <div className="ident d-flex flex-column">
                                  <i
                                        className={`
                                          
                                          ${
                                          request.object_type === "intent"
                                            ? "fa-solid fa-code-branch"
                                            : request.object_type === "entity"
                                              ? "fa-solid fa-key"
                                              : "fa-solid fa-star"
                                          }
                                        `}
                                      ></i>
                                      <b className="info color-blue">
                                        {request.object_type}
                                      </b>
                                  </div>
                                <div className="data w-100">
                                  <div className="form-check d-flex justify-content-end px-20">
                                    {request?.suggestion_job?.filename}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={_this?.checked.has(request?.id) || _this?.checkedDiscarded.has(request?.id)}
                                      id="flexCheckDefault"
                                      onChange={() => _this?.handleCheck(request)}
                                    ></input>
                                  </div>
                                  <div>
                                    <p className="h3 -b mb-10">
                                    {request?.activity?.snapshot ? request?.activity?.snapshot?.name : request?.changes.find(item => item.field === 'name')?.value}
                                    </p>
                                    {request?.flags?.discarded ? (
                                      <div className="status -deleted mx-10">
                                        <p className="info">Discarded</p>
                                      </div>
                                    ) : null}
                                    </div>
                                    <div className="row d-flex justify-content-around mt-10">
                                      <div className="col-6 col-md-3 d-flex mb-20">
                                        <i className="fa-solid fa-calendar-days"></i>
                                        <div className="row">
                                          <p className="text">Creation date:</p>
                                          <p className="text -b">
                                            {new Date(_this.dateService.convertIsoUtcToLocal(
                                              request?.activity?.created[0]?.at)
                                            ).toDateString()}
                                          </p>
                                        </div>
                                    </div>
                                      {
                                        request?.details_number ? (
                                          <div className="col-6 col-md-3 d-flex mb-20">
                                            <i
                                              className={`
                                                
                                                ${
                                                request.object_type === "intent"
                                                  ? "fa-solid fa-code-branch"
                                                  : request.object_type === "entity"
                                                    ? "fa-solid fa-key"
                                                    : "fa-solid fa-star"
                                                }
                                              `}
                                            ></i>
                                            <div className="row">
                                            <p className="text"># {
                                        request.object_type === "intent"
                                          ? "Examples"
                                          : request.object_type === "entity"
                                            ? "Synonyms"
                                            : "Details"
                                        }:</p>
                                              <p className="text -b">
                                                {request?.details_number}
                                              </p>
                                            </div>
                                          </div>
                                        ) : (<></>)
                                      }
                                      <div className="col-6 col-md-3 d-flex mb-20">
                                        <i className="fa-solid fa-user"></i>
                                        <div className="row">
                                          <p className="text">Updated by:</p>
                                          <p className="text -b">
                                            {request?.user?.first_name}{" "}
                                            {request?.user?.first_surname}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                        )}
                </>
              )}
              </div>
              {_this?.objectType !== 'discarded' ? (
              <div className="col-4">
                    <div className="card-w -large">
                      <div className="row mb-20">
                        <button
                          className={`button ${_this?.checked?.size === 0 ? '-inactive' : '-primary'} w-100`}
                          disabled={_this?.checked.size === 0}
                          data-bs-toggle="modal"
                          data-bs-target="#validateSuggestionsModal"
                        >
                          APPROVE SELECTED AND CONTINUE
                        </button>
                        {console.log(_this.requests)}
                        {console.log(_this.allRequests)}
                        <button
                          className={`button -text ${_this?.requests?.length === 0 ? '-inactive' : '-red'} mt-10 w-100`}
                          disabled={_this?.requests?.length === 0}
                          data-bs-toggle="modal"
                          data-bs-target="#discardSuggestionsModal"
                          onClick={() => {}}
                        >
                          DISCARD ALL SUGGESTIONS
                        </button>
                      </div>
                      <div className="row mb-20">
                        <div className="col-6">
                          <p className="text -r">Intents Added:</p>
                        </div>
                        <div className="col-6">
                          <p className="text -r text-e">
                            {_this?.intentsSelected}
                          </p>
                        </div>
                      </div>
                      <div className="row mb-20">
                        <div className="col-6">
                          <p className="text -r">Entities Added:</p>
                        </div>
                        <div className="col-6">
                          <p className="text -r text-e">
                            {_this?.entitiesSelected}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-7">
                          <p className="text -r">Categories Added:</p>
                        </div>
                        <div className="col-5">
                          <p className="text -r text-e">
                            {_this?.categoriesSelected}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  ) : (
                    <div className="col-4">
                        <button
                          className={`button ${_this?.checkedDiscarded?.size === 0 ? '-inactive' : '-primary'} w-100`}
                          disabled={_this?.checkedDiscarded.size === 0}
                          data-bs-toggle="modal"
                          data-bs-target="#recoverSuggestionsModal"
                        >
                          RECOVER SELECTED AND CONTINUE
                        </button>
                  </div>
                  )}
                  {_this?.totalPages > 1 && _this.requests?.length > 0 && (
                  <div className="row mt-20 mb-20">
                    <Paginator
                      pages={_this.totalPages}
                      currentPage={_this.page}
                      setPage={_this.handlePageChange}
                    />
                  </div>
                  )}
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
      <Footer />
    </div>
)}


