import React, { useState, useCallback } from 'react';
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import Header from "../../../Components/Header/headerView";
import Footer from "../../../Components/Footer/footer";
import Menu from "../../../Components/Menu/menu";
import Paginator from "../../../Components/Paginator/paginator";
import workspace from "../../../Images/workspace.png";
import { useEffect } from "react";
import workspacesService from '../../../Services/workspacesService';
import usersService from '../../../Services/usersService';
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../../Components/Loader/actions";
import { setActions } from "../../../app/slices/workspaces/workspace";
import WorkspaceEmpty from "../WorkspaceEmpty/workspaceEmpty";
import { setOrganizations } from "../../../app/slices/workspaces/workspace";

const WorkspaceManage = (props) => {

  const [org, setOrg] = useState(props.workspace.organization);

  const [loadData, setLoadData] = useState(true);
  const [allWorkspaces, setAllWorkspaces] = useState([]);
  const [workspaces, setWorkspaces] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const pageSize = 4;

  const [searching, setSearching] = useState(false);

  const dispatch = useDispatch();

  // Clear Search Button
  function handleClearSearchChange() {
    let tempPage = 1;
    setPage(tempPage);
    pageSize < allWorkspaces.length
      ? setTotalPages(Math.ceil(allWorkspaces.length / pageSize))
      : setTotalPages(1);
    let tempInstancesPage = allWorkspaces.slice(
      (tempPage - 1) * pageSize,
      tempPage * pageSize
    );
    setWorkspaces(tempInstancesPage);
    setSearch("");
  }

  // Update Search
  async function handleSearch(value) {
    let search_data = value;
    let tempPage = 1;
    setPage(tempPage);
    let temporarySearch = search_data
      ? allWorkspaces.filter((object) => object?.name?.includes(search_data))
      : allWorkspaces;
    pageSize < temporarySearch.length
      ? setTotalPages(Math.ceil(temporarySearch.length / pageSize))
      : setTotalPages(1);
    let tempInstancesPage = temporarySearch.slice(
      (tempPage - 1) * pageSize,
      tempPage * pageSize
    );
    setWorkspaces(tempInstancesPage);
    setSearching(true);
  }

  // Handle Search Input
  const handleSearchChange = (event) => {
    let search_data = event.target.value;
    setSearch(search_data);
    handleSearch(search_data);
  };

  const getActions = async () => {
    dispatch(startLoading());
    if (workspace) {
      let token = localStorage.getItem("token");
      let response = await usersService.getSuperAdminPermissions(token);
      console.log("Actions", response);
      if (response.data) {
        dispatch(setActions(response.data));
      }
    }
    dispatch(stopLoading());
  };

  // Get All Workspaces
  const getWorkspaces = useCallback(async () => {
    dispatch(startLoading());
    let params = {
      organization: org,
    };


    try{
      let res = await workspacesService.collectManyByOrganizationAdmin(params);
      if (res.status === 200) {
        console.log(res.data);
        setAllWorkspaces(res.data);

        pageSize < res.data.length
        ? setTotalPages(Math.ceil(res.data.length / pageSize))
        : setTotalPages(1);

        let tempInstancesPage = res.data.slice(
          (page - 1) * pageSize,
          page * pageSize
        );
        setWorkspaces(tempInstancesPage);
      }
    }
    catch (error) {
      console.log(error);
    }
    dispatch(stopLoading());
  }, [org]);

  useEffect(() => {
    setOrg(props.workspace.organization);
  }, [props.workspace.organization]);

  const handlePageChange = (page) => {
    setPage(page);
    let tempWorkspacesPage = allWorkspaces.slice(
      (page - 1) * pageSize,
      page * pageSize
    );
    setWorkspaces(tempWorkspacesPage);
  };

  useEffect(() => {
    if (loadData) {
      getActions();
      getWorkspaces();
      setLoadData(false);
    }
  }, [loadData]);

  return (
    <div className={`${props.className}`}>
      <Header />
      <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
        <div className="w-10">
          <Menu activeTab={7} />
        </div>
        <div className="container px-20 mt-50">
          <div className="row mt-20 mb-10">
            <p className="h1 -r">Workspace</p>
          </div>
          
          <div className="row">

          <div className="col-12 col-lg-4 d-flex mt-40">
            <div className="card">
              <div className="row">
                <div className="col-12 col-md-3 mb-10 align-self-center">
                  <img src={workspace} alt="workspaces" />
                </div>

                {/* Esta es la card del SUPER ADMIN */}
                <div className="col-12 col-md-9">
                  <p>
                    <b>Manage NLP Instances</b>
                  </p>
                  <p>
                    Manage all NLP Engine instances of the organization.
                  </p>
                  <div className="row col-md-8 justify-content-start">
                    <a href="/managenlp">
                      <button className="-text justify-content-start w-100 mt-10">
                        MANAGE NLP INSTANCES
                      </button>
                    </a>
                  </div>
                </div>

              </div>
            </div>
          </div>


          <div className="col-12 col-lg-4 d-flex mt-40">
            <div className="card">
              <div className="row">
                <div className="col-12 col-md-3 mb-10 align-self-center">
                  <img src={workspace} alt="workspaces" />
                </div>

                {/* Esta es la card del SUPER ADMIN */}
                <div className="col-12 col-md-9">
                  <p>
                    <b>Import workspace</b>
                  </p>
                  <p>
                    Import a workspace from a NLP Engine instance.
                  </p>
                  <div className="row col-md-8 justify-content-start">
                    <a href="/ImportWorkspace">
                      <button className="-text justify-content-start w-100 mt-10">
                        IMPORT NEW WORKSPACE
                      </button>
                    </a>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 d-flex mt-40">
            <div className="card">
              <div className="row">
                <div className="col-12 col-md-3 mb-10 align-self-center">
                  <img src={workspace} alt="workspaces" />
                </div>

                {/* Esta es la card del SUPER ADMIN */}
                <div className="col-12 col-md-9">
                  <p>
                    <b>Create workspace</b>
                  </p>
                  <p>
                    Create a new workspace in a NLP Engine instance.
                  </p>
                  <div className="row col-md-8 justify-content-start">
                    <a href="/CreateWorkspace">
                      <button className="-text justify-content-start w-100 mt-10">
                        CREATE NEW WORKSPACE
                      </button>
                    </a>
                  </div>
                </div>

              </div>
            </div>
          </div>
          </div>
          {/* 
          
          */}
          <div className="col-12 mt-30 mb-10">
            <div className="row">
              {loadData ? (
                <div className="d-flex justify-content-center align-items-center">
                  <span className="d-flex justify-content-center">
                    <div className="spinner-border m-20" role="status"></div>
                  </span>
                  Loading...
                </div>
              ) : allWorkspaces && allWorkspaces.length > 0 ? (
                <>
                <div className="row mt-40">
                  <div className="col-12 col-md-8">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search workspaces"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        name="searchData"
                        onChange={handleSearchChange}
                        value={search}
                      ></input>
                      <span className="input-group-text">
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </span>
                    </div>
                  </div>
                  <div className="col-6 col-md-3 col-lg-2 mb-10">
                    <button
                      className="button -text w-100"
                      onClick={() => handleClearSearchChange()}
                    >
                      CLEAR SEARCH
                    </button>
                  </div>
                </div>
                {workspaces.map((workspace, index) => (
                  <div className="col-12 col-lg-3 col-md-6 mt-20" key={index}>
                    <div className="card-w d-flex flex-column p-20 mx-10">
                      <Link to={`/EditWorkspace/${workspace.id}`}>
                        <div className="row justify-content-between mx-0 mb-20">
                          <div className="col-11 p-0">
                            <p className="h3">{workspace.name}</p>
                          </div>
                        </div>
                        <div className="row justify-content-between">
                          <div className="col-9">
                            <p className="text mb-10">
                              <i className="fa-solid fa-users color-gray"></i>
                              Users
                            </p>
                            <p className="text mb-10">
                              <i className="fa-solid fa-code-branch color-gray"></i>
                              Intents
                            </p>
                            <p className="text mb-10">
                              <i className="fa-solid fa-key color-gray"></i>
                              Entities
                            </p>
                            <p className="text">
                              <i className="fa-solid fa-star color-gray"></i>
                              Categories
                            </p>
                          </div>
                          <div className="col-3">
                            <p className="text text-c mb-10">
                              {workspace.users}
                            </p>
                            <p className="text text-c mb-10">
                              {workspace.intents}
                            </p>
                            <p className="text text-c mb-10">
                              {workspace.entities}
                            </p>
                            <p className="text text-c mb-10">
                              {workspace.categories}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
                </>
              ) : (
                <>
                <WorkspaceEmpty />
                </>
              )}
            </div>
          </div>
          {allWorkspaces?.length > pageSize && (
            <div>
              <Paginator
                pages={totalPages}
                currentPage={page}
                setPage={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    workspace: state.workspace,
    role: state.user.role,
    user: state.user,
  };
};

export default connect(mapStateToProps)(WorkspaceManage);
