import axios from "axios";
import { Modal } from 'bootstrap';
import { store } from "../app/store";
import { startRequestLoading, stopRequestLoading } from "../Components/RequestLoader/actions";

const w: any = window;
const baseURL = w._env_?.REACT_APP_API_URL || process.env.REACT_APP_API_URL;

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const API = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
    },
    withCredentials: true
});

API.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token && token != null && token !== 'undefined') {
        config.headers.Authorization = `Bearer ${token}`;
    }
    if (['post', 'put', 'patch', 'delete'].includes(config.method)) {
        store.dispatch(startRequestLoading());
    }
    return config;
});

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
    failedQueue.forEach(prom => {
        if (token) {
            prom.resolve(token);
        } else {
            prom.reject(error);
        }
    });

    failedQueue = [];
};

const refreshToken = async () => {
    try {
        console.log("Refreshing token...");
        const refreshToken = localStorage.getItem('refreshToken');
        let body = { token: refreshToken };
        const response = await axios.post(`${baseURL}/users/refreshtoken`, body, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        console.log("Token refreshed:", response.data);

        const { access_token, refresh_token } = response.data;

        localStorage.setItem('token', access_token);
        localStorage.setItem('refreshToken', refresh_token);
        return access_token;
    } catch (error) {
        console.log("Error refreshing token:", error);
        if (error.response.status === 401) {
            console.log("Token refresh failed, logging out...");
            const modalElement = document.getElementById('permissionsUpdatedModal');
            if (modalElement) {
                const modal = new Modal(modalElement);
                modal.show();
            }
        }
        else {
            console.error("Error refreshing token:", error);
            return Promise.reject
        }
    }
};

API.interceptors.response.use(
    response => {
        if (['post', 'put', 'patch', 'delete'].includes(response.config.method)) {
            store.dispatch(stopRequestLoading());
        }
        return response;
    },
    async error => {
        const originalRequest = error.config;
        console.log("Response error detected", error);

        if (error.response.status === 401 && !originalRequest._retry) {
            console.log("Error 401 detected, attempting to refresh token");

            if (isRefreshing) {
                console.log("Currently refreshing token, queuing request");
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject });
                }).then(token => {
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    return axios(originalRequest);
                }).catch(err => {
                    return Promise.reject(err);
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            try {
                // await delay(20000);
                const newToken = await refreshToken();
                processQueue(null, newToken);
                originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
                console.log("Retrying original request with new token");
                await delay(1500);
                return axios(originalRequest);
            } catch (err) {
                processQueue(err, null);
                return Promise.reject(err);
            } finally {
                isRefreshing = false;
            }
        } else {
            store.dispatch(stopRequestLoading());
            const modalElement = document.getElementById('errorModal');
            if (modalElement && !isRefreshing) {
    
                const modalInstance = Modal.getInstance(modalElement) || new Modal(modalElement);
                modalInstance.show();

            }
            console.log("Error is not 401 or retry already attempted");
        }
        if (['post', 'put', 'patch', 'delete'].includes(error.config.method)) {
            store.dispatch(stopRequestLoading());
        }

        return Promise.reject(error);
    }
);

export { baseURL };
export default API;
