import React, { Component } from 'react';
import Header from '../../../Components/Header/headerView';
import Footer from '../../../Components/Footer/footer';
import Menu from '../../../Components/Menu/menu';
import emptyWorkspace from '../../../Images/emptyWorkspace.png';

class WorkspaceEmpty extends Component {
  render() {
    return (
      <div>
        {/* // <!-- Modal Create New Workspace --> */}
        <div className="modal fade" id="NewWorkspaceModal" tabIndex="1" aria-labelledby="NewWorkspaceModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <p className="h2" id="NewWorkspaceModalLabel">Create new workspace</p>
                <button type="button" className="button-close w-100" data-bs-dismiss="modal" aria-label="Close">X</button>
              </div>
              <div className="modal-body form-group">
                <input className="col-12 input-field" type="text" placeholder="Workspace name" />
              </div>
              <div className="modal-footer">
                <div className="row w-100">
                  <div className="col-12 col-md-4 mb-10">
                    <button className='button -text w-100' data-bs-dismiss="modal">CANCEL</button>
                  </div>
                  <div className="col-12 col-md-8 mb-10">
                    <button className='button -primary w-100'>CREATE WORKSPACE</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
            <div className="row mt-30 justify-content-center">
              <div className="col-12 col-sm-3 mt-10 mb-20">
                <img src={emptyWorkspace} alt="Workspace"/>
              </div>
                <p className="h2 -r text-c">You don’t have any Workspaces</p>
                <p className="h4 mt-10 text-c">
                  Create a new workspace in which you could assign users
                </p>
              <div className="col-6 col-md-4 col-lg-2 mt-10 mb-50">
                <a href="/createworkspace">
                  <button className="-text w-100">Create new workspace</button>
                </a>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

export default WorkspaceEmpty;
