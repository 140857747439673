import Header from '../../../Components/Header/headerView';
import Footer from '../../../Components/Footer/footer';
import Menu from '../../../Components/Menu/menu';
import NDSTooltip from '../../../Components/Tooltip/ndsTooltip';
import Paginator from '../../../Components/Paginator/paginator';
import { Form, Field } from 'react-final-form';
import Select from 'react-select';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Modal } from 'bootstrap';
import { Link } from 'react-router-dom';


export const CreateIntentScreen = ({_this}) => {
return (
    <div className={`${_this.props.className}`}>
        {/* // <!-- Modal create intent--> */}
        <div
        className="modal fade"
        id="createIntentModal"
        tabIndex="1"
        aria-labelledby="createIntentModalLabel"
        aria-hidden="true"
        >
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-header">
                <p className="h2" id="createIntentModalLabel">
                Create request
                </p>
                <button
                type="button"
                className="button-close w-100"
                data-bs-dismiss="modal"
                aria-label="Close"
                >
                X
                </button>
            </div>
            <div className="modal-body">
                <p className="text">
                There are no examples added, are you sure you want to continue?
                Adding examples will help to more easily recognize the
                intentions.
                </p>
            </div>
            <div className="modal-footer">
                <div className="row w-100">
                <div className="col-12 col-md-6 mb-10">
                    <button
                    className="button -text w-100"
                    data-bs-dismiss="modal"
                    >
                    RETURN
                    </button>
                </div>
                {_this.createIntentPermission && (
                <div className="col-12 col-md-6 mb-10">
                    <button
                    onClick={() => _this.createIntent()}
                    className="button -primary w-100"
                    >
                    CREATE REQUEST
                    </button>
                </div>
                )}
                </div>
            </div>
            </div>
        </div>
        </div>
        {_this.createModal()}
        <Header />
        <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
        <div className="w-10">
            <Menu activeTab={2} />
        </div>
        <div className="container px-20 mt-20 mb-50">
            <Form
            onSubmit={() => {
                new Modal(document.getElementById("approveModal")).show();
            }}
            render={({ form, handleSubmit }) => (
                <form onSubmit={_this.handleSubmit}>
                <div className="row">
                    <div className="col-12 col-lg-9 my-20">
                    <div className="card-w -m">
                        <div className="row">
                        <p className="h3 -b">Create Request</p>
                        </div>
                        <div className="row">
                        <div className="col-12 col-lg-9 w-100">
                            <div className="form-group mt-40">
                            <Field
                                name="name"
                                validate={_this.composeValidators(
                                _this.required,
                                _this.name,
                                _this.namePrefix,
                                _this.maxLength(128),
                                _this.validateUniqueName
                                )}
                            >
                                {(props) => (
                                <>
                                    <input
                                    {...props.input}
                                    type="text"
                                    name="name"
                                    placeholder='Ej. "GetWeather"'
                                    autoComplete="off"
                                    required={true}
                                    id="name"
                                    value={_this.inputName}
                                    onChange={(e) => [
                                        _this.setInputName(e.target.value),
                                        props.input.onChange(e),
                                        _this.handleChangeError(e, "name"),
                                    ]}
                                    onBlur={(e) => [props.input.onBlur(e)]}
                                    style={
                                        props.meta?.error && props.meta.touched
                                        ? { borderColor: "red" }
                                        : null
                                    }
                                    />
                                    <label
                                    htmlFor="name"
                                    data-toggle="tooltip"
                                    title="Intent names can contain only Unicode alphanumeric, underscore, hyphen, and dot characters. It can not be empty or longer than 128 characters."
                                    style={
                                        props.meta?.error && props.meta.touched
                                        ? { color: "red" }
                                        : null
                                    }
                                    >
                                    {props.meta.error && props.meta.touched ? (
                                        <span className="error">
                                        <i className="fas fa-exclamation-circle fa-sm"></i>
                                        {props.meta.error}
                                        </span>
                                    ) : _this.errorUniqueName ? (
                                        <span className="error" style={{color: "red"}}>
                                        <i className="fas fa-exclamation-circle fa-sm"></i>
                                        Intent already exists
                                        </span>
                                    ): (
                                        "Intent name"
                                    )}
                                    </label>
                                </>
                                )}
                            </Field>
                            </div>
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-12">
                            <div className="form-group mt-40">
                            <Field
                                name="description"
                                initialValue={_this.inputDescription}
                                validate={_this.composeValidators(
                                _this.description,
                                _this.maxLength(128)
                                )}
                            >
                                {(props) => (
                                <>
                                    <input
                                    {...props.input}
                                    type="text"
                                    name="description"
                                    placeholder='Ej. "Get the weather for a city"'
                                    autoComplete="off"
                                    id="description"
                                    value={_this.inputDescription}
                                    onChange={(e) => [
                                        _this.setInputDescription(e.target.value),
                                        props.input.onChange(e),
                                        _this.handleChangeError(e, "description"),
                                    ]}
                                    onBlur={(e) => [props.input.onBlur(e)]}
                                    style={
                                        props.meta?.error && props.meta?.touched
                                        ? { borderColor: "red" }
                                        : null
                                    }
                                    />
                                    <label
                                    htmlFor="description"
                                    data-toggle="tooltip"
                                    title="This value cannot contain carriage return, newline, or tab characters. It can not be empty or longer than 128 characters."
                                    style={
                                        props.meta?.error && props.meta?.touched
                                        ? { color: "red" }
                                        : null
                                    }
                                    >
                                    {props.meta.error && props.meta.touched ? (
                                        <span className="error">
                                        <i className="fas fa-exclamation-circle fa-sm"></i>
                                        {props.meta.error}
                                        </span>
                                    ) : (
                                        "Intent description"
                                    )}
                                    </label>
                                </>
                                )}
                            </Field>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-12 col-lg-3 mt-20">
                    <div className="row">
                        <button
                        type="button"
                        className={`button w-100 ${_this.validationError ? '-inactive' : '-primary'}`}
                        onClick={() => _this.createIntent()}
                        disabled={_this.validationError}
                        >
                            CREATE REQUEST
                        </button>
                    </div>
                    </div>
                </div>
                <div className="row ">
                    <Nav tabs className="col-12 d-flex">
                    <NavItem>
                        <NavLink
                        className={{ active: _this.activeTab === "1" }}
                        onClick={() => {
                            _this.toggle("1");
                        }}
                        >
                        <span>
                            <p className="icon">
                            <i className="fa-solid fa-lines-leaning"></i>
                            </p>
                            <p className="h3 -b">EXAMPLES</p>
                        </span>
                        </NavLink>
                    </NavItem>
                    </Nav>
                </div>
                <TabContent activeTab={_this.activeTab}>
                    <TabPane tabId="1">
                    <div className="row">
                        <div className="col-12 col-lg-9">
                        <div className="card-w -m my-40">
                            <div className="row mb-10">
                            <p className="h4">
                                Write examples of what users are trying to say
                            </p>
                            </div>
                            <div className="row">
                            <div className="form-group mt-40">
                                <Field
                                name="example"
                                validate={_this.composeValidators(
                                    _this.example,
                                    _this.exampleExists,
                                    _this.empty,
                                    _this.maxLength(1024)
                                )}
                                >
                                {(props) => (
                                    <>
                                    <input
                                        {...props.input}
                                        type="text"
                                        name="example"
                                        placeholder="Ej. I want a credit card"
                                        autoComplete="off"
                                        required={false}
                                        id="example"
                                        value={_this.inputExample}
                                        onChange={(e) => [
                                        _this.setInputExample(e.target.value),
                                        props.input.onChange(e),
                                        ]}
                                        onBlur={(e) => props.input.onBlur(e)}
                                        style={
                                        props.meta?.error && props.meta?.touched
                                            ? { borderColor: "red" }
                                            : null
                                        }
                                    />

                                    <label
                                        htmlFor="example"
                                        data-toggle="tooltip"
                                        title="Examples cannot contain carriage return, newline, or tab characters. It can not be empty or longer than 128 characters."
                                        style={
                                        props.meta?.error && props.meta?.touched || (_this.listExamples.length === 0 && !props.meta?.error)
                                            ? { color: "red" }
                                            : null
                                        }
                                    >
                                        {props.meta.error &&
                                        props.meta.touched ? (
                                        <span className="error">
                                            <i className="fas fa-exclamation-circle fa-sm"></i>
                                            {props.meta.error}
                                        </span>
                                        ) : _this.listExamples.length === 0 && !props.meta?.error ? (
                                        <span className="error">
                                        <i className="fas fa-exclamation-circle fa-sm"></i>
                                            You must add at least 1 example
                                        </span>
                                        ): (
                                        "Example"
                                        )}
                                    </label>
                                    </>
                                )}
                                </Field>
                            </div>
                            </div>
                            <div className="row mt-20">
                            {!_this.exampleEdit ? (
                                <div className="col-12 col-md-4 col-lg-3">
                                <button
                                    className={`button w-100 -outline-primary -${
                                        _this.inputExample &&
                                    !form.getFieldState("example").error
                                        ? "active"
                                        : "inactive"
                                    }`}
                                    onClick={(e) => {
                                    e.preventDefault();
                                    if (
                                        !_this.exampleEdit &&
                                        !form.getFieldState("example")?.error
                                    )
                                    _this.addExample(e);
                                    }}
                                    disabled={
                                    !_this.inputExample &&
                                    !form.getFieldState("example")?.error
                                    }
                                >
                                    ADD EXAMPLE
                                </button>
                                </div>
                            ) : (
                                <>
                                <div className="col-12 col-md-4 col-lg-3">
                                    <button
                                    className="button -outline-secondary w-100"
                                    onClick={() => {
                                        _this.cancelEdition();
                                    }}
                                    >
                                    CANCEL
                                    </button>
                                </div>
                                <div className="col-12 col-md-4 col-lg-3">
                                    <button
                                    className={`button -primary w-100 ${form.getFieldState("example")?.error ? '-inactive' : '-primary'}`}
                                    onClick={() => {
                                        _this.editExample(_this.inputExample, _this.exampleEdit);
                                    }}
                                    >
                                    SAVE CHANGES
                                    </button>
                                </div>
                                </>
                            )}
                            </div>
                            <div className="row mt-50">
                            <div className="col-12">
                                <div className="bg-light">
                                </div>
                                <table>
                                <thead>
                                    <tr>
                                    <th>
                                        <input
                                        className="form-check-input -white"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckDefault"
                                        ></input>
                                    </th>
                                    <th>
                                        <p className="d-flex align-items-center w-100">
                                        Examples{" "}
                                        {/*<button className="i -white w-15">
                                            <i className="fa-solid fa-arrow-up-a-z"></i>
                                        </button>*/}
                                        </p>
                                    </th>
                                    <th>
                                        
                                    </th>
                                    <th className="row m-10 align-items-center">
                                        Actions
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_this.listExamples.map((example, index) => (
                                    <tr>
                                        <td>
                                        <input
                                            className="form-check-input -blue"
                                            type="checkbox"
                                            value=""
                                            id="flexCheckDefault"
                                        ></input>
                                        </td>
                                        <td value={example}>{example}</td>
                                        <td>
                                            <div className='mx-5'></div>
                                        </td>
                                        <td>
                                        <div className="row">
                                            <button
                                            className="button -icon"
                                            onClick={(e) => [
                                                e.preventDefault(),
                                                _this.handleEdition(
                                                  e,
                                                  example
                                                ),
                                              ]}
                                            >
                                            <span className="material-icons-outlined">
                                                <NDSTooltip
                                                icon="fa-solid fa-pencil"
                                                target="edit"
                                                tooltipMessage="Edit"
                                                placement="bottom"
                                                ></NDSTooltip>
                                            </span>
                                            </button>
                                            <button
                                            className="button -icon -danger"
                                            onClick={() =>
                                                _this.removeExample(example)
                                            }
                                            >
                                            <span className="material-icons-outlined">
                                                <NDSTooltip
                                                icon="fa-solid fa-trash-can"
                                                target="trash"
                                                tooltipMessage="Delete"
                                                placement="bottom"
                                                ></NDSTooltip>
                                            </span>
                                            </button>
                                        </div>
                                        </td>
                                    </tr>
                                    ))}
                                </tbody>
                                </table>
                            </div>
                            </div>
                            <div className="row mt-20">
                            <Paginator />
                            </div>
                        </div>
                        </div>
                    </div>
                    </TabPane>
                    <TabPane tabId="2">
                    <div className="row">
                        <div className="col-12 col-lg-9">
                        <div className="card-w -m my-40">
                            <div className="row mb-10">
                            <p className="h4">
                                You can match Intent with entities for example the
                                Intent <b>"Cancel</b> match with the entities{" "}
                                <b>“Credit card”, “Account”, etc.</b> it’s posible
                                match more than one entity for each intent, If you
                                don’t already have entities, you won’t be able to
                                join them, but you can always linked after.
                            </p>
                            </div>
                            <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="form-group mt-40">
                                <Select
                                    placeholder="search by previously created entity name"
                                    isMulti
                                    name="colors"
                                    options={_this.entitiesOptions}
                                    onChange={_this.setSelectedLinkedEntities}
                                    value={_this.selectedLinkedEntities}
                                    className="basic-multi-select w-100"
                                    classNamePrefix="select"
                                ></Select>
                                </div>
                            </div>
                            </div>
                            <div className="row mt-20">
                            <div className="col-12 col-md-4">
                                <button
                                className={`button -outline-primary w-100 ${
                                    _this.selectedLinkedEntities.length > 0
                                    ? "-active"
                                    : "-inactive"
                                }`}
                                onClick={(e) => [
                                    e.preventDefault(),
                                    _this.addLinkedEntity(),
                                ]}
                                >
                                ADD LINKED ENTITIES
                                </button>
                            </div>
                            </div>
                            <div className="row mt-50">
                            <div className="col-12">
                                <table>
                                <thead>
                                    <tr>
                                    <th>
                                        <p className="d-flex align-items-center w-100">
                                        Entities{" "}
                                        {/*<button className="i -white w-15">
                                            <i className="fa-solid fa-arrow-up-a-z"></i>
                                        </button>*/}
                                        </p>
                                    </th>
                                    <th>
                                        <p className="d-flex align-items-center w-100">
                                        Synonyms{" "}
                                        {/*<button className="i -white w-15">
                                            <i className="fa-solid fa-arrow-down-1-9"></i>
                                        </button>*/}
                                        </p>
                                    </th>
                                    <th className="row m-10 align-items-center">
                                        Actions
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {_this.linkedEntities.length > 0 &&
                                    _this.linkedEntities.map((entity, index) => (
                                        <tr>
                                        <td>
                                            {" "}
                                            <Link
                                            onClick={() => {
                                                window.open(
                                                `/entitiesdetail/${entity.value}`
                                                );
                                            }}
                                            >
                                            {entity.label}
                                            </Link>
                                        </td>
                                        <td>{entity.synonyms}</td>
                                        <td>
                                            <button
                                            className="button -icon -danger w-100"
                                            onClick={(e) => [
                                                e.preventDefault(),
                                                _this.removeLinkedIntent(index),
                                            ]}
                                            >
                                            <span className="material-icons-outlined w-100">
                                                <NDSTooltip
                                                icon="fa-solid fa-trash-can"
                                                target="trash"
                                                tooltipMessage="Delete"
                                                placement="bottom"
                                                ></NDSTooltip>
                                            </span>
                                            </button>
                                        </td>
                                        </tr>
                                    ))}
                                </tbody>
                                </table>
                            </div>
                            </div>
                            <div className="row mt-20">
                            <Paginator />
                            </div>
                        </div>
                        </div>
                    </div>
                    </TabPane>
                </TabContent>
                </form>
            )}
            />
        </div>
        </div>
        <Footer />
    </div>
    );
}