import { get } from "http";
import API from "./axios";

const requestsService = {
    //Collect a request with details (last activity, last user, original object, request) by the provided object ID.
    collect_one_with_details: async (params) => {
        return await API.get("/requests/collect_one_with_details", { params });
    },

    collect_many_with_details: async (params) => {
        return await API.get("/requests/collect_many_with_details", { params });
    },

    //Creates a new request in the database.
    create: async (params, body) => {
        return await API.post("/requests/create", body, { params });
    },

    //Retrieves a request based on the provided object ID or multiple requests tied to an entity, category or intent.
    get: async (params) => {
        return await API.get("/requests/get", { params });
    },

    //Retrieves all requests in a workspace.
    get_all: async (params) => {
        return await API.get("/requests/get_all", { params });
    },

    //Edits (Editor role) a request in the database.
    edit: async (params, body) => {
        return await API.post("/requests/edit", body, { params });
    },

    //Validates a request in the database.
    validate: async (params, body) => {
        return await API.post("/requests/validate", body, { params });
    },

    //Deploys a request in the database.
    deploy: async (params) => {
        return await API.post("/requests/deploy", {}, { params });
    },

    //Closes a request in the database.
    close: async (params, body) => {
        return await API.post("/requests/close", body, { params });
    },

    //Rolls back a request in the database.
    rollback: async (params, body) => {
        return await API.post("/requests/rollback", body, { params });
    },

    validateMany: async (params, body) => {
        return await API.post("/requests/validate_many", body, { params });
    },

    recover: async (params, body) => {
        return await API.post("/requests/recover_requests", body, { params });
    },

    discardAllSuggestions: async (params, body) => {
        return await API.post("/requests/discard_all_suggestions", body, { params });
    },

    discardSuggestion: async (params, body) => {
        return await API.post("/requests/discard_suggestion", body, { params });
    },

};

export default requestsService;