import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import Header from '../../../Components/Header/headerView';
import Footer from '../../../Components/Footer/footer';
import Menu from '../../../Components/Menu/menu';
import suggestion from '../../../Images/suggestions.png';
import history from '../../../Images/history.png';
import AnalyzingComponent from './analyzingComponent';


const SuggestionsEmpty = (props) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

    return (
      <div className={`${props.className}`}>
        <Header/>
        <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
          <div className="w-10">
            <Menu  activeTab={5}/>
          </div>
          <div className="container px-20 mb-50">
            <div className="my-50">
              <p className="h1 -r">Suggestions</p>
            </div>
            <div className="row my-l">
            <Nav tabs className='col-10 d-flex'>
              <NavItem>
                <NavLink
                  className={`nav-link ${activeTab === '1' ? 'active' : ''}`}
                  onClick={() => { toggle('1'); }}
                >
                  <span>
                    <p className="icon"><i className="fa-solid fa-upload"></i></p>
                    <p className="h3 -b">UPLOAD FILES FOR ANALYSIS</p>
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`nav-link ${activeTab === '2' ? 'active' : ''}`}
                  onClick={() => { toggle('2'); }}
                >
                  <span>
                    <p className="icon"><i className="fa-solid fa-book"></i></p>
                    <p className="h3 -b">ANALYZED FILES</p>
                  </span>
                </NavLink>
              </NavItem>
            </Nav>
            </div>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
              <AnalyzingComponent jobNames={[]}></AnalyzingComponent>
              </TabPane>
              <TabPane tabId="2">
                <div className="row my-50">
                  <div className="col-12 col-md-10 col-lg-5">
                    <div className="card">
                      <p className="h4 -l"> Previously analyzed files will appear in this section.</p>
                    </div>
                  </div>
                  <div className='col-12 col-lg-10'>
                    <div className="row my-50 justify-content-center">
                      <div className="col-3">
                        <img src={history} alt="history" />
                      </div>
                    </div>
                    <div className="row my-10">
                      <p className="h4 -l color-gray text-c">You don't  have analyzed files.</p>
                    </div>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }

export default SuggestionsEmpty;
