import React, { Component } from 'react';
import Header from '../../../Components/Header/headerView';
import Footer from '../../../Components/Footer/footer';
import Menu from '../../../Components/Menu/menu';
import Users from '../../../Images/users.png';

class UsersEmpty extends Component {


    render() {
        return (
            <div>
                <Header/>
                <div className="conteiner-fluid px-0 m-0 d-flex flex-column flex-md-row">
                    <div className="w-10">
                        <Menu activeTab={6}/>
                    </div>
                <div className='col-12 col-lg-10'>
                    <div className="row mt-50 justify-content-center">
                        <div className="col-12 col-md-4 col-sm-10 mt-50 mb-50">
                            <img src={Users} alt="Users" />
                        </div>
                        <div className="row">
                            <p className="h1 -r text-c">
                            You don’t have any users
                            </p>
                            <p className="h4 mt-20 text-c">
                            Register users to use the platform
                            </p>
                        </div>
                        <div className="col-6 col-md-4 col-lg-2 mt-30 mb-100">
                            <a href="/CreateUser"><button className="button -text w-100">CREATE NEW USER</button></a>
                        </div>
                    </div>
                </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default UsersEmpty;
