import React from 'react';
import { useSelector } from 'react-redux';
import './loader.scss';

const RequestLoader = () => {
  const isLoading = useSelector((state) => state.loadingRequest.isLoading);

  if (!isLoading) return null;

  return (
      <div className="request-loader-overlay">
        <div className="spinner"></div>
      </div>
  );
};

export default RequestLoader;