import React, { Component } from 'react';
import Header from '../../Components/Header/headerView';
import Footer from '../../Components/Footer/footer';
import Menu from '../../Components/Menu/menu';
import { store } from '../../app/store';
import { connect } from 'react-redux';
import {setActions } from "../../app/slices/workspaces/workspace";
import usersService from '../../Services/usersService';
import workspacesService from '../../Services/workspacesService';
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../Components/Loader/actions";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workspace: this.props.workspace,
      entitiesCount: 0,
      intentsCount: 0,
      categoriesCount: 0,
    }
  }

  componentDidMount() {
    this.getWorkspace(store.getState().workspace.id);
  }

  componentDidUpdate(prevProps, prevState) {
    if (store.getState().workspace.id !== this.state.workspace) {
      this.getWorkspace(store.getState().workspace.id);
    }
    //console.log("Props", this.props);
    //console.log("State", store.getState().workspace);
  }

  delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async getWorkspace (workspace_id) {
    this.props.startLoading();
    if (!workspace_id) { 
      console.error('No se encontró el workspace, está vacio')
    } else {
      try {
      const res = await workspacesService.getCount(workspace_id);
        this.setState({
          entitiesCount: res.data.entities,
          intentsCount: res.data.intents,
          categoriesCount: res.data.categories,
          validator_entity: res.data.validation_requests_entity,
          deployer_entity: res.data.deployment_requests_entity,
          manual_entity: res.data.manual_requests_entity,
          automatic_entity: res.data.automatic_requests_entity,
          validator_intent: res.data.validation_requests_intent,
          deployer_intent: res.data.deployment_requests_intent,
          manual_intent: res.data.manual_requests_intent,
          automatic_intent: res.data.automatic_requests_intent,
          validator_category: res.data.validation_requests_category,
          deployer_category: res.data.deployment_requests_category,
          manual_category: res.data.manual_requests_category,
          automatic_category: res.data.automatic_requests_category,
          workspace: workspace_id,
        });
      } catch (err) {
        console.error(err);
      }
    }
    await this.delay(2000);
    this.props.stopLoading();
  }

  render() {
    
    return (
      <div>
        <Header />
        <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
          <div className="w-10">
            <Menu activeTab={0} />
          </div>
          <div className="container px-20 mb-50">
            <div className="my-50">
              <p className="h1 -r">
                {store.getState().workspace.name
                  ? `${store.getState().workspace.name} Workspace`
                  : ""}
              </p>
              <p className="h4 color-gray">{`Welcome back ${
                store.getState().user.first_name
              }!`}</p>
            </div>
            <div className="row mb-20">
              <div className="col-12 col-sm-6 col-lg-3 mb-20">
                <div className="bg-black p-20">
                  <div className="col-12 d-flex">
                    <p className="info color-white">
                      <i className="fa-solid fa-code-branch"></i>
                    </p>
                    <p className="h3 -l color-white">Intents</p>
                  </div>
                  <div className="col-12">
                    <p className="h3 -b color-white">
                      {this.state.intentsCount}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-3">
                <div className="bg-black p-20">
                  <div className="col-12 d-flex">
                    <p className="info color-white">
                      <i className="fa-solid fa-key"></i>
                    </p>
                    <p className="h3 -l color-white">Entities</p>
                  </div>
                  <div className="col-12">
                    <p className="h3 -b color-white">
                      {this.state.entitiesCount}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-3">
                <div className="bg-black p-20">
                  <div className="col-12 d-flex">
                    <p className="info color-white">
                      <i className="fa-solid fa-star"></i>
                    </p>
                    <p className="h3 -l color-white">Categories</p>
                  </div>
                  <div className="col-12">
                    <p className="h3 -b color-white">
                      {this.state.categoriesCount}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-100">
              <div className="col-12 col-lg-6 mb-md-20">
                <div className="card">
                  <div className="row">
                    <div className="row mb-40">
                      <p className="h3 -l">In validation process</p>
                    </div>
                  </div>
                  <div className="row mb-10">
                  
                  </div>
                  <div className="row mt-20">
                    <div className="col-12 col-md-6">
                      <p className="h3 mb-10">Intents</p>
                      <p className="text">On validator dashboard</p>
                      <div className="data -validation mb-20">
                        <p className="text -b">
                          {this.state?.validator_intent ?? -1}
                        </p>
                      </div>
                      <p className="text">On deployer dashboard</p>
                      <div className="data -deploying mb-20">
                        <p className="text -b">
                          {this.state?.deployer_intent ?? -1}
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <p className="h3 mb-10">Entities</p>
                      <p className="text">On validator dashboard</p>
                      <div className="data -validation mb-20">
                        <p className="text -b">
                          {this.state?.validator_entity ?? -1}
                        </p>
                      </div>
                      <p className="text">On deployer dashboard</p>
                      <div className="data -deploying mb-20">
                        <p className="text -b">
                          {this.state?.deployer_entity ?? -1}
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <p className="h3 mb-10">Categories</p>
                      <p className="text">On validator dashboard</p>
                      <div className="data -validation mb-20">
                        <p className="text -b">
                          {this.state?.validator_category ?? -1}
                        </p>
                      </div>
                      <p className="text">On deployer dashboard</p>
                      <div className="data -deploying mb-20">
                        <p className="text -b">
                          {this.state?.deployer_category ?? -1}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="card">
                  <div className="row mb-40">
                    <p className="h3 -l">How it was added?</p>
                  </div>
                  <div className="row mt-20">
                    <div className="col-12 col-md-6">
                      <p className="h3 mb-10">Intents</p>
                      <p className="text">Manually</p>
                      <div className="number mb-20">
                        <p className="text -b">
                          {this.state?.manual_intent ?? -1}
                        </p>
                      </div>
                      <p className="text">Automatic suggestions</p>
                      <div className="number mb-20">
                        <p className="text -b">
                          {this.state?.automatic_intent ?? -1}
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <p className="h3 mb-10">Entities</p>
                      <p className="text">Manually</p>
                      <div className="number mb-20">
                        <p className="text -b">
                          {this.state?.manual_entity ?? -1}
                        </p>
                      </div>
                      <p className="text">Automatic suggestions</p>
                      <div className="number mb-20">
                        <p className="text -b">
                          {this.state?.automatic_entity ?? -1}
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <p className="h3 mb-10">Categories</p>
                      <p className="text">Manually</p>
                      <div className="number mb-20">
                        <p className="text -b">
                          {this.state?.manual_category ?? -1}
                        </p>
                      </div>
                      <p className="text">Automatic suggestions</p>
                      <div className="number mb-20">
                        <p className="text -b">
                          {this.state?.automatic_category ?? -1}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  workspace: state.workspace.id,
  user: state.user
});

const mapDispatchToProps = (dispatch) => {
  return {
      startLoading: () => dispatch(startLoading()),
      stopLoading: () => dispatch(stopLoading())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
