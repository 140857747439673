import React, { useState, useEffect, useCallback } from "react";
//import categoriesService from "../../../Services/categoriesService";
//import * as dateService from "../../../Services/format/date";
//import * as stringService from "../../../Utils/format/string";
import { connect } from "react-redux";
import { store } from "../../../app/store";
import FileSaver from "file-saver";
import { CategoriesScreen } from "./categoriesScreen";
import { humanizeDateTime } from "../../../Utils/format/date";
import categoriesService from "../../../Services/categoriesService";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../../Components/Loader/actions";

const Categories = (props) => {
  const workspace = props.workspace.id;
  const [activeTab, setActiveTab] = useState("1");
  const [loadData, setLoadData] = useState(true);

  const [searchData, setSearchData] = useState("");
  const [searchDataPrev, setSearchDataPrev] = useState("");
  //const searchRef = useRef();

  const [categories, setCategories] = useState([]);
  const [sort, setSort] = useState({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;

  const [checked, setChecked] = useState(new Set());
  const [allChecked, setAllChecked] = useState(false);
  const [createCategoryPermission, setCreateCategoryPermission] = useState(props?.workspace?.actions?.categories?.create ?? false);

  const dispatch = useDispatch();

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const formatSort = (_sort) => {
    const sortKeys = {
      Entities: "name.value",
      Status: "status.value",
      Description: "description.value",
      "Intents linked": "intents",
      Synonyms: "synonyms",
      Conflicts: "conflicts",
      Modified: "updated_on",
    };
    let sort = Object.assign(
      {},
      ...Object.entries(_sort).map(([k, x]) => ({
        [sortKeys[k]]: x.direction,
      }))
    );
    delete sort[undefined];
    return sort;
  };

  //Get category categories
  const getCategories = useCallback(
    async (page = 0, search = "", _sort = {}) => {
      dispatch(startLoading());
      
      let params = {
        workspace_id: workspace,
        limit: pageSize,
        page,
        search,
        //sort: formatSort(_sort),
      };

      var tempCategories = [];
      try{
        let res = await categoriesService.collectAll(params)
          tempCategories = res.data.categories ?? [];
          for (var i = 0; i < tempCategories.length; i++) {
            tempCategories[i].updated_on = humanizeDateTime(
              tempCategories[i].created[0].at
            );
          }

          let totalRecords = res.data?.total ?? 0;
          console.log("Total Records", totalRecords);

          pageSize < totalRecords
            ? setTotalPages(Math.ceil(totalRecords / pageSize))
            : setTotalPages(1);
          console.log("Temppp");
          console.log(tempCategories);
          setCategories(tempCategories);
          setLoadData(false);
      }
        catch (err) {
          console.log(err);
        }   
      dispatch(stopLoading());
      return tempCategories;
      
    },
    [workspace]
  );

  // Clear Search Button
  function handleClearSearchChange() {
    setPage(1);
    setSearchDataPrev("");
    setSearchData("");
    setChecked(new Map());
  }

  // Handle page change
  const handlePageChange = async (page) => {
    setPage(page);
    var categories = await getCategories(
      page - 1,
      searchData,
      sort
    );
    if (allChecked) {
      setChecked(new Set([...checked, ...categories.map((e) => e.id)]));
    }
  };

  const handleCheck = (entity) => {
    var newChecked = new Set(checked);
    var isChecked = newChecked.has(entity?.id);
    isChecked ? newChecked.delete(entity?.id) : newChecked.add(entity?.id);
    if (allChecked && isChecked) {
      setAllChecked(false);
    }
    setChecked(newChecked);
  };

  const handleAllCheck = (event, value) => {
    if (event.target.checked) {
      setChecked(new Set([...checked, ...categories.map((e) => e.id)]));
    } else {
      setChecked(new Set());
    }
    setAllChecked(event.target.checked);
  };

  const handleSearchSubmit = (event) => {
    handleSearch();
    event.preventDefault();
  };

  const handleSearchInput = (event) => {
    setSearchDataPrev(event.target.value);
  };

  async function handleSearch() {
    setPage(1);
    setSearchData(searchDataPrev);
  }

  useEffect(() => {
    if (loadData) {
      getCategories();
    }
  }, [loadData, getCategories]);

  const handleSort = (e) => {
    let header = e.target.parentElement.parentElement.innerText;
    let className = e.target.className;
    let dir = className.includes("-down-") ? 1 : -1;

    let newDir = dir === 1 ? -1 : 1;
    const t = {
      arrow: ["-up-", "-down-"],
      letter: ["-z-a", "-a-z"],
      number: ["-9-1", "-1-9"],
    };
    let newClassName = className;
    Object.entries(t).forEach(([k, v]) => {
      newClassName = newClassName.replace(...(newDir > 0 ? v : v.reverse()));
    });
    let newClicks = (sort[header]?.clicks ?? 0) + 1;

    Object.keys(sort).forEach((k) => delete sort[k]); // TODO: Admitir múltiples

    sort[header] = {
      direction: newDir,
      className: newClassName,
      clicks: newClicks,
    };
    setSort({ ...sort });
    handlePageChange(page);
  };

  async function handleDownloadSelection(e, list) {
    e.preventDefault();

    let data = list;
    console.log("Data", data);

    data = data.filter((e) => checked.has(e.id));

    let categoriesFetched = [];
    data = await Promise.all(
      data.map(async (e) => {
        let params = {
          workspace_id: workspace,
          category_id: e.id,
        };
        let res = await categoriesService.collectOne(params)
        categoriesFetched.push(res.data)
        console.log("Category", res.data);
      })
    );
    console.log("Categories", categoriesFetched);

    data = categoriesFetched;

    let str = "";
    data.forEach((e) => {
      let categoryName = e.name;
      let dataSet = [categoryName];
      str += dataSet.join(",") + "\r";
    });

    let headers = "Categories";
    str = headers + "\r" + str;

    FileSaver.saveAs(
      new Blob([str], {
        type: "data:text/csv;charset=utf-8,",
      }),
      `list.csv`
    );
  }

  useEffect(() => {
    getCategories(page - 1, searchData, sort);
  }, [page, getCategories, sort, searchData]);

  return <CategoriesScreen _this={{
    props,
    workspace,
    activeTab,
    loadData,
    setLoadData,
    searchData,
    setSearchData,
    sort,
    categories,
    page,
    setPage,
    totalPages,
    setTotalPages,
    checked,
    setChecked,
    allChecked,
    setAllChecked,
    pageSize,
    toggle,
    formatSort,
    getCategories,
    createCategoryPermission,
    handleCheck,
    handleAllCheck,
    handleSearchSubmit,
    handleSearchInput,
    handleSearch,
    handleClearSearchChange,
    handlePageChange,
    handleDownloadSelection,
    handleSort,
  }} />;
  
};

function mapStateToProps(state) {
  return {
    workspace: state.workspace,
  };
}

export default connect(mapStateToProps)(Categories);
