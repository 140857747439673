import { Link } from "react-router-dom";
import Header from "../../../Components/Header/headerView";
import Footer from "../../../Components/Footer/footer";
import Menu from "../../../Components/Menu/menu";
import NDSTooltip from '../../../Components/Tooltip/ndsTooltip';
import Paginator from "../../../Components/Paginator/paginator";

export const CategoriesScreen = ({_this}) => {
  return (
    <div className={`${_this.props.className}`}>
        <Header />
        <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
        <div className="w-10">
            <Menu activeTab={4} />
        </div>
        <div className="container px-20 mt-50">
            <div className="row mt-20 mb-10">
            <div className="d-flex">
                <p className="h1 -r">Categories</p>
            </div>
            </div>
            <form onSubmit={_this.handleSearchSubmit}>
            <div className="row mt-40">
                <div className="col-12 col-md-8">
                <div className="input-group">
                    <input
                    type="text"
                    className="form-control"
                    placeholder="Search category"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    value={_this.searchDataPrev}
                    name="searchData"
                    ref={_this.searchRef}
                    onChange={_this.handleSearchInput}
                    ></input>
                    <span className="input-group-text mx-10">
                    <i className="fa-solid fa-magnifying-glass"></i>
                    </span>
                    <div className="col-12 col-md-6 col-lg-3 mx-20">
                    <select
                        className="form-select"
                        value={_this.statusFilter}
                        onChange={(option) => {
                            _this.setStatusFilter(option.target.value);
                        }}
                        aria-label="Type"
                    >
                        <option value="all">All</option>
                    </select>
                    </div>
                </div>
                </div>
                <div className="col-6 col-md-3 col-lg-2 mb-10">
                <button
                    className="button -outline-primary bg-white w-100"
                    onClick={_this.handleSearch}
                >
                    SEARCH
                </button>
                </div>
                <div className="col-6 col-md-3 col-lg-2 mb-10">
                <button
                    className="button -text w-100"
                    onClick={_this.handleClearSearchChange}
                >
                    CLEAR SEARCH
                </button>
                </div>
            </div>
            </form>
            {_this.createCategoryPermission && (
            <>
                <div className="row mt-20">
                <div className="col-12 col-md-6 col-lg-3">
                    <a href="/createcategory">
                    <button className="button -primary w-100">CREATE CATEGORY</button>
                    </a>
                </div>
                </div>
            </>
            )}
            <div className="row mt-50">
            <div className="col-12">
                <div className="bg-light">
                <div className="row justify-content-end">
                    {/* <div className="col-3 col-md-1 d-flex justify-content-center">
                        <button className='button -text w-100'><i className="fa-solid fa-circle-xmark"></i></button>
                    </div> */}
                    <div className="col-3 col-md-2">
                    {_this.checked.size > 0 && (
                        <button
                        className="button -text w-100"
                        onClick={(e) => _this.handleDownloadSelection(e, _this.categories)}
                        >
                        <NDSTooltip
                            icon="fa-solid fa-download"
                            target="download"
                            tooltipMessage="Download"
                            placement="bottom"
                        ></NDSTooltip>
                        </button>
                    )}
                    </div>
                    {/* <div className="col-3 col-md-1 d-flex justify-content-center">
                        <button className='button -text -red w-100'><i className="fa-solid fa-trash-can"></i></button>
                    </div> */}
                </div>
                </div>
                <table className="bg-white">
                <thead>
                    <tr>
                    <th>
                        <input
                        className="form-check-input -white"
                        type="checkbox"
                        checked={_this.allChecked}
                        id="flexCheckDefault"
                        onChange={(event) => _this.handleAllCheck(event)}
                        ></input>
                    </th>
                    <th>
                        <p className="d-flex align-items-center w-100">
                        Categories{" "}
                        {/*<button className="i -white w-15">
                            <i
                            className={
                                _this.sort["Categories"]?.className ??
                                "fa-solid fa-arrow-down-a-z"
                            }
                            onClick={_this.handleSort}
                            ></i>
                        </button>*/}
                        </p>
                    </th>
                    <th>
                        <p className="d-flex align-items-center w-100">
                        Description{" "}
                        {/*<button className="i -white w-15">
                            <i
                            className={
                                _this.sort["Description"]?.className ??
                                "fa-solid fa-arrow-down-1-9"
                            }
                            onClick={_this.handleSort}
                            ></i>
                        </button>*/}
                        </p>
                    </th>
                    <th>
                        <p className="d-flex align-items-center w-100">
                        Entities{" "}
                        {/*<button className="i -white w-15">
                            <i
                            className={
                                _this.sort["Entities"]?.className ??
                                "fa-solid fa-arrow-down-1-9"
                            }
                            onClick={_this.handleSort}
                            ></i>
                        </button>*/}
                        </p>
                    </th>
                    <th>
                        <p className="d-flex align-items-center w-100">
                        Modified{" "}
                        {/*<button className="i -white w-15">
                            <i
                            className={
                                _this.sort["Modified"]?.className ??
                                "fa-solid fa-arrow-down-1-9"
                            }
                            onClick={_this.handleSort}
                            ></i>
                        </button>*/}
                        </p>
                    </th>
                    </tr>
                </thead>
                <tbody>
                    {_this.loadData ? (
                    <tr>
                        <td colSpan="5">
                        <div className="d-flex justify-content-center">
                            <div
                            className="spinner-border m-20"
                            role="status"
                            ></div>
                        </div>
                        <span className="d-flex justify-content-center">
                            Loading...
                        </span>
                        </td>
                    </tr>
                    ) : _this.categories?.length > 0 ? (
                        _this.categories.map((category, index) => (
                        <tr key={index}>
                        <td>
                            <input
                            className="form-check-input -blue"
                            type="checkbox"
                            value=""
                            checked={_this.checked.has(category?.id)}
                            id="flexCheckDefault"
                            onChange={(event) => _this.handleCheck(category)}
                            ></input>
                        </td>
                        <td>
                            <Link
                            className="link"
                            to={`/detail/category/${category?.id}`
                            }
                            >
                            {category.name}
                            </Link>
                        </td>
                        <td>{category.description}</td>
                        <td>{category.entities.length}</td>
                        <td>{category.updated_on ?? "Date"}</td>
                        </tr>
                    ))
                    ):(
                        <tr>
                            <td colSpan="8">
                                <span className="d-flex justify-content-center">
                                    No categories found
                                </span>
                            </td>
                        </tr>
                    )}
                </tbody>
                </table>
            </div>
            </div>
            <div className="row mt-20">
            {_this.categories?.length > 0 && (
            <Paginator
                pages={_this.totalPages}
                currentPage={_this.page}
                setPage={_this.handlePageChange}
            />
            )}
            </div>
        </div>
        </div>
        <Footer />
    </div>
  );
}