import React, { Component } from 'react';
import Header from '../../Components/Header/headerView';
import Footer from '../../Components/Footer/footer';


// IMG
import GetInto from "../../Images/SVG/loginGetInto.svg";
import ForgotPassword from "../../Images/SVG/loginForgotPassword.svg";
import Signup from "../../Images/SVG/loginSignup.svg";
import NewPassword from "../../Images/SVG/loginNewPassword.svg";
import RequestSend from "../../Images/SVG/loginRequest.svg";

export const AppLoginScreen = ({_this}) => {
    return (
      <div>
        <Header />
        <div className="container">
          <div className="row min-h justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 d-flex justify-content-center align-items-center">
              <div className={`login -${_this.state.action}`}>
                {/* ============================ Action ============================ */}
                <div className="action -getinto">
                  {/* ---------------------- Get into the app ---------------------- */}

                  <div className="row">
                    <div className="col-12">
                      <h1 className="-r">Login</h1>
                    </div>
                    <div className="col-12">
                      <p className="h3 color-gray">Welcome back</p>
                    </div>
                  </div>

                  <div className="row mt-50 d-flex justify-content-center align-items-center">
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type="mail"
                          placeholder="Your email"
                          name="email"
                          value={_this.state.email}
                          onChange={(e) => _this.handleChange(e)}
                          onKeyDown={(e) => e.key === 'Enter' && _this.handleLogin(e)}
                        ></input>
                        <div className="input-placeholder">Email</div>
                      </div>
                    </div>
                    <div className="col-12 mt-40">
                      <div className="form-group">
                        <input
                          type="password"
                          placeholder="Your password"
                          name="password"
                          value={_this.state.password}
                          onChange={(e) => _this.handleChange(e)}
                          onKeyDown={(e) => {e.key === 'Enter' && _this.handleLogin(e)}}
                        ></input>
                        <div className="input-placeholder">Password</div>
                      </div>
                    </div>
                    {_this.state.error && (
                      <div className="col-12 mt-40">
                        {" "}
                        <p className="h4 label -deleted">
                          {_this.state.error}
                        </p>{" "}
                      </div>
                    )}
                    <div className="col-12 mt-10 d-flex justify-content-center">
                      <button
                        className="-text"
                        onClick={() =>
                          _this.setState({ action: "forgotpassword" })
                        }
                      >
                        Forgot password?
                      </button>
                    </div>
                    <div className="col-12 mt-20 d-flex justify-content-center">
                      <button className="-primary" onClick={_this.handleLogin}>
                        Login
                      </button>
                    </div>
                    {/* <div className="col-12 mt-20">
                      <p className="text text-center mb-0">
                        Don’t have an account yet?
                      </p>
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                      <button
                        className="-text"
                        onClick={() => _this.setState({ action: "signup" })}
                      >
                        Sign up
                      </button>
                    </div> */}
                  </div>

                  {/* ---------------------- Get into the app ---------------------- */}
                </div>
                {/* ============================ Action ============================ */}

                {/* ============================ Graphic ============================ */}
                <div className="graphic-canvas -getinto">
                  <div className="graphicbg">
                    {/* ---------------------- Graphic get into the app ---------------------- */}
                    <img
                      className="getinto"
                      src={GetInto}
                      alt="Image of a desktop computer"
                    />
                    {/* ---------------------- Graphic g into the app ---------------------- */}
                  </div>
                </div>
                {/* ============================ Graphic ============================ */}

                {/* ============================ Action ============================ */}
                <div className="action -forgotpassword">
                  {/* ---------------------- Get into the app ---------------------- */}

                  <div className="row">
                    <div className="col-12">
                      <h1 className="-r">Forgot Password</h1>
                    </div>
                    <div className="col-12 mt-20">
                      <p className="h3 color-gray">
                        Only the administrator can give you permission to reset
                        your password, enter the email with which you enter the
                        platform and send your request
                      </p>
                    </div>
                  </div>

                  <div className="row mt-50">
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type="mail"
                          placeholder="your email"
                          name="email"
                          onChange={(e) => _this.handleChange(e)}
                        ></input>
                        <div className="input-placeholder">Email</div>
                      </div>
                      {_this.state.error && (
                        <div className="col-12 mt-40">
                          {" "}
                          <p className="h4 label -deleted">
                            {_this.state.error}
                          </p>{" "}
                        </div>
                      )}
                    </div>
                    <div className="col-12 mt-20 d-flex justify-content-center">
                      <button
                        className="-primary"
                        onClick={_this.handlePasswordRecovery}
                      >
                        Send request
                      </button>
                    </div>
                    <div className="col-12 mt-20 d-flex justify-content-center">
                      <button
                        className="-text"
                        onClick={() => _this.setState({ action: "getinto" })}
                      >
                        Return
                      </button>
                    </div>
                  </div>

                  {/* ---------------------- Get into the app ---------------------- */}
                </div>
                {/* ============================ Action ============================ */}

                {/* ============================ Graphic ============================ */}
                <div className="graphic-canvas -forgotpassword">
                  <div className="graphicbg">
                    {/* ---------------------- Graphic get into the app ---------------------- */}
                    <img
                      className="getinto"
                      src={ForgotPassword}
                      alt="Image of a desktop computer"
                    />
                    {/* ---------------------- Graphic g into the app ---------------------- */}
                  </div>
                </div>
                {/* ============================ Graphic ============================ */}

                {/* ============================ Action ============================ */}
                <div className="action -signup">
                  {/* ---------------------- Get into the app ---------------------- */}

                  <div className="row">
                    <div className="col-12">
                      <h1 className="-r">Sign up</h1>
                    </div>
                    <div className="col-12 mt-20">
                      <p className="h3 color-gray">
                        Send a request to the administrator so that he can
                        create your account.
                      </p>
                    </div>
                  </div>

                  <div className="row mt-50">
                    <div className="col-12">
                      <div className="form-group">
                        <input type="text" placeholder="your name"></input>
                        <div className="input-placeholder">Name</div>
                      </div>
                    </div>
                    <div className="col-12 mt-40">
                      <div className="form-group">
                        <input type="mail" placeholder="your email"></input>
                        <div className="input-placeholder">Email</div>
                      </div>
                    </div>
                    <div className="col-12 mt-20">
                      <button className="-primary">Send request</button>
                    </div>
                    <div className="col-12 mt-20">
                      <p className="text text-center mb-0">
                        I already have an acount
                      </p>
                    </div>
                    <div className="col-12">
                      <button
                        className="-text"
                        onClick={() => _this.setState({ action: "getinto" })}
                      >
                        Login
                      </button>
                    </div>
                  </div>

                  {/* ---------------------- Get into the app ---------------------- */}
                </div>
                {/* ============================ Action ============================ */}

                {/* ============================ Graphic ============================ */}
                <div className="graphic-canvas -signup">
                  <div className="graphicbg">
                    {/* ---------------------- Graphic get into the app ---------------------- */}
                    <img
                      className="getinto"
                      src={Signup}
                      alt="Image of a desktop computer"
                    />
                    {/* ---------------------- Graphic g into the app ---------------------- */}
                  </div>
                </div>
                {/* ============================ Graphic ============================ */}

                {/* ============================ Action ============================ */}
                <div className="action -newpassword">
                  {/* ---------------------- Get into the app ---------------------- */}

                  <div className="row">
                    <div className="col-12">
                      <h1 className="-r">Recover Password</h1>
                    </div>
                    <div className="col-12 mt-20">
                      <p className="h3 color-gray">Enter your new password.</p>
                    </div>
                  </div>

                  <div className="row mt-50">
                    <div className="col-12">
                      <div className="form-group">
                        <input type="mail" placeholder="your email"></input>
                        <div className="input-placeholder">Email</div>
                      </div>
                    </div>
                    <div className="col-12 mt-40">
                      <div className="form-group">
                        <input
                          type="password"
                          placeholder="your new password"
                        ></input>
                        <div className="input-placeholder">New password</div>
                      </div>
                    </div>
                    <div className="col-12 mt-20">
                      <button className="-primary">Continue</button>
                    </div>
                  </div>

                  {/* ---------------------- Get into the app ---------------------- */}
                </div>
                {/* ============================ Action ============================ */}

                {/* ============================ Graphic ============================ */}
                <div className="graphic-canvas -newpassword">
                  <div className="graphicbg">
                    {/* ---------------------- Graphic get into the app ---------------------- */}
                    <img
                      className="getinto"
                      src={NewPassword}
                      alt="Image of a desktop computer"
                    />
                    {/* ---------------------- Graphic g into the app ---------------------- */}
                  </div>
                </div>
                {/* ============================ Graphic ============================ */}

                {/* ============================ Action ============================ */}
                <div className="action -recovered">
                  {/* ---------------------- Get into the app ---------------------- */}

                  <div className="row">
                    <div className="col-12">
                      <h1 className="-r">Recover Password</h1>
                    </div>
                    <div className="col-12 mt-20">
                      <p className="h3 color-gray">
                        Your password has been reset, now you can access the
                        app.
                      </p>
                    </div>
                  </div>

                  <div className="row mt-50">
                    <div className="col-12 mt-20">
                      <button className="-primary">Login</button>
                    </div>
                  </div>

                  {/* ---------------------- Get into the app ---------------------- */}
                </div>
                {/* ============================ Action ============================ */}

                {/* ============================ Graphic ============================ */}
                <div className="graphic-canvas -recovered">
                  <div className="graphicbg">
                    {/* ---------------------- Graphic get into the app ---------------------- */}
                    <img
                      className="getinto"
                      src={NewPassword}
                      alt="Image of a desktop computer"
                    />
                    {/* ---------------------- Graphic g into the app ---------------------- */}
                  </div>
                </div>
                {/* ============================ Graphic ============================ */}

                {/* ============================ Action ============================ */}
                <div className="action -passwordrequest">
                  {/* ---------------------- Get into the app ---------------------- */}

                  <div className="row">
                    <div className="col-12">
                      <h1 className="-r">Request sent</h1>
                    </div>
                    <div className="col-12 mt-20">
                      <p className="h3 color-gray">
                        Check your email. You will receive a mail for recover
                        your password soon.
                      </p>
                    </div>
                  </div>

                  <div className="row mt-50">
                    <div className="col-12 mt-20">
                      <button
                        className="-primary"
                        onClick={() =>
                          _this.setState({ action: "getinto", error: false })
                        }
                      >
                        Continue
                      </button>
                    </div>
                  </div>

                  {/* ---------------------- Get into the app ---------------------- */}
                </div>
                {/* ============================ Action ============================ */}

                {/* ============================ Graphic ============================ */}
                <div className="graphic-canvas -passwordrequest">
                  <div className="graphicbg">
                    {/* ---------------------- Graphic get into the app ---------------------- */}
                    <img
                      className="getinto"
                      src={RequestSend}
                      alt="Image of a desktop computer"
                    />
                    {/* ---------------------- Graphic g into the app ---------------------- */}
                  </div>
                </div>
                {/* ============================ Graphic ============================ */}

                {/* ============================ Action ============================ */}
                <div className="action -signuprequest">
                  {/* ---------------------- Get into the app ---------------------- */}

                  <div className="row">
                    <div className="col-12">
                      <h1 className="-r">Request sent</h1>
                    </div>
                    <div className="col-12 mt-20">
                      <p className="h3 color-gray">
                        Check your email to complete the process.
                      </p>
                    </div>
                  </div>

                  <div className="row mt-50">
                    <div className="col-12 mt-20">
                      <button className="-primary">Continue</button>
                    </div>
                  </div>

                  {/* ---------------------- Get into the app ---------------------- */}
                </div>
                {/* ============================ Action ============================ */}

                {/* ============================ Graphic ============================ */}
                <div className="graphic-canvas -signuprequest">
                  <div className="graphicbg">
                    {/* ---------------------- Graphic get into the app ---------------------- */}
                    <img
                      className="getinto"
                      src={RequestSend}
                      alt="Image of a desktop computer"
                    />
                    {/* ---------------------- Graphic g into the app ---------------------- */}
                  </div>
                </div>
                {/* ============================ Graphic ============================ */}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  };

  /*
function setLogin (dispatch, userData){
  dispatch(login(userData));
}*/

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}


export default AppLoginScreen;
