import React, { useState, useEffect, useRef, useCallback } from "react";
import entitiesService from '../../../Services/entitiesService';
import { connect } from "react-redux";
import { humanizeDateTime } from "../../../Utils/format/date";

import FileSaver from "file-saver";
import { EntitiesScreen } from "./entitiesScreen";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../../Components/Loader/actions";

const Entities = (props) => {
  const workspace = props.workspace.id;
  const [activeTab, setActiveTab] = useState("1");
  const [loadData, setLoadData] = useState(true);

  const [searchData, setSearchData] = useState("");
  const [searchDataPrev, setSearchDataPrev] = useState("");
  const searchRef = useRef();

  const [entities, setEntities] = useState([]);
  const [sort, setSort] = useState({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;

  const [checked, setChecked] = useState(new Set());
  const [allChecked, setAllChecked] = useState(false);
  const [createEntityPermission, setCreateEntityPermission] = useState(props?.workspace?.actions?.entities?.create ?? false);
  
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const dispatch = useDispatch();

  const formatSort = (_sort) => {
    const sortKeys = {
      Entities: "name.value",
      Status: "status.value",
      Description: "description.value",
      "Intents linked": "intents",
      Synonyms: "synonyms",
      Conflicts: "conflicts",
      Modified: "updated_on",
    };
    let sort = Object.assign(
      {},
      ...Object.entries(_sort).map(([k, x]) => ({
        [sortKeys[k]]: x.direction,
      }))
    );
    delete sort[undefined];
    return sort;
  };

  //Get category entities
  const getEntities = useCallback(async (page = 0, search = "", _sort = {}) => {
    dispatch(startLoading());
    let params = {
      workspace_id: workspace,
      limit: pageSize,
      page,
      search,
      //sort: formatSort(_sort),
    };
  
    let tempEntities = [];
    try {
      const res = await entitiesService.collectAll(params);
      tempEntities = res.data.entities ?? [];
      for (let i = 0; i < tempEntities.length; i++) {
        tempEntities[i].updated_on = humanizeDateTime(tempEntities[i].created[0].at);
      }
      let totalRecords = res.data?.total ?? 0;
      pageSize < totalRecords
        ? setTotalPages(Math.ceil(totalRecords / pageSize))
        : setTotalPages(1);
      console.log(tempEntities);
      setEntities(tempEntities);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadData(false);
    }
    dispatch(stopLoading());
    return tempEntities;
    },
    [workspace]
  );

  // Clear Search Button
  function handleClearSearchChange() {
    setPage(1);
    setSearchDataPrev("");
    setSearchData("");
    setChecked(new Map());
  }

  // Handle page change
  const handlePageChange = async (page) => {
    setPage(page);
    var entities = await getEntities(
      page - 1,
      searchData,
      sort
    );
    if (allChecked) {
      setChecked(new Set([...checked, ...entities.map((e) => e.id)]));
    }
  };

  const handleCheck = (entity) => {
    var newChecked = new Set(checked);
    var isChecked = newChecked.has(entity?.id);
    isChecked ? newChecked.delete(entity?.id) : newChecked.add(entity?.id);
    if (allChecked && isChecked) {
      setAllChecked(false);
    }
    setChecked(newChecked);
  };

  const handleAllCheck = (event, value) => {
    if (event.target.checked) {
      setChecked(new Set([...checked, ...entities.map((e) => e.id)]));
    } else {
      setChecked(new Set());
    }
    setAllChecked(event.target.checked);
  };

  const handleSearchSubmit = (event) => {
    handleSearch();
    event.preventDefault();
  };

  const handleSearchInput = (event) => {
    setSearchDataPrev(event.target.value);
  };

  async function handleSearch() {
    setPage(1);
    setSearchData(searchDataPrev);
  }

  useEffect(() => {
    if (loadData) {
      getEntities();
    }
  }, [loadData, getEntities]);

  const handleSort = (e) => {
    let header = e.target.parentElement.parentElement.innerText;
    let className = e.target.className;
    let dir = className.includes("-down-") ? 1 : -1;

    let newDir = dir === 1 ? -1 : 1;
    const t = {
      arrow: ["-up-", "-down-"],
      letter: ["-z-a", "-a-z"],
      number: ["-9-1", "-1-9"],
    };
    let newClassName = className;
    Object.entries(t).forEach(([k, v]) => {
      newClassName = newClassName.replace(...(newDir > 0 ? v : v.reverse()));
    });
    let newClicks = (sort[header]?.clicks ?? 0) + 1;

    Object.keys(sort).forEach((k) => delete sort[k]); // TODO: Admitir múltiples

    sort[header] = {
      direction: newDir,
      className: newClassName,
      clicks: newClicks,
    };
    setSort({ ...sort });
    handlePageChange(page);
  };

  async function handleDownloadSelection(e, list) {
    e.preventDefault();

    let data = list;
    console.log("Data", data);

    data = data.filter((e) => checked.has(e.id));

    let entitiesFetched = [];
    try {
      data = await Promise.all(data.map(async (e) => {
        let params = {
          workspace_id: workspace,
          entity_id: e.id,
        };
        const res = await entitiesService.collectOne(params);
        console.log("Entity", res.data);
        entitiesFetched.push(res.data);
      }));
    } catch (err) {
      console.error(err);
    }
    console.log("Entities", entitiesFetched);

    data = entitiesFetched;

    let str = "";
    data.forEach((e) => {
      let entityName = e.name;
      let synonyms = [...e.synonyms.map(e => e.value)];
      synonyms.forEach(e => {
        if (e.includes(",")) {
            e = '"' + e + '"';
        }
        str += e + ", " + entityName + "\r";
    });
    });

    let headers = "Synonyms,Entity";
    str = headers + "\r" + str;

    FileSaver.saveAs(
      new Blob([str], {
        type: "data:text/csv;charset=utf-8,",
      }),
      `list.csv`
    );
  }

  useEffect(() => {
    getEntities(page - 1, searchData, sort);
  }, [page, getEntities, sort, searchData]);


  return <EntitiesScreen _this={{
    props,
    workspace,
    activeTab,
    toggle,
    loadData,
    setLoadData,
    searchData,
    setSearchData,
    entities,
    sort,
    page,
    totalPages,
    pageSize,
    checked,
    handleCheck,
    handleAllCheck,
    handleSearchSubmit,
    handleSearchInput,
    handleClearSearchChange,
    handleSort,
    handlePageChange,
    handleDownloadSelection,
    handleSearch,
    searchRef,
    handleDownloadSelection,
    getEntities,
    props,
    createEntityPermission
  }} />  
  
};

const mapStateToProps = (state) => {
  return {
    workspace: state.workspace,
  };
};

export default connect(mapStateToProps)(Entities);
