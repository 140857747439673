import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './application.scss';
import Home from './Pages/Home/homeScreen';
import Intents from './Pages/Intents/Intents/intents';
import Entities from './Pages/Entities/Entities/entities';
import Categories from './Pages/Categories/Categories/categories';
import CreateCategory from './Pages/Categories/CreateCategory/createCategory';
import CreateIntentOpt from './Pages/Intents/CreateIntentOpt/createIntentOpt';
import CreateEntityOpt from './Pages/Entities/CreateEntityOpt/createEntityOpt';
import CreateIntent from './Pages/Intents/CreateIntent/createIntent';
import CSVTemplate from './Pages/Suggestions/CSVTemplate/csvTemplate';
import CreateObjectCSV from './Pages/Suggestions/CreateObjectCSV/createObjectCSV';
import CreateEntity from './Pages/Entities/CreateEntity/createEntity';
import ObjectsDetail from './Pages/Detail/objectsDetail';
import Requests from './Pages/Requests/Requests/requests';
import EditCategory from './Pages/Edit/editCategory/editCategory';
import EditEntity from './Pages/Edit/editEntity/editEntity';
import EditIntent from './Pages/Edit/editIntent/editIntent';
import RequestsValidate from './Pages/Requests/Validate/requestsValidate'
import Suggestions from './Pages/Suggestions/Suggestions/suggestionJobs';

import UserManage from './Pages/Users/UserManage/userManage';
import UsersEmpty from './Pages/Users/UsersEmpty/usersEmpty';
import CreateUser from './Pages/Users/CreateUser/createUser';
import EditUser from './Pages/Users/EditUser/editUser';

import WorkspaceManage from './Pages/Workspaces/WorkspaceManage/workspaceManage';
import EditWorkspace from './Pages/Workspaces/EditWorkspace/editWorkspace';
import WorkspaceEmpty from './Pages/Workspaces/WorkspaceEmpty/workspaceEmpty'
import Login from './Pages/Login/login';
import ImportWorkspace from './Pages/Workspaces/ImportWorkspace/importWorkspace';
import CreateWorkspace from './Pages/Workspaces/CreateWorkspace/createWorkspace';
import ManageNlp from './Pages/Workspaces/Nlp/ManageNlp/manageNlp';
import EditNlp from './Pages/Workspaces/Nlp/EditNlp/editNlp';
import CreateNlp from './Pages/Workspaces/Nlp/CreateNlp/createNlp';
import AdminsManage from './Pages/Users/AdminsManage/adminsManage';
import OrganizationManage from './Pages/Organizations/OrganizationManage/organizationManage';
import CreateOrganization from './Pages/Organizations/CreateOrganization/createOrganization';
import EditOrganization from './Pages/Organizations/EditOrganization/editOrganization';

import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { PermissionsUpdatedModal } from './Utils/modals';
import { ErrorModal } from './Utils/modals';
import Loader from './Components/Loader/loader';
import RequestLoader from './Components/RequestLoader/loader';
import CreateAdmin from './Pages/Users/AdminsCreate/adminsCreate';
import ProtectedRoute from './Services/ProtectedRoute/protectedRoute'
import EditAdmin from './Pages/Users/AdminsEdit/adminsEdit';

function App(props) {
  const dispatch = useDispatch();
  const [thisUser, setThisUser] = useState(props.user);

  return (
    <Router basename="/">
      {(thisUser.id === "") ? (
        <Routes>
          {/* Redirect all to login */}
          <Route
            path="*"
            element={
              <Login
                setUser={setThisUser}
              />
            }
          />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={thisUser.is_super_admin ? <WorkspaceManage /> : <Home />} />
          <Route path="/home" element={thisUser.is_super_admin ? <WorkspaceManage /> : <Home />} />

          <Route path="/intents" 
            element={
              <ProtectedRoute moduleName={'intents'}>
                <Intents />
              </ProtectedRoute>
            } 
          />
          <Route path="/editintent/:intent_id" 
            element={
              <ProtectedRoute moduleName={'intents'} action={'update'}>
                <EditIntent />
              </ProtectedRoute>
            }
          />
          <Route path="/createintent" 
            element={
              <ProtectedRoute moduleName={'intents'} action={'create'}>
                <CreateIntent />
              </ProtectedRoute>
            }
          />
          <Route path="/createintentopt" 
            element={
              <ProtectedRoute moduleName={'intents'} action={'create'}>
                <CreateIntentOpt />
              </ProtectedRoute>
            }
          />
          <Route path="/entities" 
            element={
              <ProtectedRoute moduleName={'entities'}>
                <Entities />
              </ProtectedRoute>
            }
          />
          <Route path="/createentityopt"
            element={
              <ProtectedRoute moduleName={'entities'} action={'create'}>
                <CreateEntityOpt />
              </ProtectedRoute>
            }
          />
          <Route path="/editentity/:entity_id" 
            element={
              <ProtectedRoute moduleName={'entities'} action={'update'}>
                <EditEntity />
              </ProtectedRoute>
            }
          />
          <Route path="/createentity" 
            element={
              <ProtectedRoute moduleName={'entities'} action={'create'}>
                <CreateEntity />
              </ProtectedRoute>
            }
          />
          <Route path="/categories" 
            element={
              <ProtectedRoute moduleName={'categories'}>
                <Categories />
              </ProtectedRoute>
            }
          />
          <Route path="/editcategory/:category_id" 
            element={
              <ProtectedRoute moduleName={'categories'} action={'update'}>
                <EditCategory />
              </ProtectedRoute>
            }
          />
          <Route path="/createcategory" 
            element={
              <ProtectedRoute moduleName={'categories'} action={'create'}>
                <CreateCategory />
              </ProtectedRoute>
            }
          />
          <Route path="/requests" 
            element={
              <ProtectedRoute moduleName={'requests'}>
                <Requests />
              </ProtectedRoute>
            }
          />       
          <Route path="/validaterequest/:request_id"
            element={
              <ProtectedRoute moduleName={'requests'}>
                <RequestsValidate />
              </ProtectedRoute>
            }
          />
          <Route path="/jobs"
            element={
              <ProtectedRoute moduleName={'suggestions'}>
                <Suggestions />
              </ProtectedRoute>
            }
          />
          <Route path="/usermanage"
            element={
              <ProtectedRoute moduleName={'users'}>
                <UserManage />
              </ProtectedRoute>
            }
          />
          <Route path="/usersempty"
            element={
              <ProtectedRoute moduleName={'users'}>
                <UsersEmpty />
              </ProtectedRoute>
            }
          />
          <Route path="/createuser"
            element={
              <ProtectedRoute moduleName={'users'}>
                <CreateUser />
              </ProtectedRoute>
            }
          />
          <Route path="/edituser/:user_id"
            element={
              <ProtectedRoute moduleName={'users'}>
                <EditUser />
              </ProtectedRoute>
            }
          />

          {/* Generic views cannot be protected */}
          <Route path="/login" element={<Login />} />
          <Route path="/csvtemplate/:type" element={<CSVTemplate />} />
          <Route path="/createcsv/:type" element={<CreateObjectCSV />} />
          <Route path="/detail/:type/:object_id" element={<ObjectsDetail />} />

          <Route path="/managenlp"
            element={
              <ProtectedRoute moduleName={'workspaces'}>
                <ManageNlp />
              </ProtectedRoute>
            }
          />
          <Route path="/editnlp/:nlp_id"
            element={
              <ProtectedRoute moduleName={'workspaces'}>
                <EditNlp />
              </ProtectedRoute>
            }
          />
          <Route path="/createnlp"
            element={
              <ProtectedRoute moduleName={'workspaces'}>
                <CreateNlp />
              </ProtectedRoute>
            }
          />
          <Route path="/adminsmanage"
            element={
              <ProtectedRoute moduleName={'admins'}>
                <AdminsManage />
              </ProtectedRoute>
            }
          />
          <Route path="/createadmin"
            element={
              <ProtectedRoute moduleName={'admins'}>
                <CreateAdmin />
              </ProtectedRoute>
            }
          />
          <Route path="/editadmin/:user_id"
            element={
              <ProtectedRoute moduleName={'admins'}>
                <EditAdmin />
              </ProtectedRoute>
            }
          />
          <Route path="/organizationsmanage"
            element={
              <ProtectedRoute moduleName={'organizations'}>
                <OrganizationManage />
              </ProtectedRoute>
            }
          />
          <Route path="/createorganization"
            element={
              <ProtectedRoute moduleName={'organizations'}>
                <CreateOrganization />
              </ProtectedRoute>
            }
          />
          <Route path="/editorganization/:organization_id"
            element={
              <ProtectedRoute moduleName={'organizations'}>
                <EditOrganization />
              </ProtectedRoute>
            }
          />
          <Route path="/workspacemanage"
            element={
              <ProtectedRoute moduleName={'workspaces'}>
                <WorkspaceManage />
              </ProtectedRoute>
            }
          />
          <Route path="/editworkspace/:workspace_id"
            element={
              <ProtectedRoute moduleName={'workspaces'}>
                <EditWorkspace />
              </ProtectedRoute>
            }
          />
          <Route path="/createworkspace"
            element={
              <ProtectedRoute moduleName={'workspaces'}>
                <CreateWorkspace />
              </ProtectedRoute>
            }
          />
          <Route path="/importworkspace"
            element={
              <ProtectedRoute moduleName={'workspaces'}>
                <ImportWorkspace />
              </ProtectedRoute>
            }
          />
          <Route path="/workspaceempty"
            element={
              <ProtectedRoute moduleName={'workspaces'}>
                <WorkspaceEmpty />
              </ProtectedRoute>
            }
          />
        </Routes>
      )}
      <ErrorModal/>
      <Loader />
      <RequestLoader />
      <PermissionsUpdatedModal/>
    </Router>
  );
}

const mapStateToProps = (state) => {
  return {
    //workspace: state.workspace,
    user: state.user,
    store: state
  };
};


export default connect(mapStateToProps)(App);
