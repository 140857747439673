import React, { Component } from 'react';
import { Tooltip } from 'reactstrap';

export default class Example extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    return (
      <div>
        <i className={`${this.props.icon}`} id={`${this.props.target}`}></i>
        <Tooltip placement={`${this.props.placement}`} isOpen={this.state.tooltipOpen} target={`${this.props.target}`} toggle={this.toggle}>
          {this.props.tooltipMessage}
        </Tooltip>
      </div>
    );
  }
}
