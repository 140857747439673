import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";

const ProtectedRoute = ({ children, moduleName, action, ...props }) => {
  const hasAccess = action 
    ? props.workspace?.actions?.[moduleName]?.[action] ?? false
    : props.workspace?.actions?.[moduleName]?.view ?? false;

  if (!hasAccess) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const mapStateToProps = state => ({
  workspace: state.workspace,
});

export default connect(mapStateToProps)(ProtectedRoute);