import React, { useState, useCallback } from 'react';
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import Header from "../../../Components/Header/headerView";
import Footer from "../../../Components/Footer/footer";
import Menu from "../../../Components/Menu/menu";
import Paginator from "../../../Components/Paginator/paginator";
import workspace from "../../../Images/workspace.png";
import { useEffect } from "react";
import workspacesService from '../../../Services/workspacesService';
import usersService from '../../../Services/usersService';
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../../Components/Loader/actions";
import { setActions } from "../../../app/slices/workspaces/workspace";

const OrganizationManage = (props) => {

  const [org, setOrg] = useState(props.workspace.organization);

  const [loadData, setLoadData] = useState(true);
  const [allOrganizations, setAllOrganizations] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const pageSize = 4;

  const [searching, setSearching] = useState(false);

  const dispatch = useDispatch();

  // Clear Search Button
  function handleClearSearchChange() {
    let tempPage = 1;
    setPage(tempPage);
    pageSize < allOrganizations.length
      ? setTotalPages(Math.ceil(allOrganizations.length / pageSize))
      : setTotalPages(1);
    let tempInstancesPage = allOrganizations.slice(
      (tempPage - 1) * pageSize,
      tempPage * pageSize
    );
    setOrganizations(tempInstancesPage);
    setSearch("");
  }

  // Update Search
  async function handleSearch(value) {
    let search_data = value;
    let tempPage = 1;
    setPage(tempPage);
    let temporarySearch = search_data
      ? allOrganizations.filter((object) => object?.id?.includes(search_data))
      : allOrganizations;
    pageSize < temporarySearch.length
      ? setTotalPages(Math.ceil(temporarySearch.length / pageSize))
      : setTotalPages(1);
    let tempInstancesPage = temporarySearch.slice(
      (tempPage - 1) * pageSize,
      tempPage * pageSize
    );
    setOrganizations(tempInstancesPage);
    setSearching(true);
  }

  // Handle Search Input
  const handleSearchChange = (event) => {
    let search_data = event.target.value;
    setSearch(search_data);
    handleSearch(search_data);
  };

  // Get All Workspaces
  async function getOrganizations() {
    dispatch(startLoading());
    let params = {
      organization: org,
      details: true,
    };

    try{
      let res = await workspacesService.collectAllOrganizations(params);
      console.log(res.data);
      if (res.status === 200) {
        setAllOrganizations(res.data);

        pageSize < res.data.length
        ? setTotalPages(Math.ceil(res.data.length / pageSize))
        : setTotalPages(1);

        let tempInstancesPage = res.data.slice(
          (page - 1) * pageSize,
          page * pageSize
        );
        setOrganizations(tempInstancesPage);
      }
    }
    catch (error) {
      console.log(error);
    }
    setTimeout(() => {
      dispatch(stopLoading());
    }, 1000);
  };

  useEffect(() => {
    setOrg(props.workspace.organization);
  }, [props.workspace.organization]);

  const handlePageChange = (page) => {
    setPage(page);
    let tempWorkspacesPage = allOrganizations.slice(
      (page - 1) * pageSize,
      page * pageSize
    );
    setOrganizations(tempWorkspacesPage);
  };

  useEffect(() => {
    if (loadData) {
      getOrganizations();
      setLoadData(false);
    }
  }, [loadData]);

  return (
    <div className={`${props.className}`}>
      <Header />
      <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
        <div className="w-10">
          <Menu activeTab={9} />
        </div>
        <div className="container px-20 mt-50">
          <div className="row mt-20 mb-10">
            <p className="h1 -r">Organizations</p>
          </div>
          
          <div className="row">

            <div className="col-3 mt-40">
              <button
                className="button -primary"
                onClick={() => {window.location.href = "/createorganization"}}
              >
                CREATE ORGANIZATION
              </button>
            </div>

          </div>
          {/* 
          
          */}
          <div className="col-12 mt-10 mb-10">
            <div className="row">
              {loadData ? (
                <div className="d-flex justify-content-center align-items-center">
                  <span className="d-flex justify-content-center">
                    <div className="spinner-border m-20" role="status"></div>
                  </span>
                  Loading...
                </div>
              ) : allOrganizations && allOrganizations.length > 0 ? (
                <>
                <div className="row mt-40">
                  <div className="col-12 col-md-8">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search organizations"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        name="searchData"
                        onChange={handleSearchChange}
                        value={search}
                      ></input>
                      <span className="input-group-text">
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </span>
                    </div>
                  </div>
                  <div className="col-6 col-md-3 col-lg-2 mb-10">
                    <button
                      className="button -text w-100"
                      onClick={() => handleClearSearchChange()}
                    >
                      CLEAR SEARCH
                    </button>
                  </div>
                </div>
                {organizations.map((organization, index) => (
                  <div className="col-12 col-lg-3 col-md-6 mt-20" key={index}>
                    <div className="card-w d-flex flex-column p-20 mx-10">
                      <Link to={`/editorganization/${organization.id}`}>
                        <div className="row justify-content-between mx-0 mb-20">
                          <div className="col-11 p-0">
                            <p className="h3">{organization.id}</p>
                          </div>
                        </div>
                        <div className="row justify-content-between">
                          <div className="col-9">
                            <p className="text mb-10">
                              <i className="fa-solid fa-list color-gray"></i>
                              Workspaces
                            </p>
                          </div>
                          <div className="col-3">
                            <p className="text text-c mb-10">
                              {organization.workspaces}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
                </>
              ) : (
                <>
                <p>No organizations found.</p>
                </>
              )}
            </div>
          </div>
          {allOrganizations?.length > pageSize && (
            <div>
              <Paginator
                pages={totalPages}
                currentPage={page}
                setPage={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    workspace: state.workspace,
    user: state.user,
  };
};

export default connect(mapStateToProps)(OrganizationManage);
