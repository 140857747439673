import React, { Component } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
// const Paginator = (props) => { 
class Paginator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pages: [],
      visiblePages: [...Array(5).keys()].map((p) => p + 1 - 3).filter(e => e >= 0 && e < this.props.pages),
      pagesCount: this.props.pages,
      currentPage: 1
    }

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidUpdate(nextProps) {
    if (nextProps.currentPage !== this.props.currentPage || nextProps.pages !== this.props.pages) {
      this.setState({ currentPage: this.props.currentPage });
      this.setState({ pagesCount: this.props.pages });
      var visiblePages = [...Array(5).keys()].map((p) => p + this.state.currentPage - 3).filter(e => e >= 0 && e < this.props.pages);
      this.setState({ visiblePages: visiblePages });
    }
  }
  
  handleClick = (e, page) => {
    e.preventDefault();
    
    this.props.setPage(page)
    this.setState({ currentPage: page })
  }

  render() {
    //If there is only one page, don't show the paginator
    if (this.state.pagesCount === 1) {
      return null;
    }
    
    return (
      <Pagination aria-label=''>
        {(this.props.currentPage >= 4) &&
          <PaginationItem >
            <PaginationLink first onClick={e => this.handleClick(e, 1)}/>
          </PaginationItem>
        }
        {(this.props.currentPage > 1) &&
          <PaginationItem >
            <PaginationLink previous onClick={e => this.handleClick(e, this.props.currentPage-1)}/>
          </PaginationItem>
        }
  
        { [...this.state.visiblePages].map((i, index) => 
          <PaginationItem key={index} active={this.state.currentPage === i + 1}>
            <PaginationLink onClick={e => this.handleClick(e, i + 1)}>
              {i + 1}
            </PaginationLink>
          </PaginationItem>
          )     
       }
        {(this.props.currentPage < this.state.pagesCount) &&
          <PaginationItem>
            <PaginationLink next onClick={e => this.handleClick(e, this.props.currentPage + 1)} />
          </PaginationItem>
        }
        {(this.props.currentPage <= this.state.pagesCount-3 ) &&
          <PaginationItem >
            <PaginationLink last onClick={e => this.handleClick(e, this.state.pagesCount )} />
          </PaginationItem>
        }
      </Pagination>
    );
  }
}

export default Paginator;
