import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { store } from "../../app/store";
import qs from "qs";
import * as pg from './pagination';
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
var token = localStorage.getItem("token");
var w = window;
export var intentsApiSlice = createApi({
    reducerPath: "api/intents",
    baseQuery: fetchBaseQuery({
        baseUrl: "".concat(((_a = w._env_) === null || _a === void 0 ? void 0 : _a.REACT_APP_API_URL) || process.env.REACT_APP_API_URL, "/intent"),
        paramsSerializer: function (params) {
            return qs.stringify(params, {
                arrayFormat: "brackets",
            });
        },
        prepareHeaders: function (headers) {
            headers.set("Content-Type", "application/json");
            headers.set("Authorization", "Bearer ".concat(token));
            headers.set("Access-Control-Allow-Origin", "*");
            headers.set("Access-Control-Allow-Methods", "GET,PUT,POST,DELETE,PATCH,OPTIONS");
            headers.set("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept, Authorization");
            return headers;
        },
    }),
    tagTypes: ["Intent"],
    endpoints: function (builder) {
        return {
            fetchIntent: builder.query({
                query: function (_id) {
                    return "/collectOne?_id=".concat(_id);
                },
            }),
            fetchIntents: builder.query({
                query: function (params) {
                    params = __assign(__assign({}, pg.paginationDefaults["StatusPagination"]), params);
                    var reduxStore = store.getState();
                    return {
                        url: "/collectAll",
                        params: __assign(__assign({ workspace_id: reduxStore.workspace.id }, params), { sort: JSON.stringify(params.sort) }),
                    };
                },
                transformResponse: function (response) { return response; },
                providesTags: function (result, error, page) {
                    return result
                        ? __spreadArray(__spreadArray([], __read(result.resultData.map(function (_a) {
                            var _id = _a._id;
                            return ({
                                type: "Intent",
                                id: _id,
                            });
                        })), false), [
                            { type: "Intent", id: "PARTIAL-LIST" },
                        ], false) : [{ type: "Intent", id: "PARTIAL-LIST" }];
                },
            }),
            addIntent: builder.mutation({
                query: function (body) {
                    return {
                        url: "/create",
                        method: 'POST',
                        body: body,
                    };
                },
                invalidatesTags: [{ type: 'Intent', id: 'LIST' }],
            }),
        };
    },
});
export var useFetchIntentQuery = intentsApiSlice.useFetchIntentQuery, useFetchIntentsQuery = intentsApiSlice.useFetchIntentsQuery, useLazyFetchIntentQuery = intentsApiSlice.useLazyFetchIntentQuery, useAddIntentMutation = intentsApiSlice.useAddIntentMutation;
