import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import FileSaver from "file-saver";
import { humanizeDateTime } from "../../../Utils/format/date";
import { IntentsScreen } from "./intentsScreen";
import intentsService from "../../../Services/intentsService";
import { set } from "firebase/database";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../../Components/Loader/actions";

const Intents = props => {
    const workspace = props.workspace.id;
    const role = props.role;
    const [activeTab, setActiveTab] = useState("1");
    const [loadData, setLoadData] = useState(true);
    const [searchData, setSearchData] = useState("");
    const [searchDataPrev, setSearchDataPrev] = useState("");
    const [intents, setIntents] = useState([]);
    const [sort, setSort] = useState({});
    const [statusFilter, setStatusFilter] = useState("all");
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [checked, setChecked] = useState(new Set());
    const [allChecked, setAllChecked] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const pageSize = 10;
    const dispatch = useDispatch();
    const [createIntentPermission, setCreateIntentPermission] = useState(props.workspace?.actions?.intents?.create ?? false);

    const navigate = useNavigate();

    // Toggle Active Tab
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const formatSort = _sort => {
        const sortKeys = {
            Intents: "name",
            Description: "description",
            Examples: "examples",
            Modified: "updated_on",
        };
        let sort = Object.assign(
            {},
            ...Object.entries(_sort).map(([k, x]) => ({
                [sortKeys[k]]: x.direction,
            }))
        );
        delete sort[undefined];
        return sort;
    };

    //Get intents
    const getIntents = useCallback(
        async (page = 0, search = "", _sort = {}) => {
            dispatch(startLoading());
            console.log("search", search);
            let params = {
                workspace_id: workspace,
                limit: pageSize,
                page,
                search,
                //sort: formatSort(_sort),
            };
            try{
                let res = await intentsService.collectAll(params);
                let tempIntents = res.data.intents ?? [];
                for (let i = 0; i < tempIntents.length; i++) {
                    tempIntents[i].updated_on = humanizeDateTime(
                        tempIntents[i].created[0].at
                    );
                }
                let totalRecords = res.data?.total ?? 0;
                pageSize < totalRecords
                    ? setTotalPages(Math.ceil(totalRecords / pageSize))
                    : setTotalPages(1);
                setIntents(tempIntents);
                setLoadData(false);
                console.log(tempIntents);
            }
            catch(err){
                console.log(err);
            }
            dispatch(stopLoading());
        },
        [workspace, statusFilter]
    );

    // Clear Search Button
    function handleClearSearchChange() {
        setPage(1);
        setStatusFilter("all");
        setSearchDataPrev("");
        setSearchData("");
        setChecked(new Map());
    }

    // Handle page change
    const handlePageChange = async page => {
        setPage(page);
        var intents = await getIntents(page - 1, searchData, sort);
        if (allChecked) {
            setChecked(new Set([...checked, ...intents.map(e => e.id)]));
        }
    };

    const handleCheck = intent => {
        var newChecked = new Set(checked);
        var isChecked = newChecked.has(intent?.id);
        isChecked
            ? newChecked.delete(intent?.id)
            : newChecked.add(intent?.id);
        if (allChecked && isChecked) {
            setAllChecked(false);
        }
        setChecked(newChecked);
    };

    const handleAllCheck = (event, value) => {
        if (event.target.checked) {
            setChecked(new Set([...checked, ...intents.map(e => e.id)]));
        } else {
            setChecked(new Set());
        }
        setAllChecked(event.target.checked);
    };

    const handleSearchSubmit = event => {
        handleSearch();
        event.preventDefault();
    };

    const handleSearchInput = event => {
        setSearchDataPrev(event.target.value);
        console.log("handleSearchInput", event.target.value);
    };

    async function handleSearch() {
        setPage(1);
        setSearchData(searchDataPrev);
        console.log("handleSearch", searchDataPrev);
    }

    useEffect(() => {
        if (loadData) {
            getIntents();
        }
    }, [loadData, getIntents]);

    const handleSort = e => {
        let header = e.target.parentElement.parentElement.innerText;
        let className = e.target.className;
        let dir = className.includes("-down-") ? 1 : -1;

        let newDir = dir === 1 ? -1 : 1;
        const t = {
            arrow: ["-up-", "-down-"],
            letter: ["-z-a", "-a-z"],
            number: ["-9-1", "-1-9"],
        };
        let newClassName = className;
        Object.entries(t).forEach(([k, v]) => {
            newClassName = newClassName.replace(
                ...(newDir > 0 ? v : v.reverse())
            );
        });
        let newClicks = (sort[header]?.clicks ?? 0) + 1;

        Object.keys(sort).forEach(k => delete sort[k]); // TODO: Admitir múltiples

        sort[header] = {
            direction: newDir,
            className: newClassName,
            clicks: newClicks,
        };
        setSort({ ...sort });
        handlePageChange(page);
    };

    useEffect(() => {
        console.log("useEffect", searchData);
        getIntents(page - 1, searchData, sort);
    }, [statusFilter, page, getIntents, sort, searchData]);

    async function handleDownloadSelection(e, list) {
        e.preventDefault();
        let data = list.filter(e => checked.has(e.id));

        let intentsFetched = [];

        data = await Promise.all(
            data.map(async e => {
                try{
                    let params = {
                        intent_id: e.id,
                        workspace_id: workspace
                    }
                    let res = await intentsService.collectOne(params);
                    intentsFetched.push(res.data);
                }
                catch(err){
                    console.log(err);
                }
            })
        );

        data = intentsFetched;

        let str = "";
        data.forEach(e => {
            let examples = [...e.examples.map(e => e.value)];
            let name = e.name;
            examples.forEach(e => {
                if (e.includes(",")) {
                    e = '"' + e + '"';
                }
                str += e + ", " + name + "\r";
            });
        });

        let header = "Example, Intent";
        str = header + "\r" + str;

        FileSaver.saveAs(
            new Blob([str], {
                type: "data:text/csv;charset=utf-8,",
            }),
            `list.csv`
        );
    }

    return <IntentsScreen _this={{
        props,
        workspace,
        role,
        activeTab,
        toggle,
        loadData,
        setLoadData,
        searchData,
        setSearchData,
        searchDataPrev,
        setSearchDataPrev,
        intents,
        setIntents,
        sort,
        setSort,
        statusFilter,
        setStatusFilter,
        page,
        setPage,
        totalPages,
        setTotalPages,
        checked,
        setChecked,
        allChecked,
        setAllChecked,
        canEdit,
        handlePageChange,
        handleCheck,
        handleAllCheck,
        handleSearchSubmit,
        handleSearchInput,
        handleSearch,
        handleSort,
        handleDownloadSelection,
        navigate,
        handleClearSearchChange,
        createIntentPermission
    }}/>

};

const mapStateToProps = state => {
    return {
        workspace: state.workspace,
        user: state.user,
    };
};

export default connect(mapStateToProps)(Intents);
