export var paginationDefaults = {
    StatusPagination: {
        page: 0,
        limit: -1,
        project: [],
        search: "",
        sort: { "name.value": 1 },
        status: [],
    },
};
