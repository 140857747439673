import React, { } from 'react';
import Header from '../../../Components/Header/headerView';
import Footer from '../../../Components/Footer/footer';
import Menu from '../../../Components/Menu/menu';
import { useParams } from 'react-router-dom';
import FileSaver from "file-saver";
import EntityChart from '../../../Images/entityChart.png';
import IntentChart from '../../../Images/intentChart.png';

const CSVTemplate = () => {
  const type = useParams().type;
  const columns = {
    intent: ["Example", "Intent name"],
    entity: [
      "Category",
      "Entity name",
      "Synonym",
      "Synonym",
      "Synonym",
      "Synonym",
    ],
  };

  const handleDownload = () => {
    let str = (columns[type] ? columns[type].join(",") : "") + "\r\n";
    FileSaver.saveAs(
      new Blob([str], {
        type: "data:text/csv;charset=utf-8,",
      }),
      `${type}.csv`
    );
  };

    return (
      <div>
        <Header />
        <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
          <div className="w-10">
            <Menu activeTab={type === "intent" ? 2 : 3} />
          </div>
          <div className="container px-20 mb-50">
            <div className="mt-50 mb-20">
              <p className="h1 -r">
                {type[0].toUpperCase() + type.slice(1, type.length)} CSV
                Template
              </p>
            </div>
            <div className="row mb-40">
              <div className="col-12 col-sm-10">
                <div className="card mb-20 d-flex justify-content-center">
                  <p className="h4 color-gray">
                    Download the template and fill the fields, once you’re done,
                    upload the file in the{" "}
                    <a href={`/createcsv/${type}`} className="h4 -b link">
                      Create from CSV
                    </a>{" "}
                    section
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-20 mb-50">
              <div className="col-12 col-md-6 col-lg-3">
                <button
                  className="button -primary w-100"
                  onClick={handleDownload}
                >
                  DOWNLOAD TEMPLATE
                </button>
              </div>
            </div>
            {/* Intents */}
            {type === "intent" && (
              <div className="row mb-100">
                <div className="col-12 col-sm-10">
                  <div className="card-w -large mb-20">
                    <p className="h3 mb-10">
                      Example showing a correct way to fill a CSV template
                    </p>
                    {/* <img src={chart} alt="chart" /> */}
                    <img src={IntentChart} alt="Intent chart" />
                  </div>
                  <div className="card-b mb-20">
                    <p className="h4 mb-20">
                      <b>Information</b>
                    </p>
                    <p className="h4">
                      <b>Intent name:</b> The purpose of a conversation
                      initiated by a user’s input.
                    </p>
                    {/* Según yo esta ya no tendría que ir, porque no estamos incluyendo descripciones */}
                    {/* <p className="h4"><b>Intent description:</b>  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor</p> */}
                    <p className="h4">
                      <b>Examples:</b> Training phrases of what the user might
                      say.
                    </p>
                  </div>
                </div>
              </div>
            )}
            {/* Entitys */}
            {type === "entity" && (
              <div className="row mb-100">
                <div className="col-12 col-sm-10">
                  <div className="card-w -large mb-20">
                    <p className="h3 mb-10">
                      Example showing a correct way to fill a CSV template
                    </p>
                    <img src={EntityChart} alt="Entity chart" />
                  </div>
                  <div className="card-b mb-20">
                    <p className="h4 mb-20">
                      <b>Information</b>
                    </p>
                    <p className="h4">
                      <b>Entity name:</b> Refers to the particular subject or
                      object that the user is referring to. This will determine
                      the Assitant answer.
                    </p>
                    <p className="h4">
                      <b>Category:</b> The value that the virtual assistant is
                      going to identify.
                    </p>
                    <p className="h4">
                      <b>Synonyms:</b> Help to identify words or phrases with
                      equivalent meanings, but also can include different
                      grammatical forms such as verbs in different conjugations
                      or misspelled words helping the assistant to correctly map
                      the conversations.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    );
}

export default CSVTemplate;
