import API from "./axios";
import qs from "qs";

const entitiesService = {

  //Endpoint to fetch an entity by its ID.
  collectOne: async (params) => {
    return await API.get("/entities/collect_one", { params });
  },

  //Endpoint to fetch all entities from a workspace.
  collectAll: async (params) => {
    return await API.get("/entities/collect_all", { params });
  },

  //Endpoint to fetch multiple entities by their IDs.
  collectMany: async (workspace_id, entities_ids) => {
    return await API.get(`/entities/collect_many/${workspace_id}`, {
      params: {
        entities_ids,
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },

  //Endpoint to retrieve entities without any category assigned within a specified workspace.
  withoutCategory: async (params) => {
    return await API.get(`/entities/without_category/${params}`);
  },

};
export default entitiesService;