import { Form, Field } from "react-final-form";
import classNames from "classnames";
import Header from "../../../Components/Header/headerView";
import Footer from "../../../Components/Footer/footer";
import Menu from "../../../Components/Menu/menu";
import NDSTooltip from "../../../Components/Tooltip/ndsTooltip";
import Paginator from "../../../Components/Paginator/paginator";
import { Link, Navigate } from "react-router-dom";
import Select from "react-select";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { Modal } from "bootstrap";
import { useNavigate } from 'react-router-dom';

export const CreateCategoryScreen = ({_this}) => {
  const navigate = useNavigate();

return (
    <div className={`${_this.props.className}`}>
      {_this.duplicateEntityModal}
      {/* // <!-- Modal created--> */}
      <div
        className="modal fade"
        id="createModal"
        tabIndex="1"
        aria-labelledby="createModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p className="h2" id="createModalLabel">
                Category request created
              </p>
              <button
                type="button"
                className="button-close w-100"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body d-flex flex-column align-items-center justify-content-center">
              <p className="text">Category request created succesfully!</p>
            </div>
            <div className="modal-footer">
              <div className="row w-100">
                <div className="d-flex flex-column align-items-center justify-content-center">
                <button className="button -primary" data-bs-dismiss="modal" onClick={() => (navigate("/requests", { state: { tab: '2' } }))}>
                  CONTINUE
                </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // <!-- Modal error--> */}
      <Header />
      <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
        <div className="w-10">
          <Menu activeTab={4} />
        </div>
        <div className="container px-20 mt-20 mb-50">
          <Form
            onSubmit={() => {
              // new Modal(document.getElementById("approveModal")).show();
            }}
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            render={({ handleSubmit, form, submitting, pristine, values }) => {
              if (!window.setFormValue)
                window.setFormValue = form.mutators.setValue;
              return (
                <form onSubmit={handleSubmit}>
                  <div>
                    <div className="row">
                      <div className="col-12 col-lg-9 my-20">
                        <div className="row mb-40">
                          <p className="h1 -r">Create Manually</p>
                        </div>
                        <div className="card-w -m">
                          <div className="row">
                            <p className="h3 -b">Category information</p>
                          </div>
                          <div className="row">
                            <div className="col-12 col-lg-8">
                              <div className="form-group mt-40">
                              <Field
                                name="category"
                                id="category"
                                initialValue=""
                                className="creatable-select w-100"
                                component={_this.selectAdapter}
                              />
                              </div>
                              {_this.newCategory ? (
                                <div className="form-group mt-40">
                                  <Field
                                    name="categoryDescription"
                                    id="categoryDescription"
                                    className="form-control"
                                    validate={_this.composeValidators(
                                      _this.description,
                                      _this.maxLength(128)
                                    )}
                                  >
                                    {(props) => (
                                      <>
                                        <input
                                          {...props.input}
                                          type="text"
                                          name="categoryDescription"
                                          placeholder="Category description"
                                          autoComplete="off"
                                          id="categoryDescription"
                                          value={_this.categoryDescriptionC}
                                          onChange={(e) => [
                                            e.preventDefault(),
                                            props.input.onChange(e),
                                            _this.handleChangeError(e, "description", false, "category"),
                                          ]}
                                          onBlur={(e) => [
                                            e.preventDefault(),
                                            props.input.onBlur(e),
                                          ]}
                                          style={
                                            props.meta?.error &&
                                            props.meta?.touched
                                              ? { borderColor: "red" }
                                              : null
                                          }
                                          onInput={(e) => [
                                            e.preventDefault(),
                                            _this.setCategoryDescription(
                                              e.target.value
                                            ),
                                          ]}
                                        />
                                        <label
                                          htmlFor="categoryDescription"
                                          data-toggle="tooltip"
                                          title=""
                                          style={
                                            props.meta?.error &&
                                            props.meta?.touched
                                              ? { color: "red" }
                                              : null
                                          }
                                        >
                                          {props.meta.error &&
                                          props.meta.touched ? (
                                            <span className="error">
                                              <i className="fas fa-exclamation-circle fa-sm"></i>
                                              {props.meta.error}
                                            </span>
                                          ) : (
                                            "New category description"
                                          )}
                                        </label>
                                      </>
                                    )}
                                  </Field>
                                </div>
                              ) : null}
                              <div className="form-group mt-40">
                                <Field
                                  name="name"
                                  initialValue={_this.categoryNameC}
                                  validate={_this.composeValidators(
                                    _this.required,
                                    _this.name,
                                    _this.namePrefix,
                                    _this.maxLength(64),
                                    _this.minLength,
                                    _this.validateUniqueName
                                  )}
                                >
                                  {(props) => (
                                    <>
                                      <input
                                        {...props.input}
                                        type="text"
                                        name="name"
                                        placeholder="Category name"
                                        autoComplete="off"
                                        required={true}
                                        id="name"
                                        value={_this.categoryNameC}
                                        onChange={(e) => [
                                          e.preventDefault(),
                                          props.input.onChange(e),
                                          _this.handleChangeError(e, "name"),
                                        ]}
                                        onBlur={(e) => [
                                          e.preventDefault(),
                                          props.input.onBlur(e),
                                        ]}
                                        onInput={(e) => [
                                          e.preventDefault(),
                                          _this.setCategoryNameC(e.target.value),
                                          console.log("categoryNameC", _this.categoryNameC),
                                        ]}
                                        style={
                                          props.meta?.error &&
                                          props.meta?.touched
                                            ? { borderColor: "red" }
                                            : null
                                        }
                                      />
                                      <label
                                        htmlFor="name"
                                        data-toggle="tooltip"
                                        title='Entity names can contain only Unicode alphanumeric, underscore, hyphen, and dot characters. 
                                It can not be empty or longer than 64 characters and it can not begin with the prefix "sys-". '
                                        style={
                                          props.meta?.error &&
                                          props.meta?.touched
                                            ? { color: "red" }
                                            : null
                                        }
                                      >
                                        {props.meta.error &&
                                        props.meta.touched ? (
                                          <span className="error">
                                            <i className="fas fa-exclamation-circle fa-sm"></i>
                                            {props.meta.error}
                                          </span>
                                        ): _this.errorUniqueName ? (
                                          <span className="error" style={{color: "red"}}>
                                          <i className="fas fa-exclamation-circle fa-sm"></i>
                                          Category already exists
                                          </span>
                                        ): (
                                          "Category name"
                                        )}
                                      </label>
                                    </>
                                  )}
                                </Field>
                              </div>
                              <div className="form-group mt-40">
                                {/* <input type="text" className="form-control" id="description" placeholder="Entity description"
                          onInput={e => set_this.inputDescription(e.target.value) }
                          />
                          <label htmlFor="description">Entity description</label> */}
                                <Field
                                  name="description"
                                  initialValue={_this.inputDescription}
                                  validate={_this.composeValidators(
                                    _this.maxLength(128)
                                  )}
                                >
                                  {(props) => (
                                    <>
                                      <input
                                        {...props.input}
                                        type="text"
                                        name="description"
                                        placeholder="Category description"
                                        autoComplete="off"
                                        id="description"
                                        value={_this.inputDescription}
                                        onChange={(e) => [
                                          e.preventDefault(),
                                          _this.setInputDescription(e.target.value),
                                          props.input.onChange(e),
                                          _this.handleChangeError(e, "description"),
                                        ]}
                                        onBlur={(e) => [
                                          e.preventDefault(),
                                          props.input.onBlur(e),
                                        ]}
                                        style={
                                          props.meta?.error &&
                                            props.meta?.touched
                                            ? { borderColor: "red" }
                                            : null
                                        }
                                      />
                                      <label
                                        htmlFor="description"
                                        data-toggle="tooltip"
                                        title="The description cannot contain carriage return, newline, or tab characters. It can not be empty or longer than 128 characters."
                                        style={
                                          props.meta?.error &&
                                            props.meta?.touched
                                            ? { color: "red" }
                                            : null
                                        }
                                      >
                                        {props.meta.error &&
                                          props.meta.touched ? (
                                          <span className="error">
                                            <i className="fas fa-exclamation-circle fa-sm"></i>
                                            {props.meta.error}
                                          </span>
                                        ) : (
                                          "Category description"
                                        )}
                                      </label>
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-3 mt-20">
                        <div className="row">
                          <button
                            type="button"
                            className={`button w-100 ${_this.validationError ? '-inactive' : '-primary'}`}
                            onClick={() => {
                              _this.createCategory();
                            }}
                            disabled={_this.validationError}
                          >
                            CREATE REQUEST
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              );
            }}
          />
        </div>
      </div>
      <Footer />
    </div>
)}