import React, { useState, useEffect, state } from "react";
import { useParams } from "react-router-dom";
import { ObjectsDetailScreen } from "./objectsDetailScreen";
import { store } from "../../app/store";
import * as dateService from "../../Utils/format/date";
import { connect } from "react-redux";
import { Modal } from "bootstrap";
import intentsService from "../../Services/intentsService";
import entitiesService from "../../Services/entitiesService";
import categoriesService from "../../Services/categoriesService";
import usersService from "../../Services/usersService";
import requestsService from "../../Services/requestsService";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../Components/Loader/actions";

const ObjectsDetail = (props) => {
  const workspace = store.getState().workspace.id;
  const [workspaceID, setworkspaceID] = useState("");
  const user = props.user;
  const [activeTab, setActiveTab] = useState("1");
  const [loadData, setLoadData] = useState(true);
  const [object, setObject] = useState();
  const [linkedIntents, setLinkedIntents] = useState([]);
  const [details, setDetails] = useState([]);
  const [splittedDetails, setSplittedDetails] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(4);
  const itemsPerPage = 10;
  const [adminNextStatus, setAdminNextStatus] = useState("Select");
  const [deleting, setDeleting] = useState(false);
  const [newChanges, setNewChanges] = useState([]);
  const [lastEditor, setLastEditor] = useState();
  const [editIntentPermission, setEditIntentPermission] = useState(props.workspace?.actions?.intents?.update ?? false);
  const [editEntityPermission, setEditEntityPermission] = useState(props.workspace?.actions?.entities?.update ?? false);
  const [editCategoryPermission, setEditCategoryPermission] = useState(props.workspace?.actions?.categories?.update ?? false);
  const [deleteIntentPermission, setDeleteIntentPermission] = useState(props.workspace?.actions?.intents?.delete ?? false);
  const [deleteEntityPermission, setDeleteEntityPermission] = useState(props.workspace?.actions?.entities?.delete ?? false);
  const [deleteCategoryPermission, setDeleteCategoryPermission] = useState(props.workspace?.actions?.categories?.delete ?? false);

  const dispatch = useDispatch();

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const type = useParams().type;
  const objectID = useParams().object_id;

  const deleteButton = (
    <button
      type="button"
      className="button -text -red w-100 mt-10"
      data-bs-toggle="modal"
      data-bs-target="#deleteModal"
      //onClick={() => handleDelete()}
    >
      DELETE {type}
    </button>
  );

  async function getObject(objectID) {
    dispatch(startLoading());

    let params = {
      workspace_id: workspace,
    };
    let res = null;

    if (type === "entity") {
      params.entity_id = objectID;
      params.categories_names = true;
      try{
        res = await entitiesService.collectOne(params);
        console.log("res", res);
      }
      catch(err){
        console.log(err);
      }
    }
    if (type === "intent") {
      params.intent_id = objectID;
      try{
        res = await intentsService.collectOne(params);
      }
      catch(err){
        console.log(err);
      }
    }
    if (type === "category") {
      params.category_id = objectID;
      try{
        res = await categoriesService.collectOne(params);
      }
      catch(err){
        console.log(err);
      }
    }
    console.log("res", res);
    setworkspaceID(res.data.workspace_id);
    res.data.created.at = dateService.convertIsoUtcToLocal(res.data.created[0].at);
    res.data.created.by = await getUserFromID(res.data.created[0].by);
    res.data.lastEdition = await getLastEdition(res.data);

    setObject(res.data);
    setLoadData(false);
    dispatch(stopLoading());
  }



  async function getLastEdition(request) {
    let lastEdition = null;
    let lastEditor = null;
    if (request === undefined) {
      return "No edition";
    }
    lastEdition = request.created.at;
    lastEditor = request.created[0].by;
    if (lastEdition && lastEditor) {
      lastEdition = new Date(lastEdition);
      lastEditor = await getUserFromID(lastEditor);
    } else {
      lastEditor = await getUserFromID(lastEditor);
    }
    setLastEditor(lastEditor);
    lastEdition =
      lastEdition.toDateString() + "  " + lastEdition.toLocaleTimeString();

    lastEdition = {
      date: lastEdition,
      editor: lastEditor,
    };
    return lastEdition;
  }

  async function getUserFromID(id) {
    dispatch(startLoading());
    let user = null;
    var params = {
      user_id: id,
      workspace_id: workspace,
    };
    try {
      let res = await usersService.collectOne(params);
      user = res;
    } catch (err) {
      console.log(err);
    }
    dispatch(stopLoading());
    return user;
  }

  async function handleDelete() {
    let modal = new Modal(document.getElementById("createModal"));
    let params = {
      workspace_id: workspace,
      user_id: user.id,
    };
    let changes = [
      "delete"
    ]
    let body = {
      object_id: objectID,
      object_type: type,
      changes: changes,
    }
    try{
      let res = await requestsService.create(params, body);
      console.log(res);
      modal.show();
    }
    catch(err){
      console.log(err);
    }

  }

  useEffect(() => {
    if (loadData) {
      getObject(objectID);
    }
  }, [loadData, objectID]);
  
  useEffect(() => {
    if (object) {
      console.log("object", object);
      if (type === "entity") {
        setDetails(object.synonyms);
        splitDetailsIntoState(object.synonyms);
      }
      else if (type === "intent") {
        setDetails(object.examples);
        splitDetailsIntoState(object.examples);
      }
    }
  }, [object]);

  useEffect(() => {
    console.log("lastEditor", lastEditor);
  }, [lastEditor]);

  const splitArray = (array, itemsPp) => {
    array = array.map((item) => item.value);
    const splitted = [];
    if (array) {
      for (let i = 0; i < array.length; i += itemsPp) {
        const chunk = array.slice(i, i + itemsPp);
        splitted.push(chunk);
      }
    }
    console.log("splitted", splitted);
    return splitted;
  };

  const splitDetailsIntoState = (arr) => {
    const splittedExamples = splitArray(arr, itemsPerPage);
    setSplittedDetails(splittedExamples);
    setTotalPages(splittedExamples.length);
  };

  return <ObjectsDetailScreen _this={{
      type,
      props,
      workspace,
      activeTab,
      dateService,
      setActiveTab,
      loadData,
      setLoadData,
      object,
      setObject,
      linkedIntents,
      setLinkedIntents,
      details,
      setDetails,
      splittedDetails,
      page,
      setPage,
      totalPages,
      setTotalPages,
      itemsPerPage,
      adminNextStatus,
      setAdminNextStatus,
      deleting,
      setDeleting,
      newChanges,
      setNewChanges,
      toggle,
      objectID,
      deleteButton,
      lastEditor,
      getLastEdition,
      getUserFromID,
      handleDelete,
      splitArray,
      splitDetailsIntoState,
      editIntentPermission,
      editEntityPermission,
      editCategoryPermission,
      deleteIntentPermission,
      deleteEntityPermission,
      deleteCategoryPermission
    }} />;

}

const mapStateToProps = (state) => ({
    user: state.user,
    workspace: state.workspace,
  });
  
export default connect(mapStateToProps)(ObjectsDetail);