import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { Modal } from "bootstrap";
import Header from "../../../Components/Header/headerView";
import Footer from "../../../Components/Footer/footer";
import Menu from "../../../Components/Menu/menu";
import NDSTooltip from "../../../Components/Tooltip/ndsTooltip";
import Paginator from "../../../Components/Paginator/paginator";
import Users from "../../../Images/users.png";
import { connect } from "react-redux";
import usersService from '../../../Services/usersService';
import workspacesService from '../../../Services/workspacesService';
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "../../../Components/Loader/actions";

const UserManage = (props) => {
  const org = props.workspace.organization;

  const [loadData, setLoadData] = useState(true);
  const [userList, setUserList] = useState([]);
  const [workspaces, setWorkspaces] = useState([]);
  const [deleteUser, setDeleteUser] = useState("");

  // const [searchData, setSearchData] = useState('');
  const [search] = useState([]);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 5;

  const [checked, setChecked] = useState([]);
  const [allChecked] = useState(false);

  const dispatch = useDispatch();

  //Get intents
  async function getUsers() {
    dispatch(startLoading());
    let params = {
      organization: org,
    };

    try{
      const res = await usersService.collectMany(params);
      let tempUsers = res.data;
      console.log("USERS: ", tempUsers);
      for (let i = 0; i < tempUsers.length; i++) {
        let allPerms = [];
        let workspaces = [];
        allPerms = [];
        tempUsers[i] = { ...tempUsers[i], checked: false };

        for (let j = 0; j < tempUsers[i].permissions.length; j++) {
          workspaces = [
            ...workspaces,
            tempUsers[i].permissions[j].workspace_id,
          ];
          let roles = tempUsers[i].permissions[j].roles.map((role) => role);
          allPerms = [...allPerms, ...roles];
        }

        allPerms = new Set(allPerms);
        workspaces = new Set(workspaces);
        tempUsers[i] = {
          ...tempUsers[i],
          allPermissions: [...allPerms],
          workspaces: [...workspaces],
        };
      }
      console.log("USERS tmo: ", tempUsers);
      setUserList(tempUsers);
      pageSize < tempUsers.length
        ? setTotalPages(Math.ceil(tempUsers.length / pageSize))
        : setTotalPages(1);

      let tempUsersPage = tempUsers.slice(
        (page - 1) * pageSize,
        page * pageSize
      );
      setUsers(tempUsersPage);
      setLoadData(false);
    }
    catch(err){
      console.log(err);
    }
    dispatch(stopLoading());
  }

  async function getWorkspaces() {
    dispatch(startLoading());
    let params = {
      organization: org,
    };

    try{
      let res = await workspacesService.collectManyByOrganization(params);
      let tempWorkspaces = res.data;
      setWorkspaces(tempWorkspaces);
    }
    catch(err){
      console.log(err);
    }
    dispatch(stopLoading());
  };

  function showworkspaceList(user) {
    console.log("USER: ", user);
    let workspaceIDs = user.workspaces;
    let workspaceNames = [];
    for (let i = 0; i < workspaceIDs.length; i++) {
      workspaces.find((workspace) => {
        if (workspace.id === workspaceIDs[i]) {
          workspaceNames.push(workspace.name);
        }
      });
    }
    //If its longer than 3, show the first 3 and add a +x in the end
    if (workspaceNames.length > 3) {
      let tempNames = workspaceNames.slice(0, 3);
      return tempNames
        .map((workspace) => (
          <p className='pb-10' key={workspace}>
            {workspace}
          </p>
        ))
        .concat(
          <p className='pb-10 text -i' key={workspaceNames.length}>
            {" "}
            and {workspaceNames.length - 3} more ...
          </p>
        );
    }

    //Return bold list
    return workspaceNames.map((workspace) => (
      <p className='pb-10' key={workspace}>
        {workspace}
      </p>
    ));
  }

  // Handle page change
  const handlePageChange = (page) => {
    setPage(page);
    let tempUsersPage = (search.length > 0 ? search : userList).slice(
      (page - 1) * pageSize,
      page * pageSize
    );
    setUsers(tempUsersPage);
  };

  /*const handleCheck = (event, value) => {
    if (checked.includes(value)) {
      let newChecked = checked.filter((val) => val !== value);
      setChecked(newChecked);
    } else {
      setChecked([...checked, value]);
    }
  };*/

  //   const handleAllCheck = (event, value) => {
  //     if (allChecked) {
  //       setChecked([]);
  //     } else {
  //       setChecked(userList);
  //     }
  //     setAllChecked(!allChecked)
  //   };

  const deactivateUser = async (user_id) => {
    let params = {
      user_id: user_id,
      organization: org,
    };
    let res = await usersService.delete(params);
    console.log(res);
    window.location.reload();
  };

  /*const activateUser = async (user_id) => {
    let params = {
      user_id: user_id,
    };
    await API.post("/user/activateUser", params).then((res) => {
      console.log(res);
      window.location.reload();
    });
  };*/

  const assignToAdmin = async (workspaces) => {
    //Use the editPermissions function to assign permissions to admin
    let roles = ["Editor", "Validator", "Deployer"];
    let permissions = [];
    for (let i = 0; i < workspaces.length; i++) {
      permissions.push({
        workspace_id: workspaces[i],
        roles: roles,
      });
    }
    let body = {
      permissions: permissions,
      is_admin: true,
    };

    let params = {
      user_email: props.user.email,
    };
    try{
      let res = await usersService.edit(params, body);
      console.log(res);
      permissions.forEach((permission) => {
        //Licenses to empty
        permission.roles = [];
      });
      body = {
        permissions: permissions,
        is_admin: false,
      };
      let res2 = await usersService.edit(params, body);
      console.log(res2);
      let modal = new Modal(document.getElementById("AssignToAdminModal"));
      modal.show();
    }
    catch(err){
      console.log(err);
    }
  };

  const handleDelete = async (userToDelete) => {
    //Check if user to delete is admin
    // if (userToDelete.admin) {
    //   let modal = new Modal(document.getElementById("DeleteViewerModal"));
    //   modal.show();
    //   return;
    // }
    //Check if no workspace is going to be left without users
    let workspacesIDs = userToDelete.workspaces;
    userList.find((user) => {
      if (user.id === userToDelete.id) {
        return;
      }
      workspacesIDs = workspacesIDs.filter(
        (id) => !user.workspaces.includes(id)
      );
    });
    let orphanWorkspaces = [];
    let orphanWorkspacesIDs = [];
    for (let i = 0; i < workspacesIDs.length; i++) {
      workspaces.find((workspace) => {
        if (workspace.id === workspacesIDs[i]) {
          orphanWorkspaces.push({ name: workspace.name, id: workspace.id });
          orphanWorkspacesIDs.push(workspace.id);
        }
      });
    }

    userToDelete = {
      ...userToDelete,
      singleInWorkspaces: orphanWorkspaces,
      singleInWorkspacesIDs: orphanWorkspacesIDs,
    };
    setDeleteUser(userToDelete);

    let modal = new Modal(document.getElementById("DeleteUserModal"));
    modal.show();

  };

  useEffect(() => {
    if (loadData) {
      getUsers();
      getWorkspaces();
    }
  }, [loadData]);


  return (
    <div className={`${props.className}`}>
      {/* // <!-- Modal Delete viewer --> */}
      <div
        className="modal fade"
        id="DeleteUserModal"
        tabIndex="1"
        aria-labelledby="DeleteUserModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p className="h2" id="DeleteUserModalLabel">
                Delete User
              </p>
              <button
                type="button"
                className="button-close w-100"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body d-flex justify-content-center align-items-center">
              <div>
                <p className="text">
                  Are you sure you want to delete{"  "}
                  <b>{deleteUser.first_name}</b>?
                </p>
                <p className=" d-flex justify-content-center align-items-center">
                  This action can’t be reverse
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <div className="row w-100">
                <div className="col-12 col-md-6 mb-10">
                  <button
                    className="button -text w-100"
                    data-bs-dismiss="modal"
                  >
                    CANCEL
                  </button>
                </div>
                <div className="col-12 col-md-6 mb-10">
                  <button
                    className="button -warning w-100"
                    onClick={() => {
                      deactivateUser(deleteUser.id);
                    }}
                  >
                    DELETE USER
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // <!-- Modal Delete onlyUser --> */}
      <div
        className="modal fade"
        id="DeleteOnlyUserModal"
        tabIndex="1"
        aria-labelledby="DeleteOnlyUserModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <p className="h2" id="DeleteOnlyUserModalLabel">
                Conflict Detected
              </p>
              <button
                type="button"
                className="button-close w-100"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body">
              {/* <p className="text">
                Mario is the only User within the platform. You can assign all
                the user tasks and licenses to the Admin or create a new user
                and automatically designate them.
              </p> */}
              <div className="flex-row justify-content-center align-items-center">
                <p className="text">
                  <b>{deleteUser?.first_name}</b> {"  "} is the only user within
                  the following workspaces: {"  "}
                </p>
                <ul className="">
                  {deleteUser?.singleInWorkspaces?.map((workspace) => (
                    <span className=" mx-20 m-10" key={workspace.id}>
                      {workspace.name}
                    </span>
                  ))}
                </ul>
                <p className="text d-flex justify-content-center">
                  You can assign all the user tasks and licenses to the Admin or
                  create a new user and designate them.
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <div className="row w-100">
                <div className="col-12 col-md-4 mb-10">
                  <button
                    className="button -text w-100"
                    data-bs-dismiss="modal"
                  >
                    CANCEL
                  </button>
                </div>
                <div className="col-12 col-md-4 mb-10">
                  <button
                    className="button -text w-100 px-0"
                    onClick={() => {
                      window.location.href = "/CreateUser";
                    }}
                  >
                    CREATE NEW USER
                  </button>
                </div>
                <div className="col-12 col-md-4 mb-10">
                  <button
                    className="button -primary w-100"
                    onClick={() => {
                      //Close modal
                      let modal = Modal.getInstance(
                        document.getElementById("DeleteOnlyUserModal")
                      );
                      modal.hide();
                      assignToAdmin(deleteUser.singleInWorkspacesIDs);
                    }}
                  >
                    ASSIGN TO ADMIN
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal successfull assigned to admin */}
      <div
        className="modal fade"
        id="AssignToAdminModal"
        tabIndex="1"
        aria-labelledby="AssignToAdminModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p className="h2" id="AssignToAdminModalLabel">
                Success
              </p>
              <button
                type="button"
                className="button-close w-100"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body">
              <p className="text">
                <b>{deleteUser?.first_name}</b> {"  "} workspaces have been
                successfully assigned to you.
              </p>
            </div>
            <div className="modal-footer">
              <div className="row w-100">
                <div className="col-12 col-md-6 mb-10">
                  <button
                    className="button -text w-100"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    CANCEL
                  </button>
                </div>
                <div className="col-12 col-md-6 mb-10">
                  <button
                    className="button -primary w-100"
                    onClick={() => {
                      //Close modal
                      let prevModal = Modal.getInstance(
                        document.getElementById("AssignToAdminModal")
                      );
                      prevModal.hide();
                      let modal = new Modal(
                        document.getElementById("DeleteUserModal")
                      );
                      modal.show();
                    }}
                  >
                    DELETE USER
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* // <!-- Modal Delete Admin --> */}
      <div
        className="modal fade"
        id="DeleteAdminModal"
        tabIndex="1"
        aria-labelledby="DeleteAdminModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p className="h2" id="DeleteAdminModalLabel">
                Conflict Detected
              </p>
              <button
                type="button"
                className="button-close w-100"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body">
              <p className="text">
                You can’t delete yourself because you’re the only user on the
                platform.
              </p>
            </div>
            <div className="modal-footer">
              <div className="row w-100 justify-content-end">
                <div className="col-6">
                  <button className="button -primary w-100">CONTINUE</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Header />
      <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
        <div className="w-10">
          <Menu activeTab={6} />
        </div>
        <div className="container px-20 mt-50">
          <div className="row mt-20 mb-10">
            <p className="h1 -r">Users</p>
          </div>
          <div className="col-12 col-lg-6 d-flex mt-40">
            <div className="card">
              <div className="row">
                <div className="col-12 col-md-3 mb-10 align-self-center">
                  <img src={Users} alt="users" />
                </div>
                <div className="col-12 col-md-9">
                  <p>
                    <b>New user</b>
                  </p>
                  <p>
                    Each user will be able to use the platform accordingly to
                    the assigned licenses
                  </p>
                  <div className="col-12 justify-content-start">
                    <a href="CreateUser">
                      <button className="-text justify-content-start w-100">
                        CREATE NEW USER
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-40">
            <div className="col-12 col-md-8">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search users by name, license or email"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                ></input>
                <span className="input-group-text">
                  <i className="fa-solid fa-magnifying-glass"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="row mt-50">
            <div className="col-12">
              <table className="bg-white">
                <thead>
                  <tr>
                    {/* <th>
                        <input
                          className="form-check-input -white mx-10"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        ></input>
                      </th> */}
                    <th>
                      <p className="d-flex align-items-center w-100 mx-20">
                        Name{" "}
                        {/*<button className="i -white w-15">
                          <i className="fa-solid fa-arrow-up-a-z"></i>
                        </button>*/}
                      </p>
                    </th>
                    <th>
                      <p className="d-flex align-items-center w-100">Email </p>
                    </th>
                    <th>
                      <p className="d-flex align-items-center w-100">
                        License{" "}
                      </p>
                    </th>
                    <th>
                      <p className="d-flex align-items-center w-100">
                        Workspace
                      </p>
                    </th>
                    <th>
                      <p className="d-flex align-items-center w-100">
                        Actions{" "}
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loadData ? (
                    <tr>
                      <td colSpan="7">
                        <div className="d-flex justify-content-center">
                          <div
                            className="spinner-border m-20"
                            role="status"
                          ></div>
                        </div>
                        <span className="d-flex justify-content-center">
                          Loading...
                        </span>
                      </td>
                    </tr>
                  ) : (
                    userList.length > 0 &&
                    users.map((user, index) => (
                      <tr key={index}>
                        {/* <td>
                            <input
                              className="form-check-input -blue mx-10"
                              type="checkbox"
                              value=""
                              checked={allChecked || user.checked}
                              id="flexCheckDefault"
                              onChange={(event) => handleCheck(event, user)}
                              onClick={(event) =>
                                (user.checked = !user.checked)
                              }
                            ></input>
                          </td> */}
                        <td className="mx-20 ">
                          <span className="d-flex mx-20">
                            {user.first_name +
                              " " +
                              user.first_surname +
                              " " +
                              user.second_surname}
                          </span>
                        </td>
                        <td className='w-20'>{user.email}</td>
                        <td className="w-20">
                          <div className="row">
                            {user.allPermissions.join(", ")}
                            {user.is_admin && (
                              <label className="label -migrated m-10">
                                Admin
                              </label>
                            )}
                          </div>
                        </td>
                        <td className="w-20">
                          {showworkspaceList(user)}

                          {/* <p className="text -i">and 3 more</p> */}
                        </td>
                        <td className='d-flex mr-10'>
                          {/* <a href="/TransferTasks" className="link -blue">
                            <NDSTooltip
                              icon="fa-solid fa-arrow-right-arrow-left"
                              target="transferTasks"
                              tooltipMessage="Transfer tasks to another user"
                              placement="bottom"
                            ></NDSTooltip>
                          </a> */}
                          <Link
                            className="link -blue"
                            to={`/EditUser/${user?.id}`}
                          >
                            <NDSTooltip
                              icon="fa-solid fa-pencil me-2"
                              target="pencil"
                              tooltipMessage="Edit"
                              placement="bottom"
                            ></NDSTooltip>
                          </Link>
                          {user.id !== props.user.id && (
                            <a
                              className="-red"
                              onClick={() => {
                                setDeleteUser(user);
                                handleDelete(user);
                              }}
                            >
                              <NDSTooltip
                                icon="fa-solid fa-trash-can text-danger"
                                target="trash"
                                tooltipMessage="Delete"
                                placement="bottom"
                              ></NDSTooltip>
                            </a>
                          )}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt-20">
            <Paginator
              pages={totalPages}
              currentPage={page}
              setPage={handlePageChange}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    workspace: state.workspace,
  };
};

export default connect(mapStateToProps)(UserManage);
