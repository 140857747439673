import { Form, Field } from "react-final-form";
import classNames from "classnames";
import Header from "../../../Components/Header/headerView";
import Footer from "../../../Components/Footer/footer";
import Menu from "../../../Components/Menu/menu";
import NDSTooltip from "../../../Components/Tooltip/ndsTooltip";
import Paginator from "../../../Components/Paginator/paginator";
import { Link, Navigate } from "react-router-dom";
import Select from "react-select";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { Modal } from "bootstrap";
import { useNavigate } from 'react-router-dom';

export const CreateEntityScreen = ({_this}) => {
  const navigate = useNavigate();

return (
    <div className={`${_this.props.className}`}>
      {_this.duplicateEntityModal}
      {/* // <!-- Modal created--> */}
      <div
        className="modal fade"
        id="createModal"
        tabIndex="1"
        aria-labelledby="createModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <p className="h2" id="createModalLabel">
                Entity request created
              </p>
              <button
                type="button"
                className="button-close w-100"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body d-flex flex-column align-items-center justify-content-center">
              <p className="text">Entity request created succesfully!</p>
            </div>
            <div className="modal-footer">
              <div className="row w-100">
                <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="row w-100">
                <button className="button -primary" data-bs-dismiss="modal" onClick={() => (navigate("/requests", { state: { tab: '2' } }))}>
                  CONTINUE
                </button>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // <!-- Modal required synonyms--> */}
      <div
        className="modal fade"
        id="requiredSynonymsModal"
        tabIndex="1"
        aria-labelledby="requiredSynonymsModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <p className="h2" id="requiredSynonymsModalLabel">
                Required synonyms
              </p>
              <button
                type="button"
                className="button-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                X
              </button>
            </div>
            <div className="modal-body d-flex flex-column align-items-center justify-content-center">
              <p className="text">Please add at least one synonym.</p>
            </div>
            <div className="modal-footer">
              <div className="row w-100 justify-content-center">
                <div className="col-12 col-md-6 mb-10 d-flex flex-column align-items-center justify-content-center">
                  <button className="button -primary" data-bs-dismiss="modal">
                    CONTINUE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Header />
      <div className="container-fluid px-0 m-0 d-flex flex-column flex-md-row">
        <div className="w-10">
          <Menu activeTab={3} />
        </div>
        <div className="container px-20 mt-20 mb-50">
          <Form
            onSubmit={() => {
              // new Modal(document.getElementById("approveModal")).show();
            }}
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            render={({ handleSubmit, form, submitting, pristine, values }) => {
              if (!window.setFormValue)
                window.setFormValue = form.mutators.setValue;
              return (
                <form onSubmit={handleSubmit}>
                  <div>
                    <div className="row">
                      <div className="col-12 col-lg-9 my-20">
                        <div className="row mb-40">
                          <p className="h1 -r">Create Manually</p>
                        </div>
                        <div className="card-w -m">
                          <div className="row">
                            <p className="h3 -b">Entity information</p>
                          </div>
                          <div className="row">
                            <div className="col-12 col-lg-8">
                              <div className="form-group mt-40">
                                <Field
                                  name="category"
                                  id="category"
                                  className="creatable-select w-100"
                                  validate={_this.composeValidators(
                                    _this.required,
                                    _this.name,
                                    _this.maxLength(64),
                                    _this.minLength
                                  )}
                                  component={_this.selectAdapter}
                                />
                              </div>
                              {_this.newCategory ? (
                                <div className="form-group mt-40">
                                  <Field
                                    name="categoryDescription"
                                    id="categoryDescription"
                                    className="form-control"
                                    validate={_this.composeValidators(
                                      _this.description,
                                      _this.maxLength(128)
                                    )}
                                  >
                                    {(props) => (
                                      <>
                                        <input
                                          {...props.input}
                                          type="text"
                                          name="categoryDescription"
                                          placeholder="Category description"
                                          autoComplete="off"
                                          id="categoryDescription"
                                          value={_this.categoryDescriptionC}
                                          onChange={(e) => [
                                            e.preventDefault(),
                                            props.input.onChange(e),
                                            _this.handleChangeError(e, "description", false, "category"),
                                          ]}
                                          onBlur={(e) => [
                                            e.preventDefault(),
                                            props.input.onBlur(e),
                                          ]}
                                          style={
                                            props.meta?.error &&
                                            props.meta?.touched
                                              ? { borderColor: "red" }
                                              : null
                                          }
                                          onInput={(e) => [
                                            e.preventDefault(),
                                            _this.setCategoryDescription(
                                              e.target.value
                                            ),
                                          ]}
                                        />
                                        <label
                                          htmlFor="categoryDescription"
                                          data-toggle="tooltip"
                                          title=""
                                          style={
                                            props.meta?.error &&
                                            props.meta?.touched
                                              ? { color: "red" }
                                              : null
                                          }
                                        >
                                          {props.meta.error &&
                                          props.meta.touched ? (
                                            <span className="error">
                                              <i className="fas fa-exclamation-circle fa-sm"></i>
                                              {props.meta.error}
                                            </span>
                                          ) : (
                                            "New category description"
                                          )}
                                        </label>
                                      </>
                                    )}
                                  </Field>
                                </div>
                              ) : null}
                              <div className="form-group mt-40">
                                <Field
                                  name="name"
                                  initialValue={_this.entityNameC}
                                  validate={_this.composeValidators(
                                    _this.required,
                                    _this.nameEntC,
                                    _this.namePrefix,
                                    _this.maxLength(64),
                                    _this.minLength,
                                    _this.validateUniqueName
                                  )}
                                >
                                  {(props) => (
                                    <>
                                      <input
                                        {...props.input}
                                        type="text"
                                        name="name"
                                        placeholder="Entity name"
                                        autoComplete="off"
                                        required={true}
                                        id="name"
                                        value={_this.entityNameC}
                                        onChange={(e) => [
                                          e.preventDefault(),
                                          props.input.onChange(e),
                                          _this.handleChangeError(e, "name"),
                                        ]}
                                        onBlur={(e) => [
                                          e.preventDefault(),
                                          props.input.onBlur(e),
                                        ]}
                                        onInput={(e) => [
                                          e.preventDefault(),
                                          _this.setEntityName(e.target.value),
                                        ]}
                                        style={
                                          props.meta?.error &&
                                          props.meta?.touched
                                            ? { borderColor: "red" }
                                            : null
                                        }
                                      />
                                      <label
                                        htmlFor="name"
                                        data-toggle="tooltip"
                                        title='Entity names can contain only Unicode alphanumeric, underscore, hyphen, and dot characters. 
                                It can not be empty or longer than 64 characters and it can not begin with the prefix "sys-". '
                                        style={
                                          props.meta?.error &&
                                          props.meta?.touched
                                            ? { color: "red" }
                                            : null
                                        }
                                      >
                                        {props.meta.error &&
                                        props.meta.touched ? (
                                          <span className="error">
                                            <i className="fas fa-exclamation-circle fa-sm"></i>
                                            {props.meta.error}
                                          </span>
                                        ) : _this.errorUniqueName ? (
                                            <span className="error" style={{color: "red"}}>
                                            <i className="fas fa-exclamation-circle fa-sm"></i>
                                            Entity already exists
                                            </span>
                                        ): (
                                          "Entity name"
                                        )}
                                      </label>
                                    </>
                                  )}
                                </Field>
                              </div>
                              <div className="form-group mt-40">
                                {/* <input type="text" className="form-control" id="description" placeholder="Entity description"
                          onInput={e => set_this.inputDescription(e.target.value) }
                          />
                          <label htmlFor="description">Entity description</label> */}
                                <Field
                                  name="description"
                                  initialValue={_this.inputDescription}
                                  validate={_this.composeValidators(
                                    _this.maxLength(128)
                                  )}
                                >
                                  {(props) => (
                                    <>
                                      <input
                                        {...props.input}
                                        type="text"
                                        name="description"
                                        placeholder="Entity description"
                                        autoComplete="off"
                                        id="description"
                                        value={_this.inputDescription}
                                        onChange={(e) => [
                                          e.preventDefault(),
                                          _this.setInputDescription(e.target.value),
                                          props.input.onChange(e),
                                          _this.handleChangeError(e, "description"),
                                        ]}
                                        onBlur={(e) => [
                                          e.preventDefault(),
                                          props.input.onBlur(e),
                                        ]}
                                        style={
                                          props.meta?.error &&
                                            props.meta?.touched
                                            ? { borderColor: "red" }
                                            : null
                                        }
                                      />
                                      <label
                                        htmlFor="description"
                                        data-toggle="tooltip"
                                        title="The description cannot contain carriage return, newline, or tab characters. It can not be empty or longer than 128 characters."
                                        style={
                                          props.meta?.error &&
                                            props.meta?.touched
                                            ? { color: "red" }
                                            : null
                                        }
                                      >
                                        {props.meta.error &&
                                          props.meta.touched ? (
                                          <span className="error">
                                            <i className="fas fa-exclamation-circle fa-sm"></i>
                                            {props.meta.error}
                                          </span>
                                        ) : (
                                          "Entity description"
                                        )}
                                      </label>
                                    </>
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-3 mt-20">
                        <div className="row">
                          <button
                            type="button"
                            className={`button w-100 ${_this.validationError ? '-inactive' : '-primary'}`}
                            onClick={() => {
                              _this.createEntity();
                            }}
                            disabled={_this.validationError}
                          >
                            CREATE REQUEST
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <Nav tabs className="col-12 d-flex">
                        <NavItem>
                          <NavLink
                            className={classNames({
                              active: _this.activeTab === "1",
                            })}
                            onClick={() => {
                              _this.activeTab("1");
                            }}
                          >
                            <span>
                              <p className="icon">
                                <i className="fa-solid fa-lines-leaning"></i>
                              </p>
                              <p className="h3 -b">SYNONYMS</p>
                            </span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                    <TabContent activeTab={_this.activeTab}>
                      <TabPane tabId="1">
                        <div className="row">
                          <div className="col-12 col-lg-9">
                            <div className="card-w -m my-40">
                              <div className="row mb-10">
                                <p className="h4">
                                  Add synonyms to facilitate communication with
                                  users.
                                </p>
                              </div>
                              <div className="row">
                                <div className="form-group mt-40">
                                  <Field
                                    name="newSynonymName"
                                    onSubmit={(e) => [
                                      e.preventDefault(),
                                      _this.addSynonym(values),
                                    ]}
                                    validate={_this.composeValidators(
                                      _this.syn,
                                      _this.uniqueField,
                                      _this.maxLength(64),
                                      _this.minLength
                                    )}
                                  >
                                    {({ input, meta }) => (
                                      <>
                                        <input
                                          {...input}
                                          type="text"
                                          className={classNames({
                                            "form-group -invalid":
                                              meta?.error && meta?.touched,
                                          })}
                                          placeholder="Ex. If you have Credit Card like entity you can use CC for synonym"
                                          onSubmit={(e) => [
                                            e.preventDefault(),
                                            _this.addSynonym(values),
                                          ]}
                                          onKeyPress={(e) => [
                                            e.key === "Enter" &&
                                              !form.getFieldState(
                                                "newSynonymName"
                                              )?.error && [
                                                e.preventDefault(),
                                                _this.addSynonym(values),
                                              ],
                                          ]}
                                          ref={_this.synonymInputRef}
                                          onChange={(e) => {
                                            e.preventDefault();
                                            // e.target.value =
                                            //   e.target.value.toLowerCase();
                                            input.onChange(e);
                                          }}
                                        />
                                        <label
                                          htmlFor="newSynonymName"
                                          data-toggle="tooltip"
                                          title="The synonym cannot contain spaces, carriage return, newline, or tab characters. It can not be empty or longer than 64 characters."
                                          style={
                                            meta?.error && meta?.touched || (_this.synonymsList.length === 0 && !_this.props.meta?.error)
                                              ? { color: "red" }
                                              : null
                                          }
                                        >
                                          {meta.error && meta.touched ? (
                                            <span className="error">
                                              <i className="fas fa-exclamation-circle fa-sm"></i>
                                              {meta.error}
                                            </span>
                                          ) : _this.synonymsList.length === 0 && !_this.props.meta?.error ? (
                                            <span className="error">
                                            <i className="fas fa-exclamation-circle fa-sm"></i>
                                              You must add at least 1 synonym
                                            </span>
                                          ): (
                                            "Synonym"
                                          )}
                                        </label>
                                        {/* {meta.error && meta.touched && <span className={'form-group'} style={{ display: 'inline' }}><span style={{ display: 'inline' }} className={`warning-message`}>{meta.error}</span></span>} */}
                                      </>
                                    )}
                                  </Field>
                                  {/* <label htmlFor="newSynonymName">Synonym</label> */}
                                </div>
                              </div>
                              <div className="row mt-20">
                                {isNaN(_this.editingIndex) ? (
                                  <div className="col-12 col-md-4 col-lg-3">
                                    <button
                                      type="button"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (
                                          !form.getFieldState("newSynonymName")
                                            ?.error
                                        )
                                          _this.addSynonym(values);
                                      }}
                                      className={`button -outline-primary w-100 ${classNames(
                                        {
                                          "-inactive":
                                          (submitting ||
                                            pristine ||
                                            !values?.newSynonymName) || 
                                            (form.getFieldState("newSynonymName")?.error)
                                        }
                                      )}`}
                                      disabled={
                                        (submitting ||
                                        pristine ||
                                        !values?.newSynonymName) || 
                                        (form.getFieldState("newSynonymName")?.error)
                                      }
                                    >
                                      ADD SYNONYM
                                    </button>
                                  </div>
                                ) : (
                                  <>
                                    {/* BOTONES QUE APARECEN AL EDITAR UNA RESPUESTA */}
                                    <div className="col-12 col-md-4 col-lg-3">
                                      <button
                                        type="button"
                                        className="button -outline-secondary w-100"
                                        onClick={(e) => [
                                          e.preventDefault(),
                                          _this.handleCancelEdition(e),
                                        ]}
                                      >
                                        CANCEL
                                      </button>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-3">
                                      <button
                                        type="button"
                                        className={`button -primary w-100 ${classNames(
                                          {
                                            "-inactive":
                                              submitting ||
                                              pristine ||
                                              !values?.newSynonymName,
                                          }
                                        )}`}
                                        disabled={
                                          submitting ||
                                          pristine ||
                                          !values?.newSynonymName
                                        }
                                        onClick={(e) => [
                                          e.preventDefault(),
                                          _this.addSynonym(values),
                                          (_this.synonymInputRef.current.value = ""),
                                        ]}
                                      >
                                        SAVE CHANGES
                                      </button>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="row mt-50">
                                <div className="col-12">
                                  <div className="bg-light">
                                    <div className="row justify-content-end">
                                      <div
                                        className="col-4 col-sm-3 col-md-2"
                                        style={{
                                          visibility: [..._this.checked].length
                                            ? "visible"
                                            : "hidden",
                                        }}
                                      >
                                        <button
                                          type="button"
                                          className="button -text w-100"
                                          onClick={(e) =>
                                            _this.handleDownloadSelection(e)
                                          }
                                        >
                                          <i className="fa-solid fa-download"></i>
                                          DOWNLOAD
                                        </button>
                                      </div>
                                      <div
                                        className="col-4 col-sm-3 col-md-2"
                                        style={{
                                          visibility: [..._this.checked].length
                                            ? "visible"
                                            : "hidden",
                                        }}
                                      >
                                        <button
                                          type="button"
                                          className="button -text -red w-100"
                                          onClick={(e) => [
                                            e.preventDefault(),
                                            _this.handleDeleteSelection(e),
                                          ]}
                                        >
                                          <i className="fa-solid fa-trash-can"></i>
                                          DELETE
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>
                                          <input
                                            className="form-check-input -white"
                                            type="checkbox"
                                            value=""
                                            checked={_this.allChecked}
                                            id="flexCheckDefault"
                                            onChange={(event) =>
                                              _this.handleAllCheck(event)
                                            }
                                          ></input>
                                        </th>
                                        <th>
                                          <p className="d-flex align-items-center w-100">
                                            Synonyms{" "}
                                            {/*<button className="i -white w-15">
                                              <i className="fa-solid fa-arrow-up-a-z"></i>
                                            </button>*/}
                                          </p>
                                        </th>
                                        <th>
                                          <p className="d-flex align-items-center w-100">
                                            Added{" "}
                                            {/*<button className="i -white w-15">
                                              <i className="fa-solid fa-arrow-down-1-9"></i>
                                            </button>*/}
                                          </p>
                                        </th>
                                        <th className="row m-10 align-items-center">
                                          Actions
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {/* <tr>
                                <td><input className="form-check-input -blue" type="checkbox" value="" id="flexCheckDefault"></input></td>
                                <td>I want a credit card</td>
                                <td>0</td>
                                <td>1 minute ago</td>
                                <td>
                                  <div className="row">
                                    <button className="button -icon">
                                      <span className="material-icons-outlined"><i className="fa-solid fa-pencil"></i>
                                      </span>
                                    </button>
                                    <button className="button -icon -danger">
                                      <span className="material-icons-outlined"><i className="fa-solid fa-trash-can"></i></span>
                                    </button>
                                  </div>
                                </td>
                              </tr> */}

                                      {_this.synonymsList.length > 0 ? (
                                        _this.synonymsList.map((synonym, index) => (
                                          <tr key={index} id={synonym._id}>
                                            <td>
                                              <input
                                                className="form-check-input -blue"
                                                type="checkbox"
                                                value=""
                                                checked={_this.checked.has(
                                                  synonym?._id
                                                )}
                                                onChange={() =>
                                                  _this.handleCheck(synonym)
                                                }
                                                id="flexCheckDefault"
                                              ></input>
                                            </td>
                                            <td>
                                              {_this.editingIndex === index && (
                                                <div className="row">
                                                  <p className="label -edited">
                                                    Editing
                                                  </p>
                                                </div>
                                              )}
                                              {synonym.value}
                                            </td>
                                            <td>
                                              {_this.dateService.humanizeDateTime(
                                                _this.addedOn[index]
                                              )}
                                            </td>
                                            <td>
                                              {_this.editingIndex !== index && (
                                                <div className="row">
                                                  <button
                                                    className="button -icon"
                                                    onClick={(e) => [
                                                      e.preventDefault(),
                                                      _this.handleEdition(
                                                        e,
                                                        synonym,
                                                        index
                                                      ),
                                                    ]}
                                                  >
                                                    <span className="material-icons-outlined">
                                                      <NDSTooltip
                                                        icon="fa-solid fa-pencil"
                                                        target="pencil"
                                                        tooltipMessage="Edit"
                                                        placement="bottom"
                                                      ></NDSTooltip>
                                                      {/* <i className="fa-solid fa-pencil"></i> */}
                                                    </span>
                                                  </button>
                                                  <button
                                                    className="button -icon -danger"
                                                    ref={(el) =>
                                                      (_this.deleteRef.current[
                                                        synonym._id
                                                      ] = el)
                                                    }
                                                    onClick={(e) => [
                                                      e.preventDefault(),
                                                      _this.removeSynonym(index),
                                                    ]}
                                                  >
                                                    <span className="material-icons-outlined">
                                                      <NDSTooltip
                                                        icon="fa-solid fa-trash-can"
                                                        target="trash"
                                                        tooltipMessage="Delete"
                                                        placement="bottom"
                                                      ></NDSTooltip>
                                                      {/* <i className="fa-solid fa-trash-can"></i> */}
                                                    </span>
                                                  </button>
                                                </div>
                                              )}
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <tr>
                                          <td colSpan="5">No synonyms added</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="row mt-20">
                                <Paginator />
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId="2">
                        <div className="row">
                          <div className="col-12 col-lg-9">
                            <div className="card-w -m my-40">
                              <div className="row mb-10">
                                <p className="h4">
                                  You can match Entities with intents for
                                  example the Entity <b>"Credit card"</b> match
                                  with the intents{" "}
                                  <b>“Cancel”, “Request”, etc.</b> it’s posible
                                  match more than one intent for each entity, If
                                  you don’t already have intents created, you
                                  won’t be able to join them, but you can always
                                  link them after.
                                </p>
                              </div>
                              <div className="row">
                                <div className="col-12 col-md-6">
                                  <div className="form-group mt-40">
                                    <Select
                                      placeholder="search by previously created intent name"
                                      isMulti
                                      name="colors"
                                      className="basic-multi-select w-100"
                                      classNamePrefix="select"
                                      options={_this.intentOptions}
                                      value={_this.selectedIntents}
                                      onChange={_this.setSelectedIntents}
                                      onMenuOpen={_this.getIntentOptions}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row mt-20">
                                <div className="col-12 col-md-4">
                                  <button
                                    className={`button -outline-primary w-100 ${
                                      _this.selectedIntents.length > 0
                                        ? "-active"
                                        : "-inactive"
                                    }`}
                                    onClick={(e) => [
                                      e.preventDefault(),
                                      _this.addLinkedIntent(e),
                                    ]}
                                  >
                                    ADD LINKED INTENTS
                                  </button>
                                </div>
                              </div>
                              <div className="row mt-50">
                                <div className="col-12">
                                  <div className="bg-light">
                                    <div className="row justify-content-end">
                                      <div className="col-4 col-sm-3 col-md-2">
                                        <button className="button -text w-100">
                                          <NDSTooltip
                                            icon="fa-solid fa-circle-xmark"
                                            target="cancel"
                                            tooltipMessage="Cancel"
                                            placement="bottom"
                                          ></NDSTooltip>
                                          {/* <i className="fa-solid fa-circle-xmark"></i> */}
                                          {/* CANCEL */}
                                        </button>
                                      </div>
                                      <div className="col-4 col-sm-3 col-md-2">
                                        <button className="button -text w-100">
                                          <NDSTooltip
                                            icon="fa-solid fa-download"
                                            target="download"
                                            tooltipMessage="Download"
                                            placement="bottom"
                                          ></NDSTooltip>
                                          {/* <i className="fa-solid fa-download"></i> */}
                                          {/* DOWNLOAD */}
                                        </button>
                                      </div>
                                      <div className="col-4 col-sm-3 col-md-2">
                                        <button className="button -text -red w-100">
                                          <NDSTooltip
                                            icon="fa-solid fa-trash-can"
                                            target="trash"
                                            tooltipMessage="Delete"
                                            placement="bottom"
                                          ></NDSTooltip>
                                          {/* <i className="fa-solid fa-trash-can"></i> */}
                                          {/* DELETE */}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>
                                          <input
                                            className="form-check-input -white"
                                            type="checkbox"
                                            value=""
                                            id="flexCheckDefault"
                                          ></input>
                                        </th>
                                        <th>
                                          <p className="d-flex align-items-center w-100">
                                            Intents{" "}
                                            {/*<button className="i -white w-15">
                                              <i className="fa-solid fa-arrow-up-a-z"></i>
                                            </button>*/}
                                          </p>
                                        </th>
                                        <th>
                                          <p className="d-flex align-items-center w-100">
                                            Examples{" "}
                                            {/*<button className="i -white w-15">
                                              <i className="fa-solid fa-arrow-down-1-9"></i>
                                            </button>*/}
                                          </p>
                                        </th>
                                        <td className="row m-10 align-items-center">
                                          Actions
                                        </td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {/* <tr>
                                <td><input className="form-check-input -blue" type="checkbox" value="" id="flexCheckDefault"></input></td>
                                <td><a href="#/">Cancel</a></td>
                                <td>3</td>
                                <td>
                                  <button className="button -icon -danger w-100">
                                    <span className="material-icons-outlined"><i className="fa-solid fa-trash-can"></i></span>
                                  </button>
                                </td>
                              </tr> */}
                                      {_this.linkedIntents.length > 0 ? (
                                        _this.linkedIntents.map((intent, index) => (
                                          <tr key={index}>
                                            <td>
                                              <input
                                                className="form-check-input -blue"
                                                type="checkbox"
                                                value=""
                                                id="flexCheckDefault"
                                              ></input>
                                            </td>
                                            <td>
                                              <Link
                                                onClick={() => {
                                                  window.open(
                                                    `/intentsdetail/${intent.value}`
                                                  );
                                                }}
                                              >
                                                {intent.label}
                                              </Link>
                                            </td>
                                            <td>{intent.examples.length}</td>
                                            <td>
                                              <button
                                                className="button -icon -danger"
                                                onClick={(e) => [
                                                  e.preventDefault(),
                                                  _this.removeLinkedIntent(index),
                                                ]}
                                              >
                                                <span className="material-icons-outlined">
                                                  <NDSTooltip
                                                    icon="fa-solid fa-trash-can"
                                                    target="trash"
                                                    tooltipMessage="Delete"
                                                    placement="bottom"
                                                  ></NDSTooltip>
                                                  {/* <i className="fa-solid fa-trash-can"></i> */}
                                                </span>
                                              </button>
                                            </td>
                                          </tr>
                                        ))
                                      ) : (
                                        <tr>
                                          <td colSpan="4">
                                            No linked intents added
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="row mt-20">
                                <Paginator />
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                </form>
              );
            }}
          />
        </div>
      </div>
      <Footer />
    </div>
)}