import React, { Component } from 'react';
import firebaseApp from "../../firebase";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { store } from "../../app/store";
import user, { login } from "../../app/slices/user/user";
import {
  setCredentials,
} from "../../app/slices/workspaces/workspace";
import { AppLoginScreen } from './loginScreen';
import usersService from '../../Services/usersService';
import { set } from 'firebase/database';

class AppLogin extends Component {
    constructor(props) {
      super(props);
      this.state = {
        action: "getinto", // getinto, forgotpassword, signup, newpassword, requestsend
        email: "",
        password: "",
        token: "",
        error: "",
        isLogin: false,
        isForgot: false,
      };
    }
  
    handleLogin = async (e) => {
        e.preventDefault();
        let userData = null;
        const { email, password } = this.state;
        const data = {
            email: email,
            password: password,
        };
      try {
        let res = await usersService.login(data);
        console.log("RESPONSE", res);
        if (res.data.access_token) {
            console.log("RESPONSE", res.data);
            try {
                localStorage.setItem("token", res.data.access_token);
                localStorage.setItem("refreshToken", res.data.refresh_token);
                store.dispatch(login(res.data.user));
                store.dispatch(setCredentials(res.data.user));
                this.props.setUser(res.data.user);
            } catch (error) {
                this.setState({ error: 'Error: No se pudo leer el usuario de la base de datos.' });
                console.error("Error al recoger los datos del usuario:", error);
            }
        } else {
            const errorMessage = res.data.detail;
            switch (errorMessage) {
                case "INVALID_EMAIL":
                    this.setState({ error: "Email is not valid" });
                    break;
                case "USER_DISABLED":
                    this.setState({ error: "User is disabled" });
                    break;
                case "EMAIL_NOT_FOUND":
                    this.setState({ error: "Invalid email or password" });
                    break;
                case "INVALID_PASSWORD":
                    this.setState({ error: "Invalid email or password" });
                    break;
                case "MISSING_PASSWORD":
                    this.setState({ error: "Password is missing" });
                    break;
                default:
                    this.setState({ error: errorMessage });
            }
        }
    } catch (err) {
        const errorMessage = err.response.data.detail;
        switch (errorMessage) {
            case "INVALID_EMAIL":
                this.setState({ error: "Email is not valid" });
                break;
            case "MISSING_PASSWORD":
                this.setState({ error: "Password is missing" });
                break;
            case "EMAIL_NOT_FOUND":
                this.setState({ error: "Invalid email or password" });
                break;
            case "INVALID_PASSWORD":
                this.setState({ error: "Invalid email or password" });
                break;
            default:
                this.setState({ error: errorMessage });
        }
    }    
    };

    handlePasswordRecovery = async (e) => {
      e.preventDefault();
      const { email } = this.state;
      let params = {
        "receiver_email": email,
      };

      try{
        let res = await usersService.recoverPassword(params);
        if (res.status === 200) {
          this.setState({ action: "passwordrequest" });
        }
      }
      catch(err){
        console.error(err);
        this.setState({ error: err.response.data.error });
      }
    };
  
    handleChange = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };

    render = () => <AppLoginScreen _this={this}/>

}

export default AppLogin;