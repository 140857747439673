import { store } from '../../../app/store';
import * as dateService from "../../../Utils/format/date";
import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { RequestsScreen } from './requestsScreen';
import requestsService from '../../../Services/requestsService';
import activitiesService from '../../../Services/activitiesService';
import usersService from '../../../Services/usersService';
import { useDispatch } from 'react-redux';
import {startLoading, stopLoading} from '../../../Components/Loader/actions';
import user from '../../../app/slices/user/user';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Modal } from "bootstrap";
import { set } from 'firebase/database';

const Requests = props => {
    const location = useLocation();
    const workspace = store.getState().workspace.id;
    const pageSize = 5;
    const role = props.user.role;
    const [loadData, setLoadData] = useState(true);
    const [activeTab, setActiveTab] = useState(location.state ? location.state.tab : '1');
    const [requests, setRequests] = useState([]);
    const [allRequests, setAllRequests] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [changePage, setChangePage] = useState(false);
    const [search, setSearch] = useState("");
    const [objectType, setObjectType] = useState(location.state ? location.state.filter : 'all');
    const [clear, setClear] = useState(false);
    const [tab, setTab] = useState("inbox");
    const [checked, setChecked] = useState(new Set());
    const [checkedDiscarded, setCheckedDiscarded] = useState(new Set());
    const [intentsSelected, setIntentsSelected] = useState(0);
    const [entitiesSelected, setEntitiesSelected] = useState(0);
    const [categoriesSelected, setCategoriesSelected] = useState(0);
    const [checkboxValue, setCheckboxValue] = useState(location.state ? location.state.checkbox : '1');
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const handleCheck = (request) => {
        if (objectType !== "discarded") {
            setChecked(prevChecked => {
                const newChecked = new Set(prevChecked);
                if (newChecked.has(request.id)) {
                    newChecked.delete(request.id);
                    switch (request.object_type) {
                        case "intent":
                            setIntentsSelected(intentsSelected - 1);
                            break;
                        case "entity":
                            setEntitiesSelected(entitiesSelected - 1);
                            break;
                        case "category":
                            setCategoriesSelected(categoriesSelected - 1);
                            break;
                        default:
                            break;
                    }
                } else {
                    newChecked.add(request.id);
                    switch (request.object_type) {
                        case "intent":
                            setIntentsSelected(intentsSelected + 1);
                            break;
                        case "entity":
                            setEntitiesSelected(entitiesSelected + 1);
                            break;
                        case "category":
                            setCategoriesSelected(categoriesSelected + 1);
                            break;
                        default:
                            break;
                    }
                }
                return newChecked;
            });
        }
        else {
            setCheckedDiscarded(prevChecked => {
                const newChecked = new Set(prevChecked);
                if (newChecked.has(request.id)) {
                    newChecked.delete(request.id);
                } else {
                    newChecked.add(request.id);
                }
                return newChecked;
            });
        }
    };

    const validateRequests = async () => {
        const params = {
            user_id: props.user.id,
            workspace_id: workspace,
        }
        const body = {
            request_ids: Array.from(checked),
        }
        try{
            let res = await requestsService.validateMany(params, body);
            console.log("res", res);
            if (res.status === 200){
                setChecked(new Set());
                setIntentsSelected(0);
                setEntitiesSelected(0);
                setCategoriesSelected(0);
                handleTabChange('1');
            }
        }
        catch(err){
            console.log(err);
        }
    }

    const recoverRequests = async () => {
        const params = {
            user_id: props.user.id,
            workspace_id: workspace,
        }
        const body = {
            request_ids: Array.from(checkedDiscarded),
        }
        console.log("body", body);
        try{
            let res = await requestsService.recover(params, body);
            console.log("res", res);
            if (res.status === 200){
                setCheckedDiscarded(new Set());
                selectRequests(1);
            }
        }
        catch(err){
            console.log(err);
        }
    }

    const discardAllSuggestions = async () => {
        const params = {
            user_id: props.user.id,
            workspace_id: workspace,
        }
        try{
            let res = await requestsService.discardAllSuggestions(params);
            console.log("res", res);
            if (res.status === 200){
                setChecked(new Set());
                setIntentsSelected(0);
                setEntitiesSelected(0);
                setCategoriesSelected(0);
                selectRequests(5);
            }
        }
        catch(err){
            console.log(err);
        }
    }
  
    const handleSearch = (e) => {
      setSearch(e);
    };

    const searchReq = () => {
        getRequests();
    };
  
    const handleRequestSelect = (e) => {
      const tempRequests = selectRequests(e, allRequests);
      setRequests(tempRequests);
    };
  
    useEffect(() => { 
      if (loadData) {
        getPageRequests(parseInt(location.state ? location.state.tab : '1', 10)).then((result) => {
          setLoadData(false);
        });
      }
    }, [loadData, allRequests]);
  
    const handleTabChange = (tab) => {
      if (activeTab !== tab) {
        setActiveTab(tab);
        setPage(1);
        getPageRequests(tab);
      }
    };

    const getPageRequests = async (tab) => {
        if (tab == '1') {
            console.log("inbox")
            setTab("inbox");
        }
        if (tab == '2') {
            console.log("ongoing")
            setTab("ongoing");
        }
        if (tab == '3') {
            console.log("closed")
            setTab("closed");
        }
        if (tab == '4') {
            console.log("automatic")
            setTab("automatic");
        };
        setObjectType("all");
        setCheckboxValue('1');
        setSearch("");
    }

    const getRequests = async () => {
        dispatch(startLoading());
        console.log("start loading")
        var params = {
            user_id: props.user.id,
            workspace_id: workspace,
            page: page-1,
            limit: pageSize,
            search: search,
            object_type: objectType,
            tab: tab,
        };
        try {
            console.log("params", params);
            let res = await requestsService.collect_many_with_details(params);
            console.log("res", res);
            
            let tempRequests = res.data.requests || [];
            for (let i = 0; i < tempRequests.length; i++) {
                tempRequests[i] = getContentFlags(tempRequests[i]);
            }
            const numTempRequests = res.data.total || 0;
    
            pageSize < numTempRequests
                ? setTotalPages(Math.ceil(numTempRequests / pageSize))
                : setTotalPages(1);
            console.log(Math.ceil(numTempRequests / pageSize))
    
            setPage(page);
            setRequests(tempRequests);
            setAllRequests(tempRequests);
                
        } catch (err) {
            console.log(err);
            setRequests([]);
            setAllRequests([]);
        }
        dispatch(stopLoading());
    };

    const getContentFlags = (request) => {
    request.flags = {};
    if (request?.is_rejected){
        request.flags.rejected = true;
    }
    //console.log("request", request.changes[0])
    if (request.changes[0] === "delete"){
        //console.log("deleting")
        request.flags.deleting = true;
    }
    else if (request.object_id === null || request.object_id === undefined){
        request.flags.newContent = true;
    }
    if (request.status === "discarded"){
        request.flags.discarded = true;
    }
    else{
        switch (request.object_type) {
            case "intent": {
            if (
                request.changes.find(change => change.field === "examples" && change.operation == "create")
            ) {
                request.flags.added = true;
            }
            if (
                request.changes.find(change => change.field === "examples" && change.operation == "delete")
            ) {
                request.flags.deleted = true;
            }
            if (
                request.changes.find(change => change.field === "name" && change.operation == "update") ||
                request.changes.find(change => change.field === "description" && change.operation == "update")
            ) {
                request.flags.edited = true;
            }
            break;
            }
            case "entity": {
            if (
                request.changes.find(change => change.field === "synonyms" && change.operation == "create") ||
                request.changes.find(change => change.field === "category" && change.operation == "create")
            ) {
                request.flags.added = true;
            }
            if (
                request.changes.find(change => change.field === "synonyms" && change.operation == "delete") ||
                request.changes.find(change => change.field === "category" && change.operation == "delete")
            ) {
                request.flags.deleted = true;
            }
            if (
                request.changes.find(change => change.field === "name" && change.operation == "update") ||
                request.changes.find(change => change.field === "description" && change.operation == "update")
            ) {
                request.flags.edited = true;
            }
            break;
            }
            case "category": {
            if (
                request.changes.find(change => change.field === "name" && change.operation == "update") ||
                request.changes.find(change => change.field === "description" && change.operation == "update")
                ) {
                request.flags.edited = true;
            }
            break;
            }
            default: {
            break;
            }
        }
    }
    return request;
    };

    const selectRequests = (e) => {
        if (e == 1) {
            setObjectType("all");
            setCheckboxValue('1');
        } else if (e == 2) {
            setObjectType("intent");
            setCheckboxValue('2');
        } else if (e == 3) {
            setObjectType("entity");
            setCheckboxValue('3');
        } else if (e == 4) {
            setObjectType("category");
            setCheckboxValue('4');
        }
        else if (e == 5) {
            setObjectType("discarded");
            setCheckboxValue('5');
        }
        setPage(1);
    };

    useEffect(() => {
        console.log("ead")
        if (!loadData) {
            getRequests();
        }
    }, [objectType, page]);

    const handleClear = () => {
        setClear(!clear);
    }

    useEffect(() => {
        if (!loadData) {
            getRequests();
        }
    }, [clear]);

    useEffect(() => {
        getRequests();
    }, [tab]);

    const searchRequests = (e) => {
        handleClear();
        setSearch(e);
    };

    const handlePageChange = (page) => {
        setPage(page);
};

    return <RequestsScreen _this={{
    props,
    workspace,
    activeTab,
    requests,
    allRequests,
    changePage,
    page,
    dateService,
    totalPages,
    handleSearch,
    handleRequestSelect,
    handleTabChange,
    getPageRequests,
    getRequests,
    getContentFlags,
    selectRequests,
    searchRequests,
    handlePageChange,
    searchReq,
    handleCheck,
    checked,
    intentsSelected,
    entitiesSelected,
    categoriesSelected,
    validateRequests,
    objectType,
    checkedDiscarded,
    recoverRequests,
    discardAllSuggestions,
    checkboxValue,
    }}/>
};

const mapStateToProps = state => {
    return {
        workspace: state.workspace,
        user: state.user,
    };
};

export default connect(mapStateToProps)(Requests);
